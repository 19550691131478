import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import _ from 'lodash'
import {
  AuthError,
  cancelGetPhysicianSearch,
  getPhysicianSearch
} from '../../../services/ApiService'
import {
  PhysiciansSummaryDto,
  PhysiciansSummarySearchDto
} from '../../../types/swaggerTypes'
import { useLocation } from 'react-router-dom'
import { ClinTheme } from '../../../ClinTheme'
import { AxiosError } from 'axios'
import { ClinText } from '../../../components/ClinText'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { IPhysicianAutoSuggestResultProps } from './PhysicianAutoSuggest.model'
import {
  StyledPhysicianAutoSuggestResultItem,
  StyledPhysicianAutoSuggestWrapper,
  StyledPhysicianAutoSuggestLink
} from './PhysicianAutoSuggest.styles'
import { useAppContext } from '../../../context/app'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinButton } from '../../../components/ClinButton'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import i18n from '../../../i18n/config'
export interface ISearchStylesProps {
  /** Show suggestions below search */
  showSuggestions?: boolean
}
const defaultSearchParams: PhysiciansSummarySearchDto = {
  query: '',
  filter: {
    programs: [],
    patientStatuses: []
  },
  pagination: {
    skip: 0,
    take: 5
  },
  sorting: {
    sortBy: '',
    order: SortDirectionType.Ascending
  }
}

interface IPhysicianAutoSuggestContainerProps {
  /** Amount of debounce time in milliseconds */
  debounceTimeMs?: number
  /** Number of results to display */
  maxResultsToDisplay?: number
  /** Search component to pass through */
  searchComponent(props: IPhysicianAutoSuggestResultProps): React.ReactNode
  /** Call back to broadcast onChange */
  handleOnChange?: (query: string) => void
  /** Call back to broadcast onClick */
  handleOnSelect: (suggestion: PhysiciansSummaryDto) => void
  /** Call back request to add a new physician */
  handleRequestToAddPhysician: () => void
}

export const PhysicianAutoSuggest: FunctionComponent<
  IPhysicianAutoSuggestContainerProps
> = ({
  searchComponent,
  debounceTimeMs = 500,
  handleOnChange,
  handleOnSelect,
  maxResultsToDisplay = 5,
  handleRequestToAddPhysician
}: IPhysicianAutoSuggestContainerProps) => {
  const { t } = i18n
  const { dispatch } = useAppContext()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [query, setQuery] = useState<string | undefined>()
  const [suggestion, setSuggestion] = useState<
    PhysiciansSummaryDto | undefined
  >()
  const [suggestions, setSuggestions] = useState<PhysiciansSummaryDto[]>([])
  const [highlightedIndex, setHighlightedIndex] = useState(-1)

  const resetAll = () => {
    setSuggestion(undefined)
    setSuggestions([])
    setQuery('')
    setHighlightedIndex(0)
    setIsLoading(false)
    setShowSuggestions(false)
  }

  // Debounced call to API to request suggestions
  const requestSuggestions = useCallback(
    (queryStr: string) => {
      if (!queryStr || queryStr === '') {
        resetAll()
        return
      }
      setIsLoading(true)
      cancelGetPhysicianSearch()
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.SubmitSearchQuery, {
        query: queryStr,
        searchLocation: 'physicianAutosuggest',
        searchAPI: 'suggest'
      })
      getPhysicianSearch({ ...defaultSearchParams, query: queryStr })
        .then((results) => {
          setIsLoading(false)
          setHighlightedIndex(-1)
          setSuggestions(results.data.result.slice(0, maxResultsToDisplay))
          setShowSuggestions(
            results.data.result.length > 0 && queryStr.length > 0
          )
          if (queryStr && queryStr !== '') {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.ResultsForSearchQuery,
              { query: queryStr, numberResults: results.data.result.length }
            )
          }
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching suggestions. ${error.message} ${
                error.code ? error.code : ''
              }`
            )
          )
          setShowSuggestions(false)
        })
    },
    [dispatch, maxResultsToDisplay]
  )

  // Debounced call to API to request suggestions
  const debounceEventHandler = useMemo(
    () =>
      _.debounce(
        (searchString: string) => requestSuggestions(searchString),
        debounceTimeMs
      ),
    [requestSuggestions, debounceTimeMs]
  )

  // Method to provide the rendering cell for returned suggestions
  const renderSuggestions = (
    items: PhysiciansSummaryDto[]
  ): React.ReactNode | React.ReactNode[] => {
    const { t } = i18n
    let results
    if (items && items.length === 0 && !isLoading) {
      return (
        <>
          <StyledPhysicianAutoSuggestResultItem
            tabIndex={0}
            key={'no-results-title'}
            style={{
              color: ClinTheme.colors.black,
              fontSize: ClinTheme.fontSizes[2]
            }}
          >
            {t('enrol_physician_on_to_program:no_matches_found')}
          </StyledPhysicianAutoSuggestResultItem>
          <StyledPhysicianAutoSuggestLink
            tabIndex={0}
            key={'no-results'}
            selected={highlightedIndex === suggestions.length}
            style={{
              fontWeight: ClinTheme.fontWeights.medium,
              fontSize: ClinTheme.fontSizes[2],
              color: ClinTheme.colors.primaryLight
            }}
            onClick={() => handleRequestToAddPhysician()}
          >
            {t('enrol_physician_on_to_program:physician_not_listed')}
          </StyledPhysicianAutoSuggestLink>
        </>
      )
    }
    if (items && items.length > 0) {
      results = items.map((suggestion, index) => {
        return (
          <StyledPhysicianAutoSuggestResultItem
            key={`${suggestion.physicianFullName}-${index}`}
            tabIndex={0}
            selected={highlightedIndex === index}
            onClick={() => handleSelect(index)}
          >
            <ClinText
              wordBreak="break-all"
              variant={TypographyVariant.LargeParagraph}
              fontWeight={ClinTheme.fontWeights.medium}
            >
              {`${suggestion.physicianTitle ?? ''} ${
                suggestion.physicianFirstName
              } ${suggestion.physicianLastName}`}
            </ClinText>
            <ClinText
              wordBreak="break-all"
              variant={TypographyVariant.LargeParagraph}
            >
              {suggestion.physicianEmail}
            </ClinText>
          </StyledPhysicianAutoSuggestResultItem>
        )
      })
      results.push(
        <StyledPhysicianAutoSuggestLink
          tabIndex={0}
          key={'no-results'}
          selected={highlightedIndex === suggestions.length}
          style={{
            fontWeight: ClinTheme.fontWeights.medium,
            fontSize: ClinTheme.fontSizes[2],
            color: ClinTheme.colors.primaryLight
          }}
          onClick={() => handleRequestToAddPhysician()}
        >
          {t('enrol_physician_on_to_program:physician_not_listed')}
        </StyledPhysicianAutoSuggestLink>
      )
    }
    return results
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const UP = 'ArrowUp'
    const DOWN = 'ArrowDown'
    const ENTER = 'Enter'
    const ESCAPE = 'Escape'
    const TAB = 'Tab'
    const INITIAL_IDX = 0

    if (e.key === ESCAPE) {
      resetAll()
    }
    if (e.key === DOWN || e.key === TAB) {
      const idx = highlightedIndex
      const nextIdx = idx !== undefined ? idx + 1 : INITIAL_IDX

      if (nextIdx < suggestions.length) {
        setHighlightedIndex(nextIdx)
      } else {
        setHighlightedIndex(INITIAL_IDX)
      }
    }
    if (e.key === UP || (e.shiftKey && e.key === TAB)) {
      const lastIdx = suggestions.length - 1
      const idx = highlightedIndex
      const prevIdx = idx !== undefined ? idx - 1 : lastIdx

      if (prevIdx >= 0) {
        setHighlightedIndex(prevIdx)
      } else {
        setHighlightedIndex(lastIdx)
      }
    }
    if (e.key === ENTER && highlightedIndex >= 0) {
      handleSelect && handleSelect(highlightedIndex)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const queryStr = e.target.value
    setQuery(queryStr)
    debounceEventHandler(queryStr)
    handleOnChange && handleOnChange(queryStr)
  }

  const handleSubmitPhysician = () => {
    suggestion && handleOnSelect(suggestion)
    setQuery('')
    setSuggestion(undefined)
  }

  const handleSelect = (selectedIndex: number) => {
    if (suggestions[selectedIndex]) {
      setQuery(
        `${suggestions[selectedIndex].physicianTitle ?? ''} ${
          suggestions[selectedIndex].physicianFirstName
        } ${suggestions[selectedIndex].physicianLastName}`
      )
      setShowSuggestions(false)
      setSuggestion(suggestions[selectedIndex])
    }
  }

  // Detect outside click
  const parentNode = useRef<HTMLDivElement>(null)
  const handleClickedOutside = (event: MouseEvent) => {
    if (parentNode?.current?.contains(event.target as Node)) {
      // inside click
      return
    }
    // outside click - clear results
    setShowSuggestions(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickedOutside)
    return () => document.removeEventListener('click', handleClickedOutside)
  })

  useEffect(() => {
    resetAll()
    return () => cancelGetPhysicianSearch()
  }, [location.pathname])

  return (
    <StyledPhysicianAutoSuggestWrapper ref={parentNode}>
      {searchComponent({
        query,
        isLoading,
        showSuggestions: showSuggestions,
        suggestions,
        renderSuggestions,
        handleChange,
        handleKeyDown
      } as IPhysicianAutoSuggestResultProps)}
      <ClinButton
        tabIndex={0}
        onClick={() => handleSubmitPhysician()}
        variant="primary"
        disabled={!suggestion}
      >
        {t('enrol_physician_on_to_program:add_physician')}
      </ClinButton>
    </StyledPhysicianAutoSuggestWrapper>
  )
}
