import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import React from 'react'

export interface IClinDropdownMenuStyles
  extends React.HTMLAttributes<HTMLDivElement> {
  /** Define the top margin for an offset */
  marginTop?: string | number
}

export const StyledClinDropdownMenu = styled.div<IClinDropdownMenuStyles>(
  {
    display: 'inline-block',
    padding: ClinTheme.space[3],
    backgroundColor: ClinTheme.colors.white,
    borderRadius: ClinTheme.radii[1],
    boxShadow: ClinTheme.shadows[0]
  },
  ({ marginTop = 0 }: IClinDropdownMenuStyles) => ({
    marginTop
  })
)
