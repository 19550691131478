import { IIndexable } from '../../types'
import { AnalyticsEvent } from './AnalyticsEvent'

/**
 *  A function that returns a boolean when the provided path contain an exempt page name
 * @param path
 * @returns boolean
 * @usage:
 * const isExempt:boolean = isPathExempt(path)
 */
const isPathExempt = (path: string): boolean => {
  return (
    path.includes('/orders') ||
    path.includes('/order') ||
    path.includes('/products/catalogue') ||
    path.includes('/product/') ||
    path.includes('/products/catalogue/') ||
    (path.includes('/products/catalogue/') &&
      !path.includes('register-interest') &&
      !path.includes('documentation')) ||
    path.includes('/access-programs') ||
    path.includes('/patient-access-form') ||
    path.includes('/my-physicians') ||
    path.includes('/opa-checkout')
  )
}
/**
 * AnalyticsService
 * Use for tracking page views, events and errors
 * Register each analytics provider with its custom implementation of each method call
 * Any new provider must implement IAnalyticsProvider interface
 *
 * @usage Use the AnalyticsHelper component outside the switch statement of the router
 * <main role="main">
 *   <AnalyticsHelper />
 *   <Switch>
 *     <Route path="/" exact component={LogIn} />
 *
 * To instantiate the service with any number of providers
 * const mp = new MixPanelProvider()
 * mp.tokenId = config.mixPanelToken
 * analyticsService.registerProvider('MixPanel', mp)
 *
 * const gap = new GoogleAnalyticsManagerProvider()
 * gap.tokenId = config.gaId
 * analyticsService.registerProvider('GoogleAnalyticsManagerProvider',gap)
 * etc...
 *
 * Then call init on all providers
 * analyticsService.initProviders()
 *
 */

export interface IAnalyticsProvider extends IIndexable {
  tokenId: string
  initProvider(): void
  trackEvent(event: AnalyticsEvent, properties?: object): void
  trackPageView(path: string, properties?: object, isManual?: boolean): void
  trackError(error: Error): void
}

class AnalyticsService implements IAnalyticsProvider {
  /** A dictionary of all providers with their name as key */
  providers: { [key: string]: IAnalyticsProvider } = {}
  /** Show logging for all events */
  enableDebugLogging: boolean
  /** Send tracking data */
  sendTrackingData: boolean
  /** The token id for the current service */
  tokenId: string = ''
  /** The users preferred language */
  language: string = ''
  /** The user's current institute Id **/
  instituteId: string = ''
  /** The user's current Version, Global or AUSGA */
  platformVersion: string = ''

  constructor(enableDebugLogging: boolean, sendTrackingData: boolean) {
    this.enableDebugLogging = enableDebugLogging
    this.sendTrackingData = sendTrackingData
    !this.sendTrackingData &&
      !this.isRunningWithJest() &&
      console.warn(`Analytics disabled!`)
  }

  registerProvider = (providerName: string, provider: IAnalyticsProvider) => {
    if (!this.sendTrackingData) {
      return
    }
    this.logEvent(`AnalyticsService registerProvider ${providerName}`)
    this.providers[providerName] = provider
  }

  initProviders = () => {
    this.logEvent(`AnalyticsService initProvider`)
    for (const provider of Object.keys(this.providers)) {
      const providerInstance: IAnalyticsProvider = this.providers[provider]
      providerInstance && providerInstance.initProvider()
    }
  }

  initProvider = () => {
    // Not implemented in base class
  }

  trackEvent = (event: AnalyticsEvent, properties?: { [key: string]: any }) => {
    this.logEvent(
      `AnalyticsService trackEvent: ${event}, ${JSON.stringify(properties)}`
    )

    for (const provider of Object.keys(this.providers)) {
      this.providers[provider].trackEvent(event, {
        ...properties,
        language: this.language,
        instituteId: this.instituteId,
        platformVersion: this.platformVersion
      })
    }
  }

  /* 
  This function is called on every route change to track the page path. However, some pages require unique names and properties to be submitted,
  so within this function we check to see if the current path is exempt. If the path is exempt this means that this page requires a manual submittion 
  from within the page container itself. If the path isn't exempt it will simply be submitted using the trackPageView function.
  */
  trackPageView = (path: string, properties?: object) => {
    let doNotSkipEvent = !(!properties && isPathExempt(path))

    doNotSkipEvent &&
      this.logEvent(
        `AnalyticsService trackPageView: ${path} ${
          properties
            ? JSON.stringify(
                {
                  ...properties,
                  language: this.language,
                  instituteId: this.instituteId,
                  platformVersion: this.platformVersion
                },
                null,
                0
              )
            : null
        }`
      )
    for (const provider of Object.keys(this.providers)) {
      doNotSkipEvent &&
        this.providers[provider].trackPageView(path, {
          ...properties,
          language: this.language,
          instituteId: this.instituteId,
          platformVersion: this.platformVersion
        })
    }
  }

  trackError(error: Error) {
    this.logEvent(`AnalyticsService trackError: ${error.message}`)
    for (const provider of Object.keys(this.providers)) {
      this.providers[provider].trackError(error)
    }
  }

  isRunningWithJest = () => {
    return process.env.JEST_WORKER_ID !== undefined
  }

  logEvent = (message: string) => {
    this.enableDebugLogging && !this.isRunningWithJest() && console.log(message)
  }
}

export default AnalyticsService
