import { AxiosError } from 'axios'
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react'
import { RouteComponentProps } from 'react-router'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { AppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import {
  activateUser,
  addContact,
  AuthError,
  cancelActivateUser,
  cancelGetSpecialisms,
  getSpecialisms
} from '../../../services/ApiService'
import {
  CreateContactRequestDto,
  SpecialismDto
} from '../../../types/swaggerTypes'
import { InviteNewUser } from './InviteNewUser'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { UserRoleRecord, UserRole } from '../../../constants'
interface IInviteNewUserRouteParams {
  contactId: string
}
interface IInviteNewUserProps
  extends RouteComponentProps<IInviteNewUserRouteParams> {}

export const InviteNewUserContainer: FunctionComponent<IInviteNewUserProps> = ({
  match,
  history
}) => {
  const { contactId } = match.params
  const { dispatch, userRole } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [specialisms, setSpecialisms] = useState<SpecialismDto[]>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submittedUser, setSubmittedUser] = useState<CreateContactRequestDto>()

  const userRoleTypes =
    userRole && UserRoleRecord[userRole as UserRole].isMaUser
      ? [UserRole.BASIC_MA, UserRole.SUPER_MA]
      : [UserRole.BASIC, UserRole.SUPER]

  const handleFormSubmission = (data: CreateContactRequestDto) => {
    setIsSubmitting(true)
    addContact(data)
      .then((response) => {
        if (response.status === 200) {
          setSubmittedUser(data)
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.InviteUserInstitute
          )
        }
      })
      .catch((error: AxiosError) => {
        const { code, message } = error

        if (error.response) {
          const { title, detail } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error submitting new contact. ${
                title ? title : ''
              } ${message ? message : ''}`
            )
          )
          console.warn(title, detail)
        } else {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error submitting new contact. ${message} ${
                code ? code : ''
              }`
            )
          )
        }
        window.scrollTo(0, 0)
        setIsSubmitting(false)
      })
  }

  useEffect(() => {
    submittedUser &&
      submittedUser.emailAddress &&
      activateUser({ email: submittedUser.emailAddress })
        .then((response) => {
          if (response.status === 200) {
            history.push(
              {
                pathname: '/institute/details'
              },
              {
                title: 'Your invitation has been sent',
                message: 'You can view the status of their invitation below.'
              }
            )
          }
        })
        .catch((error: AxiosError) => {
          dispatch(createAnnounceEvent(AnnounceMode.Error, `${error.message}`))
        })
        .finally(() => {
          window.scrollTo(0, 0)
          setIsSubmitting(false)
        })
  }, [dispatch, history, submittedUser])

  useEffect(() => {
    setIsLoading(true)
    getSpecialisms()
      .then((response) => {
        setIsLoading(false)
        setSpecialisms(response.data.specialisms)
      })
      .catch((error: AxiosError) => {
        const { code, message } = error
        // If request is cancelled continue
        if (error.message === AuthError.RequestCancelled) {
          return
        }
        // If we have a full error show it
        if (error.response) {
          const { title, detail } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error retrieving specialisms. ${
                title ? title : ''
              } ${message ? message : ''}`
            )
          )
          console.warn(title, detail)
        } else {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error retrieving specialisms. ${message} ${
                code ? code : ''
              }`
            )
          )
        }
        setIsSubmitting(false)
        window.scrollTo(0, 0)
      })
  }, [contactId, dispatch])

  const handleCancel = () => {
    history.push('/institute/details')
  }

  useEffect(() => {
    return () => {
      cancelGetSpecialisms()
      cancelActivateUser()
    }
  }, [])

  return (
    <InviteNewUser
      isLoading={isLoading}
      specialisms={specialisms}
      roleTypes={userRoleTypes}
      isSubmitting={isSubmitting}
      handleFormSubmission={handleFormSubmission}
      handleCancel={handleCancel}
    />
  )
}
