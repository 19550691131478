import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

export interface StyledClinTableOrderToggleProps {
  /** Hide the toggle icon altogether */
  hideSortIcon?: boolean
}
export const StyledClinTableOrderToggle =
  styled.div<StyledClinTableOrderToggleProps>(
    {
      display: 'inline-flex',
      alignItems: 'center',
      userSelect: 'none',
      height: ClinTheme.space[6],
      color: ClinTheme.colors.primary,
      fontSize: ClinTheme.fontSizes[1],
      fontWeight: ClinTheme.fontWeights.medium,
      fontFamily: ClinTheme.fonts.body,
      letterSpacing: ClinTheme.letterSpacing[1],
      textTransform: 'uppercase',
      transition: '.2s linear box-shadow',
      ':focus': {
        outline: 'none',
        boxShadow: `0 0 0 2px ${ClinTheme.colors.midGrey}`
      }
    },
    ({ hideSortIcon }) => ({
      cursor: hideSortIcon ? 'default' : 'pointer'
    })
  )
