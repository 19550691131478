import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  DrugProperty,
  drugPropertyTitleAndIconRecord
} from '../../services/DrugPropertyProvider'
import { OrderLineDto } from '../../types/swaggerTypes'
import {
  StyledSummaryItemProperties,
  StyledSummaryOrderItem,
  StyledSummaryOrderItemValue,
  StyledSummaryOrderMobileLabel,
  StyledSummaryProperty,
  StyledSummaryPropertyDetail
} from './CheckoutSummaryOrderItem.styles'
import {
  IAllHoldsInformation,
  IOrderLineHoldDocument
} from '../../services/Holds/holds.types'
import { StyledRequiredDocument } from './CheckoutSummary.styles'
import { HoldAnnounce } from '../../features/HoldAnnounce'
import { HoldAnnounceMode } from '../../features/HoldAnnounce/HoldAnnounce'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { useTranslation } from 'react-i18next'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { useAppContext } from '../../context/app'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { TFunction } from 'i18next'

const getDrugPropertyItem = (
  drugProperty: DrugProperty,
  children: React.ReactNode,
  transFn: TFunction
) => {
  const { iconName, translationKey } =
    drugPropertyTitleAndIconRecord[drugProperty]
  return (
    <StyledSummaryProperty>
      <ClinIcon
        iconSize={ClinTheme.fontSizes[5]}
        iconFill={ClinTheme.colors.primary}
        iconName={iconName}
      />
      <StyledSummaryPropertyDetail>
        <ClinText
          fontWeight={ClinTheme.fontWeights.medium}
          color={ClinTheme.colors.primary}
        >
          {transFn(translationKey)}
        </ClinText>

        <ClinText color={ClinTheme.colors.primary}>{children}</ClinText>
      </StyledSummaryPropertyDetail>
    </StyledSummaryProperty>
  )
}

interface ICheckoutSummaryOrderItemProps {
  /** The current order line item */
  orderLine: OrderLineDto
  /** Any holds information for this line item */
  holdsInformation: IAllHoldsInformation
  /** Any holds information for this line item */
  orderLineHoldDocuments: IOrderLineHoldDocument[]
  /** Currency code */
  currencyCode: string
  /** Array of line items that have been cancelled */
  cancelledItemIds: number[]
  /** Callback to cancel a line item */
  handleCancelOrderLine?: (itemId: number) => void
}

export const CheckoutSummaryOrderItem: FunctionComponent<
  ICheckoutSummaryOrderItemProps
> = ({
  holdsInformation,
  orderLine,
  orderLineHoldDocuments,
  currencyCode,
  cancelledItemIds = [],
  handleCancelOrderLine
}) => {
  const { portfolioCountryCode } = useAppContext()

  const isAus = portfolioCountryCode === CountryAlphaCodes.Australia
  const { t } = useTranslation()

  const determineEmergencyStatement = () => {
    if (
      orderLine &&
      orderLine?.holds[0]?.holdDocuments[0]?.customJson &&
      orderLine?.holds[0]?.holdDocuments[0]?.customJson ===
        '{"radioOptionForHold":"Emergency Statement"}'
    ) {
      return true
    }
    return false
  }
  return (
    <StyledSummaryOrderItem>
      <ClinSpacer height={ClinTheme.space[3]} />
      <Row>
        <Col sm={12} md={6}>
          <StyledSummaryOrderItemValue>
            <StyledSummaryOrderMobileLabel>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                as="p"
                variant={TypographyVariant.Paragraph}
              >
                {t('glossary:product')}
              </ClinText>
            </StyledSummaryOrderMobileLabel>

            <Link to={`/product/sku/${orderLine.skuCatalogItem?.sku}`}>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.bold}
                as="p"
                fontSize={ClinTheme.fontSizes[3]}
              >
                <u>
                  {orderLine.skuCatalogItem?.item.genericDosageConcatenation}
                </u>
              </ClinText>
            </Link>
          </StyledSummaryOrderItemValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledSummaryOrderItemValue>
            <StyledSummaryOrderMobileLabel>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                as="p"
                variant={TypographyVariant.Paragraph}
              >
                {t('glossary:product_code')}
              </ClinText>
            </StyledSummaryOrderMobileLabel>
            <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
              {orderLine.skuCatalogItem?.sku}
            </ClinText>
          </StyledSummaryOrderItemValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledSummaryOrderItemValue>
            <StyledSummaryOrderMobileLabel>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                as="p"
                variant={TypographyVariant.Paragraph}
              >
                {t('glossary:quantity')}
              </ClinText>
            </StyledSummaryOrderMobileLabel>
            <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
              {orderLine.quantities.ordered}
            </ClinText>
          </StyledSummaryOrderItemValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledSummaryOrderItemValue>
            <StyledSummaryOrderMobileLabel>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                as="p"
                variant={TypographyVariant.Paragraph}
              >
                {t('glossary:amount')}
              </ClinText>
            </StyledSummaryOrderMobileLabel>
            <ClinText
              as="p"
              className="sub-total"
              variant={TypographyVariant.LargeParagraph}
            >
              {numberToCurrencyString(
                orderLine.totals.subTotal,
                getBrowserLocale(),
                {
                  style: 'currency',
                  currency: currencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }
              )}
            </ClinText>
          </StyledSummaryOrderItemValue>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      {!isAus && (
        <Row>
          <Col sm={12} md={2}>
            <ClinGroup alignItems="flex-end" justifyContent="flex-start">
              <ClinText as="p" color={ClinTheme.colors.primary}>
                {t('checkout_summary:status')}
              </ClinText>
              <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                {orderLine.status}
              </ClinText>
            </ClinGroup>
          </Col>
        </Row>
      )}
      {/*<pre>{JSON.stringify(holdsInformation, null, 2)}</pre>*/}
      {holdsInformation &&
        !isAus &&
        holdsInformation.holdsDetails.map((hold, index) => (
          <Row key={`${hold.type}-${index}`}>
            <Col sm={12} md={8}>
              <ClinSpacer height={ClinTheme.space[3]} />
              <HoldAnnounce
                mode={HoldAnnounceMode.Error}
                title={hold.title}
                message={hold.message.replace(
                  '{genericName}',
                  orderLine.skuCatalogItem?.item.genericDosageConcatenation
                    ? orderLine.skuCatalogItem.item.genericDosageConcatenation
                    : t('checkout_summary:drug_name')
                )}
              />
            </Col>
          </Row>
        ))}
      <ClinSpacer height={ClinTheme.space[3]} />
      <Row>
        <Col sm={12} md={9} lg={isAus ? 12 : 8}>
          <ClinAccordion>
            <ClinAccordionItem
              title={
                isAus
                  ? t('checkout_summary:product_details_with_ampersand')
                  : t('checkout_summary:product_details_title')
              }
            >
              <StyledSummaryItemProperties>
                <Row>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.DosageAndPacksize,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {
                          orderLine.skuCatalogItem?.item
                            .genericDosageConcatenation
                        }
                        &nbsp;
                        {orderLine.skuCatalogItem?.item.packSize}
                      </ClinText>,
                      t
                    )}
                  </Col>

                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.CountryOfLicense,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {orderLine.skuCatalogItem?.licenses
                          .map((license) => license.country)
                          .join(', ')}
                      </ClinText>,
                      t
                    )}
                  </Col>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.Brand,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {orderLine.skuCatalogItem?.item.brand}
                      </ClinText>,
                      t
                    )}
                  </Col>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.Overlabelled,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {orderLine &&
                        orderLine.skuCatalogItem?.item.overlabelled === 'Y'
                          ? `${orderLine.skuCatalogItem?.item.overlabelLanguage} (${orderLine.skuCatalogItem?.item.overlabelType})`
                          : t('glossary:no')}
                      </ClinText>,
                      t
                    )}
                  </Col>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.UnitPrice,
                      <ClinText
                        as="span"
                        className="unit-price"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {numberToCurrencyString(
                          orderLine.unitSellingPrice,
                          getBrowserLocale(),
                          {
                            style: 'currency',
                            currency: currencyCode,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )}
                      </ClinText>,
                      t
                    )}
                  </Col>

                  {isAus && orderLine.deliveryText && (
                    <Col sm={12} md={6}>
                      {getDrugPropertyItem(
                        DrugProperty.Delivery,
                        <ClinText
                          as="span"
                          variant={TypographyVariant.LargeParagraph}
                        >
                          {orderLine.deliveryText}
                        </ClinText>,
                        t
                      )}
                    </Col>
                  )}
                </Row>
              </StyledSummaryItemProperties>
              <ClinSpacer hasBorder={true} />
              <ClinSpacer />
              <ClinText
                color={ClinTheme.colors.primary}
                fontSize={ClinTheme.fontSizes[3]} // TODO: Font size needs addressing client request this to be smaller than h4
              >
                {t('checkout_summary:supporting_documents_title')}
              </ClinText>
              {orderLineHoldDocuments.length > 0 ? (
                orderLineHoldDocuments.map(
                  ({ holdDocumentName, documentProvided }, index) =>
                    holdDocumentName && (
                      <StyledRequiredDocument
                        key={`${holdDocumentName}-${index}`}
                      >
                        <ClinGroup
                          justifyContent="space-between"
                          display={isAus ? 'block' : 'flex'}
                        >
                          <Row
                            style={{
                              display: 'flex',
                              flexFlow: 'nowrap',
                              alignItems: 'center',
                              gap: '22px',
                              margin: '0'
                            }}
                          >
                            {isAus && (
                              <ClinIcon
                                iconSize={'27px'}
                                iconName={ClinIconPathName.Document}
                              ></ClinIcon>
                            )}

                            <ClinText
                              display="flex"
                              color={
                                isAus
                                  ? ClinTheme.colors.primary
                                  : ClinTheme.colors.black
                              }
                              fontWeight={
                                isAus
                                  ? ClinTheme.fontWeights.medium
                                  : ClinTheme.fontWeights.normal
                              }
                              variant={
                                isAus
                                  ? TypographyVariant.Paragraph
                                  : TypographyVariant.LargeParagraph
                              }
                              width={isAus ? '100%' : 'auto'}
                              lineHeight={isAus ? '29px' : '1.5'}
                            >
                              {holdDocumentName === 'SAS hold'
                                ? determineEmergencyStatement()
                                  ? t('basket:checklist_for_aus_hold.option_2')
                                  : t('basket:aus_documentation_sas_option')
                                : holdDocumentName}
                            </ClinText>
                          </Row>
                          <Row
                            style={{
                              margin: isAus ? '0 0 0 48px' : '0'
                            }}
                          >
                            <ClinText
                              fontWeight={ClinTheme.fontWeights.bold}
                              color={
                                determineEmergencyStatement()
                                  ? ClinTheme.colors.greenValid
                                  : documentProvided
                                  ? ClinTheme.colors.greenValid
                                  : ClinTheme.colors.redInvalid
                              }
                              marginBottom={'0px'}
                              variant={TypographyVariant.LargeParagraph}
                            >
                              {determineEmergencyStatement()
                                ? t('checkout_summary:provided')
                                : documentProvided
                                ? t('checkout_summary:provided')
                                : t('checkout_summary:not_provided')}
                            </ClinText>
                          </Row>
                        </ClinGroup>
                      </StyledRequiredDocument>
                    )
                )
              ) : (
                <>
                  <ClinText>
                    {t('checkout_summary:no_supporting_documents')}
                  </ClinText>
                </>
              )}
            </ClinAccordionItem>
          </ClinAccordion>
        </Col>
        {/*<Col*/}
        {/*  sm={12}*/}
        {/*  md={3}*/}
        {/*  lg={4}*/}
        {/*  style={{ display: 'flex', alignItems: 'flex-end' }}*/}
        {/*>*/}
        {/*  <StyledSummaryOrderLineDelete>*/}
        {/*    <ClinButton*/}
        {/*      className="delete-orderline-btn"*/}
        {/*      disabled={*/}
        {/*        orderLine.lineId*/}
        {/*          ? cancelledItemIds.indexOf(orderLine.lineId) > -1*/}
        {/*          : false*/}
        {/*      }*/}
        {/*      onClick={() =>*/}
        {/*        handleCancelOrderLine &&*/}
        {/*        orderLine.lineId &&*/}
        {/*        handleCancelOrderLine(orderLine.lineId)*/}
        {/*      }*/}
        {/*      justifyContent="center"*/}
        {/*      variant="link"*/}
        {/*    >*/}
        {/*      Cancel Item*/}
        {/*      <ClinIcon*/}
        {/*        style={{ marginLeft: ClinTheme.space[2] }}*/}
        {/*        iconSize={ClinTheme.fontSizes[2]}*/}
        {/*        iconName={ClinIconPathName.Cross}*/}
        {/*      />*/}
        {/*    </ClinButton>*/}
        {/*  </StyledSummaryOrderLineDelete>*/}
        {/*</Col>*/}
      </Row>

      <ClinSpacer height={ClinTheme.space[2]} />
    </StyledSummaryOrderItem>
  )
}
