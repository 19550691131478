import {
  PatientDetailsDto,
  PatientProgramDto,
  PatientVialDto,
  ProgramCatalogDto,
  ShortOrderSummaryDto
} from '../../../types/swaggerTypes'
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'
import { ClinText } from '../../../components/ClinText'
import { ClinTheme } from '../../../ClinTheme'
import { DateTime } from 'luxon'
import { numberToCurrencyString } from '../../../utils/numberToCurrencyString'
import { ClinTableToggleRowButton } from '../../../components/ClinTableToggleRowButton'
import { convertDateStringToDay } from '../../../utils/dateUtils'
import {
  cancelGetProgramFromSearchIndexById,
  cancelGetReadOnlyOpaUrl,
  getPatientDetail,
  getProgramFromSearchIndexById,
  getReadOnlyOpaUrl
} from '../../../services/ApiService'
import {
  IAugmentOrdersResponse,
  IPastOrderSummary,
  IPatientVial,
  IProgramResponse
} from './PatientDetail.types'
import { AppEvent, useAppContext } from '../../../context/app'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { IPagination } from '../../../components/ClinPagination/ClinPagination.model'
import { sortByDateFn } from '../../../utils/sortBy'
import { ProgramCountryStatus } from '../../../constants'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import { VialStatus } from '../../../features/VialReconciliationTable/VialReconcileRow'
import { TFunction } from 'i18next'
import { OrderStatus } from '../../../types/OrderStatus'
import {
  OPAOrderTypes,
  PatientOrderStatus
} from '../PatientDashboard/Patient.model'

export enum PatientTab {
  PatientDetails,
  OrderHistory
}

export enum OrderHistoryColumn {
  OrderReference = 'Order Reference',
  DateSubmitted = 'Date Submitted',
  Status = 'Status',
  TotalCost = 'Total Cost',
  VialReconciliationToggle = ''
}

/**
 * The sort order to list orders in
 */
export const statusOrdered = [
  'Submitted',
  'Processing',
  'OnHold',
  'Shipped',
  'Delivered',
  'Cancelled'
]

export const sortByStatusAndDate = (
  a: ShortOrderSummaryDto,
  b: ShortOrderSummaryDto
): number => {
  const sortedStatusA = statusOrdered.indexOf(a.orderStatus)
  const sortedStatusB = statusOrdered.indexOf(b.orderStatus)
  // Sort by date if status is the same
  if (sortedStatusA === sortedStatusB) {
    return new Date(b.orderedDate).getTime() - new Date(a.orderedDate).getTime()
  }
  // Otherwise sort by status
  return sortedStatusA > sortedStatusB ? 1 : -1
}

/**
 * Only orders that have been shipped or delivered or In transit or Out for Delivery or Failed attempt
 * Returns in sorted order
 * @param pastOrders
 * @param supportsReconciliation
 */
export const getCompletedOrdersSorted = (
  pastOrders?: ShortOrderSummaryDto[],
  supportsReconciliation?: boolean
) => {
  if (pastOrders) {
    return supportsReconciliation
      ? pastOrders
          .filter(
            (o) =>
              o.orderStatus === OrderStatus.Shipped ||
              o.orderStatus === OrderStatus.Delivered ||
              o.orderStatus === OrderStatus.InTransit ||
              o.orderStatus === OrderStatus.OutForDelivery ||
              o.orderStatus === OrderStatus.FailedAttempt ||
              o.orderStatus === OrderStatus.Processing
          )
          .sort(sortByStatusAndDate)
      : pastOrders.sort(sortByStatusAndDate)
  }
  return []
}

export const getCellContent = (
  column: OrderHistoryColumn,
  order: IPastOrderSummary,
  handleToggleRow?: (orderNumber: number) => void
): ReactNode | string => {
  const {
    orderNumber,
    orderedDate,
    orderStatus,
    orderTotal,
    currencyCode,
    isOrderToggledOpen,
    canBeReconciled
  } = order
  switch (column) {
    case OrderHistoryColumn.OrderReference:
      return (
        <ClinText fontSize={16} color={ClinTheme.colors.primaryMid}>
          {orderNumber}
        </ClinText>
      )
    case OrderHistoryColumn.DateSubmitted:
      return DateTime.fromISO(orderedDate, {zone: 'utc'}).toLocaleString()
    case OrderHistoryColumn.Status:
      return orderStatus
    case OrderHistoryColumn.TotalCost:
      return (
        <span className="order-total">
          {numberToCurrencyString(orderTotal, getBrowserLocale(), {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </span>
      )
    case OrderHistoryColumn.VialReconciliationToggle:
      return canBeReconciled ? (
        <ClinTableToggleRowButton
          isToggled={isOrderToggledOpen}
          onClick={() => handleToggleRow && handleToggleRow(orderNumber)}
        />
      ) : null
    default:
      return 'Unknown type'
  }
}

export const getColumnWidthForColumn = (column: OrderHistoryColumn): string => {
  switch (column) {
    case OrderHistoryColumn.OrderReference:
      return '20%'
    case OrderHistoryColumn.DateSubmitted:
      return '20%'
    case OrderHistoryColumn.Status:
      return '20%'
    case OrderHistoryColumn.TotalCost:
      return '15%'
    case OrderHistoryColumn.VialReconciliationToggle:
      return '5%'
  }
}

/**
 * Translate the column titles
 * @param column
 * @param transFn
 */
export const getColumnTitleTranslatedForColumn = (
  column: OrderHistoryColumn,
  transFn: TFunction
): string => {
  // Convert Column into snake case Order Reference to order_reference
  const key = column.toLowerCase().split(' ').join('_')
  return transFn(`patient_detail:column_titles.${key}`)
}

export const getVialsToReconcileForOrder = (
  orderNumber: number,
  vials: PatientVialDto[]
): PatientVialDto[] =>
  vials.filter((order) => order.orderNumber === orderNumber)

export const isProgramClosed = (program: PatientProgramDto) =>
  program.status?.toUpperCase() ===
  ProgramCountryStatus.Closed.toString().toUpperCase()

/**
 * Display an announce for vial reconciliation
 * @param totalVialsToReconcile
 * @param dispatch
 * @param isInstituteTransfer
 */
export const displayReconcileAnnounce = (
  totalVialsToReconcile: number,
  dispatch: (event: AppEvent) => void,
  isOtherInstituteTransfer: boolean = false
) => {
  const isWarning = totalVialsToReconcile > 0
  const title = isWarning ? 'Product reconciliation required' : 'Complete'
  const message = isWarning
    ? `You have ${totalVialsToReconcile} ${
        totalVialsToReconcile === 1 ? 'product' : 'products'
      } that ${
        totalVialsToReconcile === 1 ? 'needs' : 'need'
      } to be reconciled before you can ${
        isOtherInstituteTransfer ? '' : 'resupply, discontinue or'
      } transfer a patient to another institute.`
    : `All products have been reconciled.`
  const announceMode = isWarning ? AnnounceMode.Warning : AnnounceMode.Success
  dispatch(createAnnounceEvent(announceMode, message, title))
}

/**
 * Custom hook to augment past orders and vials
 * @param patientId
 * @param physicianId
 * @param setPagination
 * @param setHasReconciliation
 * @param dispatch
 */
export const useAugmentedOrders = (
  patientId: string,
  physicianId: string,
  setPagination: Dispatch<SetStateAction<IPagination>>,
  setHasReconciliation: Dispatch<SetStateAction<boolean>>,
  dispatch: (event: AppEvent) => void
): IAugmentOrdersResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [patient, setPatient] = useState<PatientDetailsDto | undefined>(
    undefined
  )
  const [isProgramClosed, setIsProgramClosed] = useState<boolean>(false)
  const [allowsDiscontinuation, setAllowsDiscontinuation] =
    useState<boolean>(false)
  const [augmentedOrders, setAugmentedOrders] = useState<IPastOrderSummary[]>(
    []
  )
  const getPatient = useCallback(() => {
    setIsLoading(true)
    getPatientDetail(patientId, physicianId)
      .then((response) => {
        return response.data
      })
      .then((patient) => {
        let canBeDiscontinued = true
        patient.pastOrders.map((p: ShortOrderSummaryDto) => {
          if (
            p.orderStatus === 'Submitted' ||
            p.orderStatus === 'Processing' ||
            p.orderStatus === 'On Hold'
          ) {
            canBeDiscontinued = false
          }
        })
        // https://clinigen.atlassian.net/browse/CLOS-7254 | CLOS-7384
        canBeDiscontinued = ![
          PatientOrderStatus.IncompleteCheckout,
          PatientOrderStatus.UnderMedicalReview
        ].includes(patient.patientOrderStatus as PatientOrderStatus)
        // https://clinigen.atlassian.net/browse/CLOS-7254 | CLOS-7384

        setPatient(patient)
        setIsProgramClosed(patient.program.status === 'Closed')
        // Get program data
        getProgramFromSearchIndexById(
          patient.program.programId.toString()
        ).then((response) => {
          // Check if program supports reconciliation
          const programSupportsReconciliation =
            response.data.program?.supportsReconcilliation === 'Yes' ||
            response.data.program?.supportsReconcilliation === 'Y'
          setHasReconciliation(programSupportsReconciliation)
          // Check if program supports discontinuation
          const programSupportsDiscontinuation =
            (response.data.program?.supportsDiscontinuation === 'Yes' ||
              response.data.program?.supportsDiscontinuation === 'Y') &&
            canBeDiscontinued

          const statusPreventDicontinue = [
            PatientOrderStatus.UnderMedicalReview,
            PatientOrderStatus.IncompleteCheckout
          ].includes(patient.patientOrderStatus as PatientOrderStatus)
          setAllowsDiscontinuation(
            programSupportsDiscontinuation && !statusPreventDicontinue
          )

          const hideDiscontinued =
            [
              PatientOrderStatus.BeginOrder,
              PatientOrderStatus.IncompletePAF,
              PatientOrderStatus.IncompleteCheckout
            ].includes(patient.patientOrderStatus as PatientOrderStatus) &&
            patient.orderType === OPAOrderTypes.InitialOrder

          const allowsDiscontinuation =
            (response.data.program?.supportsDiscontinuation === 'Yes' ||
              response.data.program?.supportsDiscontinuation === 'Y') &&
            canBeDiscontinued &&
            !hideDiscontinued
          setAllowsDiscontinuation(allowsDiscontinuation)
          // Augment past orders
          const augmentedPastOrders = augmentPastOrdersForProgramId(
            programSupportsReconciliation,
            patient
          )
          setAugmentedOrders(augmentedPastOrders)
          // Functionally update pagination (to avoid dependency warning)
          setPagination((previousPagination) => {
            return {
              ...previousPagination,
              total: augmentedPastOrders.length,
              skip: 0
            }
          })
          setIsLoading(false)
        })
      })
      .catch((error) => {
        setIsLoading(false)
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `There was an error getting vial reconciliation data. ${error}`
          )
        )
      })
  }, [dispatch, patientId, physicianId, setHasReconciliation, setPagination])

  useEffect(() => {
    if (patientId && physicianId) {
      // Get patient data
      getPatient()
    }
  }, [getPatient, patientId, physicianId])

  return {
    isLoading,
    augmentedOrders,
    patient,
    isProgramClosed,
    allowsDiscontinuation,
    getPatient
  }
}

/**
 * Augment the past orders to show information we need for view:
 * @param programSupportsReconciliation
 * @param PatientDetailsDto (pastOrders, vials)
 */
export const augmentPastOrdersForProgramId = (
  programSupportsReconciliation: boolean,
  { pastOrders, vials }: PatientDetailsDto
): IPastOrderSummary[] => {
  // Make a copy of pastOrders before we mutate it
  const pastOrdersClone = pastOrders.map((x) => Object.assign({}, x))
  // Get only completed orders (status is 'Shipped' or 'Delivered' and sort by date)
  const completedOrders = getCompletedOrdersSorted(
    pastOrdersClone,
    programSupportsReconciliation
  )
  // Loop through and add extra information (isToggled) etc
  return completedOrders
    .map((order) => {
      // Get matching vials for that order
      const vialsToReconcile = vials.filter(
        (vial) => vial.orderNumber === order.orderNumber
      )
      // Gets the date or today's date
      const dayOrderShipped = convertDateStringToDay(order.shippedDate)
      // Convert vials to correct DTO
      const vialsToReconcileRequest: IPatientVial[] = vialsToReconcile.map(
        ({ batchNumber, unit, vialStatus }) => {
          return {
            batchNumber,
            dateAdministered: undefined,
            dateAdministeredFormatted: undefined,
            vialNumber: unit,
            vialStatus,
            isDirty: false,
            isSaving: false,
            orderNumber: order.orderNumber,
            wasReconciled: false
          }
        }
      )
      return {
        ...order,
        canBeReconciled: programSupportsReconciliation,
        dayOrderShipped,
        vialsToReconcile: vialsToReconcileRequest,
        isOrderToggledOpen: vialsToReconcile.length > 0
      }
    })
    .sort(sortByVialReconciliationAndDate)
    .sort(sortByStatusAndDate)
}

export const sortByVialReconciliationAndDate = (
  a: IPastOrderSummary,
  b: IPastOrderSummary
) => {
  // Sort by ordered date if vial reconciliation is the same
  if (a.canBeReconciled === b.canBeReconciled) {
    return new Date(b.orderedDate).getTime() - new Date(a.orderedDate).getTime()
  }
  // Otherwise sort by vial reconciliation
  return a.canBeReconciled === b.canBeReconciled
    ? 0
    : a.canBeReconciled
    ? -1
    : 1
}

/**
 * Custom hook to augment past orders and vials
 * @param programId
 */
export const useProgram = (programId?: number): IProgramResponse => {
  const { dispatch } = useAppContext()
  const [isProgramLoading, setIsProgramLoading] = useState<boolean>(true)
  const [program, setProgram] = useState<ProgramCatalogDto | undefined>(
    undefined
  )
  useEffect(() => {
    setIsProgramLoading(true)
    programId &&
      getProgramFromSearchIndexById(programId.toString())
        .then((response) => {
          setProgram(response.data)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the program detail. ${error}`
            )
          )
        })
        .finally(() => setIsProgramLoading(false))

    return () => {
      cancelGetProgramFromSearchIndexById()
    }
  }, [dispatch, programId])

  return {
    isProgramLoading,
    program
  }
}

/*
 * Provide a read only patient access form
 * @param patient
 * @param dispatch
 */
export const useReadOnlyPatientAccessForm = (
  dispatch: (event: AppEvent) => void,
  patient?: PatientDetailsDto
) => {
  const [isLoadingPatientAccessForm, setIsLoadingPatientAccessForm] =
    useState(false)
  const [patientAccessFormUrl, setPatientAccessFormUrl] = React.useState<
    string | undefined
  >()

  useEffect(() => {
    if (patient && patient.pastOrders.length) {
      // Get the most recent order and token
      const sortedPastOrders = patient?.pastOrders.sort((a, b) =>
        sortByDateFn(a.orderedDate, b.orderedDate)
      )[0]
      if (
        sortedPastOrders &&
        sortedPastOrders.orderNumber &&
        sortedPastOrders.opaAccessToken &&
        sortedPastOrders.opaAccessToken.length
      ) {
        setIsLoadingPatientAccessForm(true)
        getReadOnlyOpaUrl(sortedPastOrders.orderNumber, {
          opaAccessToken: sortedPastOrders.opaAccessToken
        })
          .then((response) => {
            if (response.data.endPoint) {
              setPatientAccessFormUrl(response.data.endPoint)
            }
          })
          .catch((error) => {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error retrieving your latest patient access form. ${error}`
              )
            )
          })
          .finally(() => setIsLoadingPatientAccessForm(false))
      }
    }
    return () => {
      cancelGetReadOnlyOpaUrl()
    }
  }, [dispatch, patient])

  return {
    isLoadingPatientAccessForm,
    patientAccessFormUrl
  }
}

/**
 * Calculate how many vials need reconciling
 * @param vials
 */
export const getNumberOfVialsToReconcile = (
  augmentedOrders: IPastOrderSummary[]
): number => {
  const vialsToReconcile =
    augmentedOrders &&
    augmentedOrders.reduce((acc: number, order: IPastOrderSummary) => {
      return (
        acc +
        order.vialsToReconcile.filter(
          (v) => v.vialStatus === VialStatus.ToBeAdministered
        ).length
      )
    }, 0)
  return vialsToReconcile
}

export const getRandomColor = (): string => {
  const randomIndex = Math.floor(Math.random() * colors.length)
  return colors[randomIndex]
}

const colors = [
  '#FFBDA2',
  '#80D091',
  '#9BE7E4',
  '#8C88FF',
  '#9DCDE8',
  '#E8AEFF'
]
