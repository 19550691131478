import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import {
  NewFeatureTooltip,
  NewFeatureTooltipButtonsWrapper,
  NewFeatureTooltipContent,
  NewFeatureTooltipHeader,
  NewFeatureTooltipXButton,
  NewFeatureTooltipXButton1,
  NewFeatureTooltipXButton2
} from './ClinNewFeatureTooltipContent.styles'
import { ClinText } from '../../ClinText'
import { TypographyVariant } from '../../ClinText/ClinText.styles'
import { ClinTheme } from '../../../ClinTheme'
import {
  StyledClinNewFeatureText,
  StyledNextButton
} from '../ClinNewFeatureTooltip.styles'

interface INewFeatureTooltipProps {
  id?: string
  title: string
  description: string
  hideCloseButton?: boolean
  isCancelled?: boolean
  style?: object
  //total number of new features on page
  total?: number
  //number of current new feature on page
  current?: number
  closeTooltipHandler?: (isCancelled?: boolean) => void
}

export const ClinNewFeatureTooltipContent: FunctionComponent<
  INewFeatureTooltipProps
> = ({
  id,
  title,
  description,
  total,
  current,
  isCancelled,
  closeTooltipHandler,
  hideCloseButton,
  style
}) => {
  return (
    <NewFeatureTooltip style={style}>
      <NewFeatureTooltipHeader>
        {title && (
          <StyledClinNewFeatureText>{t(title)}</StyledClinNewFeatureText>
        )}
        {!hideCloseButton && (
          <NewFeatureTooltipXButton
            onClick={() => closeTooltipHandler && closeTooltipHandler(true)}
            id={id}
          >
            <NewFeatureTooltipXButton1></NewFeatureTooltipXButton1>
            <NewFeatureTooltipXButton2></NewFeatureTooltipXButton2>
          </NewFeatureTooltipXButton>
        )}
      </NewFeatureTooltipHeader>

      <NewFeatureTooltipContent>
        <StyledClinNewFeatureText>{t(description)}</StyledClinNewFeatureText>
      </NewFeatureTooltipContent>
      <NewFeatureTooltipButtonsWrapper
        justifyContent={current && total ? 'space-between' : 'flex-end'}
      >
        {total && current && (
          <ClinText
            variant={TypographyVariant.Paragraph}
            color={ClinTheme.colors.white}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            marginTop={'0px'}
            marginBottom={'0px'}
          >
            {`${current}/${total}`}
          </ClinText>
        )}
        {!isCancelled && (
          <StyledNextButton
            variant="link"
            onClick={() => closeTooltipHandler && closeTooltipHandler(false)}
          >
            {current && total && current !== total
              ? t('common:buttons.next')
              : t('common:buttons.got_it')}
          </StyledNextButton>
        )}
      </NewFeatureTooltipButtonsWrapper>
    </NewFeatureTooltip>
  )
}
