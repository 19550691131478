import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { DrugRequestForm } from '../../features/DrugRequestForm'
import { CreateSourcingRequestDto } from '../../types/swaggerTypes'
import { useTranslation } from 'react-i18next'
import { IDrugRequestFormState } from '../../features/DrugRequestForm/DrugRequestForm.model'
import { UserRole, isAusGaUser } from '../../constants'

interface ISourcingEnquiryProps {
  /** Form submission handler */
  handleFormSubmission: (data: CreateSourcingRequestDto) => void
  /** Default state */
  defaultDrugProperties?: IDrugRequestFormState
  /** Show an error if the submission API request failed */
  errorMessage?: string | null
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** User country*/
  userCountry: string
  /** User role*/
  userRole?: UserRole
}

export const SourcingEnquiry: FunctionComponent<ISourcingEnquiryProps> = ({
  handleFormSubmission,
  defaultDrugProperties,
  isSubmitting,
  errorMessage,
  userCountry,
  userRole
}) => {
  const { t } = useTranslation()
  return (
    <ClinPageContentFrame
      crumbs={[
        isAusGaUser(userCountry, userRole)
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        {
          path: '/sourcing-enquiry',
          name: t('sourcing_enquiry:title')
        }
      ]}
    >
      <Row>
        <Col xs={12} lg={9}>
          <Row>
            <Col xs={12}>
              <ClinText
                as="h1"
                variant={TypographyVariant.H2}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {t('sourcing_enquiry:title')}
              </ClinText>
              <ClinSpacer height={ClinTheme.space[2]} />
              <ClinText
                marginBottom={'0px'}
                variant={TypographyVariant.LargeParagraph}
              >
                {t('sourcing_enquiry:description')}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[4]} />
          <DrugRequestForm
            defaultState={defaultDrugProperties}
            handleFormSubmission={handleFormSubmission}
            isSubmitting={isSubmitting}
            errorMessage={errorMessage}
            variant="sourcingEnquiry"
          />
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
