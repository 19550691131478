import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  decodeObjToUrlParams,
  getParamValueFor,
  IPagination,
  useUpdateQueryParams
} from '../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import {
  FacetDto,
  FieldFacetsDto,
  PatientSummaryDto,
  PatientSummarySearchDto
} from '../../types/swaggerTypes'
import {
  getSortFieldForColumn,
  AssociatedPatientsColumn,
  getColumnForSortField,
  getUpdatedFiltersForTag,
  PatientFilterType
} from './AssociatedPatients.model'
import { $enum } from 'ts-enum-util'
import {
  cancelGetFacetsForPatients,
  cancelGetPatients,
  cancelGetPhysicians,
  getFacetsForPatients,
  getPatients
} from '../../services/ApiService'
import { useAppContext } from '../../context/app'
import { AxiosError } from 'axios'
import { AssociatedPatients } from './AssociatedPatients'
import { useErrorMessage } from '../../utils/useErrorMessage'
import { useOnMount } from '../../utils/useOnMount'
import { sortOptions } from '../../pages/Patients/Patients.model'

const rowsPerPage: number = 5

const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: rowsPerPage,
  total: 0
}

interface IAssociatedPatientsProps {
  physicianId: number
  preselectedPatientStatusFilters: FacetDto[] | undefined
}

export const AssociatedPatientsContainer: FunctionComponent<
  IAssociatedPatientsProps
> = ({ physicianId, preselectedPatientStatusFilters }) => {
  const location = useLocation()
  const history = useHistory()

  const defaultSearchParams: PatientSummarySearchDto = 
  {
    query: '',
    filter: {
      programs: [],
      patientOrderStatuses: [],
      physicianId: Number(physicianId)
    },
    pagination: {
      skip: 0,
      take: rowsPerPage
    },
    sorting: {
      sortBy: sortOptions[0].optionId,
      order: SortDirectionType.Ascending
    }
  }
  const handleError = useErrorMessage(
    'There was an error fetching patients.',
    true
  )
  const { dispatch } = useAppContext()

  // Restore from query params
  const urlSearchParams = new URLSearchParams(location.search)
  const patientQuery = urlSearchParams.get('q')
  const [initialQuery] = React.useState<string>(patientQuery || '')

  // Restore page Size
  const [patientsPerPage, setPatientsPerPage] = React.useState<number>(() => {
    const previousPageSize = urlSearchParams.get('pageSize')
    return previousPageSize ? parseInt(previousPageSize) : rowsPerPage
  })

  // Restore pagination from URL params
  defaultSearchParams.pagination.take = getParamValueFor(
    'pageSize',
    urlSearchParams,
    rowsPerPage
  )
  // PageIndex
  defaultPagination.take = defaultSearchParams.pagination.take
  defaultSearchParams.pagination.skip =
    getParamValueFor('pageIndex', urlSearchParams) *
    defaultSearchParams.pagination.take

  const [pagination, setPagination] =
    React.useState<IPagination>(defaultPagination)

  // Restore sort direction from URL params
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(
    () => {
      const previousSortDirection = urlSearchParams.get('patientsSortDirection')
      const restoredSortDirection = $enum(SortDirectionType).asValueOrDefault(
        previousSortDirection,
        SortDirectionType.Ascending
      )
      defaultSearchParams.sorting.order = restoredSortDirection.toString()
      return restoredSortDirection
    }
  )

  // Restore sort by column from URL params and update query
  const [sortColumn, setSortColumn] = React.useState<AssociatedPatientsColumn>(
    () => {
      const sortByColumn = urlSearchParams.get('sortBy')
      const previousSortBy = sortByColumn && decodeURIComponent(sortByColumn)
      const defaultSortByColumn = getColumnForSortField(
        defaultSearchParams.sorting.sortBy
      )
      const restoredSortByColumn = $enum(
        AssociatedPatientsColumn
      ).asValueOrDefault(previousSortBy, defaultSortByColumn)
      defaultSearchParams.sorting.sortBy =
        getSortFieldForColumn(restoredSortByColumn) || ''
      return restoredSortByColumn
    }
  )

  const [searchParams, setSearchParams] =
    React.useState<PatientSummarySearchDto>({
      ...defaultSearchParams,
      query: initialQuery
    })

  const [programFilters, setProgramFilters] = React.useState<FacetDto[]>([])
  const [patientStatusFilters, setPatientStatusFilters] = React.useState<
    FacetDto[]
  >([])

  // Restore applied/selected filters
  const [selectedProgramFilters, setSelectedProgramFilters] = React.useState<
    FacetDto[]
  >(() => {
    let previousSelectedFilters: FacetDto[] = []
    const appliedFilters = urlSearchParams.get('selectedProgramFilters')
    if (appliedFilters) {
      previousSelectedFilters = decodeObjToUrlParams(appliedFilters)
      defaultSearchParams.filter.programs = [
        ...previousSelectedFilters.map((f) => f.value)
      ]
    }
    return previousSelectedFilters
  })
  const [selectedPatientStatusFilters, setSelectedPatientStatusFilters] =
    React.useState<FacetDto[]>(() => {
      let previousSelectedFilters: FacetDto[] = []
      const appliedFilters = urlSearchParams.get('selectedPatientStatusFilters')
      if (appliedFilters) {
        previousSelectedFilters = decodeObjToUrlParams(appliedFilters)
        defaultSearchParams.filter.patientOrderStatuses = [
          ...previousSelectedFilters.map((f) => f.value)
        ]
      }
      return previousSelectedFilters
    })

  const handleFilterToggle = (tag: FacetDto, filterGroupTitle: string) => {
    cancelGetPatients()
    setIsPatientsLoading(true)
    const isPatientStatusFilter =
      filterGroupTitle === PatientFilterType.Status.toString()
    const updatedFilters = getUpdatedFiltersForTag(
      tag,
      isPatientStatusFilter
        ? selectedPatientStatusFilters
        : selectedProgramFilters
    )

    let updateSearchParams: PatientSummarySearchDto

    if (isPatientStatusFilter) {
      updateSearchParams = {
        ...searchParams,
        pagination: {
          take: searchParams.pagination.take,
          skip: 0
        },
        filter: {
          ...searchParams.filter,
          patientOrderStatuses: [...updatedFilters.map((f) => f.value)]
        }
      }
      setSelectedPatientStatusFilters(updatedFilters)
    } else {
      updateSearchParams = {
        ...searchParams,
        pagination: {
          ...searchParams.pagination,
          skip: 0
        },
        filter: {
          ...searchParams.filter,
          programs: [...updatedFilters.map((f) => f.value)]
        }
      }
      setSelectedProgramFilters(updatedFilters)
    }

    setSearchParams(updateSearchParams)
  }

  const handleClearFilters = () => {
    cancelGetPatients()
    setIsPatientsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip: 0
      },
      filter: {
        programs: [],
        patientOrderStatuses: []
      }
    })
    setSelectedProgramFilters([])
    setSelectedPatientStatusFilters([])
  }

  const [patients, setPatients] = React.useState<
    PatientSummaryDto[] | undefined
  >(undefined)
  const [isPatientsLoading, setIsPatientsLoading] = useState(true)
  const [isFiltersLoading, setIsFiltersLoading] = useState(true)

  const toggleSortDirection = (
    current: SortDirectionType
  ): SortDirectionType => {
    if (current === SortDirectionType.None) {
      return SortDirectionType.Descending
    }
    if (current === SortDirectionType.Descending) {
      return SortDirectionType.Ascending
    }
    return SortDirectionType.None
  }

  const handleRowClicked = (patientId: number) => {
    cancelGetPatients()
    history.push(`/programs/my-physicians/${physicianId}/${patientId}`, {
      from: window.location.pathname
    })
  }

  const handleToggleSort = (columnName: AssociatedPatientsColumn) => {
    cancelGetPhysicians()
    setIsPatientsLoading(true)
    const newSortDirection =
      sortColumn === columnName
        ? toggleSortDirection(sortDirection)
        : SortDirectionType.Descending
    setSortDirection(newSortDirection)
    setSortColumn(columnName)
    const sortField = getSortFieldForColumn(columnName) || ''
    setSearchParams({
      ...searchParams,
      sorting: {
        sortBy: sortField,
        order: newSortDirection
      }
    })
  }

  const handleOnSearch = (query: string) => {
    cancelGetPatients()
    setIsPatientsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip: 0
      },
      query
    })
  }

  const handlePageClicked = (selectedPageIndex: number) => {
    cancelGetPhysicians()
    setIsPatientsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * patientsPerPage,
        take: patientsPerPage
      }
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    cancelGetPhysicians()
    setIsPatientsLoading(true)
    setPatientsPerPage(pageSize)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }

  useOnMount(() => {
    getFacetsForPatients(physicianId)
      .then((response) => {
        // programs
        const programFacets: FieldFacetsDto | undefined =
          response?.data.facets.find(
            (f: FieldFacetsDto) => f.field === 'Program Name'
          )
        let values = programFacets?.values.map((f) => f)
        values && setProgramFilters(values)

        // patientStatuses
        const patientStatusFacets: FieldFacetsDto | undefined =
          response?.data.facets.find(
            (f: FieldFacetsDto) => f.field === 'Patient Status'
          )
        values = patientStatusFacets?.values.map((f) => f)
        values && setPatientStatusFilters(values)
      })
      .catch((error: AxiosError) => {
        handleError(error)
      })
      .finally(() => setIsFiltersLoading(false))
    return () => {
      cancelGetFacetsForPatients()
    }
  })

  // Get patients
  useEffect(() => {
    // searchParams and pagination changes then make call
    getPatients({
      ...searchParams,
      sorting: {
        sortBy: sortOptions[0].optionId,
        order: SortDirectionType.Ascending.toUpperCase()
      }
    })
      .then((response) => {
        if (response) {
          setIsPatientsLoading(false)
          setPatients(response.data.result)
          const paginationDto = response.data.pagination
          setPagination({
            count: paginationDto.count,
            skip: paginationDto.skip,
            take: paginationDto.take,
            total: paginationDto.total
          })
        }
      })
      .catch((error: AxiosError) => {
        handleError(error)
      })

    return () => {
      cancelGetPatients()
    }
  }, [searchParams, location, history, physicianId, dispatch, handleError])

  // update filters value using derived prop preselectedPatientStatusFilters
  useEffect(() => {
    preselectedPatientStatusFilters &&
      setSelectedPatientStatusFilters(preselectedPatientStatusFilters)
    setSearchParams({
      ...defaultSearchParams,
      filter: {
        programs: [],
        patientOrderStatuses:
          preselectedPatientStatusFilters?.map((facet) => facet.value) || [],
        physicianId: physicianId
      },
      sorting:{
        sortBy: sortOptions[0].optionId,
        order: SortDirectionType.Ascending
      }
    })
  }, [preselectedPatientStatusFilters, physicianId])

  useUpdateQueryParams([
    { q: searchParams.query },
    { selectedProgramFilters },
    { selectedPatientStatusFilters },
    { sortBy: sortColumn.toString() },
    { patientsSortDirection: sortDirection },
    { pageSize: searchParams.pagination.take },
    { pageIndex: searchParams.pagination.skip / searchParams.pagination.take }
  ])

  return (
    <AssociatedPatients
      patients={patients}
      initialQuery={searchParams.query}
      isLoading={isPatientsLoading || isFiltersLoading}
      columns={[
        AssociatedPatientsColumn.PatientNumber,
        AssociatedPatientsColumn.Initials,
        AssociatedPatientsColumn.DOB,
        AssociatedPatientsColumn.ProgramName,
        AssociatedPatientsColumn.HospitalRefNumber,
        AssociatedPatientsColumn.Status
      ]}
      pagination={pagination}
      programFilters={programFilters}
      selectedProgramFilters={selectedProgramFilters}
      patientStatusFilters={patientStatusFilters}
      selectedPatientStatusFilters={selectedPatientStatusFilters}
      selectedSortColumn={sortColumn}
      selectedSortDirection={sortDirection}
      handleRowClicked={handleRowClicked}
      handleToggleSort={handleToggleSort}
      handlePageClicked={handlePageClicked}
      handlePageSizeChange={handlePageSizeChange}
      handleFilterToggle={handleFilterToggle}
      handleClearFilters={handleClearFilters}
      handleOnSearch={handleOnSearch}
    />
  )
}
