import React, { FunctionComponent } from 'react'
import { StyledButtonContainer } from './BookmarkedConfirmation.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinText } from '../../components/ClinText'
import { useTranslation } from 'react-i18next'

interface IBookmarkedConfirmationProps {
  closeConfirmation?: () => void
}

export const BookmarkedConfirmation: FunctionComponent<
  IBookmarkedConfirmationProps
> = ({ closeConfirmation }) => {
  const { t } = useTranslation()
  return (
    <>
      <ClinText fontSize={ClinTheme.fontSizes[2]}>
        {t('product_variants:bookmark_helper')}
      </ClinText>
      <StyledButtonContainer>
        <ClinButton variant="ghost" onClick={closeConfirmation}>
          {t('product_variants:bookmark_helper_confirm')}
        </ClinButton>
      </StyledButtonContainer>
    </>
  )
}
