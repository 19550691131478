import React, { FunctionComponent } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { ClinDropdown } from '../../components/ClinDropdown'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { IDefaultStyleProps } from '../../types'
import {
  ICustomSelectStyles,
  StyledCustomSelect,
  StyledCustomSelectOption,
  StyledCustomSelectPhysicianOptions,
  StyledPhysicianCustomSelectTrigger,
  StyledPhysicianCustomSelectTriggerInner
} from './CustomSelect.styles'
import { t } from 'i18next'
import { ClinButton } from '../../components/ClinButton'
import { useHistory } from 'react-router'
import { ClinSpinner } from '../../components/ClinSpinner'

export interface ICustomSelectOption {
  /** Used as a unique reference */
  id: number | string
  /** Used to display the value in the view */
  value: string
  /** Used to display whether the option is active */
  isActive?: boolean
}

interface ICustomPhysiciansSelectProps
  extends IDefaultStyleProps,
    ICustomSelectStyles {
  /** Label to sit above the dropdown */
  label?: string
  /** Enables an array of options to be passed in to the dropdown*/
  options?: ICustomSelectOption[]
  /** Displays the selected value within the drop down */
  selectedOption?: ICustomSelectOption
  /** Placeholder if no value is selected */
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  /** The label to display below the input */
  prompt?: string
  programId?: number
  /** Enables a function to be called when an option is selected */
  handleSelect: (option: ICustomSelectOption) => void
}

export const CustomPhysiciansSelect: FunctionComponent<
  ICustomPhysiciansSelectProps
> = ({
  label,
  options,
  selectedOption,
  hasError,
  prompt,
  placeholder = 'Please select',
  disabled,
  programId,
  isLoading,
  handleSelect
}) => {
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    option: ICustomSelectOption
  ) => {
    handleSelect && event.keyCode === 13 && handleSelect(option)
  }

  const history = useHistory()
  const handleEnrollPhysician = () => {
    programId &&
      history.push({
        pathname: `/programs/access-programs/${programId}/physician-enrolment-form`,
        state: {
          from: window.location.pathname
        }
      })
  }

  return (
    <>
      {label && (
        <ClinText
          className="custom-select-label"
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color="inherit"
          marginBottom={ClinTheme.space[2]}
        >
          {label}
        </ClinText>
      )}
      {!options || options?.length === 0 ? (
        <StyledPhysicianCustomSelectTrigger
          style={disabled ? { cursor: 'not-allowed' } : {}}
        >
          <StyledPhysicianCustomSelectTriggerInner
            style={
              disabled
                ? {
                    background: 'rgb(245, 245, 245)',
                    pointerEvents: 'none'
                  }
                : {}
            }
            isActive={!!selectedOption}
            tabIndex={0}
          >
            {isLoading ? (
              <ClinText lineHeight={2.3}>
                <ClinSpinner size={ClinTheme.space[3]} />
              </ClinText>
            ) : (
              <ClinText
                whiteSpace="nowrap"
                color={ClinTheme.colors.darkGrey}
                variant={TypographyVariant.LargeParagraph}
              >
                {placeholder}
              </ClinText>
            )}
          </StyledPhysicianCustomSelectTriggerInner>
        </StyledPhysicianCustomSelectTrigger>
      ) : (
        <StyledCustomSelect>
          <ClinDropdown
            trigger={() => {
              return (
                <StyledPhysicianCustomSelectTrigger
                  style={{ marginBottom: '6px' }}
                >
                  <StyledPhysicianCustomSelectTriggerInner
                    className="custom-select-trigger"
                    options={options}
                    isActive={!!selectedOption}
                    tabIndex={0}
                    title={selectedOption && (selectedOption.value as string)}
                  >
                    <ClinText
                      whiteSpace="nowrap"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {selectedOption ? selectedOption.value : placeholder}
                    </ClinText>
                    <ClinIcon
                      iconName={ClinIconPathName.ChevronSmallDown}
                      iconSize={ClinTheme.space[4]}
                    />
                  </StyledPhysicianCustomSelectTriggerInner>
                </StyledPhysicianCustomSelectTrigger>
              )
            }}
          >
            <StyledCustomSelectPhysicianOptions>
              {options &&
                options.map((option: ICustomSelectOption, index: number) => {
                  return (
                    <StyledCustomSelectOption
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px 24px 8px 16px'
                      }}
                      className={`custom-select-option-${index}`}
                      title={option && (option.value as string)}
                      tabIndex={0}
                      isActive={option.id === selectedOption?.id}
                      key={`custom-select-${index}`}
                      onClick={() => handleSelect(option)}
                      onKeyDown={(event) => {
                        handleKeyDown(event, option)
                      }}
                    >
                      <ClinText
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.normal}
                        lineHeight={2}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                      >
                        {option.value}
                      </ClinText>
                      <ClinText
                        color={
                          option.isActive
                            ? ClinTheme.colors.greenValid
                            : ClinTheme.colors.darkGrey
                        }
                        letterSpacing={'0.7px'}
                        textTransform={'uppercase'}
                        fontWeight={ClinTheme.fontWeights.medium}
                        lineHeight={ClinTheme.lineHeights.small}
                        variant={TypographyVariant.Paragraph}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                      >
                        {!option.isActive
                          ? t(
                              'patient_detail:create_patients.unassociated_text'
                            )
                          : t('patient_detail:create_patients.associated_text')}
                      </ClinText>
                    </StyledCustomSelectOption>
                  )
                })}
              <StyledCustomSelectOption
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px 24px 8px 16px'
                }}
                onKeyDown={(event) => {
                  //handleKeyDown(event, option)
                }}
              >
                <ClinButton
                  style={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    color: ClinTheme.colors.tealishBlue,
                    fontSize: ClinTheme.fontSizes[2],
                    fontWeight: ClinTheme.fontWeights.medium,
                    lineHeight: 1.75
                  }}
                  variant={'link'}
                  color={ClinTheme.colors.tealishBlue}
                  onClick={() => handleEnrollPhysician()}
                >
                  {t('patient_detail:create_patients.enroll_physician_link')}
                </ClinButton>
              </StyledCustomSelectOption>
            </StyledCustomSelectPhysicianOptions>
          </ClinDropdown>
        </StyledCustomSelect>
      )}
      {prompt && (
        <ClinText
          className="custom-select-prompt"
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color={hasError ? ClinTheme.colors.redInvalid : 'inherit'}
          marginTop={ClinTheme.space[2]}
        >
          {prompt}
        </ClinText>
      )}
    </>
  )
}
