import {
  PatientDetailsDto,
  ProgramCatalogDto,
  ReconcileVialRequestDto,
  ShortOrderSummaryDto
} from '../../../types/swaggerTypes'
import { Day, DayValue } from '@amir04lm26/react-modern-calendar-date-picker'

/**
 * Augmented orders response type for custom hook
 */
export type IAugmentOrdersResponse = {
  isLoading: boolean
  augmentedOrders: IPastOrderSummary[]
  patient: PatientDetailsDto | undefined
  isProgramClosed: boolean
  allowsDiscontinuation: boolean
  getPatient: () => void
}

/**
 * Calendar picker interface
 */
export interface ICalendarPicker {
  /** The selected day */
  selectedDay: DayValue
  /** The minimum day to show */
  minimumDate: Day
  /** The maximum day to show */
  maximumDate?: Day
  /** Whether calendar is open or not */
  isOpen: boolean
}

/**
 * Extend Vial to include all the information we need for the view
 * */
export interface IPatientVial extends ReconcileVialRequestDto {
  /** The order number for the vial */
  orderNumber: number
  /** Human readable date (actual Date is an ISO 8601 string YYYY-MM-DD) */
  dateAdministeredFormatted?: string
  /** A flag to show if the vial has been reconciled */
  wasReconciled?: boolean
}

export interface VialDateAdministeredSaved {
  vialNumber: string
  dateAdministered: string
}

/**
 * Extend Order summary to include all the information we need for the view
 * */
export interface IPastOrderSummary extends ShortOrderSummaryDto {
  /** Show the row toggle */
  canBeReconciled: boolean
  /** The shipped date as a day */
  dayOrderShipped: Day
  /** The vials to reconcile */
  vialsToReconcile: IPatientVial[]
  /** Whether the table row is toggled open */
  isOrderToggledOpen: boolean
}

/**
 * Program for custom hook response
 */
export type IProgramResponse = {
  /** If the program is loading */
  isProgramLoading: boolean
  /** The provided program catalogue dto */
  program?: ProgramCatalogDto
}

/**
 *
 Reconciliation type for Vials
 */
export enum VialReconciliationType {
  /** Can only select ‘Administered’ or ‘Destroyed’ from Disposition */
  DispositionOnly = 'Disposition Only',
  /** Disposition and Batch - Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Batch Number */
  DispositionBatch = 'Disposition and Batch',
  /**  Disposition and Date - Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Date */
  DispositionDate = 'Disposition and Date',
  /** Disposition, Batch and Date - Can select ‘Administered’ or ‘Destroyed’ from Disposition and must complete a Date AND Batch Number */
  DispositionBatchAndDate = 'Disposition, Batch and Date'
}
