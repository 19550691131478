import React, { FunctionComponent, ReactNode } from 'react'
import { IDefaultStyleProps } from '../../types'
import { DateTime } from 'luxon'

import { ClinTableOrderToggle } from '../../components/ClinTableOrderToggle'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import {
  ClinTable,
  ClinTableBodyCell,
  ClinTableHeaderCell,
  ClinTableRow
} from '../../components/ClinTable/ClinTable'
import {
  StyledMedications,
  StyledOrderNumber,
  StyledOrdersSearch
} from './OrdersTable.styles'
import { ClinTabs } from '../../components/ClinTabs'
import { ClinTabHeader } from '../../components/ClinTabHeader'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpinner } from '../../components/ClinSpinner'
import { NoOrders } from '../NoOrders'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinTableToggleRowButton } from '../../components/ClinTableToggleRowButton'
import { IOrderSummary } from '../../types/IOrder'
import { OrderColumn } from '../../pages'
import { OrderColumnNames } from '../../constants'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { Row, Col } from 'react-grid-system'
import { BasicSearch } from '../Search/BasicSearch'
import { TableFilterSearch } from '../TableFilterSearch'
import { ClinButton } from '../../components/ClinButton'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { CountryDto } from '../../types/swaggerTypes'
import { useTranslation } from 'react-i18next'

const getColumnWidth = (columnName: string) => {
  switch (columnName) {
    case OrderColumnNames.ToggleExpand:
      return '3%'
    case OrderColumnNames.OrderNumber:
      return '8%'
    case OrderColumnNames.PurchaseOrderNumber:
      return '12%'
    case OrderColumnNames.Medications:
      return 'auto'
    case OrderColumnNames.DateSubmitted:
      return '12%'
    case OrderColumnNames.TotalCost:
      return '5%'
    case OrderColumnNames.Status:
      return '8%'
    default:
      return 'auto'
  }
}
interface IOrdersTableProps extends IDefaultStyleProps {
  /** Whether table is loading or not */
  isLoading: boolean
  /** The array of orders coming through */
  orders: IOrderSummary[]
  /** Columns to show on the orders table */
  columns: OrderColumn[]
  /** Columns to hide sort toggle for */
  hideSortToggleForColumnIndices?: number[]
  /** The selected order id */
  selectedOrderId?: string
  /** The selected tab index */
  selectedTabIndex?: number
  /** The selected column for sorting */
  selectedSortColumn?: OrderColumn
  /** The selected sort direction */
  selectedSortDirection?: SortDirectionType
  /** The initial query used for the search component on first render */
  initialQuery?: string
  /** If a search returns an empty value */
  isEmptySearch: boolean
  /** Supplied contact data */
  supportContact: CountryDto
  /** User role */
  isMaUser?: boolean
  /** Handle the row clicked and return id */
  handleRowClicked?: (orderId: string) => void
  /** Handle column sort toggle */
  handleToggleSort?: (event: React.MouseEvent<HTMLDivElement>) => void
  /** Handle tab button selection */
  handleTabSelected: (selectedTabIndex: number) => void
  /** Handle expand/collapse order row */
  handleToggleOrderRow: (orderNumber: string) => void
  /** When a search query is entered and ENTER pressed */
  handleOnSearch?: (query: string) => void
  /** When a search query is submitted */
  handleOnSubmit?: () => void
}

const getCellContent = (
  column: OrderColumn,
  order: IOrderSummary,
  handleToggleOrderRow: (orderNumber: string) => void
): ReactNode | string => {
  const {
    orderNumber,
    customerPoNumber,
    orderedDate,
    medications,
    orderTotal,
    currencyCode,
    orderStatus
  } = order
  switch (column.name) {
    case OrderColumnNames.ToggleExpand:
      return (
        <ClinTableToggleRowButton
          isToggled={order.isOpen}
          onClick={() => handleToggleOrderRow(orderNumber!)}
        />
      )
    case OrderColumnNames.DateSubmitted:
      return DateTime.fromISO(orderedDate).toLocaleString()
    case OrderColumnNames.Status:
      return orderStatus.replace('_', ' ') // fix for underscores in data status name
    case OrderColumnNames.PurchaseOrderNumber:
      return <StyledOrderNumber>{customerPoNumber}</StyledOrderNumber>
    case OrderColumnNames.TotalCost:
      return `${numberToCurrencyString(orderTotal, getBrowserLocale(), {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currencyDisplay: 'symbol'
      })}`
    case OrderColumnNames.OrderNumber:
      return <span>{orderNumber}</span>
    case OrderColumnNames.Medications:
      return (
        <StyledMedications isExpanded={order.isOpen}>
          {medications.map((m, index) => {
            // Add spaces for multiple medications in an order
            const lastLine = index < medications.length - 1
            return lastLine ? (
              <span key={`${m.name}-${index}`}>
                {m.name}
                <br />
                <br />
              </span>
            ) : (
              m.name
            )
          })}
        </StyledMedications>
      )
    default:
      return 'Unknown type'
  }
}

export const OrdersTable: FunctionComponent<IOrdersTableProps> = ({
  isLoading = true,
  orders,
  columns,
  hideSortToggleForColumnIndices,
  selectedTabIndex = 0,
  selectedSortColumn,
  selectedSortDirection = SortDirectionType.None,
  initialQuery,
  isEmptySearch,
  supportContact,
  isMaUser,
  handleRowClicked,
  handleToggleSort,
  handleTabSelected,
  handleToggleOrderRow,
  handleOnSearch,
  handleOnSubmit,
  className
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ClinTabs
        activeItemIndex={selectedTabIndex}
        handleSelected={handleTabSelected}
        isDisabled={isLoading}
      >
        <ClinTabHeader title={t('orders:current_orders')} />
        <ClinTabHeader title={t('orders:previous_orders')} />
      </ClinTabs>
      <Row>
        <Col sm={12}>
          <TableFilterSearch
            searchInput={() => (
              <StyledOrdersSearch>
                <BasicSearch
                  initialQuery={initialQuery}
                  placeholder={t('orders:search_placeholder')}
                  debounceTimeMs={0}
                  handleOnChange={(query) =>
                    handleOnSearch && handleOnSearch(query)
                  }
                  handleOnSubmit={() => handleOnSubmit && handleOnSubmit()}
                />
                <ClinButton onClick={handleOnSubmit} variant="primary">
                  {t('common:buttons.search')}
                </ClinButton>
              </StyledOrdersSearch>
            )}
          />
        </Col>
      </Row>

      {isLoading ? (
        <StyledSpinnerContainer>
          <ClinSpinner size={ClinTheme.space[7]} />
        </StyledSpinnerContainer>
      ) : (
        <ClinTable
          className={className}
          tableHeader={() =>
            columns.map((column: OrderColumn, index: number) => (
              <ClinTableHeaderCell
                width={getColumnWidth(column.name)}
                key={index}
              >
                {column.name !== OrderColumnNames.ToggleExpand && (
                  <ClinTableOrderToggle
                    sortDirection={
                      selectedSortColumn === column
                        ? selectedSortDirection
                        : SortDirectionType.None
                    }
                    hideSortIcon={
                      hideSortToggleForColumnIndices &&
                      hideSortToggleForColumnIndices.indexOf(index) > -1
                    }
                    onClick={(event) =>
                      handleToggleSort && handleToggleSort(event)
                    }
                  >
                    {t(column.translationKey)}
                  </ClinTableOrderToggle>
                )}
              </ClinTableHeaderCell>
            ))
          }
        >
          {orders && orders.length > 0 ? (
            orders.map((order, rowIndex) => {
              return (
                <ClinTableRow
                  key={rowIndex}
                  onRowClick={() =>
                    handleRowClicked &&
                    order.orderNumber &&
                    handleRowClicked(order.orderNumber)
                  }
                  onRowEnter={() =>
                    handleRowClicked &&
                    order.orderNumber &&
                    handleRowClicked(order.orderNumber)
                  }
                >
                  {columns.map((column: OrderColumn, index: number) => (
                    <ClinTableBodyCell key={index} verticalAlign="top">
                      {getCellContent(column, order, handleToggleOrderRow)}
                    </ClinTableBodyCell>
                  ))}
                </ClinTableRow>
              )
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <NoOrders
                  supportContact={supportContact}
                  isEmptySearch={isEmptySearch}
                  isMaUser={isMaUser}
                />
              </td>
            </tr>
          )}
        </ClinTable>
      )}
    </>
  )
}
