import React, { ChangeEvent, FunctionComponent } from 'react'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinText } from '../../components/ClinText'
import { ClinTheme } from '../../ClinTheme'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { OrderAddressDto } from '../../types/swaggerTypes'
import {
  StyledCheckoutBlockHeader,
  StyledCheckoutOrder,
  StyledOneStepCheckoutBlock,
  StyledOneStepCheckoutBlockBody
} from '../Checkout/Checkout.styles'
import { Col, Row } from 'react-grid-system'
import { ClinTextInput } from '../../components/ClinTextInput'
import { DeliveryAddress } from './DeliveryAddress'
import {
  StyledClinRadio,
  StyledDeliveryAddressOneStepCheckoutWrapper,
  StyledDeliveryAddressTextOneStepCheckout,
  StyledDeliveryAddressWrap,
  StyledNewShippingAddressLinkOneStepCheckout
} from './CheckoutOptions.styles'
import { ClinGroup } from '../../components/ClinGroup'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinSpinner } from '../../components/ClinSpinner'
import { Trans, useTranslation } from 'react-i18next'
import {
  StyledSummaryItemWrap,
  StyledSummaryOneStepCheckout,
  StyledSummaryTitle,
  StyledSummaryWrap
} from '../Basket/Basket.styles'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { CheckoutViewMode } from '../Checkout/CheckoutContainer'
import { IViewBasket } from '../../context/basket'
import { Link } from 'react-router-dom'
import { ClinLoader } from '../../components/ClinLoader'

interface IOneStepCheckoutOptionsProps {
  /** Supplied Purchase Order number **/
  customerPoNumber?: string
  /** Supplied deliver to **/
  deliverToContact?: string
  /** An array of delivery addresses **/
  deliveryAddresses?: OrderAddressDto[]
  /** Selected address id */
  selectedAddressId: string
  /** Error in PO */
  errorMessage?: string
  //View basket from context
  basket?: IViewBasket
  /** When back to basket is clicked **/
  handleBackToBasket?: () => void
  /** Requests that an address change has been made **/
  handleChangeAddress?: (selectedAddressId?: string) => void
  /** Handles a recipient's name change for an order **/
  handleRecipientsNameChange?: (value: string) => void
  /** Handles a po value change for an order **/
  handlePoChange?: (value: string) => void
  /** Handles a request to exit and add a new shipping address **/
  handleNewShippingAddress?: () => void
  /** The decided view which should be displayed **/
  viewMode: CheckoutViewMode
  /** Handles a request to place the orders in the checkout **/
  handleOrderSubmit: () => void
  /** Handles for sending mail on click on email address in disclaimer **/
  handleSendEmail: () => void
}

export const OneStepCheckout: FunctionComponent<
  IOneStepCheckoutOptionsProps
> = ({
  customerPoNumber,
  deliverToContact,
  selectedAddressId,
  errorMessage,
  deliveryAddresses,
  basket,
  handleBackToBasket,
  handleChangeAddress,
  handlePoChange,
  handleRecipientsNameChange,
  handleNewShippingAddress,
  handleSendEmail,
  viewMode,
  handleOrderSubmit
}) => {
  const { t } = useTranslation()

  return (
    <ClinPageContentFrame
      hideBreadcrumbs={true}
      marginBottom="0"
      className="min-height-aus-checkout-class"
    >
      {viewMode === CheckoutViewMode.Loading && (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      )}
      {viewMode === CheckoutViewMode.Submitting && (
        <>
          <Row>
            <Col sm={6}>
              <ClinText
                variant={TypographyVariant.H4}
                marginTop={ClinTheme.space[4]}
              >
                {t('checkout:placing_your_order')}
              </ClinText>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <StyledSpinnerContainer>
                <ClinLoader />
              </StyledSpinnerContainer>
            </Col>
          </Row>
        </>
      )}

      {viewMode === CheckoutViewMode.ShowOrders && (
        <>
          <Row>
            <Col sm={12}>
              <ClinText
                as="h1"
                fontWeight={ClinTheme.fontWeights.bold}
                variant={TypographyVariant.H2}
                marginTop={ClinTheme.space[6]}
                marginBottom={ClinTheme.space[1]}
              >
                {t('checkout_options:title')}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[6]} hasBorder={true} />
          <ClinButton
            style={{ marginTop: '20px' }}
            variant="link"
            onClick={() => handleBackToBasket && handleBackToBasket()}
          >
            <ClinIcon
              viewBox="0 0 24 14"
              style={{ marginRight: '14px' }}
              iconName={ClinIconPathName.LeftArrow}
            />
            {t('checkout_options:back_to_basket')}
          </ClinButton>
          <ClinSpacer height={ClinTheme.space[6]} />
          <StyledCheckoutOrder>
            <ClinSpacer />
            <Row>
              <Col xs={12} lg={8}>
                <StyledOneStepCheckoutBlock>
                  <StyledCheckoutBlockHeader
                    style={{ backgroundColor: 'white' }}
                  >
                    <Row align="center">
                      <Col sm={12} md={12}>
                        <ClinText
                          as="h2"
                          variant={TypographyVariant.H3}
                          color={ClinTheme.colors.primary}
                          marginTop={'0px'}
                          marginBottom={'0px'}
                        >
                          {t(
                            'checkout_options:checkout_options.delivery_details_title'
                          )}
                        </ClinText>
                      </Col>
                    </Row>
                  </StyledCheckoutBlockHeader>
                  <StyledOneStepCheckoutBlockBody>
                    <Row>
                      <Col sm={12} md={7}>
                        <ClinSpacer height={ClinTheme.space[4]} />
                        <ClinTextInput
                          width={'100%'}
                          id="po-number"
                          name="poNumber"
                          label={t('checkout_options:purchase_order_label')}
                          required={true}
                          hasError={!!errorMessage}
                          prompt={errorMessage}
                          defaultValue={customerPoNumber}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handlePoChange &&
                            handlePoChange(event.currentTarget.value)
                          }
                        />
                      </Col>
                      <ClinSpacer height={ClinTheme.space[4]} />
                      <Col xs={12} md={7}>
                        <ClinTextInput
                          width={'100%'}
                          id="recipients-name"
                          name="recipientsName"
                          label={t(
                            'checkout_options:checkout_options.delivery_contact'
                          )}
                          defaultValue={deliverToContact}
                          onChange={(event) =>
                            handleRecipientsNameChange &&
                            handleRecipientsNameChange(
                              event.currentTarget.value
                            )
                          }
                        />
                      </Col>
                    </Row>
                    <ClinSpacer height={ClinTheme.space[3]} />
                    <Row>
                      <Col sm={12}>
                        <>
                          <ClinText
                            as="h5"
                            variant={TypographyVariant.LargeParagraph}
                            marginBottom={ClinTheme.space[2]}
                          >
                            {t(
                              'checkout_options:checkout_options.address_select_title'
                            )}
                          </ClinText>
                          <StyledDeliveryAddressOneStepCheckoutWrapper
                            id="scrollView"
                            className="deliveryAddressWrapper"
                          >
                            {deliveryAddresses?.map((address, index) => (
                              <div key={address.addressId}>
                                <StyledClinRadio
                                  label={`${address.addressId}-index`}
                                  id={`${address.addressId}-index`}
                                  checked={
                                    address.addressId === selectedAddressId
                                  }
                                  isLast={
                                    index === deliveryAddresses.length - 1
                                  }
                                  isCompact={true}
                                  onClick={() =>
                                    handleChangeAddress &&
                                    handleChangeAddress(address.addressId)
                                  }
                                >
                                  <Row>
                                    <Col>
                                      <DeliveryAddress
                                        address={address}
                                        isCompact={true}
                                      />
                                    </Col>
                                  </Row>
                                </StyledClinRadio>
                              </div>
                            ))}
                          </StyledDeliveryAddressOneStepCheckoutWrapper>
                        </>
                      </Col>
                    </Row>
                  </StyledOneStepCheckoutBlockBody>
                  <StyledDeliveryAddressWrap id="scrollView">
                    <StyledDeliveryAddressTextOneStepCheckout as="div">
                      <ClinText
                        as="h5"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {t('checkout_options:do_not_see_delivery_options')}
                      </ClinText>
                    </StyledDeliveryAddressTextOneStepCheckout>
                    <StyledNewShippingAddressLinkOneStepCheckout
                      onClick={() =>
                        handleNewShippingAddress && handleNewShippingAddress()
                      }
                    >
                      {t(
                        'checkout_options:checkout_options.new_shipping_address_btn'
                      )}
                    </StyledNewShippingAddressLinkOneStepCheckout>
                  </StyledDeliveryAddressWrap>
                </StyledOneStepCheckoutBlock>
              </Col>
              <Col xs={12} lg={4}>
                <StyledSummaryOneStepCheckout>
                  <StyledSummaryTitle>
                    <ClinText
                      as="span"
                      variant={TypographyVariant.H4}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      {t('basket:summary')}
                    </ClinText>
                  </StyledSummaryTitle>

                  <ClinSpacer height={ClinTheme.space[4]} />
                  {basket?.items.map((item) => (
                    <StyledSummaryWrap key={item.basketItemId}>
                      <ClinGroup justifyContent="space-between">
                        <StyledSummaryItemWrap>
                          <ClinText
                            fontSize={ClinTheme.fontSizes[3]}
                            fontWeight={ClinTheme.fontWeights.bold}
                            color={ClinTheme.colors.primary}
                          >
                            {item.skuCatalogItem.genericDosageConcatenation}
                          </ClinText>
                        </StyledSummaryItemWrap>

                        <ClinText
                          as={'h5'}
                          variant={TypographyVariant.LargerParagraph}
                          color={ClinTheme.colors.primary}
                        >
                          {!item.amount
                            ? '--'
                            : numberToCurrencyString(
                                item.amount,
                                getBrowserLocale(),
                                {
                                  style: 'currency',
                                  currency:
                                    item.priceAndAvailability?.currencyCode,
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                }
                              )}
                        </ClinText>
                      </ClinGroup>
                    </StyledSummaryWrap>
                  ))}

                  <ClinText
                    variant={TypographyVariant.LargerParagraph}
                    color={ClinTheme.colors.primary}
                    marginBottom={ClinTheme.space[4]}
                  >
                    {t('checkout_options:checkout_options.gst_text')}
                  </ClinText>

                  <div>
                    <ClinGroup justifyContent="space-between">
                      <div>
                        <ClinText
                          variant={TypographyVariant.LargerParagraph}
                          fontWeight={ClinTheme.fontWeights.medium}
                          color={ClinTheme.colors.primary}
                        >
                          {t('checkout_options:checkout_options.total')}
                        </ClinText>
                      </div>

                      <ClinText
                        as={'h5'}
                        variant={TypographyVariant.H4}
                        fontWeight={ClinTheme.fontWeights.medium}
                        color={ClinTheme.colors.primary}
                      >
                        {!basket?.total
                          ? '--'
                          : numberToCurrencyString(
                              basket?.total,
                              getBrowserLocale(),
                              {
                                style: 'currency',
                                currency: basket.currencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}
                      </ClinText>
                    </ClinGroup>
                  </div>
                  <ClinSpacer height={ClinTheme.space[4]} />
                  <ClinButton
                    variant="primary"
                    onClick={() => handleOrderSubmit()}
                  >
                    {t('checkout_options:checkout_options.place_order_btn')}
                  </ClinButton>

                  <>
                    <ClinSpacer height={ClinTheme.space[4]} />
                    <ClinText
                      variant={TypographyVariant.LargeParagraph}
                      color={ClinTheme.colors.black}
                      marginTop={'0px'}
                    >
                      {t('checkout_options:checkout_options.disclamer_text')}
                    </ClinText>
                    <ClinText
                      variant={TypographyVariant.LargeParagraph}
                      color={ClinTheme.colors.black}
                      marginTop={'20px'}
                    >
                      {t('checkout_options:checkout_options.disclamer_text_2')}
                    </ClinText>
                    <ClinText
                      marginTop={'20px'}
                      variant={TypographyVariant.LargeParagraph}
                      color={ClinTheme.colors.black}
                    >
                      <Trans
                        i18nKey="checkout_options:checkout_options.disclamer_text_3"
                        components={[
                          <Link
                            style={{
                              textDecoration: 'underline',
                              color: 'black'
                            }}
                            key="link"
                            to="#"
                            onClick={() => handleSendEmail()}
                          />
                        ]}
                      />
                    </ClinText>
                  </>
                </StyledSummaryOneStepCheckout>
              </Col>
            </Row>
          </StyledCheckoutOrder>
        </>
      )}
    </ClinPageContentFrame>
  )
}
