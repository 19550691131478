import React, { FunctionComponent, useState } from 'react'
import { matchPath, Route, useLocation } from 'react-router'
import { useOnMount } from '../../utils/useOnMount'
import { cancelGetOrderById, getOrderById } from '../../services/ApiService'
import { useErrorMessage } from '../../utils/useErrorMessage'
import { OrderDto } from '../../types/swaggerTypes'
import { Col, Container, Row } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpinner } from '../ClinSpinner'
import { StyledSpinnerContainer } from '../ClinSpinner/ClinSpinner.styles'
import { MaOrderDetailContainer } from '../../pages/MaOrderDetail'
import { OrderDetailContainer } from '../../pages/OrderPages/OrderDetail'
import { IMaOrderDetailProps } from '../../pages/MaOrderDetail/MaOrderDetailContainer'
import { IOrderDetailProps } from '../../pages/OrderPages/OrderDetail/OrderDetailContainer'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { useAppContext } from '../../context/app/AppContext'

interface IOrderRouteParams {
  orderId: string
}

interface IOrderRouteProps {
  exact: boolean
  path: string
  countryCodesExcluded?: string[] //Country codes to be forbiden to access orders dashboard
}

const isMaOrder = (order: OrderDto): boolean =>
  order.lines.find((line) => line.division === 'MA') !== undefined

export const OrderRoute: FunctionComponent<IOrderRouteProps> = ({
  path,
  exact,
  countryCodesExcluded
}) => {
  const { portfolioCountryCode } = useAppContext()
  const location = useLocation()
  const [order, setOrder] = useState<OrderDto>()
  const match = matchPath<IOrderRouteParams>(location.pathname, {
    path: path,
    exact: exact
  })

  if (countryCodesExcluded?.includes(portfolioCountryCode)) {
    window.location.href = '/products/catalogue'
  }
  const orderId = match?.params.orderId

  const handleError = useErrorMessage('There was an error fetching order.')
  useOnMount(() => {
    orderId &&
      getOrderById(orderId)
        .then((response) => {
          setOrder(response.data)
        })
        .catch((error) => {
          analyticsServiceSingleton.trackError(error)
          handleError(error)
        })
    return () => {
      cancelGetOrderById()
    }
  })

  return order === undefined ? (
    <Container>
      <Row justify="center">
        <Col width="auto">
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </Container>
  ) : (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        const updatedProps = props as IMaOrderDetailProps | IOrderDetailProps
        updatedProps.location.state = {
          ...updatedProps.location.state,
          orderDto: order
        }
        return isMaOrder(order) ? (
          <MaOrderDetailContainer {...updatedProps} />
        ) : (
          <OrderDetailContainer {...updatedProps} />
        )
      }}
    />
  )
}
