import React, { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { useAppContext } from '../../../context/app'
import { ClinButton } from '../../../components/ClinButton'
import { Tooltip } from 'react-tippy'

interface IProductDetailContactProps {
  itemSku: string
}

export const ProductDetailContact: FunctionComponent<
  IProductDetailContactProps
> = ({ itemSku }) => {
  const { t } = useTranslation()
  const { supportContact } = useAppContext()

  const [hoveredLink, setHoveredLink] = React.useState<boolean>(false)
  const [iconName, setIconName] = React.useState<ClinIconPathName>(
    ClinIconPathName.CopyLink
  )
  const [open, setOpen] = React.useState<boolean>(false)
  const [tooltipText, setTooltipText] = React.useState<string>(
    t('product_detail:product_detail_contact.hover_link_text')
  )

  const copyLinkHandler = () => {
    const url = window.location.href
    navigator.clipboard.writeText(url)
  }
  const handleMouseLeave = () => {
    setIconName(ClinIconPathName.CopyLink)
    setTooltipText(t('product_detail:product_detail_contact.hover_link_text'))
    setOpen(false)
    setHoveredLink(false)
  }

  const handleMouseClick = () => {
    copyLinkHandler()
    setIconName(ClinIconPathName.Success)
    setTooltipText(t('product_detail:product_detail_contact.hovered_link_text'))
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.TrackCopyLink, {
      sku: itemSku
    })
  }
  return (
    <div>
      <ClinText
        variant={TypographyVariant.Paragraph}
        fontWeight={ClinTheme.fontWeights.medium}
        marginBottom={ClinTheme.space[3]}
        marginTop={'0px'}
      >
        {t('product_detail:product_detail_contact.title')}
      </ClinText>

      <div style={{ marginBottom: '26px' }}>
        <ClinGroup alignItems="center" justifyContent="flex-start">
          <ClinIcon
            iconName={ClinIconPathName.Phone}
            style={{ marginRight: 0 }}
            iconFill={ClinTheme.colors.primary}
          />
          <a
            className="display-telephone"
            href={`tel: ${supportContact.rawPhoneNumber}`}
            style={{ margin: 0 }}
            onClick={() => {
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.ContactClicked,
                {
                  sku: itemSku,
                  contactType: 'telephone'
                }
              )
            }}
          >
            <ClinText
              className={'telephone'}
              as="span"
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.primary}
              marginLeft={ClinTheme.space[2]}
            >
              {supportContact?.csPhoneNumber}
            </ClinText>
          </a>
        </ClinGroup>
      </div>
      <div
        style={{
          marginBottom: '26px'
        }}
      >
        <ClinGroup alignItems="center" justifyContent="flex-start">
          <ClinIcon
            iconName={ClinIconPathName.Mail}
            style={{ marginRight: 0 }}
            iconFill={ClinTheme.colors.primary}
          />
          <a
            className="raw-email"
            href={`mailto:${supportContact?.csEmailAddress}`}
            style={{ margin: 0, width: '100%', overflowWrap: 'break-word' }}
            onClick={() => {
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.ContactClicked,
                { sku: itemSku, contactType: 'email' }
              )
            }}
          >
            <ClinText
              className="email"
              as="span"
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.primaryLight}
              marginLeft={ClinTheme.space[2]}
            >
              {supportContact?.csEmailAddress}
            </ClinText>
          </a>
        </ClinGroup>
      </div>
      <div
        style={{ marginBottom: '6px', cursor: 'pointer' }}
        onMouseEnter={() => {
          setHoveredLink(true)
          setOpen(true)
        }}
        onMouseLeave={() => {
          handleMouseLeave()
        }}
        onClick={(e) => {
          handleMouseClick()
        }}
      >
        <ClinGroup alignItems="center" justifyContent="flex-start">
          <Tooltip
            style={{
              marginLeft: '0',
              display: 'flex',
              alignItems: 'center'
            }}
            title={tooltipText}
            position="top"
            open={open}
            trigger="manual"
          >
            <ClinIcon
              iconName={ClinIconPathName.CopyLinkArrow}
              style={{ marginRight: 0, paddingLeft: `${ClinTheme.space[2]}px` }}
              iconFill={ClinTheme.colors.primary}
              iconHeight={`${ClinTheme.space[4]}px`}
              iconSize={`${ClinTheme.space[4]}px`}
              viewBox="0,0,26,27"
            />

            <ClinButton variant="link" style={{ margin: 0 }}>
              <ClinText
                as="span"
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.primaryLight}
              >
                {t('product_detail:product_detail_contact.link')}
              </ClinText>
            </ClinButton>
            {hoveredLink && (
              <ClinIcon
                iconName={iconName}
                style={{
                  marginRight: 0,
                  marginLeft: `${ClinTheme.space[1]}px`
                }}
                iconFill={
                  iconName === ClinIconPathName.Success
                    ? '#00A223'
                    : `${ClinTheme.colors.primary}`
                }
                iconHeight={20}
                iconSize={20}
                viewBox="0 0 20 13"
              />
            )}
          </Tooltip>
        </ClinGroup>
      </div>
    </div>
  )
}
