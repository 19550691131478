import { createGlobalStyle } from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable;
    scrollbar-gutter: stable both-edges
    height: 100%;
  }

  .deliveryAddressWrapper::-webkit-scrollbar {
    width: 6px;
  }

  .deliveryAddressWrapper::-webkit-scrollbar-track {
    background: #e5ddf8;
  }

  .deliveryAddressWrapper::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 20px;
    max-height: 70px;
  }

  /* Works on Firefox */
  .deliveryAddressWrapper {
    scrollbar-width: thin;
    scrollbar-color: #757575 #e5ddf8;
  }
  
  body {
    font-family: ${ClinTheme.fonts.body};

    #content { min-height: calc(100vh - ${
      ClinTheme.componentsHeights.footer
    }px)}
  }

  .min-height-basket-class {
    min-height: calc(100vh - ${
      ClinTheme.componentsHeights.header +
      ClinTheme.componentsHeights.search +
      ClinTheme.componentsHeights.footer
    }px) 
  }

    .min-height-ma-checkout-class {
      min-height: calc(100vh - ${
        ClinTheme.componentsHeights.patientHeader +
        ClinTheme.componentsHeights.footer
      }px)
  }

   .min-height-aus-checkout-class {
      min-height: calc(100vh - ${
        ClinTheme.componentsHeights.header + ClinTheme.componentsHeights.footer
      }px)  //footer height 139px + header height 90px
  }
  
  @page {
    size: portrait;
    margin: 1cm;
  }
  
  .cms {
  
    p {
      font-size: ${ClinTheme.fontSizes[2]}px;
      line-height: ${ClinTheme.lineHeights.body};
    }
  
    h1, h2, h3, h4, h5, h6 {
      color: ${ClinTheme.colors.primary};
    }
    
    h4{
      font-size: ${ClinTheme.fontSizes[4]}px;
      line-height: ${ClinTheme.lineHeights.heading[1]};
      font-weight: ${ClinTheme.fontWeights.light};
      margin-bottom: ${ClinTheme.space[4]}px;
    }
  
    h5{
      font-size: ${ClinTheme.fontSizes[3]}px;
      line-height: ${ClinTheme.lineHeights.heading[1]};
      font-weight: ${ClinTheme.fontWeights.medium};
      margin-bottom: ${ClinTheme.space[2]}px
    }
  
    a {
      font-size: ${ClinTheme.fontSizes[2]}px;
      line-height: ${ClinTheme.lineHeights.body};
      color: ${ClinTheme.colors.primaryLight};
  
      &:hover {
        text-decoration: underline;
      }
    }
  
    ul, ol {
        padding-left: ${ClinTheme.space[4]}px;
        li{
          font-size: ${ClinTheme.fontSizes[2]}px;
          line-height: ${ClinTheme.lineHeights.body};
          margin-bottom: ${ClinTheme.space[2]}px;
        }
    }
    
  }

    .tippy-tooltip.light-theme {
        background-color: ${ClinTheme.colors.darkGrey}!important;
        color: ${ClinTheme.colors.white}!important;
        border-radius: ${ClinTheme.space[2]}px;
        padding: 8px 24px 8px 16px;
        font-size:${ClinTheme.fontSizes[0]}px;
        font-weight:${ClinTheme.fontWeights.normal};
        line-height: ${ClinTheme.lineHeights.largeParagraph};
        width:158px;
       text-align: left;
    }
.tippy-popper[x-placement=top] .tippy-tooltip.light-theme [x-arrow] {
    border-top-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=bottom] .tippy-tooltip.light-theme [x-arrow] {
    border-bottom-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=top-start] .tippy-tooltip.light-theme [x-arrow] {
    border-top-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}

.tippy-popper[x-placement=top-end] .tippy-tooltip.light-theme [x-arrow] {
    border-top-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=bottom-end] .tippy-tooltip.light-theme [x-arrow] {
    border-bottom-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=bottom-start] .tippy-tooltip.light-theme [x-arrow] {
    border-bottom-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=left] .tippy-tooltip.light-theme [x-arrow] {
    border-left-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=left-start] .tippy-tooltip.light-theme [x-arrow] {
    border-left-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=left-end] .tippy-tooltip.light-theme [x-arrow] {
    border-left-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=right] .tippy-tooltip.light-theme [x-arrow] {
    border-right-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=right-start] .tippy-tooltip.light-theme [x-arrow] {
    border-right-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}
.tippy-popper[x-placement=right-end] .tippy-tooltip.light-theme [x-arrow] {
    border-right-color: ${ClinTheme.colors.darkGrey}; /* your color here */
}

.relative {
    position: relative
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

.TableOverflowVisible {
  overflow: visible;
}
@media only screen and (max-width: ${ClinTheme.breakpoints[2]}px) {
  .TableOverflowVisible {
    overflow-x: auto;

  }
}

//this is only to fix issue with navbar for new feature highlight
.navbarZIndexTooltipFix {
  z-index: 0 !important;
}

.navbarZIndexTooltipUp {
  z-index: 6 !important;
}

`
