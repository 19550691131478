import React, { FunctionComponent } from 'react'
import {
  StyledAmountContainer,
  StyledBasketHoldItem,
  StyledBasketItem,
  StyledDeleteButton,
  StyledDrugPropertiesContainer,
  StyledMobileDelete,
  StyledQuantitySelectorContainer,
  StyledSummary,
  StyledSummaryTitle,
  StyledWrapper
} from './Basket.styles'
import { ClinTheme } from '../../ClinTheme'
import { Col, Hidden, Row, Visible } from 'react-grid-system'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinButton } from '../../components/ClinButton'
import { ClinTextInput } from '../../components/ClinTextInput'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../components/ClinIcon'
import { DrugProperty } from '../../services/DrugPropertyProvider'
import { IViewBasket, IViewBasketItem } from '../../types'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinCheckbox } from '../../components/ClinCheckbox'
import {
  freightChargeDisclaimer,
  getDrugPropertyItem,
  getErrorForBasketItemId,
  getVariantAnnounce
} from './Basket.model'
import { ClinLoader } from '../../components/ClinLoader'
import { IQuantityError } from './BasketContainer'
import {
  IAllHoldsInformation,
  IHoldDetails
} from '../../services/Holds/holds.types'
import { getUploadedHoldFile } from '../../services/Holds/holds'
import { DocumentUploadContainer } from '../../features/DocumentUpload/DocumentUploadContainer'
import {
  BasketItemDocumentDto,
  UpdateBasketDetailsDto,
  UploadedDocumentDto,
  UserDto
} from '../../types/swaggerTypes'
import {
  ClinStepper,
  StepState
} from '../../components/ClinStepper/ClinStepper'
import { ClinDividerHeading } from '../../components/ClinDividerHeading'
import {
  StyledCheckoutBlock,
  StyledCheckoutBlockBody,
  StyledCheckoutBlockHeader
} from '../Checkout/Checkout.styles'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { HoldType } from '../../services/Holds/holds.constants'
import { Trans, useTranslation } from 'react-i18next'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { SupportDocuments } from './SupportDocument'
import { AnalyticsEvent } from '../../services/Analytics'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  UserRole,
  UserRoleRecord,
  defaultBasketDetails,
  defaultBasketMADetails
} from '../../constants'
import { localStorageRecipientName } from '../../types/localStorageConstants'
import { getUserPhone } from '../../utils/getUserPhone'

// import { PortfolioJourneyDebug } from '../../services/PortfolioJourneys/PortfolioJourneyDebug'

export enum BasketViewState {
  Loading,
  Basket,
  Declaration,
  Submitting
}

interface IBasketProps {
  /** Which view state to show */
  basketViewState: BasketViewState
  /** Basket payload */
  basket?: IViewBasket
  /** Hold information */
  holdInformation?: IAllHoldsInformation
  /** An item is unavailable */
  isAnItemUnavailable?: boolean
  /** Country code */
  countryCode: string
  /** Quantity errors */
  quantityErrors?: IQuantityError[]
  /** Hold information */
  isUpdatingQuantity?: boolean
  /** Handle removing an item */
  handleRemoveItem?: (basketItem: IViewBasketItem) => void
  /** Handle changing an items quantities */
  handleChangeItemQuantity?: (
    basketItem: IViewBasketItem,
    quantity: number
  ) => void
  /** Handle proceed to checkout button */
  handleProceedToCheckout?: () => void
  /** Whether user has agreed */
  hasAgreedToTerms?: boolean
  /** Whether user has downloaded Saftey Letter */
  hasDownloadedSafetyLetter?: boolean
  /** Handle declaration downloaded safety letter */
  handleSafetyLetterDownloadSelect?: (agreed: boolean) => void
  /** Handle declaration selected */
  handleDeclarationSelect?: (agreed: boolean) => void
  /** Handle declaration cancel go back */
  handleDeclarationCancel?: () => void
  /** Handle declaration submitted go to check out */
  handleDeclarationSubmit?: () => void
  /** Handle document association with sku and hold for uploads */
  handleFileUploadedForBasketItem?: (
    basketItemId: string,
    document: UploadedDocumentDto,
    holdDetails: IHoldDetails
  ) => void
  /** Handle remove document for a hold on a basket item */
  handleRemoveFileForHoldAsync?: (
    holdDetails: IHoldDetails,
    basketItemId: string
  ) => Promise<void>
  /** Handle download of safety letter */
  handleDownloadSafetyLetter?: () => void
  handleRemoveSpecificFileForHoldAsync?: (item: BasketItemDocumentDto) => void
  /** Show a debug view of basket */
  showDebug?: boolean
}

// Set the appropriate basket Details
export const returnDefaultBasketValues = (
  userRole: UserRole,
  userDetails: UserDto
) => {
  const isMaUser = !!(userRole && UserRoleRecord[userRole as UserRole].isMaUser)
  const defaultRoleBasketDetails = isMaUser
    ? defaultBasketMADetails
    : defaultBasketDetails
  let basketDetails: UpdateBasketDetailsDto = {
    ...defaultRoleBasketDetails,
    deliverToContact: localStorage.getItem(localStorageRecipientName) ?? '',
    recipientEmail: userDetails?.contactCard.email,
    recipientPhoneNumber: getUserPhone(userDetails?.contactCard)
  }
  return basketDetails
}

export const Basket: FunctionComponent<IBasketProps> = ({
  basketViewState = BasketViewState.Loading,
  basket,
  holdInformation,
  quantityErrors,
  isAnItemUnavailable,
  isUpdatingQuantity,
  showDebug,
  countryCode,
  handleRemoveItem,
  handleChangeItemQuantity,
  handleProceedToCheckout,
  hasAgreedToTerms,
  hasDownloadedSafetyLetter,
  handleSafetyLetterDownloadSelect,
  handleDeclarationSelect,
  handleDeclarationCancel,
  handleDeclarationSubmit,
  handleFileUploadedForBasketItem,
  handleRemoveFileForHoldAsync,
  handleRemoveSpecificFileForHoldAsync,
  handleDownloadSafetyLetter
}) => {
  const { t } = useTranslation()

  const shouldDisplayFreightChargeDisclaimer = () => {
    return (
      countryCode &&
      countryCode === CountryAlphaCodes.Australia &&
      basket?.total &&
      basket?.total < 200
    )
  }
  return (
    <ClinPageContentFrame
      hideBreadcrumbs={true}
      className="min-height-basket-class"
      marginBottom="0"
    >
      {basketViewState === BasketViewState.Loading && (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      )}

      {basketViewState === BasketViewState.Basket &&
        basket &&
        basket.items.length === 0 && (
          <>
            <Row>
              <Col>
                <ClinText
                  as="h1"
                  variant={TypographyVariant.H2}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('basket:title')}
                </ClinText>
              </Col>
            </Row>
            <Row align="center" justify="center">
              <Col xs={12} md={8} lg={6}>
                <ClinGroup
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  space={ClinTheme.space[2]}
                >
                  <ClinIcon
                    iconName={ClinIconPathName.OrderBox}
                    iconFill={ClinTheme.colors.grey}
                    iconSize={170}
                  />
                  <ClinText
                    variant={TypographyVariant.H4}
                    fontWeight={ClinTheme.fontWeights.bold}
                    marginTop={ClinTheme.space[5]}
                  >
                    {t('basket:empty_basket:title')}
                  </ClinText>
                  <ClinText
                    textAlign={'center'}
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {t('basket:empty_basket.description')}
                  </ClinText>
                </ClinGroup>
              </Col>
            </Row>
          </>
        )}

      {basketViewState === BasketViewState.Declaration && (
        <>
          <Row>
            <Col sm={12}>
              <ClinText
                as="h1"
                fontWeight={ClinTheme.fontWeights.bold}
                variant={TypographyVariant.H3}
              >
                {t('basket:checkout')}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[3]} hasBorder={true} />
          <ClinButton
            style={{ marginTop: ClinTheme.space[3] }}
            variant="link"
            onClick={() => handleDeclarationCancel && handleDeclarationCancel()}
          >
            <ClinIcon
              style={{ marginRight: ClinTheme.space[2] }}
              iconSize={ClinTheme.fontSizes[3]}
              iconName={ClinIconPathName.ChevronLeft}
            />
            {t('basket:back_button')}
          </ClinButton>
          <ClinSpacer height={ClinTheme.space[4]} />
          <Row>
            <Col sm={12}>
              <ClinStepper
                steps={[
                  {
                    state: StepState.Active,
                    displayText: t('basket:stepper.step_1')
                  },
                  {
                    state: StepState.InComplete,
                    displayText: t('basket:stepper.step_2')
                  },
                  {
                    state: StepState.InComplete,
                    displayText: t('basket:stepper.step_3')
                  },
                  {
                    state: StepState.InComplete,
                    displayText: t('basket:stepper.step_4')
                  }
                ]}
                width={800}
              />
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
          <Row>
            <Col sm={12}>
              <ClinDividerHeading>
                <ClinText
                  as="h2"
                  textTransform="uppercase"
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.white}
                >
                  {t('basket:declaration.title')}
                </ClinText>
              </ClinDividerHeading>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[4]} />
          <Row>
            <Col sm={12}>
              <StyledCheckoutBlock>
                <StyledCheckoutBlockHeader>
                  <ClinText
                    as="h2"
                    fontWeight={ClinTheme.fontWeights.light}
                    fontSize={ClinTheme.fontSizes[4]}
                    color={ClinTheme.colors.primary}
                  >
                    {t('basket:declaration.sub_title')}
                  </ClinText>
                </StyledCheckoutBlockHeader>
                <StyledCheckoutBlockBody>
                  <Row>
                    <Col xs={12} md={8}>
                      <ClinGroup
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <ClinCheckbox
                          onClick={(
                            event: React.MouseEvent<HTMLInputElement>
                          ) =>
                            handleDeclarationSelect &&
                            handleDeclarationSelect(event.currentTarget.checked)
                          }
                          id="supply-declaration"
                        />
                        <ClinText>
                          <Trans
                            i18nKey={'basket:declaration.confirmation_text'}
                            components={{
                              embedLink: (
                                <a
                                  target="_blank"
                                  href="https://assets.publishing.service.gov.uk/media/645e19f5ad8a03000c38b3bc/The_supply_of_unlicensed_medicinal_products__special_GN14.pdf"
                                  rel="noreferrer"
                                ></a>
                              )
                            }}
                            values={[
                              <a href="https://assets.publishing.service.gov.uk/media/645e19f5ad8a03000c38b3bc/The_supply_of_unlicensed_medicinal_products__special_GN14.pdf"></a>
                            ]}
                            shouldUnescape={true}
                          ></Trans>
                        </ClinText>
                      </ClinGroup>
                    </Col>
                  </Row>
                </StyledCheckoutBlockBody>
              </StyledCheckoutBlock>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[4]} />
          {/* Safety Letter */}
          {holdInformation?.hasSafetyLetterHold ? (
            <Row>
              <Col sm={12}>
                <StyledCheckoutBlock>
                  <StyledCheckoutBlockHeader>
                    <ClinText
                      as="h2"
                      fontWeight={ClinTheme.fontWeights.light}
                      fontSize={ClinTheme.fontSizes[4]}
                      color={ClinTheme.colors.primary}
                    >
                      {t('basket:safety_letter.title')}
                    </ClinText>
                  </StyledCheckoutBlockHeader>
                  <StyledCheckoutBlockBody>
                    <Row>
                      <Col>
                        <ClinButton
                          onClick={() =>
                            handleDownloadSafetyLetter &&
                            handleDownloadSafetyLetter()
                          }
                          variant="primary"
                        >
                          {t('basket:safety_letter.button_title')}&nbsp;
                          <ClinIcon iconName={ClinIconPathName.Download} />
                        </ClinButton>
                      </Col>
                    </Row>
                    <ClinSpacer height={ClinTheme.space[4]} />
                    <Row>
                      <Col xs={12} md={8}>
                        <ClinGroup
                          alignItems="flex-start"
                          justifyContent="space-between"
                        >
                          <ClinCheckbox
                            onClick={(
                              event: React.MouseEvent<HTMLInputElement>
                            ) =>
                              handleSafetyLetterDownloadSelect &&
                              handleSafetyLetterDownloadSelect(
                                event.currentTarget.checked
                              )
                            }
                            checked={hasDownloadedSafetyLetter}
                            id="sl-declaration"
                          />
                          <ClinText>
                            {t('basket:safety_letter.confirmation_text')}
                          </ClinText>
                        </ClinGroup>
                      </Col>
                    </Row>
                  </StyledCheckoutBlockBody>
                </StyledCheckoutBlock>
              </Col>
            </Row>
          ) : null}
          <ClinSpacer height={ClinTheme.space[3]} />
          <Row>
            <Col sm={12}>
              <ClinSpacer height={ClinTheme.space[4]} />
              <ClinGroup justifyContent="flex-end">
                <ClinButton
                  onClick={() =>
                    handleDeclarationSubmit && handleDeclarationSubmit()
                  }
                  variant="primary"
                  disabled={
                    holdInformation?.hasSafetyLetterHold
                      ? !hasAgreedToTerms || !hasDownloadedSafetyLetter
                      : !hasAgreedToTerms
                  }
                >
                  {t('common:buttons.continue')}
                </ClinButton>
              </ClinGroup>
            </Col>
          </Row>
        </>
      )}

      {basketViewState === BasketViewState.Basket &&
        basket &&
        basket.items.length > 0 && (
          <StyledWrapper>
            <ClinText
              as="h1"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
              marginBottom={ClinTheme.space[2]}
            >
              {t('basket:title')}
            </ClinText>
            <Row>
              <Col xs={12} md={8}>
                {isAnItemUnavailable && (
                  <ClinAnnounceBar
                    mode={AnnounceMode.Error}
                    title={t('basket:item_unavailable_message')}
                  />
                )}
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                md={7}
                lg={8}
                style={{ marginTop: ClinTheme.space[5] }}
              >
                {/* Line items */}
                {basket &&
                  basket.items.map((basketItem, index) => (
                    <StyledBasketItem key={`basket-item-${basketItem.sku}`}>
                      <ClinText variant={TypographyVariant.H5} marginBottom={2}>
                        {t('basket:item.title')}:
                      </ClinText>
                      <Row>
                        <Col sm={12} md={10}>
                          <ClinText
                            variant={TypographyVariant.LargerParagraph}
                            fontWeight={ClinTheme.fontWeights.bold}
                            color={ClinTheme.colors.primary}
                            marginBottom={ClinTheme.space[3]}
                            marginTop={1}
                          >
                            {
                              basketItem.skuCatalogItem?.item
                                .genericDosageConcatenation
                            }
                          </ClinText>
                        </Col>
                      </Row>

                      <StyledDeleteButton
                        className="delete-btn"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <ClinButton
                          display={'inline-flex'}
                          justifyContent={'center'}
                          variant={'link'}
                          disabled={isUpdatingQuantity}
                          onClick={() =>
                            handleRemoveItem && handleRemoveItem(basketItem)
                          }
                        >
                          {t('common:buttons.delete')}
                          <ClinIcon
                            iconName={ClinIconPathName.Trashcan}
                            style={{ marginLeft: ClinTheme.space[1] }}
                          />
                        </ClinButton>
                      </StyledDeleteButton>
                      {/* Show variant announce if unavailable */}
                      {basketItem.unavailable &&
                        getVariantAnnounce(basketItem, t)}
                      {/*<PortfolioJourneyDebug*/}
                      {/*  catalogueItem={basketItem.skuCatalogItem}*/}
                      {/*/>*/}

                      <StyledQuantitySelectorContainer
                        unavailable={basketItem.unavailable}
                      >
                        {/* TODO: Disabling this on update is rendering and loosing focus */}
                        <ClinTextInput
                          className={'quantity-selector'}
                          label={t('basket:select_quantity')}
                          disabled={
                            isUpdatingQuantity || basketItem.unavailable
                          }
                          key={`${basketItem.sku}`}
                          id={`${basketItem.sku}`}
                          type={'number'}
                          name={`quantity-${basketItem.sku}`}
                          min={1}
                          max={
                            basketItem.skuCatalogItem.item
                              .maximumOrderQuantity || 10000
                          }
                          step={1}
                          width={'100%'}
                          style={{ marginBottom: ClinTheme.space[2] }}
                          defaultValue={basketItem.quantity}
                          hasError={
                            quantityErrors &&
                            quantityErrors.filter(
                              (err) =>
                                err.basketItemId === basketItem.basketItemId
                            ).length > 0
                          }
                          prompt={getErrorForBasketItemId(
                            basketItem.basketItemId,
                            quantityErrors
                          )}
                          onChange={(event) =>
                            handleChangeItemQuantity &&
                            handleChangeItemQuantity(
                              basketItem,
                              parseInt(event.currentTarget.value, 0)
                            )
                          }
                        />
                        {isUpdatingQuantity && <ClinSpinner />}
                        <Hidden md lg xl xxl>
                          <StyledMobileDelete>
                            <ClinButton
                              display={'inline-flex'}
                              justifyContent={'center'}
                              variant={'link'}
                              style={{ marginRight: '0' }}
                              onClick={() =>
                                handleRemoveItem && handleRemoveItem(basketItem)
                              }
                            >
                              {t('common:buttons.delete')}
                              <ClinIcon
                                iconName={ClinIconPathName.Trashcan}
                                style={{ marginLeft: ClinTheme.space[1] }}
                              />
                            </ClinButton>
                          </StyledMobileDelete>
                        </Hidden>
                        <StyledAmountContainer>
                          <StyledAmountContainer style={{ textAlign: 'right' }}>
                            <ClinText
                              as="h4"
                              variant={TypographyVariant.H5}
                              marginTop={'0'}
                              marginBottom={'0'}
                              textAlign="right"
                              color={ClinTheme.colors.darkPurple2}
                            >
                              {t('basket:amount')}:
                            </ClinText>
                            <ClinText
                              as="h5"
                              variant={TypographyVariant.H4}
                              marginTop={'0'}
                              marginBottom={'0'}
                              textAlign="right"
                              color={ClinTheme.colors.darkPurple}
                            >
                              {basketItem.unavailable
                                ? t('glossary:not_applicable')
                                : numberToCurrencyString(
                                    basketItem.amount,
                                    getBrowserLocale(),
                                    {
                                      style: 'currency',
                                      currency:
                                        basketItem.priceAndAvailability
                                          ?.currencyCode,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )}
                            </ClinText>
                          </StyledAmountContainer>
                        </StyledAmountContainer>
                      </StyledQuantitySelectorContainer>
                      <ClinSpacer
                        height={ClinTheme.space[6]}
                        hasBorder={index < basket.items.length - 1}
                      />

                      <ClinAccordion activeItemIndices={[]} hideBorder={true}>
                        <ClinAccordionItem
                          title={t('basket:details')}
                          hideBorder={true}
                          fontSize={3}
                          fontWeight={ClinTheme.fontWeights.normal}
                          iconSize={ClinTheme.space[7]}
                          iconHeight={ClinTheme.space[5]}
                          paddingTop={1}
                        >
                          <StyledDrugPropertiesContainer
                            unavailable={basketItem.unavailable}
                          >
                            {getDrugPropertyItem(
                              DrugProperty.DosageAndPacksize,
                              `${basketItem.skuCatalogItem?.item.formulation} ${basketItem.skuCatalogItem?.item.packSize}`,
                              t
                            )}
                            {basketItem.skuCatalogItem?.item.brand &&
                              getDrugPropertyItem(
                                DrugProperty.Brand,
                                basketItem.skuCatalogItem.item.brand,
                                t
                              )}
                            {basketItem.priceAndAvailability &&
                              getDrugPropertyItem(
                                DrugProperty.UnitPrice,
                                numberToCurrencyString(
                                  parseFloat(
                                    basketItem.priceAndAvailability.listPrice
                                  ),
                                  getBrowserLocale(),
                                  {
                                    style: 'currency',
                                    currency:
                                      basketItem.priceAndAvailability
                                        .currencyCode,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  }
                                ),
                                t
                              )}
                            {getDrugPropertyItem(
                              DrugProperty.CountryOfLicense,
                              `${basketItem.skuCatalogItem?.licenses
                                .map((license) => license.country)
                                .join(', ')}`,
                              t
                            )}
                            {getDrugPropertyItem(
                              DrugProperty.Overlabelled,
                              `${
                                basketItem &&
                                basketItem.skuCatalogItem?.item.overlabelled ===
                                  'Y'
                                  ? `${basketItem.skuCatalogItem?.item.overlabelLanguage} (${basketItem.skuCatalogItem?.item.overlabelType})`
                                  : t('glossary:no')
                              }`,
                              t
                            )}
                            {countryCode !== CountryAlphaCodes.Australia && //TODO ADD HELPER METHOD TO CHECK IF USER IS FROM AUS
                              getDrugPropertyItem(
                                DrugProperty.Delivery,
                                basketItem.priceAndAvailability?.shippingMethod,
                                t
                              )}
                          </StyledDrugPropertiesContainer>
                        </ClinAccordionItem>
                      </ClinAccordion>

                      {/* Display document upload for any holds on this basketItem/SKU */}
                      {/* Display holds for each item */}
                      {!basketItem.unavailable &&
                        basketItem.holds &&
                        basketItem.holds?.length > 0 &&
                        countryCode &&
                        countryCode !== CountryAlphaCodes.Australia && (
                          <>
                            <ClinSpacer />
                            <ClinAnnounceBar
                              mode={AnnounceMode.Information}
                              title={t('basket:supporting_documents_required')}
                              marginBottom={ClinTheme.space[3]}
                            />
                            <ClinText
                              as={'div'}
                              color={ClinTheme.colors.primary}
                              fontSize={ClinTheme.fontSizes[3]} // TODO: Font size needs addressing client request this to be smaller than h4
                            >
                              {t('basket:supporting_documents_required')}
                            </ClinText>
                            <ClinText
                              as={'p'}
                              variant={TypographyVariant.LargeParagraph}
                            >
                              {t('basket:accepted_file_types')}
                            </ClinText>
                            <ClinSpacer />
                          </>
                        )}
                      {basketItem &&
                        basketItem.holds &&
                        basketItem.holds.map((hold, index) => {
                          // Have we already uploaded a doc?
                          const submittedHoldDocument = getUploadedHoldFile(
                            basketItem.documents,
                            hold
                          )
                          return countryCode &&
                            countryCode === CountryAlphaCodes.Australia ? (
                            <SupportDocuments
                              key={`doc-upload-container-${index}`}
                              index={index}
                              hold={hold}
                              basketItem={basketItem}
                              submittedHoldDocument={submittedHoldDocument}
                              handleFileUploadedForBasketItem={
                                handleFileUploadedForBasketItem
                              }
                              handleRemoveFileForHoldAsync={
                                handleRemoveFileForHoldAsync
                              }
                              handleRemoveSpecificFileForHoldAsync={(
                                item: BasketItemDocumentDto
                              ) =>
                                handleRemoveSpecificFileForHoldAsync &&
                                handleRemoveSpecificFileForHoldAsync(item)
                              }
                            />
                          ) : (
                            <StyledBasketHoldItem
                              key={`doc-upload-container-${index}`}
                            >
                              {hold.type === HoldType.SafetyLetter ? (
                                <>
                                  <ClinText
                                    as={'div'}
                                    variant={TypographyVariant.H5}
                                  >
                                    {hold.requiredDocumentName}
                                  </ClinText>
                                  <ClinText
                                    as={'p'}
                                    variant={TypographyVariant.LargeParagraph}
                                  >
                                    {hold.documentPrompt}
                                  </ClinText>
                                </>
                              ) : (
                                <DocumentUploadContainer
                                  isPreOrderVariant={true}
                                  canRemoveFile={true}
                                  defaultFileName={
                                    submittedHoldDocument
                                      ? submittedHoldDocument?.fileName
                                      : undefined
                                  }
                                  uploadDetails={
                                    submittedHoldDocument
                                      ? {
                                          uploadDate:
                                            submittedHoldDocument.uploadedOn,
                                          uploadedBy: t('basket:you')
                                        }
                                      : undefined
                                  }
                                  handleFileUploaded={(document) => {
                                    analyticsServiceSingleton.trackEvent(
                                      AnalyticsEvent.DocumentUpload,
                                      {
                                        sku: basketItem.sku,
                                        documentType: hold.requiredDocumentName
                                      }
                                    )
                                    handleFileUploadedForBasketItem &&
                                      hold.holdDto?.holdName &&
                                      handleFileUploadedForBasketItem(
                                        basketItem.basketItemId,
                                        document,
                                        hold
                                      )
                                  }}
                                  handleFileRemoved={() =>
                                    handleRemoveFileForHoldAsync &&
                                    handleRemoveFileForHoldAsync(
                                      hold,
                                      basketItem.basketItemId
                                    )
                                  }
                                  documentPrompt={hold.documentPrompt}
                                  warehouseAddress={hold.wareHouseAddress}
                                  prompt={hold.requiredDocumentName}
                                />
                              )}
                            </StyledBasketHoldItem>
                          )
                        })}
                    </StyledBasketItem>
                  ))}
              </Col>
              {/* Summary */}
              {basket && basket.items && basket.items.length > 0 && (
                <Col xs={12} md={5} lg={4}>
                  <StyledSummary>
                    <StyledSummaryTitle>
                      <ClinText
                        as="span"
                        variant={TypographyVariant.H4}
                        color={ClinTheme.colors.darkPurple3}
                      >
                        {t('basket:summary')}
                      </ClinText>
                      <ClinText
                        whiteSpace="nowrap"
                        as="span"
                        variant={TypographyVariant.H5}
                        color={ClinTheme.colors.darkPurple4}
                      >
                        {` (${basket.items.length} ${
                          basket.items.length > 1
                            ? t('basket:item.items')
                            : t('basket:item.item')
                        })`}
                      </ClinText>
                    </StyledSummaryTitle>
                    <ClinSpacer height={ClinTheme.space[4]} />
                    <ClinGroup
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ClinText
                        variant={TypographyVariant.H5}
                        color={ClinTheme.colors.darkPurple2}
                      >
                        {t('basket:subtotal')}:
                      </ClinText>
                      <ClinText
                        as={'h5'}
                        variant={TypographyVariant.H4}
                        color={ClinTheme.colors.darkPurple3}
                        className={'basket-total'}
                      >
                        {!basket.total
                          ? '--'
                          : numberToCurrencyString(
                              basket.total,
                              getBrowserLocale(),
                              {
                                style: 'currency',
                                currency: basket.currencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}
                      </ClinText>
                    </ClinGroup>
                    <ClinSpacer height={ClinTheme.space[7]} hasBorder={true} />
                    <ClinButton
                      variant="primary"
                      disabled={
                        isUpdatingQuantity ||
                        basket.items.length === 0 ||
                        !basket.total ||
                        isAnItemUnavailable
                      }
                      onClick={() =>
                        handleProceedToCheckout && handleProceedToCheckout()
                      }
                    >
                      <Hidden xs sm md lg>
                        {t('basket:proceed_to_checkout')}
                      </Hidden>
                      <Visible xs sm md lg>
                        {t('basket:checkout')}
                      </Visible>
                    </ClinButton>
                    {shouldDisplayFreightChargeDisclaimer() && (
                      <ClinText
                        as="span"
                        variant={TypographyVariant.Paragraph}
                        color={ClinTheme.colors.black}
                        marginTop={ClinTheme.space[4]}
                      >
                        {t('basket:fright_charge_disclamer', {
                          amount: freightChargeDisclaimer.amount,
                          charges: freightChargeDisclaimer.charges
                        })}
                      </ClinText>
                    )}
                  </StyledSummary>
                </Col>
              )}
            </Row>
          </StyledWrapper>
        )}

      {basketViewState === BasketViewState.Submitting && (
        <>
          <Row>
            <Col sm={6}>
              <ClinText
                variant={TypographyVariant.H4}
                marginTop={ClinTheme.space[4]}
              >
                {t('basket:processing')}...
              </ClinText>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <StyledSpinnerContainer>
                <ClinLoader />
              </StyledSpinnerContainer>
            </Col>
          </Row>
        </>
      )}
      {showDebug && <pre>{JSON.stringify(basket, null, 2)}</pre>}
    </ClinPageContentFrame>
  )
}
