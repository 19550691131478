import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { NavLink, NavLinkProps } from 'react-router-dom'

export interface IDropdownNavButtonStyles extends NavLinkProps {
  /** Whether link is active or not */
  disabled?: boolean
}

export const StyledDropdownNavButton = styled(
  NavLink
)<IDropdownNavButtonStyles>(
  {
    display: 'block',
    fontFamily: ClinTheme.fonts.body,
    lineHeight: `${ClinTheme.space[4]}px`,
    letterSpacing: `${ClinTheme.letterSpacing[1]}px`,
    textDecoration: 'none',
    transition:
      '.2s linear background-color, .2s linear color, .2s linear borderColor, .2s linear underline',
    whiteSpace: 'nowrap',
    WebkitTapHighlightColor: ClinTheme.colors.transparent,
    color: 'inherit',
    border: 'none',
    borderBottom: `solid 1px ${ClinTheme.colors.grey}`,
    fontSize: ClinTheme.fontSizes[3],
    fontWeight: ClinTheme.fontWeights.light,
    padding: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`,
    marginBottom: 0,
    [`:focus`]: {
      outline: 'none'
    },
    [`:last-child`]: {
      borderBottom: 'none'
    },
    [`&.active`]: {
      backgroundColor: ClinTheme.colors.lightGrey
    },
    [`&[disabled]`]: {
      pointerEvents: 'none'
    }
  },
  ({ isActive, disabled }: IDropdownNavButtonStyles) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
    backgroundColor:
      isActive || disabled
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.transparent,
    ':hover': {
      backgroundColor: ClinTheme.colors.lightGrey,
      textDecoration: 'none',
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary
    }
  })
)

export const StyledDropdownUpperNavButton = styled(
  NavLink
)<IDropdownNavButtonStyles>(
  {
    display: 'block',
    fontFamily: ClinTheme.fonts.body,
    lineHeight: `${ClinTheme.space[4]}px`,
    height: '32px',
    width: '91px',
    textDecoration: 'none',
    textAlign: 'right',
    transition:
      '.2s linear background-color, .2s linear color, .2s linear borderColor, .2s linear underline',
    whiteSpace: 'nowrap',
    WebkitTapHighlightColor: ClinTheme.colors.transparent,
    color: 'inherit',
    border: 'none',
    fontSize: ClinTheme.fontSizes[1],
    fontWeight: ClinTheme.fontWeights.normal,
    paddingTop: '8px',
    paddingLeft: '35px',
    paddingRight: '13px',
    marginBottom: 0,
    [`:focus`]: {
      outline: 'none'
    },
    [`:last-child`]: {
      borderBottom: 'none'
    },
    [`&.active`]: {
      backgroundColor: ClinTheme.colors.lightGrey
    },
    [`&[disabled]`]: {
      pointerEvents: 'none'
    }
  },
  ({ isActive, disabled }: IDropdownNavButtonStyles) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
    backgroundColor:
      isActive || disabled
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.transparent,
    ':hover': {
      backgroundColor: ClinTheme.colors.lightGrey,
      textDecoration: 'none',
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary
    }
  })
)
