import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'

const MediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledOrdersAnnounce = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [mediaQuery(MediumUpBreakpoint)]: {
    flexDirection: 'row'
  }
})

export const StyledOrdersAnnounceText = styled.div({
  flex: '0 0 100%',
  [mediaQuery(MediumUpBreakpoint)]: {
    flex: '0 0 60%'
  }
})

export const StyledOrdersAnnounceCta = styled.div({
  flex: '0 0 100%',
  textAlign: 'left',
  boxSizing: 'border-box',
  marginTop: ClinTheme.space[2],

  [mediaQuery(MediumUpBreakpoint)]: {
    flex: '0 0 40%',
    textAlign: 'right',
    marginTop: 0,
    paddingRight: ClinTheme.space[2]
  }
})

export const StyledMyOrdersToggle = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'relative',
  alignItems: 'center',
  [`> .clin-text, > .clin-switch`]: {
    margin: 0
  }
})
