import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import {
  StyledCreateButton,
  StyledEnrolButton,
  StyledHeader,
  StyledHeaderButtonWrapper,
  StyledHeaderItems,
  StyledHeaderItemsWrapper,
  StyledStockOrderButton
} from './ProgramDetail.styles'

import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { useAppContext } from '../../../context/app'
import { AnalyticsEvent } from '../../../services/Analytics'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { ProgramCountryStatus } from '../../../constants'
import { SideBarMode } from './ProgramDetail.models'

interface IProgramDetailHeaderProps {
  programId: number
  showStockOrderButton?: boolean
  programName: string | undefined
  sponsor: string | undefined
  programCountryStatus: string | undefined
  isProgramEnrolled?: boolean
  programInfoVariant?: SideBarMode
}

export const ProgramDetailHeader: FunctionComponent<
  IProgramDetailHeaderProps
> = ({
  programId,
  showStockOrderButton,
  programName,
  sponsor,
  programCountryStatus,
  isProgramEnrolled,
  programInfoVariant
}) => {
  const { t } = useTranslation()
  let history = useHistory()
  const { userDetails, institute } = useAppContext()

  return (
    <StyledHeader>
      <Container>
        <Row>
          <Col sm={10}>
            <StyledHeaderItemsWrapper>
              {programName  && (
                <StyledHeaderItems>
                  <ClinText
                    fontSize={ClinTheme.fontSizes[4]}
                    fontWeight={ClinTheme.fontWeights.medium}
                  >
                    {programName}
                  </ClinText>
                  {sponsor && (<ClinText
                    fontSize={ClinTheme.fontSizes[3]}
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.darkGrey}
                  >
                    {t('program_detail:by')} {sponsor}
                  </ClinText>)}
                </StyledHeaderItems>
              )}
              {isProgramEnrolled ? (
                <StyledHeaderButtonWrapper>
                  {showStockOrderButton &&
                    programCountryStatus ===
                      ProgramCountryStatus.AcceptingNewPatients && (
                      <StyledStockOrderButton
                        variant="purpleSecondaryNarrow"
                        onClick={() => {
                          history.push(`/create-patient`, {
                            programId: programId,
                            stockOrder: true,
                            from: window.location.pathname
                          })
                        }}
                      >
                        {t('program_detail:stock_order_button')}
                      </StyledStockOrderButton>
                    )}
                  {programCountryStatus ===
                    ProgramCountryStatus.AcceptingNewPatients && (
                    <StyledCreateButton
                      variant="purpleNarrow"
                      onClick={() => {
                        analyticsServiceSingleton.trackEvent(
                          AnalyticsEvent.CreatePatient,
                          {
                            location: history.location.pathname,
                            instituteId: institute.data?.instituteId,
                            userId: userDetails?.contactCard.contactId
                          }
                        )

                        history.push(`/create-patient`, {
                          programId: programId,
                          from: window.location.pathname
                        })
                      }}
                    >
                      {t('program_detail:create_patient_button')}
                    </StyledCreateButton>
                  )}
                </StyledHeaderButtonWrapper>
              ) : (
                programInfoVariant === SideBarMode.NotEnrolled && (
                  <StyledHeaderButtonWrapper>
                    <StyledEnrolButton
                      variant="purpleNarrow"
                      onClick={() => {
                        history.push(
                          `/programs/access-programs/${programId}/enrolment-form`
                        )
                      }}
                    >
                      {t('program_detail:enrol_program_button')}
                    </StyledEnrolButton>
                  </StyledHeaderButtonWrapper>
                )
              )}
            </StyledHeaderItemsWrapper>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  )
}
