import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[0]

export const StyledPageHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '407px',
  color: ClinTheme.colors.white,
  backgroundColor: ClinTheme.colors.primary,
  [mediaQueryDown(mobileUpBreakpoint)]: {
    height: '556px',
    alignItems: 'flex-start',
    paddingTop: '152px',
    [`.description`]: {
      fontSize: ClinTheme.fontSizes[2]
    },
    [`.clin_column`]: {
      paddingLeft: '30px !important'
    }
  }
})

export const StyledSearchIcon = styled.span({
  display: 'inline',

  [mediaQueryDown(mobileUpBreakpoint)]: {
    display: 'none'
  }
})
