import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { $enum } from 'ts-enum-util'
import {
  getEnrolledPrograms,
  cancelGetEnrolledPrograms,
  getAllPrograms,
  cancelGetAllPrograms,
  AuthError
} from '../../services/ApiService'
import { useAppContext } from '../../context/app'
import { AxiosError } from 'axios'
import { AccessPrograms } from './AccessPrograms'
import { IProgramSummary } from '../../types'
import { ProgramSearchDto } from '../../types/swaggerTypes'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import {
  getParamValueFor,
  IPagination,
  useUpdateQueryParam
} from '../../components/ClinPagination/ClinPagination.model'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import {
  ProgramColumn,
  programColumns,
  getSortColumnFromURLParams,
  defaultRowsPerPage,
  defaultSearchParams,
  defaultPagination,
  changeDefaultRowsPerPage
} from './AccessProgramsContainer.models'
import { ProgramColumnNames } from '../../constants/program'
import { UserRoleRecord, UserRole } from '../../constants'
import { useEffectOnlyOnce } from '../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsPageEvent } from '../../services/Analytics/AnalyticsPageEvent'

interface IAccessProgramsContainerProps extends RouteComponentProps {}

export const AccessProgramsContainer: React.FC<
  IAccessProgramsContainerProps
> = ({ history, location }) => {
  const { institute, userRole, supportContact } = useAppContext()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [programs, setPrograms] = React.useState<IProgramSummary[]>([])
  const isMaUser = userRole && UserRoleRecord[userRole as UserRole].isMaUser

  // Restore page state from URL params
  const urlSearchParams = new URLSearchParams(location.search)

  // Restore pagination state
  // PageSize
  defaultSearchParams.pagination.take = getParamValueFor(
    'pageSize',
    urlSearchParams,
    defaultRowsPerPage
  )
  // PageIndex
  defaultPagination.take = defaultSearchParams.pagination.take
  defaultSearchParams.pagination.skip =
    defaultSearchParams.pagination.take *
    getParamValueFor('pageIndex', urlSearchParams)

  const [pagination, setPagination] =
    React.useState<IPagination>(defaultPagination)

  // TabIndex
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(() => {
    const tabIndex = urlSearchParams.get('tabIndex')
    let defaultTabIndex: number = 0
    if (typeof tabIndex === 'string') {
      defaultTabIndex = parseInt(tabIndex)
    }
    return defaultTabIndex
  })

  // Sorting column
  const [sortColumn, setSortColumn] = React.useState<ProgramColumn>(() => {
    const defaultSortColumn = programColumns[0]
    const restoredSortByColumn = getSortColumnFromURLParams(
      urlSearchParams,
      defaultSortColumn
    )
    defaultSearchParams.sorting.sortBy = restoredSortByColumn
      ? restoredSortByColumn.name.toString()
      : defaultSortColumn.name.toString()
    return restoredSortByColumn
  })

  // Sort direction
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(
    () => {
      const sortDirection = urlSearchParams.get('sortDirection')
      const restoredSortDirection = $enum(SortDirectionType).asValueOrDefault(
        sortDirection,
        SortDirectionType.Ascending
      )
      defaultSearchParams.sorting.order = restoredSortDirection
        .toString()
        .toUpperCase()
      return restoredSortDirection
    }
  )

  const [searchParams, setSearchParams] =
    React.useState<ProgramSearchDto>(defaultSearchParams)

  const { dispatch, portfolioCountryCode } = useAppContext()

  const handlePageClicked = (selectedPageIndex: number) => {
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * searchParams.pagination.take,
        take: searchParams.pagination.take
      }
    })
  }

  const handleTabSelected = (tabIndex: number) => {
    changeDefaultRowsPerPage(tabIndex)
    setIsLoading(true)
    selectedTabIndex ? cancelGetAllPrograms() : cancelGetEnrolledPrograms()
    setPrograms([])
    setPagination(defaultPagination)
    const isSameTab = selectedTabIndex === tabIndex // reset pagination
    setSearchParams({
      ...searchParams,
      query: '',
      filter: {},
      pagination: isSameTab
        ? searchParams.pagination
        : {
            skip: 0,
            take: defaultRowsPerPage
          }
    })
    setSelectedTabIndex(tabIndex)
  }

  const toggleSortDirection = (
    current: SortDirectionType
  ): SortDirectionType => {
    if (current === SortDirectionType.None) {
      return SortDirectionType.Descending
    }
    if (current === SortDirectionType.Descending) {
      return SortDirectionType.Ascending
    }
    return SortDirectionType.None
  }

  const handleToggleSort = (columnName: ProgramColumn) => {
    setIsLoading(true)
    const newSortDirection =
      sortColumn.viewName === columnName.viewName
        ? toggleSortDirection(sortDirection)
        : SortDirectionType.Descending
    setSortDirection(newSortDirection)
    setSortColumn(columnName)
    setSearchParams({
      ...searchParams,
      sorting: {
        sortBy: columnName.name,
        order: newSortDirection.toUpperCase()
      }
    })
  }

  const handleRowClicked = (programId: string) => {
    history.push(`/programs/access-programs/${programId}`, {
      from: window.location.pathname
    })
  }

  const handleOnSearch = (query: string) => {
    cancelGetAllPrograms()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip: 0
      },
      query
    })
  }

  useEffect(() => {
    const programType = selectedTabIndex ? getAllPrograms : getEnrolledPrograms
    // handle case when user sort table by "Availability" and than switching to the first tab
    if (
      !selectedTabIndex &&
      searchParams.sorting.sortBy === ProgramColumnNames.Availability
    ) {
      setSortDirection(SortDirectionType.Ascending)
      setSortColumn(programColumns[1])
      setSearchParams({
        ...searchParams,
        sorting: {
          sortBy: programColumns[1].name,
          order: SortDirectionType.Ascending.toUpperCase()
        }
      })
      return
    }

    institute?.data &&
      !institute.isLoading &&
      programType(searchParams)
        .then((response) => {
          setIsLoading(false)
          if (response) {
            const programsFromAPI: IProgramSummary[] | null =
              response.data.result
            setPrograms(programsFromAPI)
            const paginationDto = response.data.pagination
            paginationDto &&
              setPagination({
                count: paginationDto.count,
                skip: paginationDto.skip,
                take: paginationDto.take,
                total: paginationDto.total
              })
          }
        })
        .catch((error: AxiosError) => {
          const { code, message } = error

          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          // If we have a full error show it
          if (error.response) {
            const { title, detail } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error fetching access programs. ${title ?? ''} ${
                  message ?? ''
                }`
              )
            )
            console.warn(title, detail)
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error fetching access programs. ${message} ${
                  code ?? ''
                }`
              )
            )
          }

          analyticsServiceSingleton.trackError(error)
          setIsLoading(false)
        })
    return () => {
      selectedTabIndex ? cancelGetAllPrograms() : cancelGetEnrolledPrograms()
    }
  }, [
    searchParams,
    dispatch,
    institute.data,
    institute.isLoading,
    institute,
    selectedTabIndex
  ])

  const handleToggleOrderRow = (programId: number) => {
    setPrograms(
      programs.map((program) => {
        if (program.programId !== programId) {
          return program
        }
        return { ...program, isOpen: !program.isOpen }
      })
    )
  }

  const handlePageSizeChange = (pageSize: number) => {
    selectedTabIndex ? cancelGetAllPrograms() : cancelGetEnrolledPrograms()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }
  // Track changes to pagination and tabs as they update and update query string if it changes
  useUpdateQueryParam({ tabIndex: selectedTabIndex })
  useUpdateQueryParam({
    pageIndex: searchParams.pagination.skip / searchParams.pagination.take
  })
  useUpdateQueryParam({ pageSize: searchParams.pagination.take })
  useUpdateQueryParam({ sortColumn: sortColumn.name.toString() })
  useUpdateQueryParam({ sortDirection: sortDirection.toString() })

  type Deps = boolean[]
  useEffectOnlyOnce(
    () => {
      analyticsServiceSingleton.trackPageView(
        AnalyticsPageEvent.ViewProgramCatalogue,
        {}
      )
    },
    [isLoading],
    (dependencies: Deps) => dependencies[0] === false
  )

  return (
    <AccessPrograms
      isLoading={isLoading}
      programs={programs}
      pagination={pagination}
      selectedSortColumn={sortColumn}
      selectedSortDirection={sortDirection}
      selectedTabIndex={selectedTabIndex}
      isMaUser={isMaUser}
      supportContact={supportContact}
      userCountry={portfolioCountryCode}
      handleTabSelected={handleTabSelected}
      handlePageClicked={handlePageClicked}
      handleRowClicked={handleRowClicked}
      handleToggleSort={handleToggleSort}
      handleToggleOrderRow={handleToggleOrderRow}
      handlePageSizeChange={handlePageSizeChange}
      handleOnSearch={handleOnSearch}
    />
  )
}
