import React, { FunctionComponent } from 'react'
import {
  StyledOpaHeader,
  IOpaHeaderStyles,
  StyledOpaHeaderWrapper,
  StyledOpaHeaderContainer
} from './OpaHeader.styles'
import { Col, Row } from 'react-grid-system'
import { StyledTitle } from '../../features/IconValueCell/IconValueCell.styles'
import { ClinText } from '../ClinText'
import {
  StyledLogo,
  StyledLogoButton,
  StyledLogoLinkInner
} from '../ClinNavbar/ClinNavbar.styles'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { OpaHeaderStepper } from '../OpaHeaderStepper'

interface IOpaHeaderProps extends IOpaHeaderStyles {
  patientName?: string
  dateOfBirth?: string
  programDetails?: string
  physicianName?: string
  sponsorName?: string
  checkoutStep?: number
  poNumber?: string
  isParentSaving?: boolean
  isParentCancelling?: boolean
  programId? : string
  setIsLogoClicked: (value: boolean) => void
  handleCancelButton: () => void
  handleSaveAndCloseButton: (poNumber: string | undefined) => void
}

export const OpaHeader: FunctionComponent<IOpaHeaderProps> = ({
  patientName,
  dateOfBirth,
  programDetails,
  physicianName,
  sponsorName,
  isParentSaving,
  isParentCancelling,
  checkoutStep,
  programId,
  poNumber,
  setIsLogoClicked,
  handleCancelButton,
  handleSaveAndCloseButton
}) => {
  const { t } = useTranslation()
  return (
    <StyledOpaHeader>
      <StyledOpaHeaderWrapper>
        <Row style={{ width: '100%' }}>
          <Col lg={12}>
            <Row>
              <Col lg={2}>
                <StyledLogoButton
                  variant="link"
                  onClick={() => setIsLogoClicked(true)}
                >
                  <StyledLogoLinkInner>
                    <StyledLogo inverted style={{ maxWidth: '140px' }} />
                  </StyledLogoLinkInner>
                </StyledLogoButton>
              </Col>
              <Col lg={8}>
                <StyledOpaHeaderContainer style={{ maxWidth: '700px' }}>
                  <Row
                    style={{
                      paddingLeft: '26px',
                      borderLeft: '1px solid #BEB2D5'
                    }}
                  >
                    <Col>
                      <StyledTitle
                        style={{
                          color: ClinTheme.colors.white,
                          marginBottom: '14px',
                          fontWeight: ClinTheme.fontWeights.medium,
                          fontSize: ClinTheme.fontSizes[3]
                        }}
                      >
                        {t('opa_checkout:order_for_patient', {
                          PatientNumber: patientName
                        })}
                      </StyledTitle>
                      <Row>
                        {dateOfBirth && (
                          <Col lg={3}>
                            <ClinText
                              color={ClinTheme.colors.white}
                              fontWeight={ClinTheme.fontWeights.medium}
                            >
                              {t('opa_checkout:date_of_birth')}
                            </ClinText>
                            <ClinText color={ClinTheme.colors.white}>
                              {DateTime.fromISO(dateOfBirth, {zone: 'utc'}).toLocaleString()}
                            </ClinText>
                          </Col>
                        )}
                        <Col lg={3}>
                          <ClinText
                            color={ClinTheme.colors.white}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('opa_checkout:program')}
                          </ClinText>
                          <ClinText color={ClinTheme.colors.white}>
                            {programDetails}
                          </ClinText>
                        </Col>
                        <Col lg={3}>
                          <ClinText
                            color={ClinTheme.colors.white}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('opa_checkout:physician')}
                          </ClinText>
                          <ClinText color={ClinTheme.colors.white}>
                            {physicianName}
                          </ClinText>
                        </Col>
                        <Col lg={3}>
                          <ClinText
                            color={ClinTheme.colors.white}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('opa_checkout:sponsors')}
                          </ClinText>
                          <ClinText color={ClinTheme.colors.white}>
                            {sponsorName}
                          </ClinText>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </StyledOpaHeaderContainer>
              </Col>
              <Col lg={2} style={{ textAlign: 'right' }}>
                <ClinIcon
                  iconName={ClinIconPathName.Lock}
                  style={{
                    borderRadius: '50%',
                    padding: '12px',
                    backgroundColor: ClinTheme.colors.primaryLight,
                    color: ClinTheme.colors.white
                  }}
                ></ClinIcon>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledOpaHeaderWrapper>
      <OpaHeaderStepper
        currentStep={1}
        totalSteps={2}
        isParentCancelling={isParentCancelling}
        isParentSaving={isParentSaving}
        textContentArray={[
          t('opa_checkout:checkout_header_step_one'),
          t('opa_checkout:checkout_header_step_two')
        ]}
        poNumber={poNumber}
        programName={programDetails}
        programId={programId}
        handleCancelButton={handleCancelButton}
        handleSaveAndCloseButton={handleSaveAndCloseButton}
      ></OpaHeaderStepper>
    </StyledOpaHeader>
  )
}
