import React, { FunctionComponent, useEffect } from 'react'
import { BookmarkToggleButton } from './BookmarkToggleButton'
import { BookmarkStatus } from '../../pages/Bookmarks/Bookmarks.model'
import {
  createBookmark,
  getItemBookmarkStatus,
  deleteBookmark,
  AuthError,
  cancelCreateBookmark,
  cancelDeleteBookmark,
  getCancelGetLastItemBookmarkStatus
} from '../../services/ApiService'
import { BookmarkableItem } from '../../types/BookmarkableItem'
import {
  createConfirmationEvent,
  clearConfirmationEvent
} from '../../events/ConfirmationEvent'
import { BookmarkedConfirmation } from '../BookmarkedConfirmation'
import { AxiosError } from 'axios'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../context/app'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'

const confirmationLocalStorageKey = 'bookmarkConfirmationShownTo'

export const BookmarkToggleButtonContainer: FunctionComponent<
  BookmarkableItem & { iconOnly?: boolean }
> = ({ itemId, itemType, iconOnly = false }) => {
  const { dispatch, user } = useAppContext()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [bookmarkId, setBookmarkId] = React.useState<string | null | undefined>(
    undefined
  )
  const [isHover, setIsHover] = React.useState<boolean>(false)

  useEffect(() => {
    getItemBookmarkStatus({ itemId, itemType })
      .then((bookmark) => {
        setBookmarkId(bookmark && bookmark.bookmarkId)
        setIsLoading(false)
      })
      .catch((error: AxiosError) => {
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `There was an error loading your bookmark. ${error.message} ${
              error.code ? error.code : ''
            }`
          )
        )
      })
    return getCancelGetLastItemBookmarkStatus()
  }, [itemId, itemType, dispatch])

  const bookmarkedStatus = isLoading
    ? BookmarkStatus.Loading
    : bookmarkId
    ? BookmarkStatus.Bookmarked
    : BookmarkStatus.NotBookmarked

  const handleUpdateError = (error: AxiosError) => {
    // If request is cancelled continue
    if (error.message === AuthError.RequestCancelled) {
      return
    }
    dispatch(
      createAnnounceEvent(
        AnnounceMode.Error,
        `There was an error updating your bookmark. ${error.message} ${
          error.code ? error.code : ''
        }`
      )
    )
  }

  useEffect(() => {
    return () => {
      cancelCreateBookmark()
      cancelDeleteBookmark()
    }
  }, [])

  return (
    <BookmarkToggleButton
      iconOnly={iconOnly}
      bookmarkedStatus={bookmarkedStatus}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      isHover={isHover}
      toggleBookmark={() => {
        if (bookmarkedStatus === BookmarkStatus.Loading) {
          return
        }

        if (bookmarkedStatus === BookmarkStatus.NotBookmarked) {
          createBookmark({
            itemId,
            itemType
          })
            .then((response) => {
              setIsLoading(false)
              setBookmarkId(response.data.bookmarkId)

              const confirmationShownTo = (
                localStorage.getItem(confirmationLocalStorageKey) || ''
              )
                .split(',')
                .filter((sub) => !!sub)
              const subjectId = user!.profile.sub

              if (!confirmationShownTo.includes(subjectId)) {
                dispatch(
                  createConfirmationEvent({
                    children: (
                      <BookmarkedConfirmation
                        closeConfirmation={() =>
                          dispatch(clearConfirmationEvent())
                        }
                      />
                    ),
                    marginRight: 190
                  })
                )
                confirmationShownTo.push(subjectId)
                localStorage.setItem(
                  confirmationLocalStorageKey,
                  confirmationShownTo.join(',')
                )
              }

              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.BookmarkAddEvent,
                {
                  bookmarkId: itemId,
                  itemType
                }
              )
            })
            .catch(handleUpdateError)
        } else {
          deleteBookmark(bookmarkId!)
            .then(() => {
              setIsLoading(false)
              setBookmarkId(null)
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.BookmarkRemoveEvent,
                {
                  bookmarkId
                }
              )
            })
            .catch(handleUpdateError)
        }

        setIsLoading(true)
      }}
    />
  )
}
