import React, { FunctionComponent, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import NavigationPrompt from 'react-router-navigation-prompt'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinModal } from '../../components/ClinModal'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  StyledActions,
  StyledSaveButton,
  StyledSubmit
} from './OpaNavigationPrompt.styles'
import { ClinSpacer } from '../ClinSpacer'

interface IPromptProps {
  when?: boolean
  manualTrigger? :boolean
  handleCloseEvent?: () => void
  handleSaveEvent?: () => void
  setShouldShowOPAError?: (arg0: boolean) => void
}

export const OpaNavigationPrompt: FunctionComponent<IPromptProps> = ({
  handleCloseEvent,
  handleSaveEvent,
  setShouldShowOPAError,
  when = true,
  manualTrigger = false
}) => {
  const { t } = useTranslation()
  return (
    <NavigationPrompt when={when} renderIfNotActive= {manualTrigger}>
      {({ onConfirm, onCancel }) => (
        <ClinModal
          borderRadius="4px"
          height="auto"
          isOpen={true}
          maxWidth="xxs"
          padding="32px"
          isDismissible={false}
        >
          <Container>
            <Row justify="center">
              <Col style={{ padding: 0 }}>
                <Row>
                  <Col>
                    <ClinText
                      className="prompt-title"
                      as="h4"
                      variant={TypographyVariant.LargerParagraph}
                      fontWeight={ClinTheme.fontWeights.bold}
                      color={ClinTheme.colors.primary}
                      letterSpacing={0}
                    >           
                      {t('opa_checkout:opa_exit_prevention_modal.save_and_close_title')}
                    </ClinText>
                    <ClinSpacer height={'26px'}></ClinSpacer>
                    <ClinText
                      className="prompt-description"
                      variant={TypographyVariant.LargeParagraph}
                      marginBottom="0"
                      letterSpacing={0}
                    >
                      {t('opa_checkout:opa_exit_prevention_modal.save_and_close_text')}
                    </ClinText>
                  </Col>
                </Row>
                <StyledSubmit>
                  <Row justify="between">
                    <Col md={5} lg={6}>
                      <StyledActions>
                        <ClinButton
                          variant="purpleSecondaryNarrow"
                          onClick={() => {
                            handleCloseEvent && handleCloseEvent()
                            setShouldShowOPAError && setShouldShowOPAError(false)
                            onCancel()
                          }}
                          style={{
                            minWidth: '183px',
                            paddingLeft: '24px',
                            paddingRight: '24px'
                          }}
                        >
                         {t('opa_checkout:opa_exit_prevention_modal.back_to_form')}
                        </ClinButton>
                        <StyledSaveButton
                          variant="purpleNarrow"
                          onClick={() => {
                            handleSaveEvent && handleSaveEvent()
                            setShouldShowOPAError && setShouldShowOPAError(false)
                            onCancel()
                          }}
                          style={{
                            minWidth: '171px',
                            paddingLeft: '24px',
                            paddingRight: '24px'
                          }}
                        >
                           {t('common:buttons.save_and_close')}
                        </StyledSaveButton>
                      </StyledActions>
                    </Col>
                  </Row>
                </StyledSubmit>
              </Col>
            </Row>
          </Container>
        </ClinModal>
      )}
    </NavigationPrompt>
  )
}
