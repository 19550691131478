import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton/ClinButton'
import { mediaQuery } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]
const tabletUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledRealWorldDataCard = styled.aside({
  position: 'relative',
  top: '0',
  right: '0',
  width: 'auto',
  borderRadius: ClinTheme.radii[3],
  background: ClinTheme.colors.white,
  textAlign: 'center',
  height: '360px',
  boxShadow: ClinTheme.shadows[1],
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '160px',
    objectFit: 'cover',
    borderTopLeftRadius: ClinTheme.radii[3],
    borderTopRightRadius: ClinTheme.radii[3]
  },
  [mediaQuery(mobileUpBreakpoint)]: {
    position: 'relative',
    top: '0',
    right: '0',
    width: 'auto'
  },
  [mediaQuery(tabletUpBreakpoint)]: {
    position: 'absolute',
    top: '24px',
    right: '-95px',
    width: '266px',
    img: {
      width: '100%'
    }
  }
})

export const StyledRealWorldDataCardHeader = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '5px 10px',
  backgroundColor: ClinTheme.colors.primaryLightest,
  color: ClinTheme.colors.white,
  cursor: 'pointer',
  borderRadius: '5px'
})

export const StyledRealWorldDataCardContent = styled.div({
  padding: `${ClinTheme.space[3]}px ${ClinTheme.space[3]}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  [mediaQuery(mobileUpBreakpoint)]: {
    padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`
  }
})

export const StyledLearnMoreButton = styled(ClinButton)({
  padding: `7px ${ClinTheme.space[6]}px`,
  textTransform: 'initial',
  fontSize: '12px',
  lineHeight: `${ClinTheme.space[3]}px`,
  border: `1px solid ${ClinTheme.colors.primaryLight}`,
  color: ClinTheme.colors.primaryLight
})

export const StyledOverlayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  vertical-align: middle;
  width: 186px;
  height: 61px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/images/button-background.svg');
  color: ${ClinTheme.colors.white};
  border-radius: ${ClinTheme.radii[1]}px;
  p {
    padding-top: 14px;
    color: ${ClinTheme.colors.white};
    text-align: center;
  }
`
