import React, { FunctionComponent, useEffect, useState } from 'react'
import { ClinNavbar } from '../../components/ClinNavbar'
import { ClinDropdown } from '../../components/ClinDropdown'
import { DropdownNavButton } from '../../features/DropdownNavButton'
import { ClinDropdownMenu } from '../../components/ClinDropdownMenu'
import authService from '../../services/AuthService'
import { ClinTheme } from '../../ClinTheme'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../components/ClinIcon'
import {
  ITopTierNavigationItem,
  navigationItems
} from '../../services/ContentProvider'
import { matchPath, useLocation } from 'react-router'
import { createShowInstituteModalEvent } from '../../events/InstituteModalEvent'
import { useAppContext } from '../../context/app'
import { useBasket } from '../../context/basket'
import {
  getUserRoleWithCountry,
  isAusGaUser,
  isAusMaUser,
  UserRole,
  UserRoleRecord
} from '../../constants'
import {
  StyledClinHeader,
  StyledFeatureHighlightWrapper,
  StyledNavItemInner
} from './NavbarContainer.styles'
import { matchInArray } from '../../features/Search/AutoSuggest/AutoSuggest.model'
import { noop } from '../../utils/noop'
import { useTranslation } from 'react-i18next'
import { getIsPageAccessibleFromCountry } from '../../utils/getIsPageAccessibleFromCountry'
import {
  ClinNavItem,
  ClinNavSecondItem,
  ClinNavUpperItem
} from '../../components/ClinNavItem/ClinNavItem'
import { StyledNavTextAndChevron } from '../../components/ClinNavIconButton/ClinNavIconButton.styles'
import {
  StyledBasketCounter,
  StyledMainItemsName
} from '../../components/ClinNavbar/ClinNavbar.styles'
import { ClinNavIconButton } from '../../components/ClinNavIconButton'
import { DropdownUpperNavButton } from '../../features/DropdownNavButton/DropdownUpperNavButton'
import {
  NewFeatureElements,
  PageNames
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { useNewFeaturesList } from '../../hooks/useNewFeaturesList/useNewFeaturesList'
import { ClinNewFeatureTooltipContainer } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'
import { useNewFeatures, removeFeature } from '../../context/newFeatures'
import {
  FLOATING_OVERLAY_VISIBLE,
  eventEmitter
} from '../../utils/eventEmitter'

interface IMenuState {
  title: string
  isActive: boolean
}

export const NavbarContainer: FunctionComponent = () => {
  const { t } = useTranslation()
  const {
    dispatch,
    userRole,
    institute,
    portfolioCountryCode,
    numberOfEnrolledPrograms
  } = useAppContext()
  const [{ basket }] = useBasket()
  const initialMenuState = navigationItems.map((item) => {
    return {
      title: item.title,
      isActive: false
    }
  })
  const { useFeatureFlag } = useFeatureFlags()
  let patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )
  const [menuStates, setMenuStates] = useState<IMenuState[]>(initialMenuState)
  const [filteredNavItems, setFilteredNavItems] = useState<
    | React.ReactElement<typeof ClinNavItem>[]
    | React.ReactElement<typeof ClinNavItem>
  >()
  const [isFeatureVisible, setIsFeatureVisible] = useState(false)

  const { newFeaturesList, displayNewFeature } = useNewFeaturesList(
    PageNames.Navbar
  )

  const location = useLocation()
  const [, featuresDispatch] = useNewFeatures() // Get dispatch function

  const showPatientNavbarNewFeatureHighlight = () => {
    return (
      newFeaturesList[NewFeatureElements.PatientsNavbar] &&
      numberOfEnrolledPrograms &&
      displayNewFeature &&
      displayNewFeature(NewFeatureElements.PatientsNavbar) &&
      numberOfEnrolledPrograms
    )
  }

  const isAllowedToDisplay = (navItem: ITopTierNavigationItem) => {
    const userWithRoleAndCountry = getUserRoleWithCountry(
      portfolioCountryCode,
      userRole
    )
    return (
      !navItem.hideForCountriesCode?.includes(portfolioCountryCode) &&
      !navItem.hideForCountriesCodeWithRole?.includes(userWithRoleAndCountry!)
    )
  }

  const isMaUser = userRole && UserRoleRecord[userRole as UserRole].isMaUser

  const getBadgeValue = (value: number): string => {
    return value > 99 ? '99+' : value.toString()
  }

  useEffect(() => {
    const handleFeatureSwitch = (isVisible: boolean) => {
      setIsFeatureVisible(isVisible)
    }

    eventEmitter.on(FLOATING_OVERLAY_VISIBLE, handleFeatureSwitch)

    return () => {
      eventEmitter.off(FLOATING_OVERLAY_VISIBLE, handleFeatureSwitch)
    }
  }, [])

  const shouldSetClassToMenu = (
    fixStyle: boolean | undefined,
    path: string
  ) => {
    const shouldShow: boolean = !!showPatientNavbarNewFeatureHighlight()
    if (shouldShow && path === '/patients') {
      return false
    }
    if (shouldShow && fixStyle) {
      return true
    }
    if (isFeatureVisible) {
      return true
    }
    return false
  }

  useEffect(() => {
    setFilteredNavItems(
      navigationItems
        .filter((navItem) => {
          if (navItem.path === '/patients') {
            if (!isAusGaUser(portfolioCountryCode, userRole))
              return patientCentricFeatureFlag
          } else {
            return (
              (navItem.isVisibleForMaUser && isMaUser === true) ||
              (!!isMaUser === false && isAllowedToDisplay(navItem)) ||
              (navItem.isVisibleForAusMaUser &&
                isAusMaUser(portfolioCountryCode, userRole))
            )
          }
        })
        .map(
          ({
            title,
            path,
            navItems,
            translationKey,
            disabled,
            showNewFeatureHighlight,
            displayInitialSentence,
            fixStyle
          }) => (
            <ClinDropdown
              className={
                shouldSetClassToMenu(fixStyle, path)
                  ? 'navbarZIndexTooltipFix'
                  : ''
              }
              showHover={true}
              key={`${title}-${path}`}
              alignment="left"
              trigger={() => {
                const menuStateItem: IMenuState | undefined = menuStates?.find(
                  (navItem) => navItem.title === title
                )
                return (
                  <>
                    {showNewFeatureHighlight &&
                    newFeaturesList[NewFeatureElements.PatientsNavbar] &&
                    showPatientNavbarNewFeatureHighlight() ? (
                      <StyledFeatureHighlightWrapper>
                        <ClinNewFeatureTooltipContainer
                          feature={
                            newFeaturesList[NewFeatureElements.PatientsNavbar]
                          }
                          openTooltipInitially={true}
                          disableDismiss={true}
                        ></ClinNewFeatureTooltipContainer>
                      </StyledFeatureHighlightWrapper>
                    ) : (
                      <></>
                    )}
                    <ClinNavSecondItem
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => {
                        !navItems && handleDropDownClick(title, path)
                        if (
                          path === '/patients' &&
                          newFeaturesList[NewFeatureElements.PatientsNavbar]?.id
                        ) {
                          removeFeature(
                            featuresDispatch,
                            newFeaturesList[NewFeatureElements.PatientsNavbar]
                              ?.id
                          )
                        }
                      }}
                      to={path}
                      displayInitialSentence={displayInitialSentence}
                      showHighlight={
                        !!(
                          showNewFeatureHighlight &&
                          showPatientNavbarNewFeatureHighlight()
                        )
                      }
                      exact={true}
                      strict={true}
                      current={isCurrentPath(path)}
                      disabled={disabled}
                    >
                      {t(translationKey)}
                      {navItems && navItems.length > 0 && (
                        <StyledNavItemInner>
                          {menuStateItem && (
                            <ClinIcon
                              iconName={
                                menuStateItem.isActive
                                  ? ClinIconPathName.ChevronUp
                                  : ClinIconPathName.ChevronDown
                              }
                              iconSize={ClinTheme.space[3]}
                              style={{
                                position: 'relative',
                                top: ClinTheme.space[0],
                                marginLeft: ClinTheme.space[2]
                              }}
                            />
                          )}
                        </StyledNavItemInner>
                      )}
                    </ClinNavSecondItem>
                  </>
                )
              }}
            >
              {navItems && navItems.length > 0 && (
                <ClinDropdownMenu style={{ padding: 0 }}>
                  {navItems
                    .filter((navItem) =>
                      getIsPageAccessibleFromCountry(
                        portfolioCountryCode,
                        navItem.onlyAllowForCountryCodes
                      )
                    )
                    .map((navItem) => {
                      return (
                        <DropdownNavButton
                          onClick={() => {
                            handleDropDownClick(title, navItem.path)
                          }}
                          key={navItem.path}
                          to={navItem.path}
                          disabled={navItem.disabled}
                        >
                          {t(navItem.translationKey)}
                        </DropdownNavButton>
                      )
                    })}
                </ClinDropdownMenu>
              )}
            </ClinDropdown>
          )
        )
    )
  }, [
    patientCentricFeatureFlag,
    location,
    numberOfEnrolledPrograms,
    newFeaturesList,
    isFeatureVisible
  ])

  const handleDropDownClick = (title: string, path: string) => {
    const menuUpdate = menuStates
    menuUpdate.map((item) => {
      if (item.title === title) {
        item.isActive = !item.isActive
      } else {
        item.isActive = false
      }
      return item
    })
    setMenuStates([...menuUpdate])
  }

  const isCurrentPath = (path: string): boolean =>
    !!matchPath(location.pathname, { path, exact: false })

  if (matchInArray(location.pathname, ['onboarding', 'opa-checkout'])) {
    return null
  }

  const resetOnClose = () => {
    setMenuStates(initialMenuState)
  }

  return (
    <StyledClinHeader>
      <ClinNavbar
        resetOnClose={resetOnClose}
        navItems={filteredNavItems}
        navIcons={
          <>
            <ClinNavIconButton
              title={institute?.data?.instituteName ?? ''}
              iconName={ClinIconPathName.Switch}
              tooltip={t('opa_checkout:institute_hover_tooltip')}
              toLink="/institute/details"
              disabled={true}
              iconViewBox="0 0 18 20"
              className="institution-name tooltip block-institute-picker"
              onClick={() => dispatch(createShowInstituteModalEvent())}
            ></ClinNavIconButton>

            {!isMaUser && (
              <ClinNavIconButton
                title={t('navigation:bookmarks')}
                iconName={ClinIconPathName.Bookmark}
                toLink="/bookmarks"
              ></ClinNavIconButton>
            )}
            <ClinDropdown
              className={isFeatureVisible ? 'navbarZIndexTooltipFix' : ''}
              showHover={true}
              alignment="right"
              isMyAccount={true}
              trigger={() => (
                <ClinNavIconButton
                  className="my-account"
                  title={t('navigation:my_account')}
                  iconName={ClinIconPathName.Edit}
                  toLink="/account/details"
                  disabled={true}
                  isCurrentPath={isCurrentPath('/account/details')}
                ></ClinNavIconButton>
              )}
            >
              <ClinDropdownMenu
                className="account-menu"
                style={{
                  padding: 0,
                  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
                  borderRadius: '3px'
                }}
              >
                <div style={{ paddingBottom: '8px' }}>
                  {userRole && (
                    <>
                      <DropdownUpperNavButton
                        className="my-account"
                        to={`/account/details`}
                      >
                        {t('navigation:my_account')}
                      </DropdownUpperNavButton>
                      <DropdownUpperNavButton to={`/institute/details`}>
                        {t('navigation:my_institute')}
                      </DropdownUpperNavButton>
                      <DropdownUpperNavButton to={`/preferences`}>
                        {t('navigation:preferences')}
                      </DropdownUpperNavButton>
                    </>
                  )}
                  <DropdownUpperNavButton
                    className={'block-log-out'}
                    to={`/logout`}
                    onClick={(event) => {
                      event.preventDefault()
                      authService.logout().then(() => noop())
                    }}
                  >
                    {t('navigation:log_out')}
                  </DropdownUpperNavButton>
                </div>
              </ClinDropdownMenu>
            </ClinDropdown>
            {userRole && !isMaUser && (
              <StyledNavTextAndChevron
                style={{ marginRight: '15px', marginTop: '0' }}
                className="basket-link"
              >
                <ClinNavUpperItem
                  to={'/basket'}
                  exact={true}
                  strict={true}
                  style={{ position: 'relative' }}
                >
                  <ClinIcon
                    style={{ marginRight: '5px' }}
                    iconName={ClinIconPathName.NewBasket}
                    iconSize={'27px'}
                    iconHeight={'19px'}
                    iconFill={ClinTheme.colors.white}
                    viewBox="0 0 27 22"
                  />
                  {basket.data && basket.data.items.length > 0 && (
                    <StyledBasketCounter className="basket basket-items">
                      {getBadgeValue(basket?.data?.items?.length)}
                    </StyledBasketCounter>
                  )}
                  <StyledMainItemsName>
                    {t('navigation:basket')}
                  </StyledMainItemsName>
                </ClinNavUpperItem>
              </StyledNavTextAndChevron>
            )}
          </>
        }
      />
    </StyledClinHeader>
  )
}
