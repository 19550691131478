import React, { FunctionComponent, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinSpinner } from '../../../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinTabHeader } from '../../../../components/ClinTabHeader'
import { ClinTabs } from '../../../../components/ClinTabs'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../../../components/ClinPageContentFrame'
import { PatientDetailsDto } from '../../../../types/swaggerTypes'
import { DateTime } from 'luxon'
import { ClinTableOrderToggle } from '../../../../components/ClinTableOrderToggle'
import {
  StyledClinVialRow,
  StyledCTAColumn,
  StyledPatientCard,
  StyledPatientCardDetail,
  StyleOpaAction
} from '../PatientDetail.styles'
import { ClinCard } from '../../../../components/ClinCard'
import { ClinAnnounceBar } from '../../../../components/ClinAnnounceBar'
import {
  ClinTable,
  ClinTableBodyCell,
  ClinTableHeaderCell
} from '../../../../components/ClinTable/ClinTable'
import {
  getCellContent,
  getColumnTitleTranslatedForColumn,
  getColumnWidthForColumn,
  OrderHistoryColumn,
  PatientTab
} from '../PatientDetail.model'
import { TransferPatientForm } from '../TransferPatientForm'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinPagination } from '../../../../components/ClinPagination'
import {
  getCurrentPage,
  getTotalPages,
  IPagination
} from '../../../../components/ClinPagination/ClinPagination.model'
import { truncateString } from '../../../../utils/truncateString'
import { VialReconciliationTable } from '../../../../features/VialReconciliationTable'
import { ClinIcon } from '../../../../components/ClinIcon'
import { ITransferForm } from '../TransferPatientForm/TransferPatientForm'
import { ClinIconPathName } from '../../../../components/ClinIcon/ClinIcon.paths'
import {
  IPastOrderSummary,
  IPatientVial,
  VialDateAdministeredSaved,
  VialReconciliationType
} from '../PatientDetail.types'
import { ReconcileStatus } from '../PatientDetailContainer'
import { AnnounceMode } from '../../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { PatientStatus, ProgramStatus } from '../../../../constants'
import { StyledLinkUnderline } from '../../../../components/StyledLink'
import { Trans, useTranslation } from 'react-i18next'
import { patientOrderStatusTooltipTranslation } from '../../PatientDashboard/Patient.model'
import { useFeatureFlags } from '../../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../../context/featureFlags/FeatureFlagKeys'

interface IPatientDetailProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Primary CTA title */
  detailsCtaButtonTitle: string
  /** Whether we are saving a vial reconciliation */
  isSavingVial: boolean
  /** If the program is closed displaying an appropriated banner */
  isProgramClosed: boolean
  /** If the program allows for discontinuation */
  allowsDiscontinuation: boolean
  /** Selected patient */
  patient?: PatientDetailsDto
  /** Past orders */
  pastOrders: IPastOrderSummary[]
  /** Index of the selected tab */
  selectedTabIndex: number
  /** Selected row item / vial */
  selectedVialRow?: IPatientVial
  /** Columns to show on the orders table */
  columns: OrderHistoryColumn[]
  /** If true displays patient transfer form */
  isFormOpen: boolean
  /** Disable transfer button */
  disableTransferButton: boolean
  /** Called when user close form */
  handleHideTransferForm?: () => void
  /** Called when user open  form */
  handleShowTransferForm?: () => void
  /** Called when form submitted */
  handleFormSubmission?: (form: ITransferForm) => void
  /** Called when for radio is selected **/
  handleDestinationInputChange?: (selectedRadioButtonId: string) => void
  /** Called when the user selects a tab */
  onTabSelected?: (selectedTabIndex: number) => void
  /** Called when the user click on "Primary CTA" */
  handlePrimaryCTA?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Called when the user click on "Order Reference" */
  handleReferenceOrderClicked?: (selectedOrderId: string | number) => void
  /** Pagination to display if there are more than 5 orders */
  pagination?: IPagination
  /** The url to access the most recent patient access form (opa) */
  patientAccessFormUrl?: string
  /** Indicates what statuses are required or not */
  reconciliationStatus?: ReconcileStatus
  /** The number of vials to reconcile */
  vialsToReconcileCounter: number
  /** The type of vials to reconcile i.e. vials, packs etc */
  vialsUnitType: string | null
  /** The type of reconciliation - determines what can be edited */
  vialReconciliationType: VialReconciliationType
  /** User country */
  userCountry?: string
  /** Called when pagination is clicked */
  handlePageClicked?: (selectedPageIndex: number) => void
  /** Called when pagination page size change is called */
  handlePageSizeChange?: (pageSize: number) => void
  /** Called when a row is toggled */
  handleRowToggled?: (orderNumber: number) => void
  /** Triggered when the date picker is selected */
  handleVialDateInputSelected?: (vial: IPatientVial) => void
  /** Called when vial reconciliation row is saved */
  handleSaveChangesToVial?: (updatedVial: IPatientVial) => void
  /** Called when back button clicked */
  handleGoBack?: () => void
  /** Discontinue from program */
  handleDiscontinueFromProgram?: () => void
  /** Calls a function if the user requests to access the opa */
  handleOpenPatientAccessForm?: () => void
  /** Calls a function if the user wishes to contact Clinigen */
  handlePatientAccessFormError?: () => void
}

export const PatientDetail: FunctionComponent<IPatientDetailProps> = ({
  isLoading,
  detailsCtaButtonTitle,
  isSavingVial,
  isProgramClosed,
  allowsDiscontinuation,
  disableTransferButton,
  patient,
  pastOrders,
  selectedTabIndex,
  selectedVialRow,
  columns,
  isFormOpen,
  handleHideTransferForm,
  handleFormSubmission,
  handleDestinationInputChange,
  onTabSelected,
  handlePrimaryCTA,
  handleReferenceOrderClicked,
  pagination,
  patientAccessFormUrl,
  reconciliationStatus,
  vialsToReconcileCounter,
  vialsUnitType,
  vialReconciliationType,
  handlePageClicked,
  handlePageSizeChange,
  handleRowToggled,
  handleVialDateInputSelected,
  handleSaveChangesToVial,
  handleGoBack,
  handleDiscontinueFromProgram,
  handleOpenPatientAccessForm,
  handlePatientAccessFormError,
  handleShowTransferForm
}) => {
  const { t } = useTranslation()
  const physicianFullName = `${
    patient?.currentPhysician.physicianTitle ?? ''
  } ${patient?.currentPhysician.physicianFirstName} ${
    patient?.currentPhysician.physicianLastName
  }`

  const { useFeatureFlag } = useFeatureFlags()
  const [vialDateAdministeredSaved, setVialDateAdministeredSaved] = useState<
    VialDateAdministeredSaved[]
  >([])

  const handleVialDateAdministeredSaved = (vial: IPatientVial) => {
    const vialDateSaved = {
      vialNumber: vial.vialNumber!,
      dateAdministered: vial.dateAdministered!
    }

    const existingIndex = vialDateAdministeredSaved.findIndex(
      (v) => v.vialNumber === vial.vialNumber
    )

    if (existingIndex !== -1) {
      const updatedVials = vialDateAdministeredSaved.map((vial, index) =>
        index === existingIndex ? vialDateSaved : vial
      )
      setVialDateAdministeredSaved(updatedVials)
    } else {
      setVialDateAdministeredSaved([
        ...vialDateAdministeredSaved,
        vialDateSaved
      ])
    }
  }

  let patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )
  let primaryCTATitle = patientCentricFeatureFlag
    ? t(
        patientOrderStatusTooltipTranslation[
          patient?.patientOrderStatus as keyof typeof patientOrderStatusTooltipTranslation
        ] ?? 'patient_detail:title_resupply'
      )
    : t('patient_detail:title_resupply')

  if (primaryCTATitle.includes('MISSING KEY')) {
    primaryCTATitle = t('patient_detail:title_resupply')
  }

  const primaryCTAButtonTitle = patientCentricFeatureFlag
    ? t(detailsCtaButtonTitle || 'patient_detail:button_request_resupply')
    : t('patient_detail:button_request_resupply')

  if (isFormOpen) {
    return (
      <Container className="transfer-patient-form">
        <TransferPatientForm
          disableTransferButton={disableTransferButton}
          handleClose={() => handleHideTransferForm && handleHideTransferForm()}
          handleFormSubmission={(form) =>
            handleFormSubmission && handleFormSubmission(form)
          }
          handleDestinationInputChange={handleDestinationInputChange}
        />
      </Container>
    )
  }
  return isLoading || !patient ? (
    <ClinPageContentFrame>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </ClinPageContentFrame>
  ) : (
    <ClinPageContentFrame
      className="patient-detail"
      crumbs={[
        { path: '/orders', name: t('orders:page_name') },
        { path: '/programs/my-physicians', name: t('my_physicians:title') },
        {
          name: truncateString(physicianFullName),
          path: `/programs/my-physicians/${patient.currentPhysician.physicianId}`
        },
        {
          name: `${t('glossary:patient')} ${patient.patientNumber}`,
          path: `/product/${patient.patientId}`
        }
      ]}
    >
      <ClinSpacer />
      <Row>
        <Col sm={10}>
          <ClinText
            as="h1"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('patient_detail:page_name', {
              patientNumber: patient.patientNumber
            })}
          </ClinText>
        </Col>
      </Row>
      <ClinButton
        onClick={() => handleGoBack && handleGoBack()}
        style={{ marginTop: ClinTheme.space[3] }}
        variant="link"
      >
        <ClinIcon
          style={{ marginRight: ClinTheme.space[2] }}
          iconSize={ClinTheme.fontSizes[3]}
          iconName={ClinIconPathName.ArrowLeft}
        />
        {t('patient_detail:button_back_to_physician')}
      </ClinButton>
      <ClinSpacer height={ClinTheme.space[5]} hasBorder={true} />
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12}>
          {isProgramClosed && (
            <>
              <ClinAnnounceBar
                title={t('patient_detail:program_closed_announce_title')}
              >
                {t('patient_detail:program_closed_announce_description')}
              </ClinAnnounceBar>
              <ClinSpacer height={ClinTheme.space[3]} />
            </>
          )}
          <ClinText
            as="h4"
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.bold}
            marginTop="0px"
          >
            <Trans
              i18nKey="patient_detail:button_manage_patient"
              components={[
                <StyledLinkUnderline
                  key="manage-patient"
                  to="/support/access-program"
                />
              ]}
            >
              <></>
            </Trans>
          </ClinText>
          <ClinSpacer height={ClinTheme.space[2]} />
          <ClinCard>
            <Row justify="start">
              <Col sm={12} md={12} lg={4}>
                <StyledCTAColumn>
                  <ClinText fontSize={ClinTheme.fontSizes[2]}>
                    {primaryCTATitle}
                  </ClinText>
                  <ClinButton
                    className="resupply-button"
                    variant="primary"
                    disabled={isProgramClosed || detailsCtaButtonTitle === ''}
                    onClick={handlePrimaryCTA}
                  >
                    {primaryCTAButtonTitle}
                  </ClinButton>
                </StyledCTAColumn>
              </Col>
              <Col sm={12} md={12} lg={4}>
                <StyledCTAColumn>
                  <ClinText fontSize={ClinTheme.fontSizes[2]}>
                    {t('patient_detail:title_transfer_column')}
                  </ClinText>
                  <ClinButton
                    className="transfer-button"
                    disabled={
                      isProgramClosed ||
                      patient.patientStatus.toUpperCase() ===
                        PatientStatus.DISCONTINUED.toUpperCase()
                    }
                    onClick={() =>
                      handleShowTransferForm && handleShowTransferForm()
                    }
                  >
                    {t('patient_detail:button_transfer')}
                  </ClinButton>
                </StyledCTAColumn>
              </Col>
              {allowsDiscontinuation && (
                <Col sm={12} md={12} lg={4}>
                  <StyledCTAColumn>
                    <ClinText fontSize={ClinTheme.fontSizes[2]}>
                      {t('patient_detail:discontinue_title')}
                    </ClinText>
                    <ClinButton
                      className="discontinue-button"
                      disabled={
                        patient.patientStatus.toUpperCase() ===
                        PatientStatus.DISCONTINUED.toUpperCase()
                      }
                      onClick={() =>
                        handleDiscontinueFromProgram &&
                        handleDiscontinueFromProgram()
                      }
                    >
                      {t('patient_detail:button_discontinue')}
                    </ClinButton>
                  </StyledCTAColumn>
                </Col>
              )}
            </Row>
          </ClinCard>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12}>
          <ClinTabs
            activeItemIndex={selectedTabIndex}
            handleSelected={onTabSelected}
          >
            <ClinTabHeader title={t('patient_detail:tab_title_one')} />
            <ClinTabHeader title={t('patient_detail:tab_title_two')} />
          </ClinTabs>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[6]} />
      {selectedTabIndex === PatientTab.PatientDetails && (
        <>
          <Row>
            <Col sm={12}>
              <ClinText
                as="h4"
                variant={TypographyVariant.H4}
                fontWeight={ClinTheme.fontWeights.bold}
                marginBottom={ClinTheme.space[4]}
              >
                {t('patient_detail:person_details_title')}
              </ClinText>
              <StyledPatientCard removePadding={true}>
                <Row>
                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:card_patient_id')}
                      </ClinText>
                      <ClinText
                        className="patient-id"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {patient.patientNumber}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>

                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:card_patient_initials')}
                      </ClinText>
                      <ClinText
                        className="initials"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {patient.initials}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>
                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:card_patient_date_of_birth')}
                      </ClinText>
                      <ClinText
                        className="date-of-birth"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {patient.dateOfBirth
                          ? DateTime.fromISO(
                              patient.dateOfBirth
                            ).toLocaleString()
                          : t('glossary:not_applicable')}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:card_patient_institute_identifier')}
                      </ClinText>
                      <ClinText
                        className="patient-ref-number"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {patient.hospitalRefNo ? patient.hospitalRefNo : 'N/A'}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>

                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:card_patient_paf')}
                      </ClinText>

                      {pastOrders &&
                        pastOrders.length > 0 &&
                        patientAccessFormUrl !== undefined && (
                          <StyleOpaAction
                            onClick={() =>
                              patientAccessFormUrl
                                ? handleOpenPatientAccessForm &&
                                  handleOpenPatientAccessForm()
                                : handlePatientAccessFormError &&
                                  handlePatientAccessFormError()
                            }
                          >
                            <ClinIcon
                              iconName={ClinIconPathName.Document}
                              iconFill={ClinTheme.colors.primary}
                              style={{ marginLeft: -ClinTheme.space[1] }}
                            />
                            <ClinText
                              marginLeft={ClinTheme.space[2]}
                              fontSize={ClinTheme.fontSizes[2]}
                              fontWeight={ClinTheme.fontWeights.bold}
                              color={ClinTheme.colors.primary}
                            >
                              {patientAccessFormUrl
                                ? t('patient_detail:card_patient_paf')
                                : t('patient_detail:paf_completed')}
                            </ClinText>
                          </StyleOpaAction>
                        )}
                    </StyledPatientCardDetail>
                  </Col>

                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:card_patient_status')}
                      </ClinText>
                      <ClinText
                        className="patient-status"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                        color={
                          patient.patientStatus.toUpperCase() ===
                          PatientStatus.ACTIVE_TREATMENT.toUpperCase()
                            ? ClinTheme.colors.greenValid
                            : ClinTheme.colors.redInvalid
                        }
                      >
                        {patient.patientStatus}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>
                </Row>
              </StyledPatientCard>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <ClinText
                as="h4"
                variant={TypographyVariant.H4}
                fontWeight={ClinTheme.fontWeights.bold}
                marginBottom={ClinTheme.space[4]}
              >
                {t('patient_detail:program_details_title')}
              </ClinText>
              <StyledPatientCard removePadding={true}>
                <Row>
                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:program_details_program')}
                      </ClinText>
                      <ClinText
                        className="program-name"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {patient.program.programName}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>

                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:program_details_area')}
                      </ClinText>
                      <ClinText
                        className="program-therapeuticArea"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {patient.program.therapeuticArea ??
                          t('patient_detail:no_classification')}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>
                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:program_details_sponsor')}
                      </ClinText>
                      <ClinText
                        className="program-sponsor"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {patient.program.sponsor}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4}>
                    <StyledPatientCardDetail>
                      <ClinText variant={TypographyVariant.LargeParagraph}>
                        {t('patient_detail:program_details_status')}
                      </ClinText>
                      <ClinText
                        className="program-status"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.bold}
                        color={
                          patient.program.status?.toLocaleLowerCase() ===
                            ProgramStatus.ClosedNotArchived.toString().toLocaleLowerCase() ||
                          patient.program.status?.toLocaleLowerCase() ===
                            ProgramStatus.Closed.toString().toLocaleLowerCase()
                            ? ClinTheme.colors.redInvalid
                            : ClinTheme.colors.greenValid
                        }
                      >
                        {patient.program.status}
                      </ClinText>
                    </StyledPatientCardDetail>
                  </Col>
                </Row>
              </StyledPatientCard>
            </Col>
          </Row>
          {/* This has been temporarily hidden short term due to data issues which will eventually be resovled
          /* {(patient.currentPhysician || patient.pastPhysicians) && (
            <Row>
              <Col sm={12}>
                <ClinText
                  as="h4"
                  variant={TypographyVariant.H4}
                  fontWeight={ClinTheme.fontWeights.bold}
                  marginBottom={ClinTheme.space[4]}
                >
                  Associated physicians
                </ClinText>
                <ClinTable
                  tableHeader={() => (
                    <>
                      <ClinTableHeaderCell>
                        <StyledClinTableOrderToggle>
                          Physician
                        </StyledClinTableOrderToggle>
                      </ClinTableHeaderCell>
                      <ClinTableHeaderCell>
                        <StyledClinTableOrderToggle>
                          Start Date
                        </StyledClinTableOrderToggle>
                      </ClinTableHeaderCell>
                      <ClinTableHeaderCell>
                        <StyledClinTableOrderToggle>
                          End Date
                        </StyledClinTableOrderToggle>
                      </ClinTableHeaderCell>
                      <ClinTableHeaderCell>
                        <StyledClinTableOrderToggle>
                          Status
                        </StyledClinTableOrderToggle>
                      </ClinTableHeaderCell>
                    </>
                  )}
                >
                  <ClinTableRow disabled={true}>
                    <ClinTableBodyCell verticalAlign="top">
                      <ClinText
                        fontSize={16}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {`${patient.currentPhysician.physicianTitle ?? ''} ${
                          patient.currentPhysician.physicianFirstName
                        } ${patient.currentPhysician.physicianLastName}`}
                      </ClinText>
                    </ClinTableBodyCell>
                    <ClinTableBodyCell verticalAlign="top">
                      <ClinText
                        fontSize={16}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {DateTime.fromISO(
                          patient.currentPhysician.physicianFromDate
                        ).toLocaleString()}
                      </ClinText>
                    </ClinTableBodyCell>
                    <ClinTableBodyCell verticalAlign="top">
                      <ClinText
                        fontSize={16}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        Present
                      </ClinText>
                    </ClinTableBodyCell>
                    <ClinTableBodyCell verticalAlign="top">
                      <ClinText
                        fontSize={16}
                        fontWeight={ClinTheme.fontWeights.bold}
                        color={ClinTheme.colors.greenValid}
                      >
                        Treating
                      </ClinText>
                    </ClinTableBodyCell>
                  </ClinTableRow>

                  {patient.pastPhysicians.map(
                    (physician: PatientPhysicianDto, rowIndex) => {
                      return (
                        <ClinTableRow disabled={true} key={rowIndex}>
                          <ClinTableBodyCell verticalAlign="top">
                            <ClinText
                              fontSize={16}
                              fontWeight={ClinTheme.fontWeights.bold}
                            >
                              {physician.physicianFullName}
                            </ClinText>
                          </ClinTableBodyCell>
                          <ClinTableBodyCell verticalAlign="top">
                            <ClinText
                              fontSize={16}
                              fontWeight={ClinTheme.fontWeights.bold}
                            >
                              {physician.physicianFromDate &&
                                DateTime.fromISO(
                                  physician.physicianFromDate
                                ).toLocaleString()}
                            </ClinText>
                          </ClinTableBodyCell>
                          <ClinTableBodyCell verticalAlign="top">
                            <ClinText
                              fontSize={16}
                              fontWeight={ClinTheme.fontWeights.bold}
                            >
                              {physician.physicianToDate &&
                                DateTime.fromISO(
                                  physician.physicianToDate
                                ).toLocaleString()}
                            </ClinText>
                          </ClinTableBodyCell>
                          <ClinTableBodyCell verticalAlign="top">
                            <ClinText
                              fontSize={16}
                              fontWeight={ClinTheme.fontWeights.bold}
                              color={ClinTheme.colors.primaryMid}
                            >
                              Transferred
                            </ClinText>
                          </ClinTableBodyCell>
                        </ClinTableRow>
                      )
                    }
                  )}
                </ClinTable>
              </Col>
            </Row>
          )} */}
        </>
      )}
      {selectedTabIndex === PatientTab.OrderHistory &&
        !isLoading &&
        pastOrders && (
          <>
            {ReconcileStatus.SuccessfulReconciliation ===
              reconciliationStatus && (
              <>
                <ClinAnnounceBar
                  mode={AnnounceMode.Success}
                  title={t('patient_detail:message_reconcile_success_title')}
                  isDismissible={false}
                >
                  {t('patient_detail:message_reconcile_success')}
                </ClinAnnounceBar>
                <ClinSpacer height={ClinTheme.space[5]} />
              </>
            )}
            {ReconcileStatus.RequiresReconciliation === reconciliationStatus &&
              vialsToReconcileCounter > 0 && (
                <>
                  <ClinAnnounceBar
                    mode={AnnounceMode.Warning}
                    title={t('patient_detail:message_reconcile_required_title')}
                    isDismissible={false}
                  >
                    {t(
                      'patient_detail:message_reconcile_required_description',
                      { vialsToReconcileCounter: vialsToReconcileCounter }
                    )}
                  </ClinAnnounceBar>
                  <ClinSpacer height={ClinTheme.space[5]} />
                </>
              )}

            <ClinText
              as="h4"
              variant={TypographyVariant.H4}
              fontWeight={ClinTheme.fontWeights.bold}
              marginTop="0px"
            >
              {t('patient_detail:order_history_title')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[2]} />
            <ClinTable
              tableHeader={() =>
                columns.map((column: OrderHistoryColumn, index: number) => (
                  <ClinTableHeaderCell
                    key={index}
                    width={getColumnWidthForColumn(column)}
                  >
                    <ClinTableOrderToggle hideSortIcon={true}>
                      {column !== OrderHistoryColumn.VialReconciliationToggle
                        ? getColumnTitleTranslatedForColumn(column, t)
                        : ''}
                    </ClinTableOrderToggle>
                  </ClinTableHeaderCell>
                ))
              }
            >
              {pastOrders &&
                pastOrders.length > 0 &&
                pastOrders.map((order, rowIndex) => {
                  return (
                    <React.Fragment key={rowIndex}>
                      <StyledClinVialRow
                        key={rowIndex}
                        className={'past-order-tr'}
                        isOrderToggledOpen={order.isOrderToggledOpen}
                      >
                        {columns.map(
                          (column: OrderHistoryColumn, index: number) => (
                            <ClinTableBodyCell
                              key={index}
                              verticalAlign="top"
                              onCellClick={() => {
                                handleReferenceOrderClicked &&
                                  handleReferenceOrderClicked(order.orderNumber)
                              }}
                            >
                              {getCellContent(column, order, handleRowToggled)}
                            </ClinTableBodyCell>
                          )
                        )}
                      </StyledClinVialRow>
                      {/* VIAL RECONCILIATION */}
                      {order.isOrderToggledOpen && order.canBeReconciled && (
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              padding:
                                order.vialsToReconcile.length === 0
                                  ? ClinTheme.space[3]
                                  : 0
                            }}
                          >
                            {order.vialsToReconcile.length > 0 ? (
                              <VialReconciliationTable
                                showDebug={false}
                                isSavingVial={isSavingVial}
                                selectedVial={selectedVialRow}
                                patientVials={order.vialsToReconcile}
                                unitType={vialsUnitType}
                                vialReconciliationType={vialReconciliationType}
                                vialDateAdministeredSaved={
                                  vialDateAdministeredSaved
                                }
                                handleVialDateAdministeredSaved={
                                  handleVialDateAdministeredSaved
                                }
                                handleSaveChanges={handleSaveChangesToVial}
                                handleVialDateInputSelected={
                                  handleVialDateInputSelected
                                }
                              />
                            ) : (
                              <ClinAnnounceBar
                                title={t(
                                  'patient_detail:reconcile_announce_title'
                                )}
                                message={t(
                                  'patient_detail:reconcile_announce_message'
                                )}
                                mode={AnnounceMode.Information}
                                marginBottom={ClinTheme.space[3]}
                              />
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })}
            </ClinTable>
            <ClinSpacer height={ClinTheme.space[7]} />
            <Row>
              <Col>
                {!isLoading &&
                  pastOrders &&
                  pagination &&
                  pagination.total > 5 && (
                    <ClinPagination
                      currentPage={getCurrentPage(pagination)}
                      totalPages={getTotalPages(pagination)}
                      pageSize={pagination.take}
                      handlePageSelected={(pageIndex) =>
                        handlePageClicked && handlePageClicked(pageIndex)
                      }
                      handlePageSizeChange={handlePageSizeChange}
                    />
                  )}
              </Col>
            </Row>
          </>
        )}
    </ClinPageContentFrame>
  )
}
