import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ButtonVariant } from '../../types'
import { IButtonStyles } from './ClinButton'
import { transparentize } from 'polished'

const getVariantStyles = (variant: ButtonVariant, disabled: boolean) => {
  const config = {
    primary: {
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
      backgroundColor: disabled
        ? ClinTheme.colors.white
        : ClinTheme.colors.secondary,
      borderColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.secondary,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primary,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.primary
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.secondary,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: ClinTheme.colors.primary
      }
    },

    secondary: {
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
      backgroundColor: 'transparent',
      borderColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.secondary,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.secondary,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.secondary
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : transparentize(0.85, ClinTheme.colors.secondary),
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.secondary,
        outline: 'none'
      }
    },

    link: {
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primaryLight,
      backgroundColor: ClinTheme.colors.transparent,
      textAlign: 'left',
      padding: 0,
      borderRadius: 0,
      justifyContent: 'flex-start',
      ':hover': {
        textDecoration: disabled ? 'none' : 'underline',
        backgroundColor: disabled ? '' : ClinTheme.colors.transparent
      },
      ':focus': {}
    },

    ghost: {
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primaryLight,
      backgroundColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.transparent,
      justifyContent: 'flex-start',
      ':hover': {
        backgroundColor: disabled ? '' : ClinTheme.colors.lightGrey
      },
      ':focus': {
        boxShadow: ClinTheme.shadows[0]
      }
    },
    secondaryNarrow: {
      padding: `0 ${ClinTheme.space[4]}px`,
      height: `${ClinTheme.space[5]}px`,
      width: '166px',
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
      backgroundColor: 'transparent',
      borderColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.secondary,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.secondary,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.secondary
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : transparentize(0.85, ClinTheme.colors.secondary),
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.secondary,
        outline: 'none'
      }
    },
    primaryNarrow: {
      padding: `0 ${ClinTheme.space[4]}px`,
      height: `${ClinTheme.space[5]}px`,
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
      backgroundColor: disabled
        ? ClinTheme.colors.white
        : ClinTheme.colors.secondary,
      borderColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.secondary,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primary,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.primary
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.secondary,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: ClinTheme.colors.primary
      }
    },
    purpleNarrow: {
      padding: `0 ${ClinTheme.space[4]}px`,
      fontSize: '13px',
      height: `${ClinTheme.space[5]}px`,
      lineHeight: 1.23,
      letterSpacing: '0.65px',
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
      backgroundColor: disabled
        ? ClinTheme.colors.white
        : ClinTheme.colors.primaryLightest,
      borderColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.primaryLightest,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primary,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.primary
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primaryLightest,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primary,
        outline: ClinTheme.colors.primary
      }
    },
    purpleSecondaryNarrow: {
      padding: `0 ${ClinTheme.space[4]}px`,
      height: `${ClinTheme.space[5]}px`,
      fontSize: '13px',
      width: '151px',
      lineHeight: 1.23,
      letterSpacing: '0.65px',
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primaryLight,
      backgroundColor: 'transparent',
      borderColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.primaryLight
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primaryLight,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : transparentize(0.85, ClinTheme.colors.primaryLight),
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.primaryLight,
        outline: 'none'
      }
    },
    purpleLight: {
      padding: `${ClinTheme.space[1]}px ${ClinTheme.space[4]}px`,
      height: `${ClinTheme.space[5]}px`,
      boxSizing: 'border-box',
      textTransform: 'none',
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
      backgroundColor: disabled
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.primaryLight,
      borderColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.lightGrey
          : ClinTheme.colors.primaryMid,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.primaryMid
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.darkGrey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.ultraLightGrey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.grey
          : ClinTheme.colors.primaryLight,
        boxShadow: `0 0 0 2px ${ClinTheme.colors.primary}`,
        outline: 'none'
      }
    },
    purpleLightSecondary: {
      textTransform: 'none',
      padding: `${ClinTheme.space[1]}px ${ClinTheme.space[4]}px`,
      height: `${ClinTheme.space[5]}px`,
      boxSizing: 'border-box',
      color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primaryLight,
      backgroundColor: disabled ? ClinTheme.colors.lightGrey : 'transparent',
      borderColor: disabled
        ? ClinTheme.colors.midGrey
        : ClinTheme.colors.primaryLight,
      ':hover': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.white,
        backgroundColor: disabled
          ? ClinTheme.colors.ultraLightGrey
          : ClinTheme.colors.primaryLight,
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.primaryLight
      },
      ':focus': {
        color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.primaryLight,
        backgroundColor: disabled
          ? ClinTheme.colors.white
          : transparentize(0.85, ClinTheme.colors.primaryLightest),
        borderColor: disabled
          ? ClinTheme.colors.midGrey
          : ClinTheme.colors.primaryLight,
        boxShadow: `0 0 0 2px ${transparentize(
          0.5,
          ClinTheme.colors.primaryLight
        )}`,
        outline: 'none'
      }
    }
  }
  return config[variant]
}

export const ClinStyledButton = styled.button<IButtonStyles>(
  {
    alignItems: 'center',
    borderRadius: ClinTheme.radii[5],
    borderWidth: ClinTheme.borderWidths[1],
    borderStyle: 'solid',
    borderColor: ClinTheme.colors.transparent,
    fontFamily: ClinTheme.fonts.body,
    fontSize: ClinTheme.fontSizes[1],
    fontWeight: ClinTheme.fontWeights.medium,
    flex: '0 0 auto',
    lineHeight: `${ClinTheme.space[4]}px`,
    letterSpacing: `${ClinTheme.letterSpacing[1]}px`,
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[5]}px`,
    textTransform: 'uppercase',
    textDecoration: 'none',
    textAlign: 'center',
    transition:
      '.2s linear background-color, .2s linear color, .2s linear borderColor, .2s linear underline',
    whiteSpace: 'nowrap',
    WebkitTapHighlightColor: ClinTheme.colors.transparent,
    [`@media print`]: {
      display: 'none'
    },
    a: {
      textDecoration: 'none',
      color: 'inherit',
      ':focus': {
        outline: 'none'
      }
    }
  },
  ({ variant, disabled, display, justifyContent }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    display,
    justifyContent,
    width: display === 'block' || display === 'flex' ? '100%' : 'auto',
    ...(getVariantStyles(variant!, disabled!) as any)
  })
)

export const StyledLoader = styled.span({
  display: 'flex',
  marginLeft: ClinTheme.space[3]
})
