import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { isArray } from 'lodash'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinButton } from '../../../components/ClinButton'
import {
  StyledAccountDetails,
  StyledPhoneExtension,
  StyledPhoneExtensionNumber,
  StyledRow,
  StyledRowFilled,
  StyledRowTitle
} from './AccountDetails.styles'
import { ICrumb } from '../../../components/ClinBreadcrumb'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import {
  ContactCardDto,
  InstituteDto,
  LovDto,
  UpdateContactDto,
  UpdateContactPhoneDto,
  UserPhoneDto
} from '../../../types/swaggerTypes'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinTextInput } from '../../../components/ClinTextInput'
import {
  EditableFields,
  getLocalisationKeyForValue
} from './AccountDetailsCountainer'
import { ClinGroup } from '../../../components/ClinGroup'
import { Controller, useForm } from 'react-hook-form'
import { ClinSelect } from '../../../components/ClinSelect'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, object, string } from 'yup'
import {
  PhoneContactResponseType,
  PhoneResponseType,
  phoneTypesRecord,
  UserRole
} from '../../../constants'
import { ClinSpinner } from '../../../components/ClinSpinner'
import {
  StyledLoadingContainer,
  StyledLoadingSpinner
} from '../../InstitutePages/InviteNewUser/InviteNewUser.styles'
import { countryCodes } from '../../../constants'
import { TPhoneResponseType } from '../../../types/IContact'
import '../../../i18n/config'
import { useTranslation } from 'react-i18next'
import { getSquidExObjectForKeyAndValue } from '../../../utils/getSquidExObjectForKeyAndValue'
import { IRole } from '../../../types'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'

interface IAccountDetailsProps {
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** Which field is in edit mode */
  fieldToEdit?: EditableFields
  /** Users current institute */
  institute: InstituteDto
  /** Additional user details to be provided */
  userDetails: ContactCardDto
  /** An array of specialism values */
  specialisms: LovDto[]
  /** An array of job type values */
  jobTitles: LovDto[]
  /** Breadcrumbs to display */
  crumbs?: ICrumb[]
  /** Back button text (different for aus ga users) */
  backButtonText?: string
  /** When change button clicked */
  handleFormSubmission: (data: UpdateContactDto) => void
  /** When change institute button is clicked */
  handleChangeInstitute(): void
  /** When requesting to edit a field */
  handleEditRequest: (field: EditableFields) => void
  /** When requesting to go back to the dashboard */
  handleRequestToExitEditing(): void
  /** When requesting to go back to the dashboard */
  handleRequestToGoBack(): void
}

export const AccountDetails: FunctionComponent<IAccountDetailsProps> = ({
  isSubmitting,
  fieldToEdit,
  institute,
  userDetails,
  specialisms,
  jobTitles,
  crumbs,
  backButtonText,
  handleFormSubmission,
  handleChangeInstitute,
  handleEditRequest,
  handleRequestToExitEditing,
  handleRequestToGoBack
}) => {
  const { t } = useTranslation()
  const requiredText = t('forms:validations.required_field')

  const [showLicenceNumber, setShowLicenseNumber] = useState<boolean>()

  const isTelRequired =
    userDetails.customerPhones.length > 0
      ? string().required(requiredText)
      : string()

  const EditContactFormSchema = object().shape({
    preferredName: string(),
    specialism: string(),
    classification: string(),
    licenseNumber: string().when('classification', {
      is: (classification: string) => {
        const shouldRequireLicense =
          classification === 'PHYSICIAN' || classification === 'PHARMACIST'
        return shouldRequireLicense
      },
      then: string().required(requiredText),
      otherwise: string()
    }),

    customerPhones: array()
      .compact()
      .of(
        object().shape(
          {
            phoneId: string(),
            requestType: string(),
            phoneType: string(),
            countryCode: string().when(['areaCode', 'phoneNumber'], {
              is: (areaCode: any, phoneNumber: any) => areaCode || phoneNumber,
              then: isTelRequired
            }),
            areaCode: string(),
            phoneExtension: string().nullable(),
            phoneNumber: string().when(['countryCode', 'areaCode'], {
              is: (countryCode: any, areaCode: any) => countryCode || areaCode,
              then: isTelRequired
            })
          },
          [
            ['areaCode', 'phoneNumber'],
            ['countryCode', 'phoneNumber'],
            ['countryCode', 'areaCode']
          ]
        )
      ),
    roles: array().of(
      object().shape({
        roleId: string(),
        roleName: string()
      })
    )
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
    clearErrors
  } = useForm<ContactCardDto>({
    defaultValues: userDetails,
    resolver: yupResolver(EditContactFormSchema)
  })

  const userRole = userDetails.clientRelationships.find(
    (item) => item.instituteId === institute.instituteId
  )

  const fullWidthColInput = fieldToEdit === undefined ? 9 : 12
  const watchClassification = watch('classification')
  const watchCustomerPhones = watch('customerPhones')

  useEffect(() => {
    if (watchClassification) {
      const classification = watchClassification.toLowerCase().toString()
      if (classification === 'physician' || classification === 'pharmacist') {
        clearErrors('licenseNumber')
        setShowLicenseNumber(true)
      } else {
        setShowLicenseNumber(false)
      }
    }
  }, [watchClassification, clearErrors])

  useEffect(() => {
    reset(userDetails)
  }, [reset, userDetails])

  const role = getSquidExObjectForKeyAndValue(
    'key',
    userRole?.roleType as UserRole,
    t('user_roles:roles', {
      returnObjects: true
    })
  ) as IRole

  return (
    <ClinPageContentFrame crumbs={!fieldToEdit ? crumbs : undefined}>
      <form
        onSubmit={handleSubmit((values) => {
          let phoneNumbers =
            values.customerPhones as unknown as UpdateContactPhoneDto[]

          const user: UpdateContactDto = {
            /* TODO: This should be removed when a more viable solution is possible.
            The empty title value is a patch to allow the form to post.
            At this stage in the app we don't have access to the title value
            but it is required in order to post any updates for the user.
            This field won't update the original value. */
            title: '',
            preferredName: values.preferredName,
            licenseNumber: values.licenseNumber,
            specialism: values.specialism,
            classification: values.classification,
            phones: phoneNumbers ? phoneNumbers : []
          }

          let filterPhoneResponses = null
          if (user.phones && user.phones.length > 0) {
            filterPhoneResponses = user.phones.filter((item) => {
              if (
                (item.countryCode && item.phoneNumber) ||
                item.requestType === PhoneContactResponseType.DELETE
              ) {
                if (item.requestType === PhoneContactResponseType.DELETE) {
                  let contactNumber = userDetails.customerPhones.find(
                    (contact) =>
                      contact?.phoneId?.toString() === item?.phoneId?.toString()
                  )
                  item.areaCode = contactNumber?.areaCode || ''
                  item.countryCode = contactNumber?.countryCode || ''
                  item.phoneNumber = contactNumber?.phoneNumber || ''
                }
                return item
              }
              return false
            })
            user.phones = filterPhoneResponses
          }

          handleFormSubmission(user)
        })}
      >
        <div style={{ display: 'none' }}>
          <ClinTextInput
            width="100%"
            {...register(`roles.0.roleName`)}
            id="role-name"
            value={userRole?.roleType}
          />
        </div>
        <StyledAccountDetails
          className={`${fieldToEdit === undefined ? 'not-editing' : ''}`}
        >
          <Row>
            <Col xs={12} md={12} lg={11} xl={9}>
              <Row>
                <Col xs={fullWidthColInput}>
                  <ClinText
                    as="h1"
                    variant={TypographyVariant.H2}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {fieldToEdit === undefined
                      ? t('account_details:title')
                      : t(getLocalisationKeyForValue(fieldToEdit))}
                  </ClinText>
                </Col>
              </Row>

              <StyledRow
                style={{
                  display: fieldToEdit === undefined ? 'block' : 'none'
                }}
              >
                <Row align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.full_name')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={9}>
                        <ClinTextInput
                          width="100%"
                          name="name"
                          id="name"
                          readOnly={true}
                          className={
                            fieldToEdit === undefined ? 'inactive' : ''
                          }
                          value={userDetails.name}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledRow>

              <StyledRow
                style={{
                  display: fieldToEdit === undefined ? 'block' : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={12}>
                    <Row align="center">
                      <Col
                        md={3}
                        style={{
                          marginRight: '-6%'
                        }}
                      >
                        <StyledRowTitle as="div">
                          {t('common:labels.email')}:{' '}
                        </StyledRowTitle>
                      </Col>
                      <Col md={9}>
                        <ClinTextInput
                          id="email"
                          width="100%"
                          name="email"
                          readOnly={true}
                          className={
                            fieldToEdit === undefined ? 'inactive' : ''
                          }
                          value={userDetails.email}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.preferredName
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.preferred_name')}:{' '}
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <ClinTextInput
                          width="100%"
                          {...register('preferredName')}
                          id="preferred-name"
                          className={
                            fieldToEdit === undefined ? 'inactive' : ''
                          }
                          readOnly={
                            !(fieldToEdit === EditableFields.preferredName)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {fieldToEdit !== EditableFields.preferredName && (
                    <Col width="auto" style={{ textAlign: 'right' }}>
                      <ClinButton
                        onClick={() =>
                          handleEditRequest(EditableFields.preferredName)
                        }
                        variant="link"
                      >
                        {t('common:labels.edit')}
                      </ClinButton>
                    </Col>
                  )}
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.password
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.password')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <ClinTextInput
                          id="password"
                          name="password"
                          readOnly={!(fieldToEdit === EditableFields.password)}
                          className={
                            fieldToEdit === undefined ? 'inactive' : ''
                          }
                          value="*******"
                        />
                      </Col>
                    </Row>
                  </Col>
                  {fieldToEdit !== EditableFields.password && (
                    <Col width="auto" style={{ textAlign: 'right' }}>
                      <ClinButton
                        onClick={() =>
                          handleEditRequest(EditableFields.password)
                        }
                        variant="link"
                      >
                        {t('common:labels.change')}
                      </ClinButton>
                    </Col>
                  )}
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.specialism
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.specialism')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <Controller
                          name="specialism"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <div
                              style={{
                                display:
                                  fieldToEdit === EditableFields.specialism
                                    ? 'block'
                                    : 'none'
                              }}
                            >
                              <ClinSelect
                                id="specialism"
                                width="100%"
                                value={value}
                                onChange={(
                                  changeValue: ChangeEvent<HTMLSelectElement>
                                ) => onChange(changeValue.currentTarget.value)}
                                hasError={!!(errors && errors.specialism)}
                                prompt={errors.specialism?.message}
                              >
                                <option value="" disabled={true}>
                                  {getInputPlaceholder(
                                    PlaceholderType.SelectInput
                                  )}
                                </option>
                                {specialisms
                                  .sort((a, b) =>
                                    a.lovValue && b.lovValue
                                      ? a.lovValue.localeCompare(b.lovValue)
                                      : 0
                                  )
                                  .map((specialism: LovDto, index) => {
                                    return (
                                      <option
                                        key={`specialism${index}`}
                                        value={specialism.lovCode}
                                      >
                                        {specialism.lovValue}
                                      </option>
                                    )
                                  })}
                              </ClinSelect>
                            </div>
                          )}
                        />
                        {!fieldToEdit && (
                          <ClinTextInput
                            id="specialism"
                            width="100%"
                            name="specialism"
                            readOnly={true}
                            value={userDetails.specialism}
                            className="inactive"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {fieldToEdit !== EditableFields.specialism && (
                    <Col width="auto" style={{ textAlign: 'right' }}>
                      <ClinButton
                        onClick={() =>
                          handleEditRequest(EditableFields.specialism)
                        }
                        variant="link"
                      >
                        {t('common:labels.edit')}
                      </ClinButton>
                    </Col>
                  )}
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.classification
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.job_type')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <Controller
                          name="classification"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <div
                              style={{
                                boxSizing: 'border-box',
                                width: '100%',
                                display:
                                  fieldToEdit === EditableFields.classification
                                    ? 'block'
                                    : 'none'
                              }}
                            >
                              <ClinSelect
                                id="classification"
                                width="100%"
                                value={value}
                                onChange={(
                                  changeValue: ChangeEvent<HTMLSelectElement>
                                ) => onChange(changeValue.currentTarget.value)}
                                hasError={!!(errors && errors.classification)}
                                prompt={errors.classification?.message}
                              >
                                <option value="" disabled={true}>
                                  {getInputPlaceholder(
                                    PlaceholderType.SelectInput
                                  )}
                                </option>
                                {jobTitles.map((jobType: LovDto, index) => {
                                  return (
                                    <option
                                      key={`classification-${index}`}
                                      value={jobType.lovCode}
                                    >
                                      {jobType.lovValue}
                                    </option>
                                  )
                                })}
                              </ClinSelect>
                            </div>
                          )}
                        />
                        {!fieldToEdit && (
                          <ClinTextInput
                            id="classification"
                            name="classification"
                            width="100%"
                            readOnly={true}
                            className="inactive"
                            value={
                              userDetails.classification === 'SITE CO_ORDINATOR'
                                ? 'SITE CO-ORDINATOR'
                                : userDetails.classification
                            }
                          />
                        )}
                      </Col>
                    </Row>

                    {showLicenceNumber && (
                      <>
                        <ClinSpacer height={ClinTheme.space[6]} />
                        <Row>
                          <Col md={3}>
                            <StyledRowTitle as="div">
                              {t('common:labels.license_number')}:
                            </StyledRowTitle>
                          </Col>
                          <Col md={8}>
                            <ClinTextInput
                              id="license-number"
                              {...register('licenseNumber')}
                              width="100%"
                              readOnly={
                                !(fieldToEdit === EditableFields.classification)
                              }
                              className={
                                fieldToEdit === undefined ? 'inactive' : ''
                              }
                              hasError={!!(errors && errors.licenseNumber)}
                              prompt={errors.licenseNumber?.message}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                  {fieldToEdit !== EditableFields.classification && (
                    <Col width="auto" style={{ textAlign: 'right' }}>
                      <ClinButton
                        onClick={() =>
                          handleEditRequest(EditableFields.classification)
                        }
                        variant="link"
                      >
                        {t('common:labels.edit')}
                      </ClinButton>
                    </Col>
                  )}
                </Row>
              </StyledRow>
              <StyledRow
                style={{
                  display: fieldToEdit === undefined ? 'block' : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={fullWidthColInput}>
                    <Row align="center">
                      <Col md={3}>
                        <StyledRowTitle as="div">
                          {t('common:labels.hospital_or_institute')}:
                        </StyledRowTitle>
                      </Col>
                      <Col md={8}>
                        <ClinText
                          as="div"
                          fontWeight={ClinTheme.fontWeights.bold}
                        >{`${institute.instituteName} (current)`}</ClinText>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3} md={2} style={{ textAlign: 'right' }}>
                    <ClinButton
                      onClick={() => handleChangeInstitute()}
                      variant="link"
                    >
                      {t('common:labels.switch')}
                    </ClinButton>
                  </Col>
                </Row>
              </StyledRow>
              <StyledRowFilled
                style={{
                  display: fieldToEdit === undefined ? 'block' : 'none'
                }}
              >
                <Row justify="between" align="center">
                  <Col xs={9}>
                    <Row align="center">
                      <Col offset={{ xs: 0, lg: 1 }} md={4}>
                        <StyledRowTitle as="div">
                          {t('common:labels.permissions')}:
                        </StyledRowTitle>
                      </Col>
                      <Col xs={7} md={5}>
                        <ClinText
                          as="div"
                          fontWeight={ClinTheme.fontWeights.bold}
                        >
                          {role?.contact_type && role.contact_type}
                        </ClinText>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </StyledRowFilled>
              <StyledRowFilled
                className="phone-fields"
                style={{
                  display:
                    fieldToEdit === undefined ||
                    fieldToEdit === EditableFields.phoneNumber
                      ? 'block'
                      : 'none'
                }}
              >
                <Row justify="between" align="center">
                  {!fieldToEdit && (
                    <Col xs={9}>
                      <Row align="center">
                        <Col offset={{ xs: 0, lg: 1 }} md={4}>
                          <StyledRowTitle as="div">
                            {t('common:labels.phone_number', {
                              count: watchCustomerPhones.length
                            })}
                            :
                          </StyledRowTitle>
                        </Col>
                        <Col md={7}>
                          {watchCustomerPhones.map(
                            (item: UserPhoneDto, index) => {
                              return (
                                <Row key={`phone-number-${index}`}>
                                  <Col>
                                    <ClinText
                                      as="p"
                                      display={'inline-block'}
                                      fontWeight={ClinTheme.fontWeights.bold}
                                      fontSize={ClinTheme.fontSizes[3]}
                                    >
                                      {`+${item.countryCode} ${item.areaCode} ${item.phoneNumber}`}
                                    </ClinText>
                                    {item.phoneExtension && (
                                      <>
                                        <StyledPhoneExtension>
                                          Ext:
                                        </StyledPhoneExtension>
                                        <StyledPhoneExtensionNumber>
                                          {item.phoneExtension}
                                        </StyledPhoneExtensionNumber>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              )
                            }
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col
                    style={{
                      display:
                        fieldToEdit === EditableFields.phoneNumber &&
                        userDetails.customerPhones.length
                          ? 'block'
                          : 'none'
                    }}
                  >
                    {watchCustomerPhones.length !== 0 &&
                      watchCustomerPhones.map((item: UserPhoneDto, index) => {
                        const phoneErrorValues =
                          isArray(errors.customerPhones) &&
                          errors.customerPhones[index] !== undefined &&
                          errors.customerPhones[index]

                        const responseType =
                          watch(`customerPhones.${index}.countryCode`) === '' &&
                          watch(`customerPhones.${index}.areaCode`) === '' &&
                          watch(`customerPhones.${index}.phoneNumber`) === ''
                            ? PhoneContactResponseType.DELETE
                            : PhoneContactResponseType.UPDATE

                        return (
                          <Row key={`phone-type-${index}`}>
                            <Col xs={12} lg={3}>
                              <div style={{ display: 'none' }}>
                                <ClinTextInput
                                  id="phone-id"
                                  {...register(
                                    `customerPhones.${index}.phoneId`
                                  )}
                                />
                                <ClinTextInput
                                  id="phone-type"
                                  {...register(
                                    `customerPhones.${index}.phoneType`
                                  )}
                                  value={item.phoneType}
                                />
                                <ClinTextInput
                                  id="request-type"
                                  {...register(
                                    `customerPhones.${index}.requestType`
                                  )}
                                  value={responseType}
                                />
                              </div>
                              <Controller
                                name={`customerPhones.${index}.countryCode`}
                                control={control}
                                render={({ field: { onChange } }) => {
                                  return (
                                    <ClinSelect
                                      id="country-code"
                                      label={t('common:labels.country_code')}
                                      width="100%"
                                      defaultValue={item.countryCode}
                                      onChange={(
                                        changeValue: ChangeEvent<HTMLSelectElement>
                                      ) =>
                                        onChange(
                                          changeValue.currentTarget.value
                                        )
                                      }
                                      hasError={
                                        !!(
                                          phoneErrorValues &&
                                          phoneErrorValues.countryCode
                                        )
                                      }
                                      prompt={
                                        phoneErrorValues &&
                                        phoneErrorValues.countryCode
                                          ? phoneErrorValues.countryCode
                                              ?.message
                                          : undefined
                                      }
                                    >
                                      <option value="">
                                        {t('common:labels.select_placeholder')}
                                      </option>
                                      <option value="44">+44</option>
                                      {countryCodes.map(
                                        (code: string, index: number) => {
                                          return (
                                            <option
                                              key={`code-${index}`}
                                              value={code}
                                            >
                                              {`+${code}`}
                                            </option>
                                          )
                                        }
                                      )}
                                    </ClinSelect>
                                  )
                                }}
                              />
                            </Col>
                            <Col xs={12} lg={2}>
                              <ClinTextInput
                                id="area-code"
                                {...register(
                                  `customerPhones.${index}.areaCode`
                                )}
                                label={t('common:labels.area_code')}
                                type="tel"
                                width="100%"
                                hasError={
                                  !!(
                                    phoneErrorValues &&
                                    phoneErrorValues.areaCode
                                  )
                                }
                                readOnly={
                                  !(fieldToEdit === EditableFields.phoneNumber)
                                }
                                prompt={
                                  phoneErrorValues && phoneErrorValues.areaCode
                                    ? phoneErrorValues.areaCode?.message
                                    : undefined
                                }
                              />
                            </Col>
                            <Col xs={12} lg={4}>
                              <ClinTextInput
                                id="phone-number"
                                {...register(
                                  `customerPhones.${index}.phoneNumber`
                                )}
                                label={t(
                                  `${
                                    phoneTypesRecord[
                                      item.phoneType as TPhoneResponseType
                                    ].translationKey
                                  }`
                                )}
                                width="100%"
                                type="tel"
                                readOnly={
                                  !(fieldToEdit === EditableFields.phoneNumber)
                                }
                                hasError={
                                  !!(
                                    phoneErrorValues &&
                                    phoneErrorValues.phoneNumber
                                  )
                                }
                                prompt={
                                  phoneErrorValues &&
                                  phoneErrorValues.phoneNumber
                                    ? phoneErrorValues.phoneNumber?.message
                                    : undefined
                                }
                              />
                            </Col>
                            <Col xs={12} lg={2}>
                              {item.phoneType !== PhoneResponseType.MOBILE && (
                                <ClinTextInput
                                  id="extension"
                                  {...register(
                                    `customerPhones.${index}.phoneExtension`
                                  )}
                                  label={t('account_details:extension')}
                                  type="tel"
                                  width="100%"
                                  hasError={
                                    !!(
                                      phoneErrorValues &&
                                      phoneErrorValues.phoneExtension
                                    )
                                  }
                                  readOnly={
                                    !(
                                      fieldToEdit === EditableFields.phoneNumber
                                    )
                                  }
                                  prompt={
                                    phoneErrorValues &&
                                    phoneErrorValues.phoneExtension
                                      ? phoneErrorValues.phoneExtension?.message
                                      : undefined
                                  }
                                />
                              )}
                            </Col>
                          </Row>
                        )
                      })}
                  </Col>

                  <Col
                    style={{
                      display:
                        fieldToEdit === EditableFields.phoneNumber &&
                        userDetails.customerPhones.length === 0
                          ? 'block'
                          : 'none'
                    }}
                  >
                    {userDetails.customerPhones.length === 0 && (
                      <Row key={`phone-type-0`}>
                        <Col xs={12} lg={3}>
                          <div style={{ display: 'none' }}>
                            <ClinTextInput
                              id="phone-type"
                              {...register(`customerPhones.0.phoneType`)}
                              value={phoneTypesRecord['GEN'].type}
                            />
                            <ClinTextInput
                              id="request-type"
                              {...register(`customerPhones.0.requestType`)}
                              value={PhoneContactResponseType.CREATE}
                            />
                          </div>
                          <Controller
                            name={`customerPhones.0.countryCode`}
                            control={control}
                            defaultValue={'44'}
                            render={({ field: { onChange, value } }) => {
                              !value &&
                                setValue('customerPhones.0.countryCode', '44', {
                                  shouldDirty: true
                                })
                              return (
                                <ClinSelect
                                  id="country-code"
                                  label={t('common:labels.country_code')}
                                  width="100%"
                                  defaultValue={value}
                                  onChange={(
                                    changeValue: ChangeEvent<HTMLSelectElement>
                                  ) =>
                                    onChange(changeValue.currentTarget.value)
                                  }
                                  hasError={
                                    !!(
                                      isArray(errors.customerPhones) &&
                                      errors.customerPhones[0] !== undefined &&
                                      errors.customerPhones[0].countryCode
                                    )
                                  }
                                  prompt={
                                    isArray(errors.customerPhones) &&
                                    errors.customerPhones[0] !== undefined &&
                                    errors.customerPhones[0].countryCode
                                      ? errors.customerPhones[0].countryCode
                                          ?.message
                                      : undefined
                                  }
                                >
                                  <option value="">
                                    {t('common:labels.select_placeholder')}
                                  </option>
                                  <option value="44">+44</option>
                                  {countryCodes.map(
                                    (code: string, index: number) => {
                                      return (
                                        <option
                                          key={`code-${index}`}
                                          value={code}
                                        >
                                          {`+${code}`}
                                        </option>
                                      )
                                    }
                                  )}
                                </ClinSelect>
                              )
                            }}
                          />
                        </Col>
                        <Col xs={12} lg={3}>
                          <ClinTextInput
                            id="area-code"
                            {...register(`customerPhones.0.areaCode`)}
                            label={t('common:labels.area_code')}
                            type="tel"
                            width="100%"
                            hasError={
                              !!(
                                isArray(errors.customerPhones) &&
                                errors.customerPhones[0] !== undefined &&
                                errors.customerPhones[0].areaCode
                              )
                            }
                            prompt={
                              isArray(errors.customerPhones) &&
                              errors.customerPhones[0] !== undefined &&
                              errors.customerPhones[0].areaCode
                                ? errors.customerPhones[0].areaCode?.message
                                : undefined
                            }
                            readOnly={
                              !(fieldToEdit === EditableFields.phoneNumber)
                            }
                          />
                        </Col>
                        <Col xs={12} lg={6}>
                          <ClinTextInput
                            id="phone-number"
                            {...register(`customerPhones.0.phoneNumber`)}
                            label={t('common:labels.phone')}
                            width="100%"
                            type="tel"
                            readOnly={
                              !(fieldToEdit === EditableFields.phoneNumber)
                            }
                            hasError={
                              !!(
                                isArray(errors.customerPhones) &&
                                errors.customerPhones[0] !== undefined &&
                                errors.customerPhones[0].phoneNumber
                              )
                            }
                            prompt={
                              isArray(errors.customerPhones) &&
                              errors.customerPhones[0] !== undefined &&
                              errors.customerPhones[0].phoneNumber
                                ? errors.customerPhones[0].phoneNumber?.message
                                : undefined
                            }
                          />
                        </Col>
                        <Col xs={12} lg={3}>
                          <ClinTextInput
                            id="extension"
                            {...register(`customerPhones.0.phoneExtension`)}
                            label={t('account_details:extension')}
                            type="tel"
                            width="100%"
                            hasError={
                              !!(
                                isArray(errors.customerPhones) &&
                                errors.customerPhones[0] !== undefined &&
                                errors.customerPhones[0].phoneExtension
                              )
                            }
                            prompt={
                              isArray(errors.customerPhones) &&
                              errors.customerPhones[0] !== undefined &&
                              errors.customerPhones[0].phoneExtension
                                ? errors.customerPhones[0].phoneExtension
                                    ?.message
                                : undefined
                            }
                            readOnly={
                              !(fieldToEdit === EditableFields.phoneNumber)
                            }
                          />
                        </Col>
                        <ClinSpacer height={ClinTheme.space[4]} />
                      </Row>
                    )}
                  </Col>

                  {fieldToEdit !== EditableFields.phoneNumber && (
                    <Col xs={2} style={{ textAlign: 'right' }}>
                      <ClinButton
                        onClick={() =>
                          handleEditRequest(EditableFields.phoneNumber)
                        }
                        variant="link"
                      >
                        {t('common:labels.edit')}
                      </ClinButton>
                    </Col>
                  )}
                </Row>
              </StyledRowFilled>

              <ClinSpacer height={ClinTheme.space[5]} />

              {fieldToEdit === undefined && (
                <ClinButton
                  variant="primary"
                  onClick={() => handleRequestToGoBack()}
                >
                  {backButtonText}
                </ClinButton>
              )}

              {fieldToEdit !== undefined && (
                <Row justify="end">
                  <Col width="auto">
                    <ClinGroup
                      justifyContent="space-between"
                      space={ClinTheme.space[3]}
                    >
                      <ClinButton
                        onClick={() => {
                          // TODO: Clean up this mess!
                          fieldToEdit === 'Phone Numbers' &&
                            setValue('customerPhones.0.countryCode', '')
                          handleRequestToExitEditing()
                          reset()
                        }}
                      >
                        {t('common:labels.cancel')}
                      </ClinButton>
                      <StyledLoadingContainer>
                        <ClinButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="primary"
                        >
                          {t('common:labels.save')}
                        </ClinButton>
                        {isSubmitting && (
                          <StyledLoadingSpinner>
                            <ClinSpinner />
                          </StyledLoadingSpinner>
                        )}
                      </StyledLoadingContainer>
                    </ClinGroup>
                  </Col>
                </Row>
              )}

              <ClinSpacer height={ClinTheme.space[8]} />
            </Col>
          </Row>
        </StyledAccountDetails>
      </form>
    </ClinPageContentFrame>
  )
}
