import {
  OrderDto,
  PhysiciansEnrolledSummaryDto
} from '../../types/swaggerTypes'

export const isPhysicianAssociated = (
  treatingPhysicianId: number | undefined,
  physicians: PhysiciansEnrolledSummaryDto[] | undefined
) => {
  const treatingPhysician = physicians?.find(
    (physician) => physician.physicianId === treatingPhysicianId
  )
  return treatingPhysician?.physicianLinkedToUser.toUpperCase() === 'Y'
}

/**
 * Looks through order lines for any order info and returns first one or none
 */
export const getTrackingInfo = (order: OrderDto) => {
  const orderWithTracking = order.lines.find(
    (o) => o.trackingInfo && o.medicationFlag === 'Y'
  )
  return orderWithTracking && orderWithTracking.trackingInfo
    ? orderWithTracking.trackingInfo
    : undefined
}
