import React, { FunctionComponent } from 'react'
import { StyledClinBreadcrumb } from './ClinBreadcrumb.styles'
import { ClinText } from '../ClinText'
import { ClinTheme } from '../../ClinTheme'
import { Link } from 'react-router-dom'
// import { useLocation } from 'react-router'

export interface ICrumb {
  name: string
  path: string
}

interface IClinBreadcrumbProps {
  /** The crumbs to render */
  crumbs?: ICrumb[]
  /** Optionally display a truncated version */
  truncate?: boolean
  /** The crumbs to render */
  showDebug?: boolean
}

export const ClinBreadcrumb: FunctionComponent<IClinBreadcrumbProps> = ({
  crumbs = [],
  truncate,
  showDebug = false,
  ...rest
}) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null
  }

  // Remove all but the last two routes
  if (truncate) {
    crumbs.splice(0, crumbs.length - 2)
    crumbs.unshift({ name: '...', path: '/' })
  }

  return (
    <>
      <StyledClinBreadcrumb {...rest}>
        <ClinText as="div" fontSize={ClinTheme.fontSizes[1]}>
          {crumbs.map(({ name, path }, idx) =>
            idx + 1 === crumbs.length ? (
              <span key={name}>{name}</span>
            ) : (
              <span key={name}>
                <Link to={path}>{name}</Link>
                &nbsp;/&nbsp;
              </span>
            )
          )}
        </ClinText>
      </StyledClinBreadcrumb>
      {showDebug && <pre>{JSON.stringify(crumbs, null, 2)}</pre>}
    </>
  )
}
