import React, { FunctionComponent, useEffect } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../ClinIcon'
import { ClinNavItem } from '../ClinNavItem'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import {
  StyledBurgerButton,
  StyledClinNavBar,
  IStyledClinNavBarProps,
  StyledNavItemsContainer,
  StyledNavIconsContainer,
  StyledLogo,
  StyledLogoLink,
  StyledLogoLinkInner,
  StyledSecondNavWrapper,
  StyledHeaderWrapper
} from './ClinNavbar.styles'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '../../context/app'
import { isAusGaUser } from '../../constants'
interface IClinNavbarProps extends IStyledClinNavBarProps {
  /** Nav items  */
  navItems?:
    | React.ReactElement<typeof ClinNavItem>[]
    | React.ReactElement<typeof ClinNavItem>
  /** Icon nav items  */
  navIcons?: React.ReactNode
  /** Whether the navbar is open initially */
  isMenuOpen?: boolean
  /** Call back to inform when a nav menu is closed */
  resetOnClose: () => void
}

export const ClinNavbar: FunctionComponent<IClinNavbarProps> = ({
  navItems,
  navIcons,
  isMenuOpen = false,
  resetOnClose
}) => {
  const { userRole, portfolioCountryCode } = useAppContext()
  const [isBurgerOpen, setIsBurgerOpen] = React.useState<boolean>(isMenuOpen)

  const baseUrl = isAusGaUser(portfolioCountryCode, userRole)
    ? '/home'
    : '/orders'
  const pageWidth = (): number =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  const listener = () => {
    const isMobile = pageWidth() <= ClinTheme.breakpoints[0]
    isBurgerOpen && !isMobile && setIsBurgerOpen(false)
  }

  useEffect(() => {
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  })

  // Close menu if we navigate away
  const location = useLocation()
  useEffect(() => {
    setIsBurgerOpen(false)
  }, [location])

  // If open on mobile hide overflow to stop scrolling
  useEffect(() => {
    document.body.style.overflow = isBurgerOpen ? 'hidden' : 'auto'
  }, [isBurgerOpen])

  return (
    <>
      <StyledClinNavBar>
        <StyledBurgerButton
          className="nav-burger"
          onClick={() => {
            setIsBurgerOpen(!isBurgerOpen)
            isBurgerOpen && resetOnClose()
          }}
        >
          <ClinIcon
            iconName={
              isBurgerOpen
                ? ClinIconPathName.BurgerClosed
                : ClinIconPathName.Burger
            }
            iconSize={ClinTheme.space[5]}
            iconFill={ClinTheme.colors.white}
          />
        </StyledBurgerButton>
        <StyledHeaderWrapper>
          <StyledLogoLink to={baseUrl}>
            <StyledLogoLinkInner>
              <StyledLogo inverted={true} />
            </StyledLogoLinkInner>
          </StyledLogoLink>
          <StyledNavIconsContainer className="nav-icons-container">
            {navIcons}
          </StyledNavIconsContainer>
        </StyledHeaderWrapper>
      </StyledClinNavBar>
      <StyledSecondNavWrapper>
        <StyledNavItemsContainer
          isMenuOpen={isBurgerOpen}
          className="nav-items-container"
          style={{ paddingTop: '0' }}
        >
          {navItems}
        </StyledNavItemsContainer>
      </StyledSecondNavWrapper>
    </>
  )
}
