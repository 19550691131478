export interface sortOption {
  optionId: string
  optionValue: string
}

export enum PatientColumn {
  Patient = 'Initials',
  Program = 'Program',
  Physician = 'PhysicianFullName',
  LastUpdated = 'lastUpdateDate',
  Order = 'patientOrderStatus'
}
export enum PastOrderColumn {
  OrderNumber = 'orderNumber',
  Medications = 'medications',
  OrderedDate = 'orderedDate',
  OrderStatus = 'orderStatus',
  PatientAccessForm = 'patientAccessForm'
}
export enum PatientFilterOptions {
  MyPrograms = 'my_programs',
  MyPatients = 'my_patients'
}
export const sortOptions: sortOption[] = [
  {
    optionId: PatientColumn.LastUpdated,
    optionValue: 'patient_detail:sort_options.sort_last_updated'
  },
  {
    optionId: PatientColumn.Order,
    optionValue: 'patient_detail:sort_options.sort_other'
  },
  {
    optionId: PatientColumn.Patient,
    optionValue: 'patient_detail:sort_options.sort_patients_a_z'
  },
  {
    optionId: PatientColumn.Program,
    optionValue: 'patient_detail:sort_options.sort_my_programs'
  },
  {
    optionId: PatientColumn.Physician,
    optionValue: 'patient_detail:sort_options.sort_physicians_a_z'
  }
]

export const filterOptions: sortOption[] = [
  {
    optionId: PatientFilterOptions.MyPrograms,
    optionValue: 'patient_detail:filter_options.filter_option_my_programs'
  },
  {
    optionId: PatientFilterOptions.MyPatients,
    optionValue: 'patient_detail:filter_options.filter_option_my_patients'
  }
]
