import React, { FunctionComponent, ReactNode } from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { ClinChip } from '../../../components/ClinChip'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { Col, Row } from 'react-grid-system'
import {
  ClinTable,
  ClinTableBodyCell,
  ClinTableHeaderCell
} from '../../../components/ClinTable/ClinTable'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { StyledClinTableRow } from './ProductSearch.styles'
import { CatalogDto, FacetDto } from '../../../types/swaggerTypes'
import { ClinTableOrderToggle } from '../../../components/ClinTableOrderToggle'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinPagination } from '../../../components/ClinPagination'
import {
  getCurrentPage,
  getTotalPages,
  IPagination
} from '../../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { TableFilterSearch } from '../../../features/TableFilterSearch'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ProductColumn, productColumnRecord } from '../Product.models'
import { BookmarkToggleButtonContainer } from '../../../features/BookmarkToggleButton/BookmarkToggleButtonContainer'
import { BookmarkableItemType } from '../../../types/BookmarkableItem'
import { IProductCatalog } from '../../../types'
import { ClinTableToggleRowButton } from '../../../components/ClinTableToggleRowButton'
import { NoProducts } from '../../../features/NoProducts'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { BasicSearch } from '../../../features/Search/BasicSearch'
import { useAppContext } from '../../../context/app'
import {
  UserRoleRecord,
  UserRole,
  isAusMaUser,
  isAusGaUser
} from '../../../constants'
import { useTranslation } from 'react-i18next'

interface IProductSearchProps {
  /** Whether table is loading or not */
  isLoading?: boolean
  /** User type */
  isMaUser?: boolean
  /** The initial query */
  initialQuery?: string
  /** The array of products search results */
  products?: CatalogDto[]
  /** The array of therapeutic areas */
  areaFilters?: FacetDto[]
  /** The array of therapeutic areas selected */
  selectedAreaFilters?: FacetDto[]
  /** The array of availability values */
  availabilityFilters?: FacetDto[]
  /** The selected availability values */
  selectedAvailabilityFilters?: FacetDto[]
  /** Columns to show on the orders table */
  columns: ProductColumn[]
  /** The selected column */
  selectedSortColumn?: ProductColumn
  /** The selected sort direction */
  selectedSortDirection?: SortDirectionType
  /** Pagination */
  pagination?: IPagination
  /** User country*/
  userCountry?: string
  /** When table row clicked */
  handleRowClicked?: (itemId: string | number, isProgram: boolean) => void
  /** When pagination page is selected */
  handlePageClicked?: (selectedPageIndex: number) => void
  /** Select results per page */
  handlePageSizeChange?: (pageSize: number) => void
  /** When a filter is selected */
  handleFilterToggle?: (filter: FacetDto, filterGroupTitle: string) => void
  /** Clear all filters */
  handleClearFilters?: () => void
  /** Select the toggle sort column */
  handleToggleSort?: (selectedColumn: ProductColumn) => void
  /** When a search query is entered and ENTER pressed */
  handleOnChange: (query: string) => void
  /** Toggle the opening of the selected row */
  handleToggleProductRow: (productId: string) => void
}

// Maximum number of brands to render before we show (+N more)
const MAX_BRAND_ENTRIES = 3

export const ProductSearch: FunctionComponent<IProductSearchProps> = ({
  isLoading,
  isMaUser,
  initialQuery,
  products,
  areaFilters,
  selectedAreaFilters,
  availabilityFilters,
  selectedAvailabilityFilters,
  columns,
  selectedSortColumn,
  selectedSortDirection,
  pagination,
  userCountry,
  handleRowClicked,
  handlePageClicked,
  handlePageSizeChange,
  handleFilterToggle,
  handleClearFilters,
  handleToggleSort,
  handleOnChange,
  handleToggleProductRow
}) => {
  const { t } = useTranslation()

  const { userRole } = useAppContext()

  const getColumnWidth = (column: ProductColumn) => {
    switch (column) {
      case ProductColumn.ToggleExpand:
        return '4%'
      case ProductColumn.GenericName:
      case ProductColumn.BrandName:
        return '18%'
      case ProductColumn.TherapeuticArea:
        return '30%'
      case ProductColumn.Availability:
      case ProductColumn.Bookmark:
        return '15%'
      default:
        return 'auto'
    }
  }

  const getCellContent = (
    column: ProductColumn,
    product: IProductCatalog
  ): ReactNode | string => {
    const {
      catalogItemName, // genericName
      brands, // brandNames
      tags, // therapeuticAreas
      availabilityText,
      sponsor, // sponsor name
      catalogDocumentId
    } = product
    switch (column) {
      case ProductColumn.ToggleExpand:
        if (brands.length <= MAX_BRAND_ENTRIES) {
          return null
        }
        return (
          <ClinTableToggleRowButton
            isToggled={brands.length > MAX_BRAND_ENTRIES && product.isOpen}
            onClick={() => {
              if (brands.length > 3) {
                handleToggleProductRow(product.catalogDocumentId)
              }
            }}
          />
        )
      case ProductColumn.GenericName:
        return (
          <ClinText fontWeight={ClinTheme.fontWeights.bold} as="span">
            {catalogItemName}
          </ClinText>
        )
      case ProductColumn.BrandName:
        return (
          <>
            {brands.map((brand: string, index: number) =>
              product.isOpen ? (
                <ClinText as="div" key={`brand-all-${brand}`}>
                  {brand}
                </ClinText>
              ) : index < MAX_BRAND_ENTRIES - 1 ||
                (index === MAX_BRAND_ENTRIES - 1 &&
                  brands.length === MAX_BRAND_ENTRIES) ? (
                <ClinText as="div" key={`brand-${brand}`}>
                  {brand}
                </ClinText>
              ) : index === MAX_BRAND_ENTRIES - 1 ? (
                <ClinText as="div" key={`brand-toggle-${brand}`}>
                  <em>
                    (+ {brands.length - (MAX_BRAND_ENTRIES - 1)}{' '}
                    {t('common:more')})
                  </em>
                </ClinText>
              ) : (
                <ClinText as="div" key={`brand-${brand}`} display="none">
                  {brand}
                </ClinText>
              )
            )}
          </>
        )
      case ProductColumn.TherapeuticArea:
        return (
          <>
            {tags.map((ta) => (
              <ClinChip key={`chip-${ta}`}>{ta}</ClinChip>
            ))}
          </>
        )
      case ProductColumn.Availability:
        return (
          <ClinText as="span" color={ClinTheme.colors.greenValid}>
            {availabilityText}
          </ClinText>
        )
      case ProductColumn.Bookmark:
        return (
          !product.isBookmarkable &&
          !product.programId && (
            <div onClick={(e) => e.stopPropagation()}>
              <BookmarkToggleButtonContainer
                itemType={BookmarkableItemType.Generic}
                itemId={catalogDocumentId}
                iconOnly={true}
              />
            </div>
          )
        )
      case ProductColumn.Sponsor:
        return <ClinText as="span">{sponsor}</ClinText>
      default:
        return t('product_catalogue:filter.unknown_type')
    }
  }

  const showAvailability =
    isMaUser && !isAusMaUser(userCountry!, userRole) //TODO CHECK THIS NULLABLE USER COUNTRY
      ? // Could not be null or empty object due to rendering issues in TableFilterSearch component
        {
          title: '',
          titleSelected: '',
          filters: [],
          selectedFilters: []
        }
      : {
          title: t('product_catalogue:filter.title'),
          titleSelected: `${t('product_catalogue:filter.availability')} (${
            selectedAvailabilityFilters?.length
          }) ${t('product_catalogue:filter.selected')}`,
          filters: availabilityFilters,
          selectedFilters: selectedAvailabilityFilters
        }

  return (
    <ClinPageContentFrame
      crumbs={
        isMaUser && !isAusMaUser(userCountry!, userRole)
          ? []
          : [
              isAusGaUser(userCountry!, userRole)
                ? {
                    path: '/home',
                    name: t('navigation:home')
                  }
                : {
                    path: '/orders',
                    name: t('product_catalogue:breadcrumbs.previous_page')
                  },
              {
                name: t('product_catalogue:breadcrumbs.current_page'),
                path: '/products'
              }
            ]
      }
    >
      <Row>
        <Col sm={12}>
          <ClinText
            as="h1"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {isMaUser && !isAusMaUser(userCountry!, userRole)
              ? t('product_catalogue:ma_title')
              : t('product_catalogue:non_ma_title')}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer />
      <Row>
        <Col sm={12}>
          <TableFilterSearch
            title={t('product_catalogue:filter.title')}
            filterGroups={[
              showAvailability,
              {
                title: t('product_catalogue:filter.group_selected'),
                titleSelected: `${t(
                  'product_catalogue:filter.group_selected'
                )} (${selectedAreaFilters?.length}) ${t(
                  'product_catalogue:filter.selected'
                )}`,
                filters: areaFilters,
                selectedFilters: selectedAreaFilters
              }
            ]}
            handleSelectFilterGroup={(filter, filterGroupTitle) =>
              handleFilterToggle && handleFilterToggle(filter, filterGroupTitle)
            }
            handleClearAllFilters={handleClearFilters}
            searchInput={() => (
              <BasicSearch
                initialQuery={initialQuery}
                placeholder={
                  userRole && UserRoleRecord[userRole as UserRole].isMaUser
                    ? t('product_catalogue:search.ma_placeholder')
                    : t('product_catalogue:search.non_ma_placeholder')
                }
                debounceTimeMs={500}
                handleOnChange={handleOnChange}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {isLoading && (
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          )}
          {!isLoading && products && (
            <ClinTable
              tableHeader={() =>
                columns.map((column: ProductColumn, index: number) => (
                  <ClinTableHeaderCell
                    key={column}
                    width={getColumnWidth(column)}
                    textAlign={
                      column === ProductColumn.Bookmark ? 'center' : undefined
                    }
                  >
                    {column === ProductColumn.ToggleExpand ? (
                      <span />
                    ) : column === ProductColumn.Bookmark ? (
                      <ClinTableOrderToggle hideSortIcon={true}>
                        {t('product_catalogue:bookmark')}
                      </ClinTableOrderToggle>
                    ) : (
                      <ClinTableOrderToggle
                        sortDirection={
                          selectedSortColumn === column
                            ? selectedSortDirection
                            : SortDirectionType.None
                        }
                        onClick={() =>
                          handleToggleSort && handleToggleSort(column)
                        }
                      >
                        {column === ProductColumn.GenericName &&
                        isMaUser &&
                        !isAusMaUser(userCountry!, userRole)
                          ? t(
                              productColumnRecord[ProductColumn.ProgramName]
                                .translationKey
                            )
                          : t(productColumnRecord[column].translationKey)}
                      </ClinTableOrderToggle>
                    )}
                  </ClinTableHeaderCell>
                ))
              }
            >
              {products.length > 0 ? (
                products.map((product: IProductCatalog) => {
                  return (
                    <StyledClinTableRow key={product.programId}>
                      {columns.map((column: ProductColumn, index: number) => {
                        const isProgram =
                          product.derivedFrom === `ManagedAccessProgram`
                        const itemId = isProgram
                          ? product.programId
                          : product.catalogDocumentId
                        return (
                          <ClinTableBodyCell
                            key={column}
                            onCellClick={() => {
                              handleRowClicked &&
                                handleRowClicked(itemId, isProgram)
                            }}
                            onCellEnter={() => {
                              handleRowClicked &&
                                product.brands.length < 2 &&
                                handleRowClicked(itemId, isProgram)
                            }}
                            verticalAlign={'top'}
                            textAlign={
                              column === ProductColumn.Bookmark
                                ? 'center'
                                : undefined
                            }
                            /* TODO: Technical debt on the component ! */
                            paddingTop={
                              column === ProductColumn.Bookmark ? 24 : undefined
                            }
                          >
                            {getCellContent(column, product)}
                          </ClinTableBodyCell>
                        )
                      })}
                    </StyledClinTableRow>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={columns.length}>
                    <NoProducts isMaUser={isMaUser} />
                  </td>
                </tr>
              )}
            </ClinTable>
          )}
        </Col>
      </Row>
      <ClinSpacer />
      <Row>
        <Col>
          {!isLoading &&
            products &&
            products?.length > 0 &&
            pagination &&
            pagination.total > 0 && (
              <ClinPagination
                currentPage={getCurrentPage(pagination)}
                totalPages={getTotalPages(pagination)}
                pageSize={pagination.take}
                handlePageSelected={(pageIndex) =>
                  handlePageClicked && handlePageClicked(pageIndex)
                }
                handlePageSizeChange={handlePageSizeChange}
              />
            )}
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
