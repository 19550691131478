import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  RouteComponentProps,
  StaticContext,
  matchPath,
  useLocation
} from 'react-router'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import {
  AuthError,
  cancelGetOrderById,
  cancelGetPatientDefaultDetail,
  cancelGetProgramFromSearchIndexById,
  cancelUpdateOrder,
  deleteOpa,
  getOrderById,
  getPatientDefaultDetail,
  getPatientDetail,
  getPatients,
  getPhysicianPatients,
  getProgramFromSearchIndexById,
  newUnvalidatedShippingAddress,
  removeOrder,
  updateOrder,
  updatePatient,
  updatePatientDefaultDetail
} from '../../services/ApiService'
import {
  CreateCdhShippingAddressDto,
  CreateShippingAddressDto,
  DeleteOrderDto,
  OrderDto,
  OrgAddressDto,
  PatientSummaryAssociatedToPhysiciansSearchDto,
  PatientSummarySearchDto,
  ProgramCatalogDto,
  UpdateBasketDetailsDto
} from '../../types/swaggerTypes'
import { useBeforeUnload } from '../../utils/useBeforeUnload'
import { useErrorMessage } from '../../utils/useErrorMessage'
import { useOnMount } from '../../utils/useOnMount'
import { OpaCheckout } from './OpaCheckout'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { showWootricSurvey } from '../../services/Wootric/showWootricSurvey'
import { useTranslation } from 'react-i18next'
import { useEffectOnlyOnce } from '../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsPageEvent } from '../../services/Analytics/AnalyticsPageEvent'
import { ActionType, useBasket } from '../../context/basket'
import { defaultBasketDetails } from '../../constants'
import { returnDefaultBasketValues } from '../Basket/Basket'
import { getUserPhone } from '../../utils/getUserPhone'
import { Col, Container, Row } from 'react-grid-system'
import { StyledSpinnerContainer } from './OpaCheckout.styles'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinTheme } from '../../ClinTheme'
import { AssociatedPatientsSortField } from '../../features/AssociatedPatients/AssociatedPatients.model'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { CreateAddressModal } from '../CheckoutOptions/CreateAddressModal/CreateAddressModal'
import { useCountries } from '../../utils/useCountries'
import { LocalStorageOPAKeys } from './OpaCheckout.models'
import useChangeBackgroundColor from '../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { AxiosError } from 'axios'

import {
  NewFeatureElements,
  PageNames
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { useNewFeaturesList } from '../../hooks/useNewFeaturesList/useNewFeaturesList'
import { RouteStateType } from '../PatientAccessForm/PatientAccessFormContainer'
import { DeletePAFModal } from './DeletePAFModal'
import { ModalMode } from '../PhysicianDetails/PhysicianDetailsContainer'
import {
  OPAOrderTypes,
  PatientOrderStatus
} from '../Patients/PatientDashboard/Patient.model'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'
import { OpaExitPreventionModal } from './OpaExitPreventionModal/OpaExitPreventionModal'
import { OpaExitPreventionPromptModal } from './OpaExitPreventionModal/OpaExitPreventionPromptModal'

interface IOpaCheckoutRouteParams {
  orderId: string
}

interface LocationState {
  programId: string
  patientNumber?: string
  patientId?: number
  orderType?: string
  newFeature?: NewFeatureElements
  stockOrder?: boolean
  from?: string
}

interface IOpaCheckoutProps
  extends RouteComponentProps<
    IOpaCheckoutRouteParams,
    StaticContext,
    LocationState
  > {}

export interface IDeliveryData {
  deliveryRadioId: string
  recipientName: string
  recipientPhoneNumber?: string
  recipientEmail?: string
  shippingAddressId: string
  poNumber: string
}

export const OpaCheckoutContainer: FunctionComponent<IOpaCheckoutProps> = ({
  match,
  location,
  history
}) => {
  const { t } = useTranslation()
  const { countries } = useCountries()
  const {
    dispatch,
    supportContact,
    user,
    userRole,
    userDetails,
    institute,
    portfolioCountryCode,
    defaultShippingAddress
  } = useAppContext()

  // eslint-disable-line
  const [, basketDispatch] = useBasket()
  const { useFeatureFlag } = useFeatureFlags()
  let patientCentricFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )
  const { newFeaturesList, displayNewFeature } = useNewFeaturesList(
    PageNames.OpaCheckout
  )

  const { orderId } = match.params

  const [programId, setProgramId] = useState<string>(
    history.location.state?.programId
  ) // || '2028' // for debug
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(true)
  const [isProgramLoading, setIsProgramLoading] = useState<boolean>(true)
  const [isDefaultDetailsLoading, setIsDefaultDetailsLoading] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmittingSaveAndClose, setIsSubmittingSaveAndClose] =
    useState<boolean>(false)
  const [isSubmittingAddress, setIsSubmittingAddress] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [displayEditMode, setDisplayEditMode] = useState<boolean>(false)
  const [order, setOrder] = useState<OrderDto>()
  const [program, setProgram] = useState<ProgramCatalogDto>()
  const [selectedAddress, setSelectedAddress] = useState<
    OrgAddressDto | undefined
  >(defaultShippingAddress)
  const [physicianTitleAndFullName, setPhysicianTitleAndFullName] = useState<
    string | undefined
  >()
  const [patientDOB, setPatientDOB] = useState<string | undefined>()
  const [patientStatus, setPatientStatus] = useState<string | undefined>()

  const [patientOrderType, setPatientOrderType] = useState<string | undefined>()
  const [resetForm, setResetForm] = useState<boolean>(false)

  const [deliveryNote, setDeliveryNote] = useState<string>('')

  const [physicianId, setPhysicianId] = useState<string | undefined>()
  const [patientNumber, setPatientNumber] = useState<string | undefined>()
  const [isNewShippingAddressOpen, setIsNewShippingAddressOpen] =
    useState<boolean>(false)
  const [deliveryAddresses, setDeliveryAddresses] = useState<OrgAddressDto[]>(
    []
  )
  const [errorVisible, setErrorVisible] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const [newFeature, setNewFeature] = React.useState<NewFeatureElements>()
  const routeState = useLocation<RouteStateType>()
  const [isLogoClicked, setIsLogoClicked] = useState<boolean>(false)
  const [showExitPreventionModal, setShowExitPreventionModal] =
    useState<boolean>(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false)
  const [lastLocation, setLastLocation] = useState<any>(null)
  const [isPromptModalShown, setIsPromptModalShown] = useState<boolean>(false)

  const matchPatientDashboardAndPatientDetails =
    (routeState.state &&
      matchPath(routeState.state.from, {
        path: '/patients'
      })) ||
    routeState?.state?.from?.startsWith('/programs/my-physicians/')

  const defaultPatientsSearchParams: PatientSummaryAssociatedToPhysiciansSearchDto =
    {
      query: '',
      filter: {
        programs: [],
        patientStatuses: []
      },
      pagination: {
        skip: 0,
        take: 100
      },
      sorting: {
        sortBy: AssociatedPatientsSortField.Status,
        order: SortDirectionType.Ascending.toUpperCase()
      }
    }

  const defaultDeliveryData: IDeliveryData = {
    deliveryRadioId: '2',
    recipientName: userDetails?.contactCard.name ?? '',
    poNumber: '',
    recipientEmail: userDetails?.contactCard.email
      ? userDetails?.contactCard.email
      : '',
    recipientPhoneNumber: getUserPhone(userDetails?.contactCard)
      ? getUserPhone(userDetails?.contactCard)
      : '',

    shippingAddressId: defaultShippingAddress
      ? defaultShippingAddress.addressId
      : '0'
  }
  const [deliveryData, setDeliveryData] =
    useState<IDeliveryData>(defaultDeliveryData)

  const [oldAddressId, setOldAddressId] = useState<string>(
    deliveryData.shippingAddressId
  )

  const [addressModalError, setAddressModalError] = useState<string>()

  const [deletePAFModalMode, setDeletePAFModalMode] = React.useState<
    ModalMode | undefined
  >()

  const [deletePAFError, setDeletePAFError] = React.useState<string>()

  //Passed down to OPAHeader to disable other buttons
  const [isCancelling, setIsCancelling] = useState<boolean>(false)

  const supportPhone: string =
    supportContact.csPhoneNumber ?? '+44 (0) 1932 824100'

  const handleProgramError = useErrorMessage(t('opa_checkout:program_error'))

  const handlePatientDefaultDetailsError = useErrorMessage(
    t('opa_checkout:program_error')
  )

  const handlePatientError = useErrorMessage(
    'There was an error fetching patient information'
  )

  const leaveMessage = t('opa_checkout:leave_message')
  useBeforeUnload(leaveMessage, !isSubmitted)

  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  const handleDeliveryDataChange = (value: IDeliveryData) => {
    setDeliveryData(value)
    setOldAddressId(value.shippingAddressId)

    if (physicianId && patientNumber && institute?.data?.instituteId) {
      updatePatientDefaultDetail(physicianId, patientNumber, {
        instituteId: institute.data?.instituteId.toString(),
        patientNumber,
        defaultRecipientName: value.recipientName,
        defaultShippingAddressId: ''
      })
        .then(() => {})
        .catch((error) =>
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred submitting your patient details. ${error}`
            )
          )
        )
    }
  }

  const handleAddressChange = (address: OrgAddressDto) => {
    setSelectedAddress(address)
    setDeliveryData({
      ...deliveryData,
      shippingAddressId: address.addressId
    })
  }

  const buttonZIndex = newFeaturesList[NewFeatureElements.EditButtonMaChekout]
    ? '999'
    : undefined

  const noteZIndex =
    newFeaturesList[NewFeatureElements.DeliveryNote] &&
    !newFeaturesList[NewFeatureElements.EditButtonMaChekout]
      ? '999'
      : undefined

  const checkboxShaddow =
    newFeaturesList[NewFeatureElements.DeliveryNote] &&
    !newFeaturesList[NewFeatureElements.EditButtonMaChekout]
      ? '0px 0px 20px 0px #FFF'
      : undefined

  useOnMount(() => {
    orderId &&
      getOrderById(orderId)
        .then((response) => {
          setOrder(response.data)
          const orderDetails = response.data
          const orderItem = response.data.lines.filter(
            (x) => x.medicationFlag === 'Y'
          )[0]
          const physicianId = orderItem?.physician?.physicianId
          physicianId && setPhysicianId(physicianId?.toString())
          const patientNumber = orderItem.patientNumber
          if (!programId) {
            setProgramId(orderItem.programId?.toString())
          }
          if (physicianId && patientNumber) {
            setIsDefaultDetailsLoading(true)
            getPatientDefaultDetail(patientNumber, physicianId.toString())
              .then((result) => {
                if (matchPatientDashboardAndPatientDetails) {
                  if (response.data.deliveryNote)
                    setDeliveryNote(response.data.deliveryNote)
                  setDeliveryData({
                    ...deliveryData,
                    recipientName:
                      orderDetails?.deliverToContact ??
                      result.data.defaultRecipientName,
                    shippingAddressId: orderDetails?.deliverTo.addressId
                      ? orderDetails?.deliverTo.addressId
                      : result.data.defaultShippingAddressId
                      ? result.data.defaultShippingAddressId
                      : defaultShippingAddress?.addressId ?? '',
                    recipientEmail:
                      orderDetails?.recipientEmail ??
                      userDetails?.contactCard.email ??
                      '',
                    recipientPhoneNumber: orderDetails?.recipientPhoneNumber
                      ? orderDetails?.recipientPhoneNumber
                      : getUserPhone(userDetails?.contactCard)
                      ? getUserPhone(userDetails?.contactCard)
                      : '',
                    poNumber:
                      orderDetails?.customerPoNumber ??
                      orderDetails?.orderNumber ??
                      ''
                  })
                } else if (
                  result.data.defaultRecipientName &&
                  result.data.defaultShippingAddressId
                ) {
                  setDeliveryData({
                    ...deliveryData,
                    recipientName: result.data.defaultRecipientName,
                    shippingAddressId: result.data.defaultShippingAddressId
                  })
                } else if (result.data.defaultShippingAddressId) {
                  setDeliveryData({
                    ...deliveryData,
                    shippingAddressId: result.data.defaultShippingAddressId
                  })
                } else if (result.data.defaultRecipientName) {
                  setDeliveryData({
                    ...deliveryData,
                    recipientName: result.data.defaultRecipientName
                  })
                }

                if (orderItem?.physician) {
                  let physicianName = orderItem.physician.physicianFullName
                  if (orderItem.physician.physicianTitle) {
                    physicianName = orderItem.physician?.physicianTitle.concat(
                      ' ',
                      orderItem.physician.physicianFullName
                    )
                  }
                  setPhysicianTitleAndFullName(physicianName)
                }

                setPhysicianId(physicianId.toString())
                setPatientNumber(patientNumber)
                if (patientCentricFlag) {
                  const defaultSearchParams: PatientSummarySearchDto = {
                    query: patientNumber ?? '',
                    filter: {
                      programs: [],
                      patientOrderStatuses: [],
                      physicianId: undefined,
                      showMyPatient: false,
                      showMyPhysician: false,
                      showMyProgram: false
                    },
                    pagination: {
                      skip: 0,
                      take: 100
                    },
                    sorting: {
                      sortBy: 'patientOrderStatus',
                      order: SortDirectionType.Descending
                    }
                  }
                  getPatients(defaultSearchParams).then((result) => {
                    let patientSummary = result?.data.result.filter((item) => {
                      return item.patientNumber === patientNumber
                    })
                    if (patientSummary && patientSummary[0]) {
                      setPatientDOB(patientSummary[0].dateOfBirth)
                      setPatientStatus(patientSummary[0].patientOrderStatus)
                      setPatientOrderType(patientSummary[0].orderType)
                    }
                  })
                } else {
                  getPhysicianPatients(physicianId, defaultPatientsSearchParams)
                    .then((result) => {
                      let patientSummary = result?.data.result.filter(
                        (item) => {
                          return item.patientNumber === patientNumber
                        }
                      )
                      patientSummary &&
                        patientSummary[0]?.patientId &&
                        getPatientDetail(
                          patientSummary[0].patientId.toString(),
                          physicianId.toString()
                        ).then((result) => {
                          result?.data?.dateOfBirth &&
                            setPatientDOB(result.data.dateOfBirth)
                        })
                    })
                    .catch((error) => handlePatientError(error))
                }
              })
              .catch((error) => handlePatientDefaultDetailsError(error))
              .finally(() => setIsDefaultDetailsLoading(false))
          }
        })
        .catch(() => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              t('opa_checkout:announce_customer_services_title', {
                supportPhone: supportPhone
              }),
              t('opa_checkout:announce_customer_services_description', {
                supportPhone: supportPhone
              })
            )
          )
        })
        .finally(() => setIsOrderLoading(false))
    return () => {
      cancelGetOrderById()
      cancelGetProgramFromSearchIndexById()
      cancelGetPatientDefaultDetail()
      cancelUpdateOrder()
    }
  })

  const handleDeliveryNote = () => {
    if (deliveryNote) {
      localStorage.setItem(LocalStorageOPAKeys.DeliveryNote, deliveryNote)
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.AddDeliveryNote, {})
    } else {
      localStorage.setItem(LocalStorageOPAKeys.DeliveryNote, '')
    }
  }
  useEffect(() => {
    programId &&
      getProgramFromSearchIndexById(programId)
        .then((result) => {
          setProgram(result.data)
        })
        .catch((error) => handleProgramError(error))
        .finally(() => setIsProgramLoading(false))
  }, [programId])

  useEffect(() => {
    if (
      patientCentricFlag &&
      patientStatus &&
      patientStatus !== PatientOrderStatus.IncompleteCheckout
    ) {
      const patientId = history.location?.state?.patientId
      physicianId &&
        patientId &&
        updatePatient(physicianId, patientId, {
          orderStatus: PatientOrderStatus.IncompleteCheckout
        })
          .then((response) => {})
          .catch((error: AxiosError) => {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `An error occurred updating patient status. ${error}`
              )
            )
          })
    }
  }, [physicianId, patientCentricFlag, patientStatus])

  // this useEffect need to redirect to the OpaOrderSummary page without a navigation guard when the order is booked
  useEffect(() => {
    if (isSubmitted) {
      if (order) {
        history.replace(`/opa-order-summary/${orderId}`, {
          programId: programId
        })
      } else {
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            'There was an error placing order.'
          )
        )
      }
    } else if (isSaved) {
      let stockOrder =
        newFeature === NewFeatureElements.CancelCheckout
          ? history.location.state.stockOrder
          : undefined
      let redirect = '/patients'
      if (location?.state?.from) {
        redirect = location.state.from
      }
      history.push(redirect, {
        programId: '',
        patientNumber:
          order?.lines.filter((x) => x.medicationFlag === 'Y')[0]
            .patientNumber ?? '',
        stockOrder: stockOrder,
        newFeature: newFeature
      })
    }

    return () => {}
  }, [isSubmitted, order, history, orderId, programId, dispatch, isSaved])

  const handleSaveAndCloseButton = (
    programName?: string,
    programId?: string,
    poNumber?: string
  ) => {
    setConfirmedNavigation(true) // Add this line
    window.scrollTo(0, 0)
    setIsSubmittingSaveAndClose(true)
    updateOrder(orderId, {
      bookedFlag: 'N',
      shippingHold: 'Y',
      deliverToContact: deliveryData.recipientName,
      custPONumber: poNumber ?? order?.orderNumber,
      recipientEmail: deliveryData.recipientEmail,
      recipientPhoneNumber: deliveryData.recipientPhoneNumber,
      deliveryNote: deliveryNote,
      shipToOrgId: !isNaN(parseInt(deliveryData.shippingAddressId))
        ? parseInt(deliveryData.shippingAddressId)
        : null
    })
      .then((response) => {
        if (response.status == 200) {
          setNewFeature(NewFeatureElements.ContinueCheckout)
          setIsSaved(true)
        } else {
          setIsSaved(false)
        }

        analyticsServiceSingleton.trackEvent(AnalyticsEvent.SaveAndClose, {
          location: 'ma checkout',
          orderId: orderId,
          programName: programName,
          programId: programId
        })
      })
      .catch((error) => {
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `An error occurred updating your order. ${error}`
          )
        )
      })
  }

  const handleConfirmOrder = (poNumber: string | undefined) => {
    window.scrollTo(0, 0)
    setIsSubmitting(true)
    triggerDelayedError(15000)
    updateOrder(orderId, {
      bookedFlag: 'Y',
      shippingHold: 'Y',
      deliverToContact: deliveryData.recipientName,
      custPONumber: poNumber || order?.orderNumber,
      recipientEmail: deliveryData.recipientEmail,
      recipientPhoneNumber: deliveryData.recipientPhoneNumber,
      deliveryNote: deliveryNote,
      shipToOrgId: !isNaN(parseInt(deliveryData.shippingAddressId))
        ? parseInt(deliveryData.shippingAddressId)
        : null
    })
      .then(({ data }) => {
        const patientId =
          data && data.lines.length > 0 ? data?.lines[0].patientNumber : 'N/A'
        analyticsServiceSingleton.trackEvent(AnalyticsEvent.OrderCompleted, {
          orderId: data?.orderNumber,
          order_number: data?.orderNumber, // for hubspot format
          division: 'MA',
          institute_id: user?.institute?.instituteId,
          has_hold: data ? data?.holds.length > 0 : false,
          order_value: data?.totals.total,
          patient_id: patientId
        })
        analyticsServiceSingleton.trackEvent(
          AnalyticsEvent.PurchaseOrderNumber,
          {}
        )
        setOrder(data)
        setIsSubmitted(true)
        let basketDetails: UpdateBasketDetailsDto = defaultBasketDetails
        if (userRole && userDetails)
          basketDetails = returnDefaultBasketValues(userRole, userDetails)
        basketDispatch({
          type: ActionType.UpdateBasketDetails,
          basketDetails: basketDetails
        })
        handleDeliveryNote()
        // Trigger survey
        showWootricSurvey(user, order?.orderNumber, 'MA')
      })
      .catch((error) =>
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `An error occurred submitting your order. ${error}`
          )
        )
      )
      .finally(() => setIsSubmitting(false))
  }

  const getAddressResponseErrorMessage = (message: any) => {
    if (message) {
      let messageLine = message.split(',')
      if (messageLine && messageLine[6]) {
        let messageLineText = messageLine[6].split(':')
        if (messageLineText) {
          let errorMessage = messageLineText[2]
          if (errorMessage) {
            let errorMessageWithoutQuotations = errorMessage.split('"')
            if (errorMessageWithoutQuotations) {
              let finalErrorMessage = errorMessageWithoutQuotations[1]
              return finalErrorMessage ?? ''
            }
          }
        }

        return ''
      }
    }
  }

  const handleNewShippingAddress = (data: CreateShippingAddressDto) => {
    if (data && data.address) {
      setIsSubmittingAddress(true)
      let newAddress: CreateCdhShippingAddressDto = {
        address: {
          ...data.address,
          countryCode: data.address.country
        }
      }
      newUnvalidatedShippingAddress(newAddress)
        .then((response) => {
          if (response?.data) {
            const newAddress = response?.data
            if (newAddress) {
              let newDeliveryAddresses: OrgAddressDto[] =
                deliveryAddresses.slice()
              newDeliveryAddresses.unshift(newAddress)
              setDeliveryAddresses(newDeliveryAddresses)
              setSelectedAddress(newAddress)
              setDeliveryData({
                ...deliveryData,
                shippingAddressId: newAddress.addressId
              })
            }
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.CreateDeliveryAddress,
              {}
            )
            setIsNewShippingAddressOpen(false)
            setAddressModalError('')
            setResetForm(true)
          }
        })
        .catch((error: AxiosError) => {
          const { code, message } = error
          setResetForm(false)
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          // If we have a full error show it
          if (error.response && error.response.data) {
            setAddressModalError(
              getAddressResponseErrorMessage(
                error.response.data.dependency.response
              )
            )
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `${t(
                  'request_new_shipping_address:submitted_fail_message'
                )} ${message} ${code ?? ''}`
              )
            )
          }
        })
        .finally(() => {
          setIsSubmittingAddress(false)
        })
    }
  }

  const handleCancelButton = () => {
    setIsCancelling(true)
    setDeletePAFModalMode(ModalMode.active)
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.CancelOrderMA, {
      orderId: orderId,
      programName: order?.lines[0].programName,
      programId: order?.lines[0].programId,
      location: 'Ma Checkout'
    })
  }

  // handlers of delete  modal window
  const handleDeletePAFConfirmation = () => {
    setConfirmedNavigation(true) // Add this line
    const deleteReason: DeleteOrderDto = {
      cancelReason: 'deleted from checkout',
      cancelComments: ''
    }
    deleteOpa(
      programId,
      physicianId ?? '',
      defaultShippingAddress?.addressId ?? '',
      patientNumber ?? ''
    )
      .then(() => {
        if (order) {
          setDeletePAFModalMode(ModalMode.submitting)
          removeOrder(order.orderNumber, deleteReason)
            .then(() => {
              const patientId = history.location?.state?.patientId
              const newStatus =
                patientOrderType === OPAOrderTypes.Resupply
                  ? PatientOrderStatus.Ready_for_resupply
                  : PatientOrderStatus.BeginOrder
              if (physicianId && patientId) {
                setNewFeature(NewFeatureElements.CancelCheckout)
                updatePatient(physicianId, patientId, {
                  orderStatus: newStatus
                })
                  .then((response) => {
                    setIsSaved(true)
                  })
                  .catch((error: AxiosError) => {
                    setDeletePAFModalMode(ModalMode.hasError)
                    setDeletePAFError(error.message)
                  })
              }
            })
            .catch((error: AxiosError) => {
              setDeletePAFModalMode(ModalMode.hasError)
              setDeletePAFError(error.message)
            })
        }
      })
      .catch((error: AxiosError) => {
        setDeletePAFModalMode(ModalMode.hasError)
        setDeletePAFError(error.message)
      })
      .finally(() => setIsCancelling(false))
  }

  const handleDeletePAFClose = () => {
    setIsCancelling(false)
    setDeletePAFModalMode(undefined)
  }

  type Deps = [boolean, ProgramCatalogDto]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(
        AnalyticsPageEvent.ViewOPACheckout,
        {
          'Program name': dependencies[1].programName,
          'Program ID': dependencies[1].projectId
        }
      )
    },
    [isProgramLoading, program],
    (dependencies: Deps) => !dependencies[0] && dependencies[1]
  )

  useEffect(() => {
    if (institute) {
      const currentAddress = institute.data?.shipTo.find(
        (x) => x.addressId == deliveryData.shippingAddressId
      )
      if (currentAddress) {
        setSelectedAddress(currentAddress)
      }
    }

    return () => {}
  }, [deliveryData.shippingAddressId, institute])

  useEffect(() => {
    if (institute?.data) {
      setDeliveryAddresses(institute.data.shipTo.map((address) => address))
    }

    return () => {}
  }, [institute.data, institute])

  useEffect(() => {
    if (order) {
      const orderItem = order.lines.filter((x) => x.medicationFlag === 'Y')[0]
      if (orderItem?.physician) {
        let physicianName = orderItem.physician.physicianFullName
        if (orderItem.physician.physicianTitle) {
          physicianName = orderItem.physician?.physicianTitle.concat(
            ' ',
            orderItem.physician.physicianFullName
          )
        }
        setPhysicianTitleAndFullName(physicianName)
      }
    }
    return () => {}
  }, [order])

  const triggerDelayedError = (delay: number) => {
    setTimeout(() => {
      setErrorVisible(true)
    }, delay)
  }

  useEffect(() => {
    const unblock = history.block((location, action): any => {
      if (!confirmedNavigation) {
        if (location.pathname.includes('/opa-order-summary')) {
          return true
        }
        if (location.pathname === '/orders') {
          return true
        }
        if (!isPromptModalShown) {
          setIsPromptModalShown(true)
          setIsLogoClicked(false)
        } else {
          setShowExitPreventionModal(true)
        }
        setLastLocation(location)
        return false
      }
      return true
    })

    return () => {
      unblock()
    }
  }, [confirmedNavigation, isPromptModalShown])

  const handleCancelNavigation = () => {
    setShowExitPreventionModal(false)
    setConfirmedNavigation(false)
  }

  return order ? (
    <>
      <OpaExitPreventionPromptModal
        isOpen={isLogoClicked}
        handleClose={() => setIsLogoClicked(false)}
        handleOnConfirm={() => history.push('/orders')}
      />
      <OpaExitPreventionModal
        isOpen={showExitPreventionModal}
        handleClose={handleCancelNavigation}
      />
      <DeletePAFModal
        isOpen={deletePAFModalMode !== undefined}
        isSubmitting={deletePAFModalMode === ModalMode.submitting}
        hasError={deletePAFModalMode === ModalMode.hasError}
        errorMessage={deletePAFError}
        isCancelling={isCancelling}
        handleConfirmation={handleDeletePAFConfirmation}
        handleClose={handleDeletePAFClose}
      />
      <CreateAddressModal
        addressModalError={addressModalError}
        isOpen={isNewShippingAddressOpen}
        handleFormSubmit={handleNewShippingAddress}
        handleClose={() => {
          setAddressModalError('')
          setIsNewShippingAddressOpen(false)
        }}
        isSubmitting={isSubmittingAddress}
        selectedCountry={portfolioCountryCode}
        countries={countries}
        hasError={!!addressModalError}
        resetForm={resetForm}
      />
      <OpaCheckout
        isLoading={
          isOrderLoading ||
          isProgramLoading ||
          !order ||
          isDefaultDetailsLoading
        }
        isSubmitting={isSubmitting}
        isSubmittingSaveAndClose={isSubmittingSaveAndClose}
        isSubmitted={isSubmitted}
        isErrorVisible={errorVisible}
        isSaved={isSaved}
        order={order}
        program={program}
        displayEditMode={displayEditMode}
        deliveryData={deliveryData}
        shippingAddress={selectedAddress}
        deliveryAddresses={deliveryAddresses}
        deliveryNote={deliveryNote}
        newFeaturesList={newFeaturesList}
        noteZIndex={noteZIndex}
        buttonZIndex={buttonZIndex}
        checkboxShaddow={checkboxShaddow}
        oldAddressId={oldAddressId}
        setIsLogoClicked={setIsLogoClicked}
        displayNewFeature={displayNewFeature}
        isCancelling={isCancelling}
        handleSetOldAddressId={setOldAddressId}
        setDeliveryNote={setDeliveryNote}
        handleSetDeliveryData={setDeliveryData}
        handleSetDisplayMode={setDisplayEditMode}
        handleConfirmOrder={handleConfirmOrder}
        handleSetIsNewShippingAddressOpen={() =>
          setIsNewShippingAddressOpen(true)
        }
        handleDeliveryDataChange={handleDeliveryDataChange}
        handleSetShippingAddress={handleAddressChange}
        patientNumber={patientNumber!}
        physicianTitleAndFullName={physicianTitleAndFullName}
        programSponsor={program?.program?.sponsorName}
        patientDob={patientDOB}
        handleCancelButton={handleCancelButton}
        handleSaveAndCloseButton={handleSaveAndCloseButton}
        handleErrorModal={setShowExitPreventionModal}
      />
    </>
  ) : (
    <Container
      style={{
        height: `calc(100vh - ${ClinTheme.componentsHeights.footer}px)`
      }}
    >
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </Container>
  )
}
