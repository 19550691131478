export enum PatientsColumn {
  Patient = 'Patient',
  Program = 'Program',
  TreatingPhysician = 'TreatingPhysician',
  LastUpdated = 'LastUpdated',
  PatientStatus = 'PatientStatus',
  Actions = 'Actions'
}

export enum OPAOrderTypes {
  Resupply = 'Resupply',
  InitialOrder = 'Initial Order',
  StockOrder = 'Stock Order'
}

export enum PatientOrderStatus {
  UnderMedicalReview = 'Under Medical Review',
  Processing = 'Processing',
  In_Transit = 'In Transit',
  Out_for_delivery = 'Out for delivery',
  Failed_attempt = 'Failed attempt',
  Failed_Attempt = 'Failed Attempt',
  Delivered = 'Delivered',
  Available_for_Pickup = 'Available for Pickup',
  Expired = 'Expired',
  Pending = 'Pending',
  Shipped = 'Shipped',
  Failed_Delivery = 'Failed Delivery',
  Ready_for_resupply = 'Ready for resupply',
  PatientDiscontinued = 'This patient has been discontinued',
  Program_closed = 'Program closed',
  Delayed = 'Delayed',
  BeginOrder = 'Begin your order',
  IncompletePAF = 'Continue with your order',
  IncompleteCheckout = 'One more step to go'
}

export const getRandomColor = (value: number) => {
  const colors = [
    '#FFBDA2',
    '#80D091',
    '#9BE7E4',
    '#8C88FF',
    '#9DCDE8',
    '#E8AEFF'
  ]

  let colorArray: string[] = []

  for (let i = 0; i < value; i++) {
    const randomIndex = Math.floor(Math.random() * colors.length)
    colorArray.push(colors[randomIndex])
  }

  return colorArray
}

export enum ActionDropdownConstants {
  BeginOrder = 'BeginOrder',
  DiscontinuePatient = 'DiscontinuePatient',
  ContinueOrder = 'ContinueOrder',
  OrderResuply = 'OrderResuply',
  TrackYourOrder = 'TrackYourOrder',
  ReconcileVials = 'ReconcileVials',
  TransferPatient = 'TransferPatient',
  OneMoreStepToGo = 'OneMoreStepToGo',
  DeletePatient = 'DeletePatinet',
  NoActions = 'NoActions'
}

export enum CreatePatientsViewMode {
  ShowForm = 'ShowForm',
  Submitting = 'Submitting',
  Loading = 'Loading',
  DuplicatePatient = 'Duplicate'
}

export const getGenders = () => {
  return [
    'patient_detail:create_patients.gender_male',
    'patient_detail:create_patients.gender_female'
  ]
}

export const patientOrderStatusTranslation = {
  [PatientOrderStatus.UnderMedicalReview]:
    'patient_detail:patient_order_statuses.under_medical_review',
  [PatientOrderStatus.Processing]:
    'patient_detail:patient_order_statuses.processing',
  [PatientOrderStatus.In_Transit]:
    'patient_detail:patient_order_statuses.in_transit',
  [PatientOrderStatus.Out_for_delivery]:
    'patient_detail:patient_order_statuses.out_for_delivery',
  [PatientOrderStatus.Failed_attempt]:
    'patient_detail:patient_order_statuses.failed_attempt',
  [PatientOrderStatus.Failed_Attempt]:
    'patient_detail:patient_order_statuses.failed_attempt',
  [PatientOrderStatus.Delivered]:
    'patient_detail:patient_order_statuses.delivered',
  [PatientOrderStatus.Available_for_Pickup]:
    'patient_detail:patient_order_statuses.available_for_pickup',
  [PatientOrderStatus.Expired]: 'patient_detail:patient_order_statuses.expired',
  [PatientOrderStatus.Pending]: 'patient_detail:patient_order_statuses.pending',
  [PatientOrderStatus.Shipped]: 'patient_detail:patient_order_statuses.shipped',
  [PatientOrderStatus.Delayed]: '',
  [PatientOrderStatus.Failed_Delivery]:
    'patient_detail:patient_order_statuses.failed_delivery',
  [PatientOrderStatus.Ready_for_resupply]:
    'patient_detail:patient_order_statuses.ready_for_resupply',
  [PatientOrderStatus.PatientDiscontinued]:
    'patient_detail:patient_order_statuses.discontinued',
  [PatientOrderStatus.Program_closed]:
    'patient_detail:patient_order_statuses.program_closed',
  [PatientOrderStatus.BeginOrder]:
    'patient_detail:patient_order_statuses.new_patient',
  [PatientOrderStatus.IncompletePAF]:
    'patient_detail:patient_order_statuses.incomplete_paf',
  [PatientOrderStatus.IncompleteCheckout]:
    'patient_detail:patient_order_statuses.incomplete_checkout'
}

export const patientOrderStatusTooltipTranslation = {
  [PatientOrderStatus.UnderMedicalReview]:
    'patient_detail:patient_status_tooltip.under_medical_review',
  [PatientOrderStatus.Processing]:
    'patient_detail:patient_status_tooltip.processing',
  [PatientOrderStatus.In_Transit]:
    'patient_detail:patient_status_tooltip.in_transit',
  [PatientOrderStatus.Out_for_delivery]:
    'patient_detail:patient_status_tooltip.out_for_delivery',
  [PatientOrderStatus.Failed_attempt]:
    'patient_detail:patient_status_tooltip.failed_attempt',
  [PatientOrderStatus.Failed_Attempt]:
    'patient_detail:patient_status_tooltip.failed_attempt',
  [PatientOrderStatus.Delivered]:
    'patient_detail:patient_status_tooltip.delivered',
  [PatientOrderStatus.Available_for_Pickup]:
    'patient_detail:patient_status_tooltip.available_for_pickup',
  [PatientOrderStatus.Expired]: 'patient_detail:patient_status_tooltip.expired',
  [PatientOrderStatus.Pending]: 'patient_detail:patient_status_tooltip.pending',
  [PatientOrderStatus.Shipped]: 'patient_detail:patient_status_tooltip.shipped',
  [PatientOrderStatus.Failed_Delivery]:
    'patient_detail:patient_status_tooltip.failed_delivery',
  [PatientOrderStatus.Ready_for_resupply]:
    'patient_detail:patient_status_tooltip.ready_for_resupply',
  [PatientOrderStatus.PatientDiscontinued]:
    'patient_detail:patient_status_tooltip.discontinued',
  [PatientOrderStatus.Program_closed]:
    'patient_detail:patient_status_tooltip.program_closed',
  [PatientOrderStatus.BeginOrder]:
    'patient_detail:patient_status_tooltip.new_patient',
  [PatientOrderStatus.IncompletePAF]:
    'patient_detail:patient_status_tooltip.incomplete_paf',
  [PatientOrderStatus.IncompleteCheckout]:
    'patient_detail:patient_status_tooltip.incomplete_checkout'
}
