import React, { RefObject, useEffect } from 'react'
import { Position as TooltipPosition } from 'react-tippy'
import { ClinNewFeatureDot } from './ClinNewFeatureDot/ClinNewFeatureDot'
import {
  StyledClinNewFeatureOverlay,
  StyledFloatingUITooltip,
  StyledFloatingUITooltipArrow,
  StyledTooltip
} from './ClinNewFeatureTooltip.styles'
import { ClinNewFeatureTooltipContent } from './ClinNewFeatureTooltipContent/ClinNewFeatureTooltipContent'
import { NewFeatureDotWrap } from './ClinNewFeatureDot/ClinNewFeatureDot.styles'
import {
  ReferenceType,
  Strategy,
  FloatingOverlay
} from '@floating-ui/react-dom-interactions'
import { ClinTheme } from '../../ClinTheme'
import {
  FLOATING_OVERLAY_VISIBLE,
  eventEmitter
} from '../../utils/eventEmitter'

interface IClinNewFeatureTooltipProps {
  id: string
  style?: object
  title?: string
  showFeatureFromTable?: boolean
  description: string
  total?: number
  current?: number
  position?: TooltipPosition
  color?: string
  cssClass?: string
  offsetNumber?: number
  isCancelled?: boolean
  open: boolean
  tooltip: HTMLElement
  button: HTMLElement
  arrowEl?: HTMLElement
  hideCloseButton?: boolean
  tooltipWidth?: string
  arrowRef?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
  reference: (node: ReferenceType | null) => void
  floating: (node: HTMLElement | null) => void
  strategy: Strategy
  x: number | null
  y: number | null
  getReferenceProps: () => {}
  getFloatingProps: () => {}
  handleRemoveTooltip: (isCancelled?: boolean) => void
  handleOpenTooltip: () => void
}

export const ClinNewFeatureTooltipFloatingUI: React.FC<
  IClinNewFeatureTooltipProps
> = ({
  id,
  title,
  description,
  total,
  current,
  showFeatureFromTable,
  cssClass,
  color,
  tooltipWidth,
  open,
  arrowRef,
  hideCloseButton,
  isCancelled,
  reference,
  floating,
  strategy,
  x,
  y,
  getReferenceProps,
  getFloatingProps,
  handleRemoveTooltip,
  handleOpenTooltip
}) => {
  useEffect(() => {
    eventEmitter.emit(FLOATING_OVERLAY_VISIBLE, !showFeatureFromTable)
  }, [showFeatureFromTable])

  const renderTooltipContent = () => (
    <StyledFloatingUITooltip
      ref={floating}
      {...getFloatingProps()}
      id={`tooltip-${id}`}
      className="feature-tooltip"
      style={{
        position: strategy,
        left: x ?? '0',
        top: y ?? '0',
        backgroundColor: color ?? ClinTheme.colors.primaryMid,
        width: tooltipWidth ?? 'max-content'
      }}
    >
      <ClinNewFeatureTooltipContent
        id={id}
        title={title ?? ''}
        description={description}
        total={total}
        current={current}
        hideCloseButton={hideCloseButton}
        closeTooltipHandler={handleRemoveTooltip}
      />
      {arrowRef && (
        <StyledFloatingUITooltipArrow
          id={`arrow-${id}`}
          ref={arrowRef}
          color={color}
        />
      )}
    </StyledFloatingUITooltip>
  )

  return (
    <StyledTooltip>
      <NewFeatureDotWrap
        className={id}
        id={`button-${id}`}
        {...getReferenceProps()}
        ref={reference}
        onClick={handleOpenTooltip}
      >
        <ClinNewFeatureDot />
      </NewFeatureDotWrap>
      {!showFeatureFromTable && !isCancelled && <StyledClinNewFeatureOverlay />}
      {open && (
        <FloatingOverlay
          style={{
            pointerEvents: 'auto',
            zIndex: ClinTheme.zIndices.newFeatureFloatingUi
          }}
        >
          {renderTooltipContent()}
        </FloatingOverlay>
      )}
      {isCancelled && renderTooltipContent()}
    </StyledTooltip>
  )
}
