import React, { FunctionComponent } from 'react'
import {
  StyledSearchWrapperForAUS,
  StyledGlobalSearchforAUS,
  StyledSearchInputAus,
  StyledResultsAus
} from './GlobalSearch.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { IAutoSuggestProps } from '../AutoSuggest/AutoSuggest.model'
import { useTranslation } from 'react-i18next'
import { StyledSearchIcon } from '../../../pages/Homepage/Homepage.styles'

export const GlobalSearchAUS: FunctionComponent<IAutoSuggestProps> = ({
  query = '',
  suggestions = [],
  isLoading,
  showSuggestions,
  handleChange,
  handleKeyDown,
  handleEnter,
  renderSuggestions
}) => {
  const { t } = useTranslation()
  return (
    <StyledGlobalSearchforAUS>
      <StyledSearchWrapperForAUS
        showSuggestions={showSuggestions}
        className={'clin-global-search-input-wrapper'}
        onKeyDown={handleKeyDown}
      >
        <StyledSearchInputAus
          className={'clin-global-search-input'}
          showSuggestions={showSuggestions}
          placeholder={t('global_search:start_typing_placeholder')}
          value={query}
          onChange={handleChange}
          onKeyDown={(event) => {
            handleEnter && event.keyCode === 13 && handleEnter(event)
          }}
        />

        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: ClinTheme.space[2]
            }}
          >
            <ClinSpinner size={ClinTheme.space[3]} />
          </div>
        )}
        <StyledSearchIcon>
          <ClinIcon
            style={{
              position: 'absolute',
              right: ClinTheme.space[3],
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            iconHeight={'19.5'}
            iconSize={'19.5'}
            iconName={ClinIconPathName.Search}
          />
        </StyledSearchIcon>
        <StyledResultsAus showSuggestions={showSuggestions}>
          {suggestions && renderSuggestions && renderSuggestions(suggestions)}
        </StyledResultsAus>
      </StyledSearchWrapperForAUS>
    </StyledGlobalSearchforAUS>
  )
}
