import AnalyticsService, { IAnalyticsProvider } from './AnalyticsService'
import {
  ApplicationInsights,
  SeverityLevel
} from '@microsoft/applicationinsights-web'
import { AnalyticsEvent } from './AnalyticsEvent'
import authService from '../AuthService'

/**
 * ApplicationInsightsProvider
 *
 * @usage
 * Install dependencies
 * Pass through the correct id token
 * npm install @microsoft/applicationinsights-web -S
 *
 * @further-information
 * https://github.com/microsoft/ApplicationInsights-JS#readme
 */
export class ApplicationInsightsProvider
  extends AnalyticsService
  implements IAnalyticsProvider
{
  private appInsights: ApplicationInsights | undefined

  initProvider = () => {
    this.logEvent(`ApplicationInsightsProvider initProvider`)
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.tokenId,
        disableFetchTracking: false,
        enableDebug: this.enableDebugLogging,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        disableTelemetry: false
      }
    })
    this.appInsights.loadAppInsights()
    // Assign user ID
    authService
      .getUserAsync()
      .then(
        (user) =>
          user &&
          this.appInsights &&
          this.appInsights.setAuthenticatedUserContext(user?.profile.sub)
      )
  }

  trackError = (error: Error) => {
    this.logEvent(`ApplicationInsightsProvider trackError ${error}`)
    this.appInsights &&
      this.appInsights.trackException({
        error,
        severityLevel: SeverityLevel.Error
      })
  }

  trackEvent = (event: AnalyticsEvent, properties?: object) => {
    this.logEvent(`ApplicationInsightsProvider trackEvent ${event}`)
    this.appInsights && this.appInsights.trackEvent({ name: event }, properties)
  }

  trackPageView = (path: string) => {
    this.logEvent(`ApplicationInsightsProvider trackPageView ${path}`)
    this.appInsights && this.appInsights.trackPageView({ name: path })
  }
}
