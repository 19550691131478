import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  StyledGlobalSearch,
  StyledSearchWrapper,
  StyledSearchInput,
  StyledResults,
  StyledResultsWithNewFeature
} from './GlobalSearch.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { IAutoSuggestProps } from '../AutoSuggest/AutoSuggest.model'
import { useTranslation } from 'react-i18next'
import {
  NewFeatureElements,
  PageNames
} from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { useAppContext } from '../../../context/app'
import { useNewFeatures, removeFeature } from '../../../context/newFeatures'
import { useNewFeaturesList } from '../../../hooks/useNewFeaturesList/useNewFeaturesList'

export const GlobalSearch: FunctionComponent<IAutoSuggestProps> = ({
  query = '',
  suggestions = [],
  isLoading,
  showSuggestions,
  isAusGa,
  handleChange,
  handleKeyDown,
  handleEnter,
  renderSuggestions
}) => {
  const { t } = useTranslation()
  const { userDetails } = useAppContext()
  const [, dispatch] = useNewFeatures()

  const localStorageKey = userDetails ? userDetails.contactCard.name : ''

  const { newFeaturesList } = useNewFeaturesList(PageNames.GlobalSearch)

  const newUserFastTrack =
    userDetails?.contactCard.name &&
    localStorage.getItem(userDetails?.contactCard.name)

  const [showFeature, setShowFeature] = useState<boolean>(false)

  const checkDisplayFeature = () => {
    const featureExists: boolean =
      !!newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature] &&
      !isAusGa
    setShowFeature(featureExists && !newUserFastTrack)
  }

  useEffect(() => {
    checkDisplayFeature()
  }, [localStorage.getItem(localStorageKey), newFeaturesList])

  const handleSearchInputClick = () => {
    if (newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature]) {
      // Remove focus from input
      const input = document.querySelector(
        '.clin-global-search-input'
      ) as HTMLInputElement
      input && input.blur()
      removeFeature(
        dispatch,
        newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature].id
      )
    }
  }

  return (
    <StyledGlobalSearch>
      <StyledSearchWrapper
        className={'clin-global-search-input-wrapper'}
        onKeyDown={handleKeyDown}
        style={{
          zIndex: showFeature ? ClinTheme.zIndices.dropdown : 'unset'
        }}
      >
        {newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature] &&
          !isAusGa &&
          !newUserFastTrack && (
            <span className="tooltip-span" style={{ marginRight: '15px' }}>
              <ClinNewFeatureTooltipContainer
                feature={
                  newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature]
                }
                openTooltipInitially={true}
                disableDismiss={true}
                disableClinFeature={true}
              ></ClinNewFeatureTooltipContainer>
            </span>
          )}

        <StyledSearchInput
          style={{
            pointerEvents: 'auto',
            zIndex: showFeature ? ClinTheme.zIndices.stickyTableRowTop : 'unset'
          }}
          className={'clin-global-search-input'}
          showGlowEffect={showFeature}
          autoFocus={true}
          showSuggestions={showSuggestions}
          placeholder={t('global_search:start_typing_placeholder')}
          value={query}
          onChange={handleChange}
          onKeyDown={(event) => {
            handleEnter && event.keyCode === 13 && handleEnter(event)
          }}
          onClick={handleSearchInputClick}
        />

        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: ClinTheme.space[2]
            }}
          >
            <ClinSpinner size={ClinTheme.space[3]} />
          </div>
        )}
        <ClinIcon
          style={{
            position: 'absolute',
            right: ClinTheme.space[3],
            top: '50%',
            transform: 'translateY(-50%)'
          }}
          iconName={ClinIconPathName.Search}
        />
        {showFeature && (
          <StyledResultsWithNewFeature showSuggestions={showSuggestions}>
            {suggestions && renderSuggestions && renderSuggestions(suggestions)}
          </StyledResultsWithNewFeature>
        )}
        {!showFeature && (
          <StyledResults showSuggestions={showSuggestions}>
            {suggestions && renderSuggestions && renderSuggestions(suggestions)}
          </StyledResults>
        )}
      </StyledSearchWrapper>
    </StyledGlobalSearch>
  )
}
