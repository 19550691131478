import React from 'react'
import {
  NewFeatureDotCircle1,
  NewFeatureDotCircle2,
  NewFeatureDotCircle3,
  NewFeatureDotRinging
} from './ClinNewFeatureDot.styles'
import { ClinTheme } from '../../../ClinTheme'

interface INewFeatureDotProps {
  ref?: any
  id?: string
}

export const ClinNewFeatureDot = React.forwardRef<
  HTMLInputElement,
  INewFeatureDotProps
>((props: INewFeatureDotProps, ref) => {
  return (
    <div
      ref={ref}
      id={props.id}
      style={{
        position: 'relative',
        cursor: 'pointer',
        pointerEvents: 'auto',
        zIndex: ClinTheme.zIndices.stickyTableRowTop
      }}
      {...props}
    >
      <NewFeatureDotRinging></NewFeatureDotRinging>
      <NewFeatureDotCircle1>
        <NewFeatureDotCircle2>
          <NewFeatureDotCircle3></NewFeatureDotCircle3>
        </NewFeatureDotCircle2>
      </NewFeatureDotCircle1>
    </div>
  )
})
