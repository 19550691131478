import React, { FunctionComponent, useEffect } from 'react'
import {
  FileInputLabel,
  IDocumentUploadStyleProps,
  StyledButtonArea,
  StyledFileRow,
  StyledDocumentUpload
} from './DocumentUpload.styles'
import { ClinButton } from '../../components/ClinButton'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinTheme } from '../../ClinTheme'
import { BasketItemDocumentDto, OrgAddressDto } from '../../types/swaggerTypes'
import { ClinSpacer } from '../../components/ClinSpacer'
import {
  maxDocumentUploadSasHold,
  validateFileExtensionAllowed
} from './DocumentUpload.model'
import { Trans, useTranslation } from 'react-i18next'
import { IViewBasketItem } from '../../types'
import { DocumentUploadItem } from './DocumentUploadItem'
import { ClinSpinner } from '../../components/ClinSpinner'
import { HoldType } from '../../services/Holds/holds.constants'

export interface IUploadedDetails {
  /** Name of the person who uploaded the file */
  uploadedBy?: string
  /** Date the file was uploaded */
  uploadDate?: string
}

export interface DocumentUploadPromptProps {
  /** Title prompt describing the file that should be uploaded*/
  prompt?: string
  /** More detailed information on the file that should be uploaded */
  documentPrompt?: any
  /** Optional warehouse address */
  warehouseAddress?: OrgAddressDto
  /** Valid file extensions */
  accept?: string
  /** Name of the selected file */
  fileName?: string | null
  /** Show required flag */
  required?: boolean
  /** Render visually different version */
  isPreOrderVariant?: boolean
}

interface DocumentUploadPropsAus
  extends IDocumentUploadStyleProps,
    DocumentUploadPromptProps {
  // TODO: This needs cleaning up - technical debt - we should be able to compose stuff into here
  /** Document details from a document which has already been uploaded */
  uploadDetails?: IUploadedDetails | null
  /** Whether this document is currently being uploaded */
  isUploading?: boolean
  /** Whether this document is currently being removed */
  isRemoving?: boolean
  /** Whether user can remove a file */
  showRemoveFile?: boolean
  /** custom style for AUS */
  styleForAUS?: boolean
  /** An error message for the file */
  error?: string
  basketItem?: IViewBasketItem
  /** Called when a file is selected */
  handleFileSelected: (file: File) => void
  /** Called when the user wants to remove uploaded file */
  handleClickRemove: (item: BasketItemDocumentDto) => void
}

export const DocumentUploadAus: FunctionComponent<DocumentUploadPropsAus> = ({
  documentPrompt,
  prompt,
  isPreOrderVariant,
  warehouseAddress,
  styleForAUS,
  handleFileSelected,
  handleClickRemove,
  accept = '.docx,.doc,.pdf,.jpg,.jpeg',
  fileName,
  isUploading,
  isRemoving,
  showRemoveFile = false,
  uploadDetails,
  required,
  error,
  basketItem,
  ...props
}) => {
  const { t } = useTranslation()
  const [items, setItems] = React.useState<BasketItemDocumentDto[] | undefined>(
    []
  )

  useEffect(() => {
    const sasHoldDocumentItems = basketItem?.documents.filter(
      (item) =>
        item.uploadedDocument !== null && item.holdName === HoldType.SASHold
    )
    setItems(sasHoldDocumentItems)
  }, [basketItem])

  return (
    <StyledDocumentUpload
      isPreOrderVariant={isPreOrderVariant}
      {...props}
      style={{ paddingBottom: '0px' }}
    >
      <StyledFileRow
        isPreOrderVariant={isPreOrderVariant}
        styleForAUS={styleForAUS}
      >
        <ClinText
          marginBottom={fileName ? ClinTheme.space[3] : 0}
          className="clin-document-upload__prompt"
        >
          {prompt}
          {!fileName && required && (
            <strong> {t('common:document_upload.required')}</strong>
          )}
        </ClinText>

        <div>
          <ClinText
            as={'div'}
            variant={TypographyVariant.LargeParagraph}
            color={ClinTheme.colors.black}
            fontWeight={ClinTheme.fontWeights.bold}
            marginBottom={0}
          >
            {t('common:buttons.upload_file')}
          </ClinText>
          <ClinText
            as={'div'}
            whiteSpace={'pre-line'}
            className={'sku-information'}
            variant={TypographyVariant.LargeParagraph}
            color={ClinTheme.colors.black}
            fontWeight={ClinTheme.fontWeights.normal}
            marginBottom={ClinTheme.space[2]}
            marginTop={0}
          >
            {items && items.length >= maxDocumentUploadSasHold ? (
              <Trans
                i18nKey={'basket:accepted_file_types_and_max_items_aus_holds'}
                values={{ newLine: '\n', max: maxDocumentUploadSasHold }}
              >
                <></>
              </Trans>
            ) : (
              <Trans
                i18nKey={
                  'basket:checklist_for_aus_hold.accepted_file_types_aus'
                }
                values={{ newLine: '\n' }}
              >
                <></>
              </Trans>
            )}
          </ClinText>
        </div>

        <StyledButtonArea styleForAUS={styleForAUS}>
          {!isUploading && !isRemoving && (
            <FileInputLabel>
              <ClinButton
                as={
                  items && items?.length >= maxDocumentUploadSasHold
                    ? undefined
                    : 'span'
                }
                variant="link"
                disabled={items && items?.length >= maxDocumentUploadSasHold}
                style={{ marginRight: '27px', textTransform: 'uppercase' }}
              >
                {t('common:buttons.add_file')}
              </ClinButton>
              <input
                type="file"
                style={{ display: 'none' }}
                accept={accept}
                onChange={(e) => {
                  const input = e.target as HTMLInputElement
                  const file = input.files && input.files[0]
                  if (file) {
                    const isAllowed = validateFileExtensionAllowed(
                      file.name,
                      accept
                    )
                    if (!isAllowed) {
                      window.alert(
                        `${t(
                          'common:validation_warnings.choose_valid_file_type'
                        )}: ${accept}`
                      )
                      return
                    }
                    isAllowed && handleFileSelected && handleFileSelected(file)
                  }
                }}
              />
            </FileInputLabel>
          )}
          {(isUploading || isRemoving) && (
            <ClinSpinner
              size={ClinTheme.space[4]}
              fillColor={ClinTheme.colors.primary}
            />
          )}
        </StyledButtonArea>
      </StyledFileRow>
      {error && (
        <ClinText
          color={ClinTheme.colors.redInvalid}
          fontWeight={ClinTheme.fontWeights.bold}
        >
          {error}
        </ClinText>
      )}

      {items?.length && items.length > 0 ? <ClinSpacer height={16} /> : null}
      {items?.length && items.length > 0 ? (
        items.map((item) => (
          <DocumentUploadItem
            {...props}
            key={item.basketItemDocumentId}
            styleForAUS={styleForAUS}
            basketItem={basketItem}
            item={item}
            handleClickRemove={(item) => handleClickRemove(item)}
            handleFileSelected={(file) => {
              handleFileSelected(file)
            }}
          />
        ))
      ) : (
        <></>
      )}
    </StyledDocumentUpload>
  )
}
