import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { NavLink } from 'react-router-dom'
import { mediaQuery } from '../../utils/mediaQuery'
import { IClinNavItemProps, IStyledNavTextProps } from './ClinNavItem'
import {
  StyledBasketCounter,
  StyledMainItemsName
} from '../ClinNavbar/ClinNavbar.styles'

const xLargeUpBreakpoint = ClinTheme.breakpoints[3]

export const StyledClinNavLinkInner = styled.span({
  cursor: 'pointer',
  paddingLeft: ClinTheme.space[3],
  paddingRight: ClinTheme.space[3],
  paddingBottom: ClinTheme.space[3],
  paddingTop: ClinTheme.space[3],
  color: ClinTheme.colors.primary,
  fontSize: ClinTheme.fontSizes[4],
  fontWeight: ClinTheme.fontWeights.medium,
  letterSpacing: ClinTheme.letterSpacing[1],
  display: 'block',
  transition: '.2s linear border-bottom, .2s linear color',
  width: '100%',

  [mediaQuery(xLargeUpBreakpoint)]: {
    textTransform: 'uppercase',
    fontWeight: ClinTheme.fontWeights.medium,
    fontSize: ClinTheme.fontSizes[1],
    borderBottom: `solid 2px ${ClinTheme.colors.transparent}`,
    paddingTop: 0,
    paddingBottom: ClinTheme.space[1],
    marginLeft: 0,
    paddingLeft: 0,
    paddingRight: 0,
    color: ClinTheme.colors.white
  }
})

export const StyledClinUpperNavLinkInner = styled.span({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
  color: ClinTheme.colors.white,
  fontSize: '13px',
  lineHeight: '1.84',
  fontWeight: ClinTheme.fontWeights.normal,
  letterSpacing: ClinTheme.letterSpacing[0],
  transition: '.2s linear border-bottom, .2s linear color',
  width: '100%',
  [mediaQuery(xLargeUpBreakpoint)]: {
    color: ClinTheme.colors.white,
    paddingBottom: '2px'
  }
})

export const StyledClinSecondNavLinkInner = styled.span({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
  justifyContent: 'center',
  color: ClinTheme.colors.primary,
  fontSize: ClinTheme.fontSizes[4],
  lineHeight: ClinTheme.lineHeights.heading[0],
  fontWeight: ClinTheme.fontWeights.normal,
  letterSpacing: ClinTheme.letterSpacing[0],
  transition: '.2s linear border-bottom, .2s linear color',
  width: '100%',
  padding: '16px',
  paddingBottom: '14px',

  [mediaQuery(xLargeUpBreakpoint)]: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    color: ClinTheme.colors.white,
    fontSize: ClinTheme.fontSizes[1],
    lineHeight: ClinTheme.lineHeights.heading[0],
    fontWeight: ClinTheme.fontWeights.normal,
    letterSpacing: ClinTheme.letterSpacing[0],
    transition: '.2s linear border-bottom, .2s linear color',
    width: '100%'
  }
})

export const StyledClinNavLink = styled(NavLink)<IClinNavItemProps>({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: '.2s linear border-bottom, .2s linear color',
  [`:hover`]: {
    [StyledClinNavLinkInner]: {
      color: ClinTheme.colors.magenta,
      textDecoration: 'none'
    }
  },
  [`&.active`]: {
    [StyledClinNavLinkInner]: {
      backgroundColor: ClinTheme.colors.lightGrey,
      borderBottom: `solid 1px ${ClinTheme.colors.grey}`
    }
  },

  [mediaQuery(xLargeUpBreakpoint)]: {
    paddingTop: 0,
    paddingBottom: ClinTheme.space[1],
    marginLeft: 0,
    paddingRight: ClinTheme.space[5],
    paddingLeft: 0,

    [`&.active`]: {
      [StyledClinNavLinkInner]: {
        backgroundColor: ClinTheme.colors.transparent,
        borderBottom: `solid 2px ${ClinTheme.colors.primary}`
      }
    },
    [`:hover`]: {
      [StyledClinNavLinkInner]: {
        textDecoration: 'none',
        borderBottom: `solid 2px ${ClinTheme.colors.magenta}`
      }
    }
  }
})

export const StyledNavText = styled.span<IStyledNavTextProps>(
  {
    borderBottom: '2px solid transparent'
  },
  ({ showHighlight, textTransformStyle }: IStyledNavTextProps) => ({
    textTransform: textTransformStyle ? 'initial' : 'inherit',
    zIndex: showHighlight ? 7 : 'inherit',
    fontWeight: showHighlight ? ClinTheme.fontWeights.bold : 'inherit'
  })
)

export const StyledClinUpperNavLink = styled(NavLink)<IClinNavItemProps>({
  display: 'flex',
  textTransform: 'capitalize',
  paddingBottom: '0',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: '8px',
  transition: '.2s linear border-bottom, .2s linear color',
  [`:hover`]: {
    [StyledNavText]: {
      textDecoration: 'none'
    }
  },
  [`&.active`]: {
    [StyledClinSecondNavLinkInner]: {
      backgroundColor: ClinTheme.colors.lightGrey
    }
  },

  [mediaQuery(xLargeUpBreakpoint)]: {
    paddingTop: 0,
    marginLeft: 0,
    paddingRight: '5px',
    paddingLeft: 0,

    [`&.active`]: {
      [StyledNavText]: {
        fontWeight: ClinTheme.fontWeights.bold,
        backgroundColor: ClinTheme.colors.transparent,
        borderBottom: `solid 2px ${ClinTheme.colors.white}`
      },
      [StyledClinSecondNavLinkInner]: {
        backgroundColor: ClinTheme.colors.transparent
      }
    },
    [`:hover`]: {
      [StyledNavText]: {
        textDecoration: 'none',
        borderBottom: `solid 2px ${ClinTheme.colors.primaryLight}`,
        color: ClinTheme.colors.primaryLight
      },
      [StyledMainItemsName]: {
        color: ClinTheme.colors.primaryLight
      },
      [StyledBasketCounter]: {
        color: ClinTheme.colors.primaryLight
      }
    },
    [`:hover svg path:first-child`]: {
      fill: ClinTheme.colors.primaryLight
    }
  }
})
