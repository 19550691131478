import { DateTime } from 'luxon'
import { RelationshipDto, UserDto } from '../../types/swaggerTypes'
import {
  AudienceParameters,
  FeatureFlag,
  FeatureFlagVariant,
  FeatureFlagWithTargetingFilter,
  FeatureFlagWithTimeWindow,
  TimeWindowFilter
} from './FeatureFlagModels'

export function isFeatureFlagWithTargetingFilter(
  flag: FeatureFlag
): flag is FeatureFlagWithTargetingFilter {
  return (
    (flag as FeatureFlagWithTargetingFilter).conditions.client_filters[0]
      ?.name === FeatureFlagVariant.Targeting
  )
}

export function isFeatureFlagWithTimeWindow(
  flag: FeatureFlag
): flag is FeatureFlagWithTimeWindow {
  return (
    (flag as FeatureFlagWithTimeWindow).conditions.client_filters[0]?.name ===
    FeatureFlagVariant.TimeWindow
  )
}

export const parseFeatureFlagData = (raw: any) => {
  let parsedData: Record<string, FeatureFlag> = {}
  try {
    for (const key in raw) {
      if (Object.prototype.hasOwnProperty.call(raw, key)) {
        const flag: FeatureFlag = JSON.parse(raw[key])
        parsedData[flag.id] = flag
      }
    }
  } catch (error) {
    console.error(
      'Error parsing feature flag data, please raise an issue with our customer services: ',
      error
    )
    return {} // Abort if json is malformed, means a breaking change in Azure App Config structure
  }
  return parsedData
}

export const evaluateFeatureFlags = (
  parsedFeatureFlagData: Record<string, FeatureFlag>,
  userDetails: UserDto | undefined
) => {
  const evaluatedFlags: Record<string, boolean> = {}
  for (const key in parsedFeatureFlagData) {
    if (parsedFeatureFlagData.hasOwnProperty(key)) {
      evaluatedFlags[key] = evaluateFeatureFlag(
        parsedFeatureFlagData[key],
        userDetails
      )
    }
  }
  return evaluatedFlags
}

// TODO break down into individual methods based on FeatureFlagVariant
export const evaluateFeatureFlag = (
  featureFlag: FeatureFlag,
  userDetails: UserDto | undefined
): boolean => {
  // Feature flag disabled?
  if (featureFlag.enabled === false) {
    return false
  }

  // Base feature flag, if it's on, it's on
  if (featureFlag.conditions.client_filters.length === 0) {
    return featureFlag.enabled
  }

  // Targeting feature flag - variants are audience and user/group
  if (isFeatureFlagWithTargetingFilter(featureFlag)) {
    const targetingFilter = featureFlag.conditions.client_filters.find(
      (filter) => filter.name === FeatureFlagVariant.Targeting
    )

    if (targetingFilter) {
      const audience: AudienceParameters = targetingFilter.parameters?.Audience
      const currentUser = userDetails?.contactCard.email // Get the current user
      const userCountryCodes = getShipToCountryCodes() // Get the user's country codes

      // If we don't have the current user we have bigger issues than a feature flag
      if (!currentUser) return false

      // Ensure ExcludedCountries, IncludedCountries, IncludedUsers, and ExcludedUsers are arrays
      const includedCountries = audience.IncludedCountries || []
      const excludedCountries = audience.ExcludedCountries || []
      const includedUsers = audience.IncludedUsers || []
      const excludedUsers = audience.ExcludedUsers || []

      // If all arrays are empty, return true
      if (
        includedCountries.length === 0 &&
        excludedCountries.length === 0 &&
        includedUsers.length === 0 &&
        excludedUsers.length === 0
      ) {
        return true
      }

      // If the user is in the excluded users list, return false
      if (excludedUsers.includes(currentUser)) {
        return false
      }

      // If user is in the included users list, return true
      if (includedUsers.includes(currentUser)) {
        return true
      }

      // If the user is not in the included users list but includedUsers is not empty, return false
      if (includedUsers.length > 0 && !includedUsers.includes(currentUser)) {
        return false
      }

      // If the user is not in excluded users list and included users list is empty, return true
      if (
        includedUsers.length === 0 &&
        !excludedUsers.includes(currentUser) &&
        excludedUsers.length > 0
      ) {
        return true
      }

      // If excluded countries are empty but there's a match with included countries
      if (
        includedCountries.length > 0 &&
        excludedCountries.length === 0 &&
        includedCountries.some((country) => userCountryCodes.includes(country))
      ) {
        return true
      }

      // If excluded countries are empty and included countries do not match, return false
      if (
        includedCountries.length > 0 &&
        excludedCountries.length === 0 &&
        !includedCountries.some((country) => userCountryCodes.includes(country))
      ) {
        return false
      }

      // If included users and excluded users are empty but there's a match with included countries
      if (includedUsers.length === 0 && excludedUsers.length === 0) {
        if (
          includedCountries.some((country) =>
            userCountryCodes.includes(country)
          )
        ) {
          return true
        }
      }

      // If included users are empty, excluded users are empty, and included countries are empty
      // but there's a match with excluded countries, return false
      if (
        includedUsers.length === 0 &&
        excludedUsers.length === 0 &&
        includedCountries.length === 0
      ) {
        if (
          excludedCountries.some((country) =>
            userCountryCodes.includes(country)
          )
        ) {
          return false
        }
      }

      // If no user country is matched by excludedCountries, return true
      const isCountryExcluded = excludedCountries.some((country) =>
        userCountryCodes.includes(country)
      )
      if (!isCountryExcluded) {
        return true
      }

      // Check if the current user is included in the included users and not in excluded users or countries
      const isUserIncluded = includedUsers.includes(currentUser)

      if (isUserIncluded && !isCountryExcluded) {
        return true
      } else if (
        audience.DefaultRolloutPercentage > 0 &&
        audience.DefaultRolloutPercentage <= 100
      ) {
        const randomPercentage = Math.random() * 100
        return randomPercentage <= audience.DefaultRolloutPercentage
      } else {
        return false
      }
    }
  }

  // Time window feature flag - if enabled and if it's the right time
  if (isFeatureFlagWithTimeWindow(featureFlag)) {
    const timeWindowFilter: TimeWindowFilter | undefined =
      featureFlag.conditions.client_filters.find(
        (filter) => filter.name === FeatureFlagVariant.TimeWindow
      )

    if (timeWindowFilter) {
      const currentTime: DateTime = DateTime.now() // Get the current time
      const endTime: DateTime = DateTime.fromFormat(
        timeWindowFilter.parameters.End,
        "EEE, dd MMM yyyy HH:mm:ss 'GMT'"
      )

      // Check if the current time is before the specified end time
      if (currentTime < endTime) {
        return true
      } else {
        return false
      }
    }
  }

  // Space for custom feature flag evaluations. Expected to expand upon types in models.
  return false // Fallback value set to false always
}

const getCurrentUser = () => {
  const currentUserJson = localStorage.getItem('current_user')
  if (!currentUserJson) {
    console.error('No current user found in local storage')
    return null
  }

  try {
    return JSON.parse(currentUserJson)
  } catch (error) {
    console.error('Error parsing current user data from local storage:', error)
    return null
  }
}

const getShipToCountryCodes = () => {
  const currentUser = getCurrentUser()
  if (!currentUser) {
    return []
  }

  const contactCard = currentUser.contactCard
  if (!contactCard || !contactCard.clientRelationships) {
    console.error('Invalid contact card data')
    return []
  }

  return contactCard.clientRelationships.map(
    (relationship: RelationshipDto) => relationship.shipToCountryCode
  )
}
