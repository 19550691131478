import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery, mediaQueryDown } from '../../../utils/mediaQuery'
import { ISearchStylesProps } from '../AutoSuggest/AutoSuggest'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledGlobalSearch = styled.section({
  padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
  backgroundColor: ClinTheme.colors.primaryLight,
  [mediaQuery(mobileUpBreakpoint)]: {
    padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`
  },
  [`@media print`]: {
    display: 'none'
  }
})

export const StyledGlobalSearchforAUS = styled.section({
  paddingLeft: '20px',
  paddingRight: '20px',
  backgroundColor: ClinTheme.colors.lightGrey,
  height: 'calc(100vh - 635px)'
})

export const StyledSearchWrapper = styled.div({
  backgroundColor: ClinTheme.colors.primaryLight,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  [`.clin-icon`]: {
    transition: '.2s linear color',
    color: ClinTheme.colors.white
  },
  [`> input:focus`]: {
    [`~ .clin-icon`]: {
      color: ClinTheme.colors.primary
    }
  },
  [mediaQuery(mobileUpBreakpoint)]: {
    maxWidth: 650,
    margin: 'auto'
  }
})

export const StyledSearchWrapperForAUS = styled.div<ISearchStylesProps>(
  {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    top: '-40px',
    borderRadius: '60px',
    height: '68px',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.16), 0px 0px 8px rgba(0, 0, 0, 0.16)',
    padding: `${ClinTheme.space[0]}px ${ClinTheme.space[4]}`,
    [`.clin-icon`]: {
      transition: '.2s linear color',
      color: ClinTheme.colors.white
    },
    [`> input:focus`]: {
      [`~ .clin-icon`]: {
        color: ClinTheme.colors.primary
      },
      borderColor: ClinTheme.colors.primaryMid
    },
    [mediaQuery(mobileUpBreakpoint)]: {
      maxWidth: 1094,
      maxHeight: 68,
      margin: 'auto'
    },
    [mediaQueryDown(ClinTheme.breakpoints[0])]: {
      top: '-290px'
    }
  },
  ({ showSuggestions }) => ({
    [`> input:focus`]: {
      borderBottom: showSuggestions
        ? `1px solid ${ClinTheme.colors.primaryLight}`
        : `8px solid ${ClinTheme.colors.primaryMid}`
    }
  })
)

export const StyledSearchInput = styled.input<ISearchStylesProps>(
  {
    fontFamily: ClinTheme.fonts.body,
    position: 'relative',
    flex: '1 1 auto',
    marginBottom: '0',
    textIndent: ClinTheme.space[4],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[2]}px`,
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.light,
    color: ClinTheme.colors.white,
    borderRadius: ClinTheme.space[5],
    transition: '.2s linear background-color, .2s linear color',
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    [`:focus`]: {
      color: ClinTheme.colors.primary,
      backgroundColor: ClinTheme.colors.white,
      outline: '0'
    },
    [mediaQuery(mobileUpBreakpoint)]: {
      fontSize: ClinTheme.fontSizes[3]
    }
  },
  ({ showSuggestions }) => ({
    backgroundColor: showSuggestions
      ? ClinTheme.colors.white
      : ClinTheme.colors.primaryMid,
    borderBottomLeftRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderBottomRightRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderTopLeftRadius: showSuggestions
      ? ClinTheme.space[3]
      : ClinTheme.space[5],
    borderTopRightRadius: showSuggestions
      ? ClinTheme.space[3]
      : ClinTheme.space[5]
  }),
  ({ showGlowEffect }) => ({
    border: showGlowEffect ? '1px solid white' : 'none',
    boxShadow: showGlowEffect ? '0 0 5px #fff' : 'none'
  })
)

export const StyledSearchInputAus = styled.input<ISearchStylesProps>(
  {
    fontFamily: ClinTheme.fonts.body,
    position: 'relative',
    flex: '1 1 auto',
    marginBottom: '0',
    textIndent: ClinTheme.space[4],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[2]}px`,
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.normal,
    color: ClinTheme.colors.white,
    borderRadius: ClinTheme.space[5],
    transition: '.2s linear background-color, .2s linear color',
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    [`:focus`]: {
      color: ClinTheme.colors.primary,
      border: `${ClinTheme.space[2]}px solid ${ClinTheme.colors.primaryMid}`,
      backgroundColor: ClinTheme.colors.white,
      outline: ClinTheme.space[0]
    },
    [mediaQuery(mobileUpBreakpoint)]: {
      fontSize: ClinTheme.fontSizes[3]
    }
  },
  ({ showSuggestions }) => ({
    backgroundColor: showSuggestions
      ? ClinTheme.colors.white
      : ClinTheme.colors.primaryMid,
    border: showSuggestions
      ? `${ClinTheme.space[2]}px solid ${ClinTheme.colors.primaryMid}`
      : `${ClinTheme.space[2]}px solid ${ClinTheme.colors.white}`,
    borderBottom: showSuggestions
      ? `1px solid ${ClinTheme.colors.primaryMid}`
      : `${ClinTheme.space[2]}px solid ${ClinTheme.colors.white}`,
    borderBottomLeftRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderBottomRightRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderTopLeftRadius: showSuggestions ? '28px' : ClinTheme.space[5],
    borderTopRightRadius: showSuggestions ? '28px' : ClinTheme.space[5]
  })
)

export const StyledResults = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)

export const StyledResultsAus = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    border: `${ClinTheme.space[2]}px solid ${ClinTheme.colors.primaryMid}`,
    borderRadius: '0 0 28px 28px',
    borderTop: 'none',
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)

export const StyledResultsWithNewFeature = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: '51px',
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    pointerEvents: 'auto',
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)
