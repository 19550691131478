import React, { FunctionComponent } from 'react'
import {
  StyledDocumentationIcon,
  StyledDocumentCard,
  StyledDocumentCardInner,
  StyledHoldItem,
  StyleDocumentationDetail,
  StyleDocumentationDetailButton,
  StyleDocumentationDetails,
  StyledProductAccordion,
  StyledProductDetail,
  StyledSKUQuantitySelector
} from './ProductDetail.styles'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Hidden, Row } from 'react-grid-system'
import { ClinSpacer } from '../../../components/ClinSpacer'
import {
  CatalogDto,
  DistributorDto,
  ItemDocumentDto,
  UploadedDocumentDto
} from '../../../types/swaggerTypes'
import { ClinButton } from '../../../components/ClinButton'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../../components/ClinAccordion'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { IconValueCell } from '../../../features/IconValueCell'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinIcon } from '../../../components/ClinIcon'
import { DateTime } from 'luxon'
import { ClinTextInput } from '../../../components/ClinTextInput'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  StyledProductCell,
  StyledProductHeader
} from '../ProductVariant/ProductVariant.styles'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import {
  BookmarkableItem,
  BookmarkableItemType
} from '../../../types/BookmarkableItem'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinChip } from '../../../components/ClinChip'
import {
  IAllHoldsInformation,
  IHoldDetails
} from '../../../services/Holds/holds.types'
import { DocumentUploadContainer } from '../../../features/DocumentUpload/DocumentUploadContainer'
import { ClinGroup } from '../../../components/ClinGroup'
import { ReportAdverseEventCTA } from '../../../features/ReportAdverseEventCTA'
import {
  IAugmentedCatalogItemDto,
  UIVariationState
} from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import { numberToCurrencyString } from '../../../utils/numberToCurrencyString'
import {
  ProductDocumentUiState,
  RequestSubmissionState
} from './ProductDetailContainer'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import { Trans, useTranslation } from 'react-i18next'
import 'react-tippy/dist/tippy.css'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import { EstimatedDeliveryDate } from '../../../components/ClinEstimatedDeliveryDate/EstimatedDeliveryDate'
import { EstimatedDeliveryDateAus } from '../../../components/ClinEstimatedDeliveryDate/EstimatedDeliveryDateAus'
import { ProductSourceSystem } from '../Product.models'
import {
  shouldDisplaAvailability,
  shouldDisplayEstimatedDeliveryDateProductPage
} from '../../../features/VariantCard/VariantCard.model'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { isAusGaUser, isAusMaUser, UserRole } from '../../../constants'
import { ProductDetailContact } from './ProductDetailContact'

// import { PortfolioJourneyDebug } from '../../../services/PortfolioJourneys/PortfolioJourneyDebug'

interface IProductDetailProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Whether we are loading the unit price info or not */
  isLoadingPrice: boolean
  /** Whether we are submitting a register interest request */
  registerInterestSubmissionState: RequestSubmissionState
  /** Adding an item to basket */
  isAddingToBasket: boolean
  /** Allows the view to determine whether to display a sku */
  isSkuOnly: boolean
  /** Selected product */
  product?: CatalogDto // TODO: Refactor to catalog in order to match data name rather than UI name
  /** Selected catalogueItem */
  catalogueItem?: IAugmentedCatalogItemDto
  /** Minimum amount that can be purchased */
  minQuantity?: number | null
  /** Maximum amount that can be purchased */
  maxQuantity?: number | null
  /** Incremental size */
  stepSize?: number | null
  /** Errors with min max quantity */
  quantityError?: string | null
  /** Currency code for this product */
  currencyCode: string | undefined
  /** Total cost */
  totalCost?: number
  /** Unit price */
  unitPrice: number
  /** All the holds information for this product */
  holdsInformation?: IAllHoldsInformation
  /** Link to sourcing request form with URL params */
  sourcingRequestURL?: string
  /** Distributor information */
  distributor?: DistributorDto
  /** Multiple currencies are in the basket or basket contains different currency to product */
  hasCurrencyError?: boolean
  /** Documents to be displayed to the user **/
  productDocuments?: ProductDocumentUiState[]
  /** Whether this is a TSE Expired or Restricted product (for debugging) */
  isRestricted?: boolean
  /** Handle back button click */
  handleGoBack?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Handle document download */
  handleDownload: (documentItem: ItemDocumentDto) => void
  /** Handle changing quantity */
  handleChangeQuantity?: (quantity: number) => void
  /** Handle add to basket */
  handleAddToBasket?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Request to print order **/
  handlePrint?: () => void
  /** Render prop for showing bookmarks */
  renderBookmarkToggle?: FunctionComponent<BookmarkableItem>
  /** Handle document association with sku and hold for uploads */
  handleFileUploadForSku?: (
    sku: string,
    holdDetails: IHoldDetails,
    document: UploadedDocumentDto
  ) => void
  /** Register interest for GA **/
  handleRegisterInterestForProduct?: () => void
  /** Request skinny sourcing **/
  handleSkinnySourcingRequest?: () => void
  /** Request skinny sourcing flag **/
  skinnySourcingButtonDisabled?: boolean
  /** User country*/
  userCountry: string
  /** User role*/
  userRole?: UserRole
}

export const ProductDetail: FunctionComponent<IProductDetailProps> = ({
  isLoading,
  isLoadingPrice,
  registerInterestSubmissionState,
  isAddingToBasket,
  product,
  isSkuOnly,
  catalogueItem,
  minQuantity = 1,
  maxQuantity = 10,
  stepSize = 1,
  quantityError,
  totalCost = 0,
  currencyCode,
  unitPrice,
  holdsInformation,
  sourcingRequestURL,
  distributor,
  hasCurrencyError,
  productDocuments,
  isRestricted,
  userCountry,
  userRole,
  handleGoBack,
  handleDownload,
  handleChangeQuantity,
  handleAddToBasket,
  handlePrint,
  renderBookmarkToggle,
  handleFileUploadForSku,
  handleRegisterInterestForProduct,
  handleSkinnySourcingRequest,
  skinnySourcingButtonDisabled = false
}) => {
  const { t } = useTranslation()

  const getCrumbs = () => {
    let crumbsArray = !isSkuOnly
      ? [
          isAusGaUser(userCountry, userRole)
            ? {
                name: t('navigation:home'),
                path: '/home'
              }
            : { path: '/orders', name: t('orders:page_name') },
          {
            name: t('product_catalogue:non_ma_title'),
            path: '/products/catalogue'
          },
          {
            name: product ? product.catalogItemName : '',
            path: `/product/${product && product.catalogDocumentId}`
          },
          {
            name: catalogueItem ? catalogueItem.sku : '',
            path: `/product/${product && product.catalogDocumentId}/variant/${
              catalogueItem?.sku
            }`
          }
        ]
      : [
          isAusGaUser(userCountry, userRole)
            ? {
                name: t('navigation:home'),
                path: '/home'
              }
            : { path: '/orders', name: t('orders:page_name') },
          {
            name: t('product_catalogue:non_ma_title'),
            path: '/products/catalogue'
          },
          {
            name: product ? product.catalogItemName : '',
            path: `/product/${product && product.catalogDocumentId}`
          }
        ]

    return crumbsArray
  }

  return isLoading || !product ? (
    <ClinPageContentFrame>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </ClinPageContentFrame>
  ) : (
    <ClinPageContentFrame crumbs={getCrumbs()}>
      <StyledProductDetail>
        <Row>
          <Col sm={10}>
            <ClinText
              as="h1"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {product && product.catalogItemName}
            </ClinText>
          </Col>
        </Row>
        {/* Display currently unavailable */}
        {catalogueItem &&
        catalogueItem?.uiVariantState ===
          UIVariationState.GloballyUnavailable ? (
          <>
            <Row>
              <Col>
                <ClinButton
                  style={{ marginTop: ClinTheme.space[3] }}
                  variant="link"
                  onClick={(event) => handleGoBack && handleGoBack(event)}
                >
                  <ClinIcon
                    style={{ marginRight: ClinTheme.space[2] }}
                    iconSize={ClinTheme.fontSizes[3]}
                    iconName={ClinIconPathName.ChevronLeft}
                  />
                  {t('common:buttons.return_to_previous_page')}
                </ClinButton>
              </Col>
            </Row>
            <Row align="center" justify="center">
              <Col xs={12} md={12} lg={6}>
                <ClinGroup
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  space={ClinTheme.space[2]}
                >
                  <ClinIcon
                    iconName={ClinIconPathName.OrderBox}
                    iconFill={ClinTheme.colors.grey}
                    iconSize={170}
                  />
                  <ClinText
                    variant={TypographyVariant.H4}
                    fontWeight={ClinTheme.fontWeights.bold}
                    marginTop={ClinTheme.space[5]}
                  >
                    {catalogueItem.item.division === 'MA'
                      ? t('product_detail:please_visit_cliniport')
                      : t('product_detail:product_unavailable_online')}
                  </ClinText>
                  <ClinText
                    textAlign={'center'}
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {catalogueItem.item.division === 'MA'
                      ? `${t('product_detail:managed_access_program')} \n` +
                        '\n' +
                        `${t('product_detail:view_or_order_visit_clinigen')}`
                      : `${t(
                          'product_detail:unavailable_online_customer_service'
                        )}`}
                  </ClinText>
                  {catalogueItem.item.division === 'MA' && (
                    <a href="https://www.clinigengroup.com">
                      <ClinButton variant={'primary'}>
                        {t('product_detail:view_cliniport')}
                      </ClinButton>
                    </a>
                  )}
                </ClinGroup>
                <ClinSpacer />
              </Col>
              <Col xs={12} lg={4} push={{ lg: 1 }}>
                <ReportAdverseEventCTA product={product} />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <StyledProductHeader>
              <StyledProductCell>
                <Hidden xs={true} sm={true}>
                  <ClinIcon
                    iconName={ClinIconPathName.TherapeuticArea}
                    iconSize={ClinTheme.space[4]}
                  />
                </Hidden>
                <ClinText
                  as="div"
                  fontWeight={ClinTheme.fontWeights.medium}
                  marginLeft={ClinTheme.space[2]}
                  marginRight={ClinTheme.space[3]}
                >
                  {t('glossary:therapeutic_area')}
                </ClinText>
                {product.tags.map((tag, index) => (
                  <ClinChip key={`ta-${tag}-${index}`}>{tag}</ClinChip>
                ))}
              </StyledProductCell>
              <StyledProductCell justifyContent={'space-between'}>
                {renderBookmarkToggle &&
                  catalogueItem &&
                  renderBookmarkToggle({
                    itemType: BookmarkableItemType.SKU,
                    itemId: catalogueItem.sku
                  })}
                <ClinButton
                  variant="ghost"
                  onClick={() => handlePrint && handlePrint()}
                >
                  <span style={{ marginRight: ClinTheme.space[2] }}>
                    {t('common:buttons.print')}
                  </span>{' '}
                  <ClinIcon iconName={ClinIconPathName.Print} />
                </ClinButton>
              </StyledProductCell>
            </StyledProductHeader>

            {!isSkuOnly && (
              <Row>
                <Col>
                  <ClinButton variant="link" onClick={handleGoBack}>
                    <ClinIcon iconName={ClinIconPathName.ArrowLeft} />
                    &nbsp;{t('product_detail:return_to_product_variant')}
                  </ClinButton>
                  <ClinSpacer />
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <Row justify="between">
                  <Col sm={12} lg={7}>
                    <StyledProductAccordion>
                      <ClinAccordion activeItemIndices={[0, 1, 2, 3]}>
                        <ClinAccordionItem
                          title={t('product_detail:variant_details')}
                        >
                          <IconValueCell
                            iconName={ClinIconPathName.StrengthAndDosage}
                            title={t('glossary:strength')}
                            maxTitleWidth={'50%'}
                          >
                            {catalogueItem &&
                              catalogueItem.products.map((prod, index) => (
                                <div key={`${prod.genericName}-${index}`}>
                                  <ClinText
                                    as="div"
                                    variant={TypographyVariant.LargeParagraph}
                                    marginLeft={ClinTheme.space[4]}
                                  >
                                    {prod.genericName}
                                    <br />
                                    {prod.strength}
                                  </ClinText>
                                </div>
                              ))}
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.PackSize}
                            title={t('glossary:dosage_and_packsize')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem && catalogueItem.item.formulation}
                              <br />
                              {catalogueItem && catalogueItem.item.packSize}
                            </ClinText>
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.BrandName}
                            title={t('glossary:brand')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="p"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem && catalogueItem.item.brand}
                            </ClinText>
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.Manufacturer}
                            title={t('glossary:manufacturer')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="p"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem && catalogueItem.item.manufacturer}
                            </ClinText>
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.Marker}
                            title={t('glossary:country_of_license')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="p"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem &&
                                catalogueItem.licenses
                                  .map((license) => license.country)
                                  .join(', ')}
                            </ClinText>
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.Overlabelled}
                            title={t('glossary:overlabelled')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="p"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem &&
                              catalogueItem.item.overlabelled === 'Y'
                                ? `${catalogueItem.item.overlabelLanguage} (${catalogueItem.item.overlabelType})`
                                : t('glossary:no')}
                            </ClinText>
                          </IconValueCell>
                        </ClinAccordionItem>
                        <ClinAccordionItem
                          title={t('glossary:handling_and_storage')}
                        >
                          <IconValueCell
                            iconName={ClinIconPathName.ExpiryDate}
                            title={t('glossary:expiry_date')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {t('product_detail:greater_than_six_months')}
                            </ClinText>
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.Storage}
                            title={t('glossary:storage')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem &&
                                catalogueItem.item.storageTemperature}
                              &#176;C
                            </ClinText>
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.ExclamationOutline}
                            title={t('glossary:controlled_drug_status')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem &&
                                catalogueItem.item.controlledDrug}
                            </ClinText>
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.Cytotoxicity}
                            title={t('glossary:cytotoxicity')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem &&
                              catalogueItem.item.cytotoxic === 'Y'
                                ? t('glossary:yes')
                                : t('glossary:no')}
                            </ClinText>
                          </IconValueCell>
                        </ClinAccordionItem>
                        <ClinAccordionItem title={t('glossary:licensing')}>
                          <IconValueCell
                            iconName={ClinIconPathName.LicenseNumber}
                            title={t('glossary:license_number')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem &&
                                catalogueItem.licenses.map(
                                  (license) => license.licenseNumber
                                )}
                            </ClinText>
                          </IconValueCell>
                          <IconValueCell
                            iconName={ClinIconPathName.MaHolder}
                            title={t('glossary:license_holder')}
                            maxTitleWidth={'50%'}
                          >
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph}
                              marginLeft={ClinTheme.space[4]}
                            >
                              {catalogueItem && catalogueItem.item.maHolder}
                            </ClinText>
                          </IconValueCell>
                        </ClinAccordionItem>
                        <ClinAccordionItem
                          title={t('glossary:documentation')}
                          isHidden={
                            catalogueItem &&
                            catalogueItem.documents.filter(
                              (item: ItemDocumentDto) =>
                                item.externallyVisible === 'Y'
                            ).length === 0
                          }
                        >
                          {/* Document Cards */}
                          {productDocuments &&
                            productDocuments
                              .filter(
                                (item: ProductDocumentUiState) =>
                                  item.document.externallyVisible === 'Y'
                              )
                              .map((documentItem, index) => (
                                <StyledDocumentCard
                                  key={`${documentItem.document.documentUrl}-${index}`}
                                >
                                  <ClinText
                                    as="div"
                                    variant={TypographyVariant.LargeParagraph}
                                    color={ClinTheme.colors.primary}
                                    marginTop="0"
                                    marginBottom={ClinTheme.space[3]}
                                  >
                                    {documentItem.document.documentType}
                                  </ClinText>
                                  <StyledDocumentCardInner>
                                    <StyledDocumentationIcon>
                                      <ClinIcon
                                        iconName={ClinIconPathName.Document}
                                        iconSize={ClinTheme.space[9]}
                                        iconFill={ClinTheme.colors.magenta}
                                      />
                                    </StyledDocumentationIcon>
                                    <StyleDocumentationDetails>
                                      <StyleDocumentationDetail>
                                        {documentItem.document
                                          .documentLanguage && (
                                          <div>
                                            <ClinText
                                              as="div"
                                              color={ClinTheme.colors.primary}
                                              fontWeight={
                                                ClinTheme.fontWeights.medium
                                              }
                                            >
                                              {t('glossary:language')}
                                            </ClinText>
                                            <ClinText
                                              as="div"
                                              variant={
                                                TypographyVariant.LargeParagraph
                                              }
                                            >
                                              {
                                                documentItem.document
                                                  .documentLanguage
                                              }
                                            </ClinText>
                                          </div>
                                        )}
                                        <div>
                                          <ClinText
                                            as="div"
                                            color={ClinTheme.colors.primary}
                                            fontWeight={
                                              ClinTheme.fontWeights.medium
                                            }
                                          >
                                            {t('glossary:last_uploaded')}
                                          </ClinText>
                                          <ClinText
                                            as="div"
                                            variant={
                                              TypographyVariant.LargeParagraph
                                            }
                                          >
                                            {documentItem.document.revisionDate
                                              ? DateTime.fromISO(
                                                  documentItem.document
                                                    .revisionDate
                                                ).toLocaleString()
                                              : t('glossary:not_applicable')}
                                          </ClinText>
                                        </div>
                                      </StyleDocumentationDetail>
                                      <StyleDocumentationDetailButton>
                                        <ClinButton
                                          variant="ghost"
                                          onClick={() =>
                                            documentItem.document &&
                                            handleDownload(
                                              documentItem.document
                                            )
                                          }
                                          disabled={documentItem.isDownloading}
                                        >
                                          {documentItem.isDownloading
                                            ? `${t('common:downloading')} `
                                            : `${t(
                                                'common:buttons.download'
                                              )} `}
                                          &nbsp;
                                          {documentItem.isDownloading ? (
                                            <ClinSpinner
                                              size={ClinTheme.space[3]}
                                            />
                                          ) : (
                                            <ClinIcon
                                              iconName={
                                                ClinIconPathName.Download
                                              }
                                            />
                                          )}
                                        </ClinButton>
                                      </StyleDocumentationDetailButton>
                                    </StyleDocumentationDetails>
                                  </StyledDocumentCardInner>
                                </StyledDocumentCard>
                              ))}
                        </ClinAccordionItem>
                      </ClinAccordion>
                    </StyledProductAccordion>
                  </Col>
                  {/* SKU Selector */}
                  {isLoadingPrice ? (
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                      <ClinSpinner />
                    </Col>
                  ) : (
                    <Col sm={12} lg={4} style={{ padding: 0 }}>
                      <StyledSKUQuantitySelector
                        className={'sku-quantity-selector'}
                      >
                        <ClinText
                          as="div"
                          variant={TypographyVariant.H4}
                          marginTop={'0px'}
                        >
                          {product.catalogItemName}
                        </ClinText>
                        <Row justify="between">
                          <Col width="auto">
                            <ClinText
                              as="div"
                              variant={TypographyVariant.H5}
                              fontWeight={ClinTheme.fontWeights.medium}
                              marginBottom={ClinTheme.space[3]}
                            >
                              {t('glossary:product_code')}:
                            </ClinText>
                            <pre
                              className={'debug'}
                              style={{ display: 'none' }}
                            >
                              {isRestricted
                                ? 'TSE Expired, Restricted status or Supply Problem'
                                : ''}
                              <br />
                              Portfolio: {catalogueItem?.uiVariantState}{' '}
                              {catalogueItem?.endpoint}
                            </pre>
                          </Col>
                          <Col width="auto">
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph}
                              marginBottom={ClinTheme.space[3]}
                            >
                              {catalogueItem && catalogueItem.sku}
                            </ClinText>
                          </Col>
                        </Row>
                        {/* we don't want to display availability for aus user where product division is sp - CLOS-2750 */}

                        {catalogueItem &&
                          shouldDisplaAvailability(
                            catalogueItem!,
                            userCountry
                          ) && (
                            <ClinText
                              as="div"
                              variant={TypographyVariant.LargeParagraph} //have changed font size and font weight for availability according to changes in design on 03.08.23
                              fontWeight={ClinTheme.fontWeights.medium}
                              color={
                                catalogueItem && catalogueItem.availableColour
                              }
                              marginBottom={ClinTheme.space[3]}
                            >
                              {catalogueItem && catalogueItem.availableText}
                            </ClinText>
                          )}

                        {/*<PortfolioJourneyDebug*/}
                        {/*  showDebug={false}*/}
                        {/*  catalogueItem={catalogueItem}*/}
                        {/*  showSKU={false}*/}
                        {/*/>*/}
                        {/* Register Interest (portfolio journeys 10, 11, 12) */}
                        {catalogueItem &&
                          sourcingRequestURL &&
                          catalogueItem.canRegisterInterest && (
                            <>
                              <ClinText
                                as="div"
                                variant={TypographyVariant.Paragraph}
                                fontWeight={ClinTheme.fontWeights.medium}
                                marginBottom={ClinTheme.space[3]}
                              >
                                {t(
                                  'product_detail:not_available_for_sale_in_your_country'
                                )}
                              </ClinText>
                              <ClinText
                                as="div"
                                variant={TypographyVariant.Paragraph}
                                marginBottom={ClinTheme.space[3]}
                              >
                                {t(
                                  'product_detail:please_register_your_interest'
                                )}
                              </ClinText>
                              <ClinButton
                                variant="primary"
                                display="block"
                                onClick={handleRegisterInterestForProduct}
                                disabled={
                                  registerInterestSubmissionState ===
                                    RequestSubmissionState.Submitted ||
                                  registerInterestSubmissionState ===
                                    RequestSubmissionState.Submitting
                                }
                              >
                                {registerInterestSubmissionState ===
                                RequestSubmissionState.NotSubmitted ? (
                                  t('product_detail:register_interest')
                                ) : (
                                  <>
                                    {registerInterestSubmissionState ===
                                    RequestSubmissionState.Submitting ? (
                                      <ClinGroup
                                        alignItems="center"
                                        justifyContent="space-between"
                                      >
                                        {registerInterestSubmissionState ===
                                        RequestSubmissionState.Submitting
                                          ? t('product_detail:registering')
                                          : t('product_detail:registered')}
                                        {registerInterestSubmissionState ===
                                          RequestSubmissionState.Submitting && (
                                          <div
                                            style={{
                                              marginLeft: 'auto',
                                              marginTop: ClinTheme.space[1]
                                            }}
                                          >
                                            <ClinSpinner
                                              size={ClinTheme.space[3]}
                                            />
                                          </div>
                                        )}
                                      </ClinGroup>
                                    ) : (
                                      t('product_detail:registered')
                                    )}
                                  </>
                                )}
                              </ClinButton>
                            </>
                          )}

                        {/* Request Product (portfolio journeys 7, 14, 15, 6) */}
                        {catalogueItem &&
                          sourcingRequestURL &&
                          catalogueItem.canAccessSourcingRequestForm && (
                            <>
                              {catalogueItem.uiVariantState !==
                                UIVariationState.AvailableOnRequestOnRequest && (
                                <>
                                  <ClinText
                                    as="div"
                                    variant={TypographyVariant.Paragraph}
                                    fontWeight={ClinTheme.fontWeights.medium}
                                    marginBottom={ClinTheme.space[3]}
                                  >
                                    {t('product_detail:not_available_for_sale')}
                                  </ClinText>
                                  <ClinText
                                    as="div"
                                    variant={TypographyVariant.Paragraph}
                                    marginBottom={ClinTheme.space[3]}
                                  >
                                    {t(
                                      'product_detail:please_register_your_interest'
                                    )}
                                  </ClinText>
                                </>
                              )}
                              <ClinSpacer hasBorder />
                              <ClinTextInput
                                name="quantity"
                                id="quantity"
                                label={t('common:labels.select_quantity')}
                                type="number"
                                autoFocus={!hasCurrencyError}
                                disabled={skinnySourcingButtonDisabled}
                                hasError={!!quantityError}
                                prompt={quantityError ? quantityError : ''}
                                step={stepSize || undefined}
                                min={minQuantity || undefined}
                                max={maxQuantity || 10000}
                                width={'100%'}
                                onChange={(event) =>
                                  handleChangeQuantity &&
                                  handleChangeQuantity(
                                    parseInt(event.currentTarget.value)
                                  )
                                }
                              />
                              <ClinSpacer />
                              <ClinButton
                                variant="primary"
                                display="block"
                                disabled={
                                  skinnySourcingButtonDisabled ||
                                  !!quantityError
                                }
                                onClick={handleSkinnySourcingRequest}
                              >
                                {skinnySourcingButtonDisabled
                                  ? t('product_detail:requested_product')
                                  : t('product_detail:request_product')}
                              </ClinButton>
                            </>
                          )}

                        {catalogueItem &&
                          catalogueItem.showDistributorInfo &&
                          distributor && (
                            <>
                              <ClinText variant={TypographyVariant.Paragraph}>
                                <Trans
                                  i18nKey="product_detail:medicine_supplied_through_affiliate"
                                  components={{
                                    bold: <strong />
                                  }}
                                />
                              </ClinText>
                              <ClinSpacer hasBorder={true} />
                              {distributor.distributorName && (
                                <ClinText variant={TypographyVariant.Paragraph}>
                                  <strong>{distributor.distributorName}</strong>
                                </ClinText>
                              )}
                              {distributor.phoneNumber && (
                                <ClinText
                                  as="div"
                                  variant={TypographyVariant.Paragraph}
                                  marginBottom={ClinTheme.space[2]}
                                >
                                  {distributor.phoneNumber
                                    .split(',')
                                    .map((ph, i) => (
                                      <div key={`phone-${i}`}>{ph}</div>
                                    ))}
                                </ClinText>
                              )}
                              {distributor.emailAddress && (
                                <ClinText
                                  as="div"
                                  wordBreak="break-word"
                                  variant={TypographyVariant.Paragraph}
                                  marginBottom={ClinTheme.space[2]}
                                >
                                  {distributor.emailAddress
                                    .split(',')
                                    .map((ph, i) => (
                                      <div key={`email-${i}`}>{ph}</div>
                                    ))}
                                </ClinText>
                              )}
                              {distributor.website && (
                                <ClinText
                                  variant={TypographyVariant.Paragraph}
                                  wordBreak="break-word"
                                >
                                  {distributor.website}
                                </ClinText>
                              )}
                              {distributor.address && (
                                <ClinText
                                  as="div"
                                  variant={TypographyVariant.Paragraph}
                                >
                                  {distributor.address
                                    .split(',')
                                    .map((ph, i) => (
                                      <div key={`address-${i}`}>{ph}</div>
                                    ))}
                                </ClinText>
                              )}
                              {/*<pre>{JSON.stringify(distributorInfo, null, 2)}</pre>*/}
                            </>
                          )}

                        {/* SKU selector pricing and quantity selector panel */}
                        {catalogueItem && !catalogueItem.cannotOrder && (
                          <div>
                            <ClinSpacer hasBorder={true} />
                            <div className={'price-row'}>
                              <ClinIcon
                                iconName={ClinIconPathName.UnitPrice}
                                iconSize={ClinTheme.space[4]}
                                iconFill={ClinTheme.colors.primary}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%'
                                }}
                              >
                                <ClinText
                                  className={'price-title'}
                                  as={'span'}
                                  fontWeight={ClinTheme.fontWeights.medium}
                                  marginLeft={ClinTheme.space[3]}
                                  color={ClinTheme.colors.primary}
                                >
                                  {t('product_detail:pack_price')}
                                </ClinText>
                                <ClinText as="span" className={'unit-price'}>
                                  {unitPrice &&
                                    currencyCode &&
                                    numberToCurrencyString(
                                      unitPrice,
                                      getBrowserLocale(),
                                      {
                                        style: 'currency',
                                        currency: currencyCode,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }
                                    )}
                                </ClinText>
                              </div>
                            </div>
                            <ClinSpacer hasBorder={true} />
                            {!isAusMaUser(userCountry, userRole) ? ( //related to clos-1614 we don't want to show this part for aus ma users
                              <div>
                                {/* Added condition for stock levels greater than 0 for displaying edd (clos-1193), modified by (clos-1515) */}
                                {userCountry !== CountryAlphaCodes.Australia &&
                                shouldDisplayEstimatedDeliveryDateProductPage(
                                  catalogueItem.expectedDate,
                                  catalogueItem,
                                  catalogueItem.cutOffTime
                                ) ? (
                                  <>
                                    <EstimatedDeliveryDate
                                      expectedDeliveryDate={
                                        catalogueItem.expectedDate
                                      }
                                      cutOffTime={catalogueItem.cutOffTime}
                                    />
                                    <ClinSpacer hasBorder={true} />
                                  </>
                                ) : null}
                                {/* we have different design for austraia users estimated delivery date */}
                                {userCountry === CountryAlphaCodes.Australia &&
                                  catalogueItem.item.sourceSystem ===
                                    ProductSourceSystem.Sagex3 && (
                                    <>
                                      <EstimatedDeliveryDateAus
                                        dateWidth="50%"
                                        isLimitedAvailable={
                                          catalogueItem.item.supplyProblem ===
                                          'Y'
                                        }
                                        padding={`${ClinTheme.space[3]}px 0px`}
                                        stockLevel={
                                          catalogueItem.reservableStock
                                            ? catalogueItem.reservableStock
                                            : 0
                                        }
                                      ></EstimatedDeliveryDateAus>
                                      <ClinSpacer hasBorder={true} />
                                    </>
                                  )}
                                <ClinSpacer />

                                <ClinTextInput
                                  name="quantity"
                                  id="quantity"
                                  label={t('common:labels.select_quantity')}
                                  type="number"
                                  autoFocus={!hasCurrencyError}
                                  hasError={!!quantityError}
                                  prompt={quantityError ? quantityError : ''}
                                  step={stepSize || undefined}
                                  min={minQuantity || undefined}
                                  max={maxQuantity || 10000}
                                  width={'100%'}
                                  onChange={(event) =>
                                    handleChangeQuantity &&
                                    handleChangeQuantity(
                                      parseInt(event.currentTarget.value)
                                    )
                                  }
                                />

                                <div className={'price-row'}>
                                  <ClinText
                                    as="div"
                                    variant={TypographyVariant.Paragraph}
                                    fontWeight={ClinTheme.fontWeights.medium}
                                    color={ClinTheme.colors.primary}
                                    marginTop={ClinTheme.space[3]}
                                    marginBottom={ClinTheme.space[3]}
                                  >
                                    {t('product_detail:subtotal')}
                                  </ClinText>
                                  <ClinText
                                    as="span"
                                    className={'total-cost'}
                                    variant={TypographyVariant.H4}
                                    fontWeight={ClinTheme.fontWeights.normal}
                                    color={ClinTheme.colors.primary}
                                  >
                                    {currencyCode &&
                                      numberToCurrencyString(
                                        totalCost,
                                        getBrowserLocale(),
                                        {
                                          style: 'currency',
                                          currency: currencyCode,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        }
                                      )}
                                  </ClinText>
                                </div>

                                {/* Display holds */}
                                {userCountry !== CountryAlphaCodes.Australia &&
                                  holdsInformation &&
                                  holdsInformation.requiresDocuments && (
                                    <>
                                      <ClinAnnounceBar
                                        mode={AnnounceMode.Information}
                                        title={t(
                                          'product_detail:documentation_required_before_shipping'
                                        )}
                                        marginBottom={ClinTheme.space[3]}
                                      />
                                      <ClinText
                                        as={'div'}
                                        color={ClinTheme.colors.primary}
                                        fontSize={ClinTheme.fontSizes[3]}
                                      >
                                        {t(
                                          'product_detail:required_supporting_documents'
                                        )}
                                      </ClinText>
                                      <ClinText
                                        as={'p'}
                                        variant={
                                          TypographyVariant.LargeParagraph
                                        }
                                      >
                                        {t(
                                          'product_detail:accepted_file_types'
                                        )}
                                      </ClinText>
                                      <ClinSpacer />
                                    </>
                                  )}
                                {/* For holds with required documents show announce and upload */}
                                {/*<pre>{JSON.stringify(holdsInformation, null, 2)}</pre>*/}
                                {userCountry !== CountryAlphaCodes.Australia &&
                                  holdsInformation &&
                                  holdsInformation.holdsDetails
                                    .filter((h) => h.showPostOrder !== true)
                                    .map((hold, index) => {
                                      // Either show hold with document upload or text
                                      return hold.requiresDocumentUpload &&
                                        hold.requiredDocumentName ? (
                                        <StyledHoldItem
                                          key={`doc-upload-${index}`}
                                        >
                                          <DocumentUploadContainer
                                            isPreOrderVariant={true}
                                            documentPrompt={hold.documentPrompt}
                                            warehouseAddress={
                                              hold.wareHouseAddress
                                            }
                                            prompt={hold.requiredDocumentName}
                                            handleFileUploaded={(document) => {
                                              analyticsServiceSingleton.trackEvent(
                                                AnalyticsEvent.DocumentUpload,
                                                {
                                                  sku: catalogueItem.sku,
                                                  documentType:
                                                    hold.requiredDocumentName
                                                }
                                              )
                                              handleFileUploadForSku &&
                                                hold.holdDto?.holdName &&
                                                catalogueItem?.sku &&
                                                handleFileUploadForSku(
                                                  catalogueItem.sku,
                                                  hold,
                                                  document
                                                )
                                            }}
                                          />
                                        </StyledHoldItem>
                                      ) : (
                                        hold.documentPrompt && (
                                          <StyledHoldItem
                                            key={`doc-upload-prompt-${index}`}
                                          >
                                            <ClinSpacer />
                                            <ClinText
                                              as={'div'}
                                              variant={TypographyVariant.H5}
                                            >
                                              {hold.requiredDocumentName}
                                            </ClinText>
                                            <ClinText
                                              as={'p'}
                                              variant={
                                                TypographyVariant.LargeParagraph
                                              }
                                            >
                                              {hold.documentPrompt}
                                            </ClinText>
                                            <ClinSpacer />
                                          </StyledHoldItem>
                                        )
                                      )
                                    })}
                                <ClinSpacer />
                                <ClinButton
                                  variant={'primary'}
                                  display={'block'}
                                  disabled={
                                    isLoadingPrice ||
                                    !!quantityError ||
                                    isAddingToBasket ||
                                    hasCurrencyError
                                  }
                                  onClick={handleAddToBasket}
                                >
                                  {t('product_detail:add_to_basket')}
                                </ClinButton>
                              </div>
                            ) : (
                              <ProductDetailContact
                                itemSku={catalogueItem.sku}
                              />
                            )}
                          </div>
                        )}
                      </StyledSKUQuantitySelector>
                      <ClinSpacer />
                      <ReportAdverseEventCTA product={product} />
                    </Col>
                  )}
                </Row>
                {/*<pre>{JSON.stringify(catalogueItem, null, 2)}</pre>*/}
              </Col>
            </Row>
          </>
        )}
      </StyledProductDetail>
    </ClinPageContentFrame>
  )
}
