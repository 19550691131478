import {
  UserRole,
  UserRoleWithCountry,
  getUserRoleWithCountry
} from '../constants'

export const redirectUser = (
  portfolioCountryCode: string,
  userRole: UserRole | undefined,
  previousPathname?: string
) => {
  let locationHref = ''
  const userRoleWithCountry = getUserRoleWithCountry(
    portfolioCountryCode,
    userRole
  )
  switch (userRoleWithCountry) {
    case UserRoleWithCountry.AusBase:
    case UserRoleWithCountry.AusSuper:
      locationHref = '/home'
      break
    default:
      locationHref = '/orders'
  }
  if (previousPathname) {
    locationHref = previousPathname
  }
  window.location.href = locationHref
}
