import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ClinCard } from '../../components/ClinCard'

export const StyleRecentSkuCards = styled.div({
  '.swiper-container': {
    paddingBottom: ClinTheme.space[8],
    alignItems: 'stretch'
  },

  '.swiper-slide': {
    display: 'flex',
    height: 'auto'
  },

  '.swiper-pagination': {
    bottom: 0
  },

  '.swiper-pagination-bullet': {
    width: ClinTheme.space[3],
    height: ClinTheme.space[3],
    background: ClinTheme.colors.white,
    border: `${ClinTheme.borderWidths[2]}px solid ${ClinTheme.colors.primaryLight}`,
    opacity: 1,
    boxSizing: 'border-box',
    '&.swiper-pagination-bullet-active': {
      background: ClinTheme.colors.primaryLight
    }
  }
})

export const StyleRecentSkuCard = styled(ClinCard)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column'
})

export const StyleRecentSkuCardDetail = styled.div({
  paddingBottom: ClinTheme.space[3],
  marginBottom: 'auto',
  flex: '1 0 auto'
})

export const StyledDetailRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: ClinTheme.space[3]
})

export const StyledDetailDescription = styled.div({
  marginLeft: ClinTheme.space[2]
})
