import React from 'react'

import { Homepage } from './Homepage'
import useChangeBackgroundColor from '../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { ClinTheme } from '../../ClinTheme'

export const HomepageContainer: React.FC = () => {
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)
  return (
    <>
      <Homepage />
    </>
  )
}
