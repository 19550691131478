import React, { FunctionComponent, useEffect, useState } from 'react'
import { AccountDetails } from './AccountDetails'
import { useHistory } from 'react-router'
import { useAppContext } from '../../../context/app'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { ICrumb } from '../../../components/ClinBreadcrumb'
import { createShowInstituteModalEvent } from '../../../events/InstituteModalEvent'
import { UpdateContactDto, UserDto } from '../../../types/swaggerTypes'
import { AxiosError } from 'axios'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  clearAnnounceEvent,
  createAnnounceEvent
} from '../../../events/AnnounceEvent'
import { AuthError, updateContact } from '../../../services/ApiService'
import { ClinTheme } from '../../../ClinTheme'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { useTranslation } from 'react-i18next'
import { useLov } from '../../../utils/useLov'
import { LovName, isAusGaUser } from '../../../constants'
import { createGetUserAuthEvent } from '../../../events/AuthEvent'

export enum EditableFields {
  firstName = 'First Name',
  lastName = 'Last Name',
  preferredName = 'Preferred Name',
  password = 'Password',
  classification = 'Job Type and License number',
  specialism = 'Specialism',
  licenseNumber = 'License Number',
  phoneNumber = 'Phone Numbers'
}

/**
 * Convert enum to translation key
 * @param value
 */
export const getLocalisationKeyForValue = (value: EditableFields): string => {
  switch (value) {
    case EditableFields.classification:
      return 'common:labels.classification'
    case EditableFields.firstName:
      return 'common:labels.classification'
    case EditableFields.lastName:
      return 'common:labels.full_name'
    case EditableFields.licenseNumber:
      return 'common:labels.full_name'
    case EditableFields.password:
      return 'common:labels.full_name'
    case EditableFields.phoneNumber:
      return 'common:labels.phone_number_one'
    case EditableFields.preferredName:
      return 'common:labels.preferred_name'
    case EditableFields.specialism:
      return 'common:labels.specialism'
    default:
      return 'common:missing'
  }
}

export enum MarketingMode {
  Editable = 'Editable',
  Submitting = 'Submitting',
  Submitted = 'Submitted',
  Error = 'Error'
}

export const AccountDetailsContainer: FunctionComponent = () => {
  const { dispatch, institute, userDetails, portfolioCountryCode, userRole } =
    useAppContext()
  const history = useHistory()
  const [currentDetails, setCurrentDetails] = useState<UserDto | undefined>(
    userDetails
  )
  const [activeField, setActiveField] = useState<EditableFields | undefined>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [marketingMode, setMarketingMode] = useState<MarketingMode>(
    MarketingMode.Editable
  )
  const { isLovLoading: isSpecialismsLoading, lovValues: specialisms } = useLov(
    LovName.SPECIALISM
  )
  const { isLovLoading: isJobTitlesLoading, lovValues: jobTitles } = useLov(
    LovName.JOB_TITLES
  )

  // Set up current language for switcher
  const { t } = useTranslation()

  const crumbs: ICrumb[] = [
    isAusGaUser(portfolioCountryCode, userRole)
      ? { path: '/home', name: t('navigation:home') }
      : { path: '/orders', name: t('orders:page_name') },
    { name: t('account_details:title'), path: '/account' }
  ]

  useEffect(() => {
    setCurrentDetails(userDetails)
  }, [userDetails])

  const handleFormSubmission = (data: UpdateContactDto) => {
    setIsSubmitting(true)
    userDetails &&
      updateContact(userDetails.contactCard.contactId.toString(), data)
        .then((response) => {
          if (response.status === 200) {
            dispatch(createGetUserAuthEvent())
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Success,
                t('account_details:account_details_updated'),
                ' '
              )
            )
            setActiveField(undefined)
            setIsSubmitting(false)
            window.scrollTo(0, 0)
            history.push('/account/details')
          }
        })
        .catch((error: AxiosError) => {
          const { code, message } = error
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          // If we have a full error show it
          if (error.response) {
            const { title, detail } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `${t('account_details:error_updating_details')} ${
                  title ? title : ''
                } ${message ? message : ''}`
              )
            )
            console.warn(title, detail)
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `${t('account_details:error_updating_details')} ${message} ${
                  code ? code : ''
                }`
              )
            )
          }
          setIsSubmitting(false)
          window.scrollTo(0, 0)
        })
  }

  const handleChangeInstitute = () => {
    if (marketingMode === MarketingMode.Submitting) {
      return
    }
    dispatch(createShowInstituteModalEvent())
  }

  const handleEditRequest = (field: EditableFields) => {
    dispatch(clearAnnounceEvent())
    if (marketingMode === MarketingMode.Submitting) {
      return
    }
    if (field === EditableFields.password) {
      analyticsServiceSingleton.trackEvent(AnalyticsEvent.PasswordChange)
      history.push('/account/change-password')
    } else {
      window.scrollTo(0, 0)
      setActiveField(field)
    }
  }

  const handleRequestToGoBack = () => {
    dispatch(clearAnnounceEvent())
    const redirectUrl = isAusGaUser(portfolioCountryCode, userRole)
      ? '/home'
      : '/orders'
    history.push(redirectUrl)
  }

  const handleRequestToExitEditing = () => {
    setMarketingMode(MarketingMode.Editable)
    setActiveField(undefined)
  }

  return (
    <>
      {isSpecialismsLoading ||
      isJobTitlesLoading ||
      !institute.data ||
      !currentDetails ? (
        <StyledSpinnerContainer>
          <ClinSpinner size={ClinTheme.space[7]} />
        </StyledSpinnerContainer>
      ) : (
        <AccountDetails
          isSubmitting={isSubmitting}
          fieldToEdit={activeField}
          institute={institute.data}
          userDetails={currentDetails.contactCard}
          specialisms={specialisms}
          jobTitles={jobTitles}
          crumbs={crumbs}
          backButtonText={
            isAusGaUser(portfolioCountryCode, userRole)
              ? t('common:buttons.back_to_home')
              : t('common:buttons.back')
          }
          handleFormSubmission={handleFormSubmission}
          handleChangeInstitute={handleChangeInstitute}
          handleEditRequest={handleEditRequest}
          handleRequestToExitEditing={handleRequestToExitEditing}
          handleRequestToGoBack={handleRequestToGoBack}
        />
      )}
    </>
  )
}
