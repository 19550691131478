import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import _ from 'lodash'
import {
  AuthError,
  cancelGetProgramFromSearchIndexById,
  cancelGetProgramsSuggestion,
  getProgramFromSearchIndexById,
  getProgramsSuggestion
} from '../../../services/ApiService'
import {
  ProgramCatalogDto,
  ProgramDtoSuggestionDto,
  ProgramSearchDto
} from '../../../types/swaggerTypes'
import { useLocation } from 'react-router-dom'
import { ClinTheme } from '../../../ClinTheme'
import { AxiosError } from 'axios'
import { ClinText } from '../../../components/ClinText'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../../context/app'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import i18n from '../../../i18n/config'
import { IProgramAutoSuggestResultProps } from './ProgramAutoSuggest.mode'
import {
  StyledProgramAutoSuggestNoResultItem,
  StyledProgramAutoSuggestResultItem,
  StyledProgramAutoSuggestWrapper,
  StyledProgramLink
} from './ProgramAustoSuggest.styles'
import {
  StyledInputWrapper,
  StyledLabel
} from '../../../components/ClinTextInput/ClinTextInput.styles'
import { ProgramCountryStatus } from '../../../constants'
export interface ISearchStylesProps {
  /** Show suggestions below search */
  showSuggestions?: boolean
}
export const defaultSearchParams: ProgramSearchDto = {
  query: '',
  filter: {
    therapeuticAreas: [],
    availabilities: []
  },
  pagination: {
    skip: 0,
    take: 10
  },
  sorting: {
    sortBy: 'programName',
    order: SortDirectionType.Ascending
  }
}

interface IProgramAutoSuggestContainerProps {
  /** Amount of debounce time in milliseconds */
  debounceTimeMs?: number
  /** Number of results to display */
  maxResultsToDisplay?: number
  //if we have to populate program search with initial program
  initiallySelectedProgram?: number
  countryCode: string
  label?: string
  id: string
  enrolledPrograms?: string[]
  setIsStockOnly?: (flag: boolean) => void
  setResupplyError?: (flag: boolean) => void
  /** Search component to pass through */
  searchComponent(props: IProgramAutoSuggestResultProps): React.ReactNode
  /** Call back to broadcast onChange */
  handleOnChange?: (query: string) => void
  /** Call back to broadcast onClick */
  handleOnSelect: (
    suggestion: ProgramDtoSuggestionDto,
    flagIsNotInitial: boolean
  ) => void
}

export const ProgramAutoSuggest: FunctionComponent<
  IProgramAutoSuggestContainerProps
> = ({
  id,
  searchComponent,
  debounceTimeMs = 500,
  label,
  countryCode,
  setResupplyError,
  handleOnChange,
  handleOnSelect,
  maxResultsToDisplay = 5,
  initiallySelectedProgram,
  enrolledPrograms,
  setIsStockOnly
}: IProgramAutoSuggestContainerProps) => {
  const { dispatch } = useAppContext()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [query, setQuery] = useState<string | undefined>()
  const [suggestions, setSuggestions] = useState<ProgramDtoSuggestionDto[]>([])
  const [highlightedIndex, setHighlightedIndex] = useState(-1)

  const resetAll = () => {
    setSuggestions([])
    setQuery('')
    setHighlightedIndex(0)
    setIsLoading(false)
    setShowSuggestions(false)
  }

  //if we send only one character and it is special character we are getting 500 error clos-6942
  const checkSpecialCharacter = (val: string) => {
    if (val.length !== 1) {
      return false
    }

    const pattern = /^[a-zA-Z]$/
    if (!pattern.exec(val)) {
      return true
    }

    return false
  }

  // Debounced call to API to request suggestions
  const requestSuggestions = useCallback(
    (queryStr: string) => {
      if (!queryStr || queryStr === '') {
        resetAll()
        return
      }

      //Prevent user to send % and other character since it is throwing 500 error clos-6942
      if (checkSpecialCharacter(queryStr)) {
        return
      }

      setIsLoading(true)
      cancelGetProgramsSuggestion()
      // analyticsServiceSingleton.trackEvent(AnalyticsEvent.SubmitSearchQuery, {
      //   query: queryStr,
      //   searchLocation: 'global',
      //   searchAPI: 'suggest'
      // })
      getProgramsSuggestion({
        query: queryStr,
        limit: maxResultsToDisplay,
        derivedFromFilter: '',
        countryCode: countryCode
      })
        .then((results) => {
          if (results) {
            setIsLoading(false)
            setHighlightedIndex(-1)
            setSuggestions(results.data.result)
            setShowSuggestions(
              results.data.result.length > 0 && queryStr.length > 0
            )
          }
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching program suggestions. ${
                error.message
              } ${error.code ? error.code : ''}`
            )
          )
          setShowSuggestions(false)
          setIsLoading(false)
        })
    },
    [dispatch, maxResultsToDisplay, countryCode]
  )

  // Debounced call to API to request suggestions
  const debounceEventHandler = useMemo(
    () =>
      _.debounce(
        (searchString: string) => requestSuggestions(searchString),
        debounceTimeMs
      ),
    [requestSuggestions, debounceTimeMs]
  )

  // Method to provide the rendering cell for returned suggestions
  const renderSuggestions = (
    items: ProgramDtoSuggestionDto[]
  ): React.ReactNode | React.ReactNode[] => {
    const { t } = i18n
    let results: React.JSX.Element[] = []
    if (items && items.length === 0 && !isLoading) {
      return (
        <StyledProgramAutoSuggestNoResultItem
          tabIndex={0}
          key={'no-results-title'}
          style={{
            color: ClinTheme.colors.black,
            fontSize: ClinTheme.fontSizes[2]
          }}
        >
          {t('enrol_physician_on_to_program:no_matches_found')}
        </StyledProgramAutoSuggestNoResultItem>
      )
    }
    if (items && items.length > 0) {
      results = items.map((suggestion, index) => {
        return (
          <StyledProgramAutoSuggestResultItem
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            key={`${suggestion.document.programName}-${index}`}
            tabIndex={0}
            selected={highlightedIndex === index}
            onClick={() => handleSelect(index)}
          >
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontWeight={ClinTheme.fontWeights.normal}
              lineHeight={ClinTheme.lineHeights.heading[0]}
              marginBottom={'0px'}
              marginTop={'0px'}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: suggestion.text ?? ''
                }}
              ></span>
            </ClinText>
            <StyledProgramLink
              color={
                suggestion.document.isUserEnrolledInProgram
                  ? ClinTheme.colors.greenValid
                  : ClinTheme.colors.darkGrey
              }
              variant={TypographyVariant.Paragraph}
            >
              {!suggestion.document.isUserEnrolledInProgram
                ? t('patient_detail:create_patients.not_enrolled_text')
                : t('patient_detail:create_patients.enrolled_text')}
            </StyledProgramLink>
          </StyledProgramAutoSuggestResultItem>
        )
      })
    }

    return results
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const UP = 'ArrowUp'
    const DOWN = 'ArrowDown'
    const ENTER = 'Enter'
    const ESCAPE = 'Escape'
    const TAB = 'Tab'
    const INITIAL_IDX = 0

    if (e.key === ESCAPE) {
      resetAll()
    }
    if (e.key === DOWN || e.key === TAB) {
      const idx = highlightedIndex
      const nextIdx = idx !== undefined ? idx + 1 : INITIAL_IDX

      if (nextIdx < suggestions.length) {
        setHighlightedIndex(nextIdx)
      } else {
        setHighlightedIndex(INITIAL_IDX)
      }
    }
    if (e.key === UP || (e.shiftKey && e.key === TAB)) {
      const lastIdx = suggestions.length - 1
      const idx = highlightedIndex
      const prevIdx = idx !== undefined ? idx - 1 : lastIdx

      if (prevIdx >= 0) {
        setHighlightedIndex(prevIdx)
      } else {
        setHighlightedIndex(lastIdx)
      }
    }
    if (e.key === ENTER && highlightedIndex >= 0) {
      handleSelect && handleSelect(highlightedIndex)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const queryStr = e.target.value
    setQuery(queryStr)
    debounceEventHandler(queryStr)
    handleOnChange && handleOnChange(queryStr)
  }

  const handleSelect = (selectedIndex: number) => {
    if (suggestions[selectedIndex]) {
      setQuery(`${suggestions[selectedIndex].document.programName ?? ''}`)
      setShowSuggestions(false)
      handleOnSelect(suggestions[selectedIndex]!, true)
    }
  }

  // Detect outside click
  const parentNode = useRef<HTMLDivElement>(null)
  const handleClickedOutside = (event: MouseEvent) => {
    if (parentNode?.current?.contains(event.target as Node)) {
      // inside click
      return
    }
    // outside click - clear results
    setShowSuggestions(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickedOutside)
    return () => document.removeEventListener('click', handleClickedOutside)
  })

  useEffect(() => {
    resetAll()
    return () => cancelGetProgramsSuggestion()
  }, [location.pathname])

  const isUserEnrolledIntoProgram = (programId: number) => {
    return enrolledPrograms?.some((x) => x === programId.toString())
  }

  useEffect(() => {
    if (initiallySelectedProgram && enrolledPrograms?.length) {
      cancelGetProgramFromSearchIndexById()
      getProgramFromSearchIndexById(initiallySelectedProgram?.toString())
        .then((response) => {
          if (
            response &&
            response.data.programCountry?.countryProgramStatus !==
              ProgramCountryStatus.OpenForResupply
          ) {
            setResupplyError && setResupplyError(false)
            const programFromAPI: ProgramCatalogDto | null = response.data
            let stockOnly =
              programFromAPI?.programCountry?.initialOrderType === 'N' &&
              programFromAPI.programCountry?.stock === 'Y'
            setIsStockOnly && setIsStockOnly(stockOnly)
            if (programFromAPI.programName) {
              let selectedProgram: ProgramDtoSuggestionDto = {
                text: programFromAPI.programName,
                document: {
                  programId: programFromAPI.projectId,
                  programName: programFromAPI.programName,
                  isUserEnrolledInProgram: !!(
                    programFromAPI.projectId &&
                    isUserEnrolledIntoProgram(programFromAPI.projectId)
                  ),
                  realWorldDataEnrolFlag:
                    programFromAPI.program?.realWorldDataFlag === 'Yes' &&
                    programFromAPI.programCountry?.realWorldDataFlag === 'Y',
                  stockOrderFlag: programFromAPI.programCountry?.stock === 'Y'
                }
              }
              handleOnSelect(selectedProgram, false)
              setHighlightedIndex(-1)
              setQuery(`${programFromAPI.programName}`)
            }
          } else {
            setResupplyError && setResupplyError(true)
          }
        })
        .catch((error: AxiosError) => {
          const { code, message } = error

          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          // If we have a full error show it
          if (error.response) {
            const { title, detail } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error fetching access program. ${title ?? ''} ${
                  message ?? ''
                }`
              )
            )
            console.warn(title, detail)
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error fetching access program. ${message} ${
                  code ?? ''
                }`
              )
            )
          }

          analyticsServiceSingleton.trackError(error)
        })
    }
  }, [initiallySelectedProgram, enrolledPrograms, dispatch])

  const disabled = false
  return (
    <StyledLabel
      tabIndex={-1}
      className={`program-search`}
      htmlFor={id}
      disabled={disabled}
    >
      {label && (
        <ClinText
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color={disabled ? ClinTheme.colors.grey : 'initial'}
          marginBottom={ClinTheme.space[2]}
        >
          {label}
        </ClinText>
      )}

      <StyledInputWrapper>
        <StyledProgramAutoSuggestWrapper ref={parentNode}>
          {searchComponent({
            query,
            isLoading,
            showSuggestions: showSuggestions,
            suggestions,
            renderSuggestions,
            handleChange,
            handleKeyDown
          } as IProgramAutoSuggestResultProps)}
        </StyledProgramAutoSuggestWrapper>
      </StyledInputWrapper>
    </StyledLabel>
  )
}
