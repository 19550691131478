import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import { ClinConfirmation } from '../../components/ClinConfirmation'
import { clearConfirmationEvent } from '../../events/ConfirmationEvent'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '../../context/app'

export const ConfirmationContainer: FunctionComponent = () => {
  const { confirmation, dispatch } = useAppContext()
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined)
  const location = useLocation()

  useEffect(
    () => {
      clearTimeout(timeoutId)
      if (confirmation) {
        setTimeoutId(
          window.setTimeout(
            () => dispatch(clearConfirmationEvent()),
            confirmation.autoHideAfterMs
          )
        )
      }
      return () => clearTimeout(timeoutId)
    },
    // Don't need to run the effect again after the timeout is updated
    [confirmation, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(
    () => {
      if (confirmation) {
        dispatch(clearConfirmationEvent())
      }
    },
    // Don't re-run clearConfirmationEvent if the confirmation changes
    // The hook doesn't really depend on the confirmation, but if we avoid
    // triggering the event on every navigation we don't clutter
    // up the console as much
    [dispatch, location.pathname] // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Detect outside click
  const el = useRef<HTMLDivElement>(null)
  const handleClickedOutside = (event: MouseEvent) => {
    if (el?.current?.contains(event.target as Node)) {
      // inside click
      return
    }
    // outside click - clear confirmation
    confirmation && dispatch(clearConfirmationEvent())
  }

  useEffect(() => {
    document.addEventListener('click', handleClickedOutside)
    return () => document.removeEventListener('click', handleClickedOutside)
  })

  return confirmation ? (
    <ClinConfirmation confirmation={confirmation} ref={el} />
  ) : null
}
