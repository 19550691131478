import React, { FunctionComponent, useEffect, useState } from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'
import { ProgramDetail } from './ProgramDetail'
import {
  IPhysicianColumn,
  IProgramDocumentColumn,
  LoadingDocument,
  SideBarMode,
  defaultDocumentPagination,
  TabModel,
  useProgramStatus
} from './ProgramDetail.models'
import { useLocation } from 'react-router-dom'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useOnMount } from '../../../utils/useOnMount'
import { useAppContext } from '../../../context/app'
import { AddressModal } from './AddressModal'
import { useAddressModal } from './AddressModal/AddressModal.models'
import {
  DocumentSearchResultDto,
  PaginationResultDto,
  PatientSummarySearchDto,
  PhysiciansEnrolledSummaryDto,
  ProgramCatalogDto,
  ProgramDocumentDto,
  ProgramDocumentsSearchDto
} from '../../../types/swaggerTypes'
import {
  AuthError,
  cancelProgramDocumentSearch,
  downloadDocumentById,
  getPatients,
  programDocumentSearch
} from '../../../services/ApiService'
import { useGetEnrolledPhysicians } from '../../../hooks/useGetEnrolledPhysicians/useGetEnrolledPhysicians'
import { useErrorMessage } from '../../../utils/useErrorMessage'
import { useTranslation } from 'react-i18next'
import { useEffectOnlyOnce } from '../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsPageEvent } from '../../../services/Analytics/AnalyticsPageEvent'
import useChangeBackgroundColor from '../../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { ClinTheme } from '../../../ClinTheme'
import { AxiosError } from 'axios'
import { sortOptions } from '../../Patients/Patients.model'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinText } from '../../../components/ClinText'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { DateTime } from 'luxon'
import {
  StyledDocumentLoaderWrapper,
  StyledStatusButtonWrapper
} from './ProgramDetail.styles'
import { LocationState } from 'history'
import download from 'downloadjs'
import { getFilenameFromUrl } from '../../../utils/getFilenameFromUrl'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinNotification } from '../../../components/ClinNotification'

interface RouteStateType {
  hasRegisteredInterest: boolean
}

interface IProgramDetailRouteParams {
  programId: string
}
export interface IProductDetailProps
  extends RouteComponentProps<
    IProgramDetailRouteParams,
    StaticContext,
    LocationState
  > {
  isProgramEnrolled: boolean
}

export const ProgramDetailContainer: FunctionComponent<IProductDetailProps> = ({
  match,
  history,
  isProgramEnrolled
}) => {
  const { t } = useTranslation()
  const { dispatch, portfolioCountryCode } = useAppContext()
  const { programId } = match.params
  const [displayEnrolledToast, setDisplayEnrolledToast] =
    useState<boolean>(false)

  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const handleCountError = useErrorMessage(
    'There was an error fetching program document count'
  )

  const handlePhysiciansCountError = useErrorMessage(
    'There was an error fetching program patients count'
  )
  useChangeBackgroundColor(ClinTheme.colors.lightGrey, tabIndex)
  const location = useLocation<{ successMessage: string | undefined }>()

  const [patientCount, setPatientCount] = React.useState<number>(0)

  const documentColumns: IProgramDocumentColumn[] = [
    {
      title: t('program_detail:documents_columns.category_name'),
      renderValue: function column(document: DocumentSearchResultDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.medium}
            lineHeight={ClinTheme.lineHeights.largeParagraph}
            color={'#272727'}
          >
            {document.documentTypeName}
          </ClinText>
        )
      },
      width: '25%',
      name: 'documentTypeName',
      isSortable: true
    },
    {
      title: t('program_detail:documents_columns.document_name'),
      renderValue: function column(document: DocumentSearchResultDto) {
        return (
          <ClinText
            whiteSpace="pre-line"
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.normal}
            lineHeight={ClinTheme.lineHeights.largeParagraph}
          >
            {document.documentName}
          </ClinText>
        )
      },
      width: '30%',
      name: 'documentName',
      isSortable: true
    },
    {
      title: t('program_detail:documents_columns.language'),
      renderValue: function column(document: DocumentSearchResultDto) {
        return (
          <ClinText
            whiteSpace="nowrap"
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.normal}
            lineHeight={ClinTheme.lineHeights.largeParagraph}
          >
            {document.documentLanguage}
          </ClinText>
        )
      },
      width: '15%',
      name: 'documentLanguage',
      isSortable: true
    },
    {
      title: t('program_detail:documents_columns.last_updated'),
      renderValue: function column(document: DocumentSearchResultDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[0]}
            lineHeight={ClinTheme.lineHeights.largeParagraph}
            color="#4F5A65"
          >
            {document.programDocument?.lastUpdatedDate
              ? DateTime.fromISO(document.programDocument?.lastUpdatedDate)
                  .toFormat('dd/MMM/yyyy')
                  .toUpperCase()
              : ''}
          </ClinText>
        )
      },
      width: '30%',
      name: 'revisionDate',
      isSortable: true
    },

    {
      title: '',
      renderValue: function column(
        document: DocumentSearchResultDto,
        index?: number
      ) {
        return (
          <div style={{ position: 'relative' }}>
            {loadingDocument &&
            loadingDocument.documentId === document.generatedId &&
            loadingDocument.isLoading ? (
              <StyledDocumentLoaderWrapper>
                <ClinSpinner size={ClinTheme.space[3]} />
              </StyledDocumentLoaderWrapper>
            ) : (
              <ClinIcon
                iconName={ClinIconPathName.Download}
                iconFill={ClinTheme.colors.darkGrey}
                iconSize={28}
                onClick={() =>
                  handleDownload(document.programDocument, document.generatedId)
                }
                viewBox="0 0 25 26"
              />
            )}
          </div>
        )
      },
      width: '8.5%',
      isSortable: false
    }
  ]

  const defaultDocumentSearchParams = {
    query: '',
    filter: {
      programId: parseInt(programId),
      types: [],
      languages: [],
      countryCode: portfolioCountryCode
    },
    pagination: {
      skip: 0,
      take: 5
    },
    sorting: {
      sortBy: documentColumns[0].name,
      order: SortDirectionType.Ascending
    }
  }

  const [documentCount, setDocumentCount] = React.useState<number>(0)
  const [documents, setDocuments] = React.useState<
    DocumentSearchResultDto[] | undefined
  >([])

  const [documentSearchParams, setDocumentSearchParams] =
    React.useState<ProgramDocumentsSearchDto>(() => ({
      ...defaultDocumentSearchParams,
      sorting: {
        sortBy: defaultDocumentSearchParams.sorting.sortBy ?? '', // Ensure sortBy is always a string
        order: defaultDocumentSearchParams.sorting.order
      }
    }))

  const [documentPagination, setDocumentPagination] =
    React.useState<PaginationResultDto>(defaultDocumentPagination)

  const [documentsPerPage, setDocumentsPerPage] = React.useState<number>(5)
  const [loadingDocument, setLoadingDocument] =
    React.useState<LoadingDocument | null>()

  const handleDocumentToggleSort = (columnName: string | undefined) => {
    const currentScrollY = window.scrollY
    const sortColumn = documentColumns.find((x) => x.name === columnName)
      ?.name as string

    if (sortColumn) {
      const newSortDirection =
        documentSearchParams.sorting.sortBy === sortColumn &&
        documentSearchParams.sorting.order === SortDirectionType.Descending
          ? SortDirectionType.Ascending
          : SortDirectionType.Descending

      setDocumentSearchParams({
        ...documentSearchParams,
        sorting: {
          sortBy: sortColumn,
          order: newSortDirection
        }
      })

      setTimeout(() => {
        window.scrollTo(0, currentScrollY)
      }, 0)
    }
  }

  const handleDownload = (
    documentItem?: ProgramDocumentDto,
    documentId?: string
  ) => {
    if (documentId && documentItem?.generatedId) {
      setLoadingDocument({ isLoading: true, documentId: documentId })
      downloadDocumentById(documentId)
        .then((response) => {
          documentItem.documentTypeName &&
            documentItem.documentUrl &&
            download(
              response.data,
              getFilenameFromUrl(documentItem.documentUrl),
              documentItem.documentTypeName
            )
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error downloading your document. ${error}`
            )
          )
        })
        .finally(() => setLoadingDocument(null))
    }
  }

  const handleDocumentRowClicked = (
    documentItem?: ProgramDocumentDto,
    documentId?: string
  ) => {
    handleDownload(documentItem, documentId)
  }

  const handleDocumentsPageClicked = (selectedPageIndex: number) => {
    cancelProgramDocumentSearch()
    setDocumentSearchParams({
      ...documentSearchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * documentsPerPage,
        take: documentsPerPage
      },
      sorting: {
        sortBy: documentSearchParams.sorting.sortBy,
        order: documentSearchParams.sorting.order
      }
    })
  }

  const handleDocumentsPageSizeChange = (pageSize: number) => {
    cancelProgramDocumentSearch()
    setDocumentsPerPage(pageSize)
    setDocumentSearchParams({
      ...documentSearchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }

  const handleDocumentQueryChanged = (query: string) => {
    setDocumentSearchParams({
      ...documentSearchParams,
      query: query
    })
  }

  useEffect(() => {
    programDocumentSearch(documentSearchParams)
      .then((response) => {
        setDocumentCount(response.data.pagination?.total ?? 0)
        setDocuments(response.data.result)
        setDocumentPagination(response.data.pagination!)
      })
      .catch((error) => {
        console.warn(error)
        handleCountError(error)
      })
    return () => {
      cancelProgramDocumentSearch()
    }
  }, [handleCountError, portfolioCountryCode, programId, documentSearchParams])

  const { isProgramLoading, programError, program, programUiState } =
    useProgramStatus(programId)

  const isEnrolled = programUiState?.sideBarMode === SideBarMode.Enrolled

  const defaultSearchParams: PatientSummarySearchDto = {
    query: '',
    filter: {
      programs: program?.programName ? [program?.programName] : [],
      patientOrderStatuses: [],
      physicianId: undefined,
      showMyPatient: false,
      showMyPhysician: false,
      showMyProgram: true
    },
    pagination: {
      skip: 0,
      take: 10
    },
    sorting: {
      sortBy: sortOptions[0].optionId,
      order: SortDirectionType.Ascending
    }
  }

  const { physiciansLoading, physiciansError, physicians } =
    useGetEnrolledPhysicians(programId, isEnrolled)

  const handlePhysicianRowClicked = (selectedPhysicianId: string | number) => {
    history.push(`/programs/my-physicians/${selectedPhysicianId}`)
  }

  const physicianColumns: IPhysicianColumn[] = [
    {
      title: t('program_detail:physicians_columns.physician_name'),
      renderValue: function column(physician: PhysiciansEnrolledSummaryDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.medium}
            lineHeight={ClinTheme.lineHeights.largeParagraph}
            color={'#272727'}
          >
            {physician.physicianFullName}
          </ClinText>
        )
      },
      width: '30%',
      name: 'physicianFullName',
      isSortable: true
    },
    {
      title: t('program_detail:physicians_columns.email_address'),
      renderValue: function column(physician: PhysiciansEnrolledSummaryDto) {
        return (
          <ClinText
            whiteSpace="nowrap"
            fontSize={ClinTheme.fontSizes[0]}
            fontWeight={ClinTheme.fontWeights.normal}
            lineHeight={ClinTheme.lineHeights.largeParagraph}
          >
            {physician.physicianEmail}
          </ClinText>
        )
      },
      width: '30%',
      name: 'physicianEmail',
      isSortable: true
    },
    {
      title: t('program_detail:physicians_columns.status'),
      renderValue: function column(physician: PhysiciansEnrolledSummaryDto) {
        let statusComponent

        switch (physician.physicianLinkedToUser) {
          case 'Y':
            statusComponent = (
              <StyledStatusButtonWrapper
                background="rgba(0, 162, 35, 0.1)"
                color={ClinTheme.colors.greenValid}
              >
                {t('program_detail:associated')}
              </StyledStatusButtonWrapper>
            )
            break
          case 'N':
            statusComponent = (
              <StyledStatusButtonWrapper
                background="rgba(117, 117, 117, 0.1)"
                color={ClinTheme.colors.darkGrey}
              >
                {t('program_detail:unassociated')}
              </StyledStatusButtonWrapper>
            )
            break

          default:
            statusComponent = (
              <ClinText
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.primary}
                fontSize={ClinTheme.fontSizes[0]}
                lineHeight={'2'}
              >
                {physician.physicianLinkedToUser}
              </ClinText>
            )
            break
        }

        return statusComponent
      },
      width: '30%',
      name: 'physicianLinkedToUser',
      isSortable: false
    }
  ]

  useEffect(() => {
    program?.programName &&
      getPatients({
        ...defaultSearchParams
      })
        .then((response) => {
          if (response) {
            setPatientCount(response?.data.pagination.total)
          }
        })
        .catch((error: AxiosError) => handlePhysiciansCountError(error))
  }, [handlePhysiciansCountError, portfolioCountryCode, programId, program])

  const {
    isAddressModalOpen,
    currentAddress,
    selectedAddress,
    addresses,
    handleCloseAddressModal,
    handleAddressSelected,
    handleSubmitAddress
  } = useAddressModal()

  // Check if we have just submitted registered interest form
  const routeState = useLocation<RouteStateType>()
  useOnMount(() => {
    const hasRegisteredInterest = routeState?.state?.hasRegisteredInterest
    if (hasRegisteredInterest) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Success,
          t('program_register_interest:confirmation_message'),
          t('program_register_interest:confirmation_title')
        )
      )
    }
    const searchParams = new URLSearchParams(location.search)

    if (searchParams.has('autoenrolled')) {
      setDisplayEnrolledToast(true)
    }
  })

  type Deps = [boolean, ProgramCatalogDto]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(AnalyticsPageEvent.ViewProgram, {
        'Program name': dependencies[1].programName,
        'Program ID': dependencies[1].projectId
      })
    },
    [isProgramLoading || (isEnrolled && physiciansLoading), program],
    (dependencies: Deps) => dependencies[0] === false && dependencies[1]
  )

  const handleChangeTab = (tabIndex: number) => {
    setTabIndex(tabIndex)
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }

  const tabData: TabModel[] = [
    {
      linkText: 'Patients',
      showCountNumber: true,
      countNumber: patientCount,
      tabIndex: 0,
      onClick: handleChangeTab
    },
    {
      linkText: 'Physicians',
      showCountNumber: true,
      countNumber: physicians?.length ?? 0,
      tabIndex: 1,
      onClick: handleChangeTab
    },
    {
      linkText: 'Documents',
      showCountNumber: true,
      countNumber: documentCount,
      tabIndex: 2,
      onClick: handleChangeTab
    }
  ]

  return (
    <>
      {displayEnrolledToast && (
        <ClinNotification
          title={t('enrol_user_on_to_program:you_are_enrolled_toast')}
          onClose={() => setDisplayEnrolledToast(false)}
        />
      )}

      {addresses && (
        <AddressModal
          isOpen={isAddressModalOpen}
          currentAddress={currentAddress}
          selectedAddress={selectedAddress}
          addresses={addresses}
          isSubmitting={false}
          requestShippingAddressLink={'/new-shipping-address'}
          handleAddressSelected={handleAddressSelected}
          handleSubmitAddress={handleSubmitAddress}
          handleClose={handleCloseAddressModal}
        />
      )}
      <ProgramDetail
        isLoading={isProgramLoading || (isEnrolled && physiciansLoading)}
        hasError={programError || physiciansError}
        program={program}
        programUiState={programUiState}
        physicians={physicians}
        physiciansColumns={physicianColumns}
        documentColumns={documentColumns}
        documentCount={documentCount}
        programDocuments={documents}
        tabData={tabData}
        documentPagination={documentPagination}
        selectedTabIndex={tabIndex}
        documentsQuery={documentSearchParams.query ?? ''}
        isProgramEnrolled={isProgramEnrolled}
        handleChangeTab={handleChangeTab}
        handleDocumentPageClicked={handleDocumentsPageClicked}
        handleDocumentPageSizeChanged={handleDocumentsPageSizeChange}
        handlePhysicianRowClicked={handlePhysicianRowClicked}
        handleDocumentQueryChanged={handleDocumentQueryChanged}
        handlePatientCountChanged={setPatientCount}
        handleDocumentRowClicked={handleDocumentRowClicked}
        handleDocumentToggleSort={handleDocumentToggleSort}
        selectedDocumentSortDirection={documentSearchParams.sorting.order}
        selectedDocumentSortColumn={documentSearchParams.sorting.sortBy}
      />
    </>
  )
}
