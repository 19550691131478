import { t } from 'i18next'
import React, { FunctionComponent, useContext } from 'react'
import { useHistory } from 'react-router'
import { AppContext } from '../../context/app'
import { ConfirmationProductDetail } from '../../pages/SourcingEnquiry/SourcingEnquiry.models'
import { ConfirmationScreen } from './ConfirmationScreen'
import { isAusGaUser } from '../../constants'

interface IConfirmatinScreenProps {
  confirmationProductDetail: ConfirmationProductDetail
  title: string
  detailsDescription: string
}

export const ConfirmationScreenContainer: FunctionComponent<
  IConfirmatinScreenProps
> = ({ confirmationProductDetail, title, detailsDescription }) => {
  const { portfolioCountryCode, userRole } = useContext(AppContext)
  const history = useHistory()

  const handleBackButton = () => {
    const returnUrl = isAusGaUser(portfolioCountryCode, userRole)
      ? '/home'
      : '/orders'
    history.push(returnUrl)
  }
  return (
    <ConfirmationScreen
      productDetials={confirmationProductDetail}
      title={title}
      detailsDescription={detailsDescription}
      portfolioCountryCode={portfolioCountryCode}
      submitButtonText={
        isAusGaUser(portfolioCountryCode, userRole)
          ? t('common:buttons.back_to_home')
          : t('common:buttons.back')
      }
      handleBackButton={handleBackButton}
    />
  )
}
