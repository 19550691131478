import React, { FunctionComponent, useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { OrdersContainer } from '..'
import { NotFound } from '../NotFound'
import { AccountDetailsContainer } from '../AccountPages/AccountDetails/AccountDetailsCountainer'
import { GlobalStyle } from './App.styles'
import { setConfiguration } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { AnnounceContainer } from '../../containers/AnnounceContainer'
import { LogIn } from '../LogIn'
import { ChangePasswordContainer } from '../AccountPages/ChangePassword'
import { NavbarContainer } from '../../containers/NavbarContainer'
import { AnalyticsHelper } from '../../services/Analytics/AnalyticsHelper'
import { ScrollToTop } from '../../utils/ScrollToTop'
import { ProductSearchContainer } from '../Products/ProductSearch'
import { ProductVariantContainer } from '../Products/ProductVariant'
import { ProductDetailContainer } from '../Products/ProductDetail'
import { InstituteDetailContainer } from '../InstitutePages/InstituteDetail'
import { BookmarksContainer } from '../Bookmarks'
import { SupportContainer } from '../Support'
import { AboutUsContainer } from '../AboutUs'
import { ReportShortageContainer } from '../ShortagesPages/ReportShortage'
import { ConfirmationContainer } from '../../containers/ConfirmationContainer'
import { ReportAdverseEventContainer } from '../Products/ReportAdverseEvent'
import { BasketContainer } from '../Basket'
import { ContextDebugger } from '../../context/ContextDebugger'
import { AutoSuggest } from '../../features/Search/AutoSuggest'
import { GlobalSearch } from '../../features/Search/GlobalSearch'
import { InstituteModalContainer } from '../../containers/InstituteModalContainer'
import { CheckoutContainer } from '../Checkout'
import { CheckoutOptionsContainer } from '../CheckoutOptions'
import { CheckoutSummaryContainer } from '../CheckoutSummary'
import { SourcingEnquiryContainer } from '../SourcingEnquiry'
import { BasketProvider } from '../../context/basket'
import { CheckoutErrorModalContainer } from '../../containers/CheckoutErrorModalContainer'
import { EditContactContainer } from '../InstitutePages/EditContact'
import { NewShippingAddressContainer } from '../InstitutePages/NewShippingAddress'
import { InviteNewUserContainer } from '../InstitutePages/InviteNewUser'
import { PrivateCheckoutRoute } from '../../components/PrivateCheckoutRoute'
import { AccessProgramsContainer } from '../AccessPrograms'
import { MyPhysiciansContainer } from '../MyPhysicians'
import { PhysicianDetailsContainer } from '../PhysicianDetails'
import {
  PatientDetailContainer,
  PatientDetailContainerOld
} from '../Patients/PatientDetail'
import { PatientAccessFormContainer } from '../PatientAccessForm'
import { ContactUsContainer } from '../ContactUs'
import { SupportDetailContainer } from '../SupportDetail'
import { PrivateRoleRoute } from '../../components/PrivateRoleRoute'
import {
  UserRoleWithCountry,
  adminUsers,
  allRoles,
  getUserRoleWithCountry,
  isAusGaUser,
  maUsers,
  nonMaUsers
} from '../../constants'
import { TransferPatientContainer } from '../TransferPatient/TransferPatientToPhysician'
import { TransferPatientToInstituteContainer } from '../TransferPatient/TransferPatientToInstitute'
import { PrivatePatientRoute } from '../../components/PrivatePatientRoute'
import { ProgramRegisterInterestContainer } from '../Programs/ProgramRegisterInterest'
import { OpaCheckoutContainer } from '../OpaCheckout'
import { FooterContainer } from '../../features/Footer/FooterContainer'
import { RequestAccessContainer } from '../InstitutePages/RequestAccess'
import { OnboardingContainer } from '../Onboarding'
import { OpaOrderSummaryContainer } from '../OpaOrderSummary'
import { EnrolUserOnToProgramContainer } from '../EnrolUserOnToProgram'
import { OrderRoute } from '../../components/OrderRoute'
import { EnrolPhysicianOnToProgramContainer } from '../EnrolPysicianOnToProgram'
import { SupportVideoGuideContainer } from '../SupportVideoGuide'
import { ProgramDocumentationContainer } from '../ProgramDocumentation'
import { BootstrapSplashContainer } from '../BootstrapSplash'
import { SessionTimeOutContainer } from '../../features/SessionTimeOut/SessionTimeOutContainer'
import config from '../../services/ConfigProvider'
import { useTranslation } from 'react-i18next'
import { PreferencesContainer } from '../Preferences'
import { PatientDiscontinuationContainer } from '../PatientDiscontinuation/PatientDiscontinuationContainer'
import { NewFeaturesProvider } from '../../context/newFeatures'
import { SourcingEnquirySubmitContainer } from '../SourcingEnquiry/SourcingEnquirySubmit/SourcingEnquirySubmitContainer'
import { ReportShortageSubmitContainer } from '../ShortagesPages/ReportShortage/ReportShortageSubmit/ReportShortageSubmitContainer'
import { CountryAlphaCodes } from '../../constants/countryAlpha2Codes'
import { ShortagesContainer } from '../ShortagesPages/Shortages'
import { OneStepCheckoutContainer } from '../CheckoutOptions/OneStepCheckoutContainer'
import { HomepageContainer } from '../Homepage/HomepageContainer'
import { PrivateCountryRoleRoute } from '../../components/PrivateCountryRoleRoute'
import { AppContext } from '../../context/app'
import { PrivateCountryRoute } from '../../components/PrivateCoutryRoute/PrivateCountryRoute'
import { PrivateCountryRoleEnumRoute } from '../../components/PrivateCountryRoleEnumRoute'
import { PatientDashboardContainer } from '../Patients/PatientDashboard/PatientDashboardContainer'
import { CreatePatientContainer } from '../Patients/CreatePatient/CreatePatientContainer'
import { ProgramsRoute } from '../../components/ProgramsRoute/ProgramsRoute'
import { StyledClinNewFeatureOverlay } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.styles'
import { RealWorldDataProgramContainer } from '../RealWorldDataProgram/RealWorldDataProgramContainer'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'

setConfiguration({
  gridColumns: 12,
  gutterWidth: 32,
  containerWidths: ClinTheme.containerWidths,
  breakpoints: ClinTheme.breakpoints,
  maxScreenClass: 'xl'
})

export const App: FunctionComponent = () => {
  const { t } = useTranslation()
  const { portfolioCountryCode, userRole } = useContext(AppContext)
  const { setFeatureFlagRawData, useFeatureFlag } = useFeatureFlags()
  let newPatientDetailsPageFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.NewPatientDetailsPage
  )
  const userRoleWithCountry = getUserRoleWithCountry(
    portfolioCountryCode,
    userRole
  )
  //This useEffect listens for the configUpdate event and appropriately consumes the data recieved
  useEffect(() => {
    const featureFlagRenewTimerMs = config.featureFlagRenewTimerMs
      ? Number(config.featureFlagRenewTimerMs)
      : 1e6
    const fetchFeatureFlags = async () => {
      try {
        const response = await fetch('/feature-flags')
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        setFeatureFlagRawData(data)
      } catch (error) {
        console.error('Error fetching feature flags', error)
      }
    }

    const intervalId = setInterval(() => {
      fetchFeatureFlags()
    }, featureFlagRenewTimerMs)

    // Fetch immediately on mount
    fetchFeatureFlags()

    return () => {
      clearInterval(intervalId) // Cleanup interval on unmount
    }
  }, [])

  return (
    <BasketProvider>
      <NewFeaturesProvider>
        <GlobalStyle />
        <AnalyticsHelper />
        {window.location.hostname === 'localhost' ? <ContextDebugger /> : null}
        <InstituteModalContainer />
        <BootstrapSplashContainer>
          <>
            <SessionTimeOutContainer
              inactivityTimeoutSecs={config.sessionTimeoutSecs}
              dialogueDurationSecs={config.sessionModalTimeSecs}
            />
            <CheckoutErrorModalContainer />
            <StyledClinNewFeatureOverlay
              className="feature-overlay"
              style={{ display: 'none' }}
            />
            <ScrollToTop
              exceptionLocations={[
                '/orders',
                '/programs/my-physicians/:physicianId',
                '/programs/my-physicians/:physicianId/:patientId',
                '/programs/access-programs'
              ]}
            />
            <div id="content">
              <NavbarContainer />
              <ConfirmationContainer />
              <AutoSuggest
                pathsToHide={[
                  'account',
                  'support',
                  'checkout',
                  'products/catalogue',
                  '/home',
                  'results',
                  'onboarding',
                  'patients'
                ]}
                noResultsText={t('global_search:no_results_text')}
                maUserNoResultsText={t('global_search:ma_user_no_results_text')}
                suggestionText={t('global_search:suggestion_text')}
                noResultsPath={'/products/catalogue?q=*'}
                searchComponent={(props) => (
                  <GlobalSearch
                    {...props}
                    isAusGa={isAusGaUser(portfolioCountryCode, userRole)}
                  />
                )}
              />
              <AnnounceContainer />
              <Switch>
                <Route path="/" exact={true} component={LogIn} />
                <Route path="/login" exact={true} component={LogIn} />
              </Switch>
              <main role="main">
                <Switch>
                  <Route
                    path="/onboarding"
                    exact={true}
                    component={OnboardingContainer}
                  />
                  <Route
                    path="/homepage"
                    render={() =>
                      userRoleWithCountry === UserRoleWithCountry.AusBase ||
                      userRoleWithCountry === UserRoleWithCountry.AusSuper ? (
                        <Redirect to="/home" />
                      ) : (
                        <Redirect to="/orders" />
                      )
                    }
                    exact={true}
                  />
                  <PrivateCountryRoleEnumRoute //This is new route guardian that check UserRoleWithCountry enum values
                    path="/home"
                    exact={true}
                    component={HomepageContainer}
                    allowedCountryWithRole={[
                      UserRoleWithCountry.AusBase,
                      UserRoleWithCountry.AusSuper
                    ]}
                  />
                  <Route
                    path="/institute/request-access"
                    exact={true}
                    component={RequestAccessContainer}
                  />
                  <Route
                    path="/orders"
                    exact={true}
                    component={OrdersContainer}
                  />
                  <Route
                    path="/programs/access-programs/real-world-data"
                    exact={true}
                    component={RealWorldDataProgramContainer}
                  />
                  <Route
                    path="/account/details"
                    component={AccountDetailsContainer}
                  />
                  <Route
                    path="/account/change-password"
                    exact={true}
                    component={ChangePasswordContainer}
                  />
                  <OrderRoute path="/order/:orderId" exact={true} />
                  <PrivateCountryRoleRoute
                    path="/programs/access-programs"
                    exact={true}
                    component={AccessProgramsContainer}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <ProgramsRoute
                    path="/programs/access-programs/:programId"
                    exact={true}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <PrivateCountryRoleRoute
                    path="/programs/access-programs/:programId/register-interest"
                    exact={true}
                    component={ProgramRegisterInterestContainer}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <PrivateCountryRoleRoute
                    path="/programs/access-programs/:programId/documentation"
                    exact={true}
                    component={ProgramDocumentationContainer}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <PrivateCountryRoleRoute
                    path="/programs/my-physicians"
                    exact={true}
                    component={MyPhysiciansContainer}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <PrivateCountryRoleRoute
                    path="/programs/my-physicians/:physicianId"
                    exact={true}
                    component={PhysicianDetailsContainer}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <Route
                    path="/sourcing-enquiry-submit"
                    exact={true}
                    component={SourcingEnquirySubmitContainer}
                  />
                  <PrivateCountryRoleRoute
                    path="/programs/my-physicians/:physicianId/:patientId"
                    exact={true}
                    component={
                      newPatientDetailsPageFeatureFlag
                        ? PatientDetailContainer
                        : PatientDetailContainerOld
                    }
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <PrivatePatientRoute
                    path="/programs/my-physicians/:physicianId/:patientId/transfer-patient"
                    exact={true}
                    component={TransferPatientContainer}
                  />
                  <PrivateCountryRoleRoute
                    path="/programs/my-physicians/:physicianId/:patientId/discontinue-patient"
                    exact={true}
                    component={PatientDiscontinuationContainer}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <PrivateCountryRoleRoute
                    path="/programs/my-physicians/:physicianId/:patientId/transfer-patient/physician"
                    exact={true}
                    component={TransferPatientContainer}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <PrivateCountryRoleRoute
                    path="/programs/my-physicians/:physicianId/:patientId/transfer-patient/institute"
                    exact={true}
                    component={TransferPatientToInstituteContainer}
                    forbidForCountryCodes={[CountryAlphaCodes.Australia]}
                    forbidRoleTypes={nonMaUsers}
                  />
                  <Route
                    path="/support"
                    exact={true}
                    component={SupportContainer}
                  />
                  <Route
                    path="/support/:pageName"
                    exact={true}
                    component={SupportDetailContainer}
                  />
                  <Route
                    path="/support/video-guide/:videoId"
                    exact={true}
                    component={SupportVideoGuideContainer}
                  />
                  <Route
                    path="/about"
                    exact={true}
                    component={AboutUsContainer}
                  />
                  <Route
                    path="/about/contact-us"
                    exact={true}
                    component={ContactUsContainer}
                  />
                  <Route
                    path="/products/adverse-event"
                    exact={true}
                    component={ReportAdverseEventContainer}
                  />
                  <Route
                    path="/programs/access-programs/:programId/patient-access-form/:physicianId"
                    exact={true}
                    component={PatientAccessFormContainer}
                  />
                  <Route
                    path="/programs/access-programs/:programId/patient-access-form/:physicianId/form/:formId"
                    exact={true}
                    component={PatientAccessFormContainer}
                  />
                  <Route
                    path="/opa-checkout/:orderId"
                    exact={true}
                    component={OpaCheckoutContainer}
                  />
                  <Route
                    path="/opa-order-summary/:orderId"
                    exact={true}
                    component={OpaOrderSummaryContainer}
                  />
                  <Route
                    path="/institute/details"
                    exact={true}
                    component={InstituteDetailContainer}
                  />
                  <Route
                    path="/new-shipping-address"
                    exact={true}
                    component={NewShippingAddressContainer}
                  />
                  <Route
                    path="/sourcing-enquiry"
                    exact={true}
                    component={SourcingEnquiryContainer}
                  />
                  <PrivateCheckoutRoute
                    path="/checkout"
                    exact={true}
                    redirectPath="/orders"
                    component={CheckoutContainer}
                  />
                  <PrivateCheckoutRoute
                    path="/checkout/delivery"
                    exact={true}
                    redirectPath="/orders"
                    component={CheckoutOptionsContainer}
                  />
                  <Route
                    path="/checkout/summary"
                    exact={true}
                    component={CheckoutSummaryContainer}
                  />
                  <PrivateCountryRoute
                    path="/one-step-checkout"
                    exact={true}
                    component={OneStepCheckoutContainer}
                    onlyAllowForCountryCodes={[CountryAlphaCodes.Australia]}
                  />
                  <Route
                    path={`/programs/access-programs/:programId/enrolment-form`}
                    exact={true}
                    component={EnrolUserOnToProgramContainer}
                  />
                  <Route
                    path={`/programs/access-programs/:programId/physician-enrolment-form`}
                    exact={true}
                    component={EnrolPhysicianOnToProgramContainer}
                  />
                  <PrivateRoleRoute
                    path="/products/catalogue"
                    exact={true}
                    component={ProductSearchContainer}
                    acceptedRoleTypes={nonMaUsers}
                    countriesExcludedFromThisRule={[
                      CountryAlphaCodes.Australia
                    ]}
                  />
                  <PrivateRoleRoute
                    path="/results"
                    exact={true}
                    component={ProductSearchContainer}
                    acceptedRoleTypes={maUsers}
                    countriesExcludedFromThisRule={[
                      CountryAlphaCodes.Australia
                    ]}
                  />
                  <Route
                    path="/product/:productId"
                    exact={true}
                    component={ProductVariantContainer}
                  />
                  <PrivateRoleRoute
                    path="/product/:productId/variant/:sku"
                    exact={true}
                    component={ProductDetailContainer}
                    acceptedRoleTypes={nonMaUsers}
                    countriesExcludedFromThisRule={[
                      CountryAlphaCodes.Australia
                    ]}
                  />
                  <PrivateRoleRoute
                    path="/product/sku/:sku"
                    exact={true}
                    component={ProductDetailContainer}
                    acceptedRoleTypes={nonMaUsers}
                    countriesExcludedFromThisRule={[
                      CountryAlphaCodes.Australia
                    ]}
                  />
                  <PrivateRoleRoute
                    path="/basket"
                    exact={true}
                    component={BasketContainer}
                    acceptedRoleTypes={nonMaUsers}
                  />
                  <PrivateRoleRoute
                    path="/shortages/uk-shortages"
                    exact={true}
                    component={ShortagesContainer}
                    acceptedRoleTypes={nonMaUsers}
                    onlyAllowForCountryCodes={['GB']}
                  />
                  <PrivateRoleRoute
                    path="/shortages/aus-shortages"
                    exact={true}
                    component={ShortagesContainer}
                    acceptedRoleTypes={allRoles}
                    onlyAllowForCountryCodes={[CountryAlphaCodes.Australia]}
                  />
                  <PrivateRoleRoute
                    path="/shortages/report"
                    exact={true}
                    component={ReportShortageContainer}
                    acceptedRoleTypes={nonMaUsers}
                    countriesExcludedFromThisRule={[
                      CountryAlphaCodes.Australia
                    ]}
                  />
                  <Route
                    path="/report-shortages-submit"
                    exact={true}
                    component={ReportShortageSubmitContainer}
                  />
                  <PrivateRoleRoute
                    path="/bookmarks"
                    exact={true}
                    component={BookmarksContainer}
                    acceptedRoleTypes={nonMaUsers}
                  />
                  <PrivateRoleRoute
                    path="/new-user"
                    exact={true}
                    component={InviteNewUserContainer}
                    acceptedRoleTypes={adminUsers}
                  />
                  <PrivateRoleRoute
                    path="/edit-user/:contactId"
                    exact={true}
                    component={EditContactContainer}
                    acceptedRoleTypes={adminUsers}
                  />
                  <Route
                    path="/preferences"
                    exact={true}
                    component={PreferencesContainer}
                  />
                  <Route
                    path="/patients"
                    exact={true}
                    component={PatientDashboardContainer}
                  />
                  <Route
                    path="/create-patient"
                    exact={true}
                    component={CreatePatientContainer}
                  />
                  <Route path="*" exact={true} component={NotFound} />
                </Switch>
              </main>
            </div>
            <FooterContainer />
          </>
        </BootstrapSplashContainer>
      </NewFeaturesProvider>
    </BasketProvider>
  )
}

export default App
