import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router'
import { useAppContext } from '../../context/app'
import { NotFound } from '../../pages/NotFound'
import { getIsPageAccessibleFromUserRoleAndCountry } from '../../utils/getIsPageAccessibleFromCountry'
import { UserRoleWithCountry } from '../../constants'

interface IPrivateCountryRoleEnumRouteProps {
  component: FunctionComponent<any>
  exact: boolean
  path: string
  redirect?: string
  /** Optional error page (uses standard NotFound 404 page by default) */
  errorPage?: FunctionComponent<any>
  allowedCountryWithRole: UserRoleWithCountry[] // if we have this than only allow for this country with user role
}

//use this route because aus ga users can not see programs pages (related to clos-1610)
export const PrivateCountryRoleEnumRoute: FunctionComponent<
  IPrivateCountryRoleEnumRouteProps
> = ({
  component,
  path,
  exact,
  redirect,
  errorPage = NotFound,
  allowedCountryWithRole
}) => {
  const { portfolioCountryCode, userRole } = useAppContext()

  return (
    <>
      {userRole &&
      getIsPageAccessibleFromUserRoleAndCountry(
        portfolioCountryCode,
        userRole,
        allowedCountryWithRole
      ) ? (
        <Route path={path} exact={exact} component={component} />
      ) : (
        <>
          {redirect ? (
            <Redirect to={redirect} exact={true} />
          ) : (
            <Route path={path} exact={true} component={errorPage} />
          )}
        </>
      )}
    </>
  )
}
