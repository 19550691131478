import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { IOrder } from '../../types/IOrder'
import {
  StyledCheckoutSummary,
  StyledCheckoutSummaryOrder,
  StyledCheckoutTableSummaryHeader,
  StyledOrderLink,
  StyledSummaryHeading,
  StyledSummaryIcon
} from './CheckoutSummary.styles'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinCard } from '../../components/ClinCard'
import { CheckoutSummaryOrderItem } from './CheckoutSummaryOrderItem'
import {
  DocumentUploadSummaryDto,
  OrderLineDto
} from '../../types/swaggerTypes'
import { ClinDividerHeading } from '../../components/ClinDividerHeading'
import { IHoldDetails } from '../../services/Holds/holds.types'
import {
  getOrderLineHoldDocuments,
  getOrderHoldsInformation
} from '../../services/Holds/holds'
import { getAdditionalLineItems } from '../OrderPages/OrderDetail/OrderDetail.model'
import { HoldAnnounce } from '../../features/HoldAnnounce'
import { DateTime } from 'luxon'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { ClinButton } from '../../components/ClinButton'
import { ClinThreeBoxes } from '../../components/ClinThreeBoxes'
import {
  StyledProductCell,
  StyledProductHeader
} from '../Products/ProductVariant/ProductVariant.styles'
import {
  IEddArray,
  IEddItemForLocalStorage
} from '../Products/ProductDetail/ProductDetailContainer'
import { SourceSystem } from '../../constants'

interface ICheckoutSummaryProps {
  /** An array of processed orders **/
  orders?: IOrder[]
  /** An array of any uploaded documents **/
  uploadedDocuments?: DocumentUploadSummaryDto[]
  /** Whether we are loading or not */
  isLoading: boolean
  /** Provides an array of holds to display above the order */
  orderLevelHolds?: IHoldDetails[]
  /** Array of line items that have been cancelled */
  cancelledItemIds?: number[]
  isAus?: boolean
  deliveryEdds?: IEddArray

  /** Handles cancelling an order line for an order **/
  handleCancelOrderLine?: (orderNumber: string, orderLineId: number) => void
  handleGoBack: () => void
  handlePrint: () => void
}

export const CheckoutSummary: FunctionComponent<ICheckoutSummaryProps> = ({
  orders,
  isLoading,
  orderLevelHolds,
  cancelledItemIds = [],
  uploadedDocuments = [],
  isAus,
  deliveryEdds,
  handleGoBack,
  handlePrint,
  handleCancelOrderLine
}) => {
  const { t } = useTranslation()
  const getOrderLineWithDeliveryText = (
    orderLine: OrderLineDto
  ): OrderLineDto => {
    const isLimitedAvailablePoduct =
      isAus &&
      orderLine.skuCatalogItem?.item &&
      orderLine.skuCatalogItem?.item?.sourceSystem === SourceSystem.Sage &&
      orderLine.skuCatalogItem?.item?.supplyProblem === 'Y'
    const orderSkuFromEdds: IEddItemForLocalStorage | undefined =
      deliveryEdds?.skuAndEddArray.find(
        (x) => x.itemSku === orderLine.skuCatalogItem?.sku
      )
    return {
      ...orderLine,
      deliveryText: isLimitedAvailablePoduct
        ? t('checkout_summary:cs_estimated_delivery_limited_availability')
        : orderSkuFromEdds
        ? orderSkuFromEdds.itemDisplayedStock
          ? t('checkout_summary:delivery_date')
          : t('checkout_summary:delivery_date_0_stock')
        : ''
    }
  }
  return (
    <ClinPageContentFrame>
      {isLoading && (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      )}

      {!isLoading && (
        <StyledCheckoutSummary>
          <StyledSummaryHeading>
            <StyledSummaryIcon>
              <ClinIcon
                iconName={ClinIconPathName.ArrowRight}
                iconSize={ClinTheme.space[4]}
              />
            </StyledSummaryIcon>

            <ClinText
              variant={TypographyVariant.H2}
              as="span"
              fontWeight={ClinTheme.fontWeights.bold}
              marginLeft={ClinTheme.space[2]}
            >
              {isAus
                ? t('checkout_summary:title_aus')
                : t('checkout_summary:title')}
            </ClinText>
          </StyledSummaryHeading>

          {isAus && (
            <StyledProductHeader className="productHeaderAus">
              <div>
                <ClinText variant={TypographyVariant.H5} width="100%">
                  {t('checkout_summary:product_request_refference_number')}
                </ClinText>
                {orders && (
                  <ClinText variant={TypographyVariant.H2}>
                    {orders[0].orderNumber}
                  </ClinText>
                )}
              </div>
              <StyledProductCell justifyContent={'space-between'}>
                <ClinButton
                  variant="link"
                  onClick={() => handlePrint && handlePrint()}
                >
                  <span style={{ marginRight: ClinTheme.space[2] }}>
                    {t('common:download_pdf')}
                  </span>{' '}
                  <ClinIcon
                    iconName={
                      isAus ? ClinIconPathName.Download : ClinIconPathName.Print
                    }
                  />
                </ClinButton>
              </StyledProductCell>
            </StyledProductHeader>
          )}
          {!isAus && <ClinSpacer hasBorder={true} />}

          {isAus && (
            <ClinButton onClick={() => handleGoBack()} variant="link">
              <ClinIcon
                style={{ marginRight: ClinTheme.space[2] }}
                iconSize={ClinTheme.fontSizes[3]}
                iconName={
                  !isAus
                    ? ClinIconPathName.ChevronLeft
                    : ClinIconPathName.ArrowLeft
                }
              />
              {t('product_not_available:back_to_products_btn')}
            </ClinButton>
          )}
          {!isAus && (
            <>
              <ClinText
                fontSize={ClinTheme.fontSizes[2]}
                fontWeight={ClinTheme.fontWeights.light}
              >
                {t('checkout_summary:introduction')}
              </ClinText>

              <ClinSpacer height={ClinTheme.space[3]} />
              <ClinText
                fontSize={ClinTheme.fontSizes[2]}
                fontWeight={ClinTheme.fontWeights.light}
              >
                <Trans
                  i18nKey="checkout_summary:return_to_dashboard"
                  components={[<Link key="link" to="../orders" />]}
                >
                  <></>
                </Trans>
              </ClinText>
            </>
          )}

          {isAus && (
            <ClinThreeBoxes
              moduleTitle={t('checkout_summary:what_happens_next')}
              firstBoxTitle={t('checkout_summary:order_placed')}
              firstBoxContent={'checkout_summary:first_box_description'}
              firstBoxIcon={ClinIconPathName.Basket}
              secondBoxTitle={t('checkout_summary:document_verification')}
              secondBoxContent={'checkout_summary:second_box_description'}
              secondBoxIcon={ClinIconPathName.Document}
              thirdBoxTitle={t('checkout_summary:order_shippment')}
              thirdBoxContent={'checkout_summary:third_box_description'}
              thirdBoxIcon={ClinIconPathName.OrderBox}
            ></ClinThreeBoxes>
          )}
          {orders?.map((order: IOrder, index: number) => {
            const orderLinesCount = order.lines.filter(
              (l) => l.medicationFlag === 'Y'
            ).length
            return (
              <StyledCheckoutSummaryOrder key={`order-${order.orderNumber}`}>
                {!isAus && (
                  <ClinDividerHeading marginBottom={ClinTheme.space[5]}>
                    <ClinText
                      as="h5"
                      color={ClinTheme.colors.white}
                      textTransform="uppercase"
                    >
                      {t('checkout_summary:order_line_count', {
                        current: index + 1,
                        ordersCount: orders.length,
                        orderItemsCount: orderLinesCount,
                        item: t('glossary:item', {
                          count: orderLinesCount
                        })
                      })}
                    </ClinText>
                  </ClinDividerHeading>
                )}
                {/*<pre>{JSON.stringify(orderLevelHoldDetails, null, 2)}</pre>*/}

                {orderLevelHolds?.map((orderLevelHold) => (
                  <HoldAnnounce
                    key={`order-error-${orderLevelHold.title}`}
                    mode={orderLevelHold.announceMode}
                    title={orderLevelHold.title}
                    marginBottom={ClinTheme.space[5]}
                  >
                    {orderLevelHold.message}
                  </HoldAnnounce>
                ))}

                {!isAus && (
                  <ClinCard>
                    <ClinText as="h3" variant={TypographyVariant.H3}>
                      {t('checkout_summary:order_details')}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <Row>
                      <Col sm={12} md={3} lg={3}>
                        <ClinText
                          color={ClinTheme.colors.primary}
                          marginBottom={ClinTheme.space[2]}
                          fontWeight={ClinTheme.fontWeights.medium}
                        >
                          {t('checkout_summary:order_reference')}
                        </ClinText>
                        <StyledOrderLink to={`../order/${order.orderNumber}`}>
                          {order.orderNumber}
                        </StyledOrderLink>
                        <ClinSpacer height={ClinTheme.space[2]} />
                      </Col>
                      <Col sm={12} md={3} lg={3}>
                        <ClinText
                          color={ClinTheme.colors.primary}
                          marginBottom={ClinTheme.space[2]}
                          fontWeight={ClinTheme.fontWeights.medium}
                        >
                          {t('checkout_summary:date_submitted')}
                        </ClinText>
                        <ClinText
                          as="p"
                          variant={TypographyVariant.LargeParagraph}
                        >
                          {DateTime.fromISO(order.orderedDate).toLocaleString()}
                        </ClinText>
                        <ClinSpacer height={ClinTheme.space[2]} />
                      </Col>
                      <Col sm={12} md={3} lg={3}>
                        <ClinText
                          color={ClinTheme.colors.primary}
                          marginBottom={ClinTheme.space[2]}
                          fontWeight={ClinTheme.fontWeights.medium}
                        >
                          {t('checkout_summary:submitted_by')}
                        </ClinText>
                        <ClinText
                          as="p"
                          variant={TypographyVariant.LargeParagraph}
                        >
                          {order.shipToContact}
                        </ClinText>
                        <ClinSpacer height={ClinTheme.space[2]} />
                      </Col>
                      <Col sm={12} md={3} lg={3}>
                        <ClinText
                          color={ClinTheme.colors.primary}
                          marginBottom={ClinTheme.space[2]}
                          fontWeight={ClinTheme.fontWeights.medium}
                        >
                          {t('checkout_summary:order_status')}
                        </ClinText>
                        <ClinText
                          as="p"
                          variant={TypographyVariant.LargeParagraph}
                        >
                          {order.orderStatus}
                        </ClinText>
                        <ClinSpacer height={ClinTheme.space[2]} />
                      </Col>
                    </Row>
                  </ClinCard>
                )}
                <ClinSpacer height={ClinTheme.space[2]} />
                {!isAus ? (
                  <ClinCard style={{ marginBottom: '30px' }}>
                    <ClinText
                      marginTop="0px"
                      marginBottom="0px"
                      as="h3"
                      variant={TypographyVariant.H3}
                    >
                      {t('checkout_summary:your_order', {
                        orderItemsCount: orderLinesCount
                      })}
                    </ClinText>
                  </ClinCard>
                ) : (
                  <ClinCard style={{ marginBottom: '30px' }}>
                    <ClinText
                      marginTop="0px"
                      marginBottom="0px"
                      as="h3"
                      variant={TypographyVariant.H3}
                    >
                      {t('checkout_summary:items', {
                        orderItemsCount: orderLinesCount
                      })}
                    </ClinText>
                  </ClinCard>
                )}
                <ClinCard>
                  <StyledCheckoutTableSummaryHeader>
                    <Row>
                      <Col sm={12} md={6}>
                        <ClinText as="h5" variant={TypographyVariant.H5}>
                          {t('glossary:product')}
                        </ClinText>
                      </Col>
                      <Col sm={12} md={2}>
                        <ClinText
                          textAlign="right"
                          as="h5"
                          variant={TypographyVariant.H5}
                        >
                          {t('glossary:product_code')}
                        </ClinText>
                      </Col>
                      <Col sm={12} md={2}>
                        <ClinText
                          textAlign="right"
                          as="h5"
                          variant={TypographyVariant.H5}
                        >
                          {t('glossary:quantity')}
                        </ClinText>
                      </Col>
                      <Col sm={12} md={2}>
                        <ClinText
                          textAlign="right"
                          as="h5"
                          variant={TypographyVariant.H5}
                        >
                          {t('checkout_summary:subtotal')}
                        </ClinText>
                      </Col>
                    </Row>
                  </StyledCheckoutTableSummaryHeader>
                  {order.lines
                    .filter((ol) => ol.medicationFlag === 'Y')
                    .map((orderLine: OrderLineDto) => (
                      <CheckoutSummaryOrderItem
                        key={`order-line-${orderLine.itemId}`}
                        orderLine={getOrderLineWithDeliveryText(orderLine)}
                        cancelledItemIds={cancelledItemIds}
                        holdsInformation={getOrderHoldsInformation(
                          orderLine.holds
                        )}
                        orderLineHoldDocuments={getOrderLineHoldDocuments(
                          order.orderNumber,
                          orderLine,
                          uploadedDocuments
                        )}
                        handleCancelOrderLine={(lineId) =>
                          handleCancelOrderLine &&
                          handleCancelOrderLine(order.orderNumber, lineId)
                        }
                        currencyCode={order.currencyCode}
                      />
                    ))}
                </ClinCard>
                <Row style={{ marginTop: '85px' }}>
                  <Col sm={12} md={6}>
                    <ClinCard>
                      <ClinText as="h3" variant={TypographyVariant.H3}>
                        {t('checkout_summary:delivery_and_po_details')}
                      </ClinText>

                      <ClinText
                        color={
                          isAus
                            ? ClinTheme.colors.black
                            : ClinTheme.colors.primary
                        }
                        fontWeight={
                          isAus
                            ? ClinTheme.fontWeights.normal
                            : ClinTheme.fontWeights.medium
                        }
                        as="p"
                        variant={
                          isAus
                            ? TypographyVariant.LargeParagraph
                            : TypographyVariant.Paragraph
                        }
                      >
                        {t('checkout_summary:delivery_method')}
                      </ClinText>
                      <ClinText
                        as="p"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {!order.shipMethod
                          ? isAus
                            ? t('checkout_summary:default_shipping_text_aus')
                            : order.shipMethod
                          : order.shipMethod}
                      </ClinText>
                      <ClinSpacer />
                      <ClinText
                        color={
                          isAus
                            ? ClinTheme.colors.black
                            : ClinTheme.colors.primary
                        }
                        fontWeight={
                          isAus
                            ? ClinTheme.fontWeights.normal
                            : ClinTheme.fontWeights.medium
                        }
                        as="p"
                        variant={
                          isAus
                            ? TypographyVariant.LargeParagraph
                            : TypographyVariant.Paragraph
                        }
                      >
                        {t('checkout_summary:delivery_address')}
                      </ClinText>
                      <ClinText
                        variant={TypographyVariant.LargeParagraph}
                        wordBreak={'break-word'}
                      >
                        {order.deliverToContact && (
                          <>
                            {`c/o ${order.deliverToContact}`}
                            <br />
                          </>
                        )}
                        {order.deliverTo?.address1 && (
                          <>
                            {order.deliverTo?.address1}
                            <br />
                          </>
                        )}
                        {order.deliverTo?.address2 && (
                          <>
                            {order.deliverTo.address2}
                            <br />
                          </>
                        )}
                        {order.deliverTo?.address3 && (
                          <>
                            {order.deliverTo.address3}
                            <br />
                          </>
                        )}
                        {order.deliverTo?.address4 && (
                          <>
                            {order.deliverTo.address4}
                            <br />
                          </>
                        )}
                        {order.deliverTo?.city && (
                          <>
                            {order.deliverTo.city}
                            <br />
                          </>
                        )}
                        {order.deliverTo?.country && (
                          <>
                            {order.deliverTo.country}
                            <br />
                          </>
                        )}
                        {order.deliverTo?.postalCode && (
                          <>
                            {order.deliverTo.postalCode}
                            <br />
                          </>
                        )}
                        {order.deliverTo?.state && <>{order.deliverTo.state}</>}
                      </ClinText>
                      <ClinSpacer />
                      <ClinText
                        color={
                          isAus
                            ? ClinTheme.colors.black
                            : ClinTheme.colors.primary
                        }
                        fontWeight={
                          isAus
                            ? ClinTheme.fontWeights.normal
                            : ClinTheme.fontWeights.medium
                        }
                        as="p"
                        variant={
                          isAus
                            ? TypographyVariant.LargeParagraph
                            : TypographyVariant.Paragraph
                        }
                      >
                        {isAus
                          ? t('checkout_summary:po_number_aus')
                          : t('checkout_summary:your_po_number')}
                      </ClinText>
                      <ClinText
                        as="p"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {order.customerPoNumber}
                      </ClinText>
                    </ClinCard>
                  </Col>
                  <Col sm={12} md={6}>
                    <ClinCard>
                      <ClinText as="h3" variant={TypographyVariant.H3}>
                        {t('checkout_summary:order_summary')}
                      </ClinText>
                      {getAdditionalLineItems(order).map(
                        (additionalItem, idx) => (
                          <div
                            key={`order-line-item-other-${additionalItem.itemId}`}
                          >
                            <Row justify="between">
                              <Col width="auto">
                                <ClinText
                                  color={
                                    isAus
                                      ? ClinTheme.colors.black
                                      : ClinTheme.colors.primary
                                  }
                                  fontWeight={
                                    isAus
                                      ? ClinTheme.fontWeights.normal
                                      : ClinTheme.fontWeights.medium
                                  }
                                  as="p"
                                  variant={
                                    isAus
                                      ? TypographyVariant.LargeParagraph
                                      : TypographyVariant.Paragraph
                                  }
                                >
                                  {additionalItem.itemDescription}:
                                </ClinText>
                              </Col>
                              <Col width="auto" className="sub-total">
                                {numberToCurrencyString(
                                  additionalItem.totals.subTotal,
                                  getBrowserLocale(),
                                  {
                                    style: 'currency',
                                    currency: order.currencyCode
                                      ? order.currencyCode
                                      : 'GBP',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  }
                                )}
                              </Col>
                            </Row>
                            <ClinSpacer
                              height={ClinTheme.space[5]}
                              hasBorder={true}
                            />
                          </div>
                        )
                      )}
                      {!isAus ? (
                        <Row justify="between">
                          <Col width="auto">
                            <ClinText
                              color={ClinTheme.colors.primary}
                              fontWeight={ClinTheme.fontWeights.medium}
                              as="p"
                              variant={TypographyVariant.Paragraph}
                            >
                              {t('checkout_summary:vat')}
                            </ClinText>
                          </Col>
                          <Col width="auto" className="tax">
                            {numberToCurrencyString(
                              order.totals.tax,
                              getBrowserLocale(),
                              {
                                style: 'currency',
                                currency: order.currencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                      <ClinSpacer hasBorder={true} />
                      <Row justify="between">
                        <Col width="auto">
                          <ClinText
                            color={ClinTheme.colors.primary}
                            fontWeight={
                              isAus
                                ? ClinTheme.fontWeights.normal
                                : ClinTheme.fontWeights.medium
                            }
                            as="p"
                            variant={
                              isAus
                                ? TypographyVariant.LargeParagraph
                                : TypographyVariant.Paragraph
                            }
                          >
                            {t('checkout_summary:total')}
                          </ClinText>
                        </Col>
                        <Col width="auto" className="total">
                          {numberToCurrencyString(
                            order.totals.total,
                            getBrowserLocale(),
                            {
                              style: 'currency',
                              currency: order.currencyCode,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )}
                        </Col>
                      </Row>
                    </ClinCard>
                  </Col>
                </Row>
              </StyledCheckoutSummaryOrder>
            )
          })}
        </StyledCheckoutSummary>
      )}
    </ClinPageContentFrame>
  )
}
