import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'
import { mediaQueryDown } from '../../utils/mediaQuery'
import { ClinButton } from '../ClinButton'
const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledSubmit = styled.div({
  paddingTop: '28px'
})

export const StyledActions = styled.div({
  display: 'inline-flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',

  [mediaQuery(mobileUpBreakpoint)]: {
    flexDirection: 'row',
    flexWrap: 'nowrap',

    button: {
      marginBottom: 0,
      marginRight: ClinTheme.space[3]
    },

    'button:last-of-type': {
      marginBottom: 0
    }
  }
})
export const StyledSaveButton = styled(ClinButton)({
  backgroundColor: ClinTheme.colors.primaryLight,
  borderColor: ClinTheme.colors.primaryLight,
  marginRight: '14px'
})
